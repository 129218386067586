import type { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import Settings from './Settings/Settings';

export const UserPage = (): ReactElement => {
    return (
        <Routes>
            <Route path="*" element={<Settings />} />
        </Routes>
    );
};
