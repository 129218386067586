import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { ViewCardIcon, ViewListIcon } from '@xeris/components/icons';
import { settingsActions } from '@xeris/pages/product/reducers/settingsSlice';
import { useAppDispatch } from '@xeris/reducers';

type ListTypeProps = {
    listType: 'cards' | 'list';
};

export const ListType = ({ listType }: ListTypeProps): ReactElement => {
    const { t } = useTranslation('products');
    const dispatch = useAppDispatch();

    return (
        <ToggleButtonGroup
            size={'small'}
            sx={{ marginBlock: 1, flexShrink: 0 }}
            value={listType}
            exclusive
            onChange={(_, value) => {
                dispatch(settingsActions.setListType(value));
            }}
        >
            <ToggleButton
                color={'secondary'}
                value={'cards'}
                aria-label={t('brand.cardView')}
            >
                <ViewCardIcon fontSize={'small'} />
            </ToggleButton>
            <ToggleButton
                color={'secondary'}
                value={'list'}
                aria-label={t('brand.listView')}
            >
                <ViewListIcon fontSize={'small'} />
            </ToggleButton>
        </ToggleButtonGroup>
    );
};
