import { type ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { fileUploadApi } from '@xeris/api';
import { FileInput, FormDrawer, SelectInput } from '@xeris/components/forms';
import { PlusIcon } from '@xeris/components/icons';

import { importApi } from '../../api/importApi';

const importJobSchema = yup
    .object({
        importer: yup.string().required('required'),
        files: yup
            .array()
            .of(yup.mixed<File>().required('required'))
            .min(1)
            .required('required'),
    })
    .required('required');

type FormData = yup.InferType<typeof importJobSchema>;

export const CreateImportJob = (): ReactElement => {
    const { t } = useTranslation('administration');

    const [isOpen, setIsOpen] = useState(false);

    const [getSignedUploadUrl, { isLoading: isLoadingUrl }] =
        fileUploadApi.useGetSignedUploadUrlMutation();
    const [uploadFile, { isLoading: isLoadingUpload }] =
        fileUploadApi.useUploadFileMutation();
    const { data } = importApi.useGetImportsQuery({});

    const { control, handleSubmit, setValue, setError } = useForm<FormData>({
        resolver: yupResolver(importJobSchema),
        defaultValues: {
            files: [],
        },
    });

    useEffect(() => {
        setValue('importer', data?.importers[0]?.id ?? '');
    }, [setValue, data]);

    return (
        <>
            <Button
                variant={'contained'}
                onClick={() => setIsOpen(true)}
                startIcon={<PlusIcon />}
            >
                {t('imports.import')}
            </Button>
            <FormDrawer
                open={isOpen}
                isLoading={isLoadingUrl || isLoadingUpload}
                onClose={() => setIsOpen(false)}
                onSubmit={handleSubmit(async ({ importer, files }) => {
                    const file = files[0];

                    if (!file) return;

                    const fileExtension = file.name.split('.').pop();
                    const validExtension =
                        data?.importers
                            .find((i) => i.id === importer)
                            ?.accept.some((f) => f === `.${fileExtension}`) ??
                        false;

                    if (!validExtension) {
                        return setError('files', {
                            type: 'invalidFileType',
                        });
                    }

                    const formatUrl = file.name.split(' ').join('_');

                    const { url } = await getSignedUploadUrl({
                        uploadFormat: importer,
                        formatUrl: formatUrl,
                    }).unwrap();

                    await uploadFile({ url, file }).unwrap();

                    setIsOpen(false);
                })}
                title={t('imports.importFile')}
                cancelText={t('common.cancel')}
                saveText={t('imports.importFile')}
            >
                <FileInput
                    fieldName={'files'}
                    control={control}
                    label={t('imports.create.file')}
                    showError
                />
                <SelectInput
                    control={control}
                    fieldName={'importer'}
                    label={t('imports.create.importer')}
                    options={
                        data?.importers.map((importer) => ({
                            id: importer.id,
                            label: importer.name,
                            secondaryLabel: importer.accept.join(' '),
                        })) ?? []
                    }
                />
            </FormDrawer>
        </>
    );
};
