import { type ReactElement, type ReactNode } from 'react';

import { Box, type BoxProps } from '@mui/material';

type SelectGroupProps = BoxProps & {
    children?: ReactNode;
};

export const SelectGroup = ({
    children,
    sx,
    ...boxProps
}: SelectGroupProps): ReactElement => {
    return (
        <Box
            display={'flex'}
            sx={{
                '.MuiInputBase-root': {
                    borderRadius: '0',
                },
                '.MuiInputBase-root:first-of-type': {
                    borderRadius: '6px 0 0 6px',
                },
                '.MuiInputBase-root:last-of-type': {
                    borderRadius: '0 6px 6px 0',
                },
                '.MuiOutlinedInput-root:not(:last-of-type)>fieldset': {
                    borderRightColor: 'transparent',
                },
                '.MuiOutlinedInput-root:not(:last-of-type):hover>fieldset': {
                    borderRightColor: 'currentColor',
                },
                '.Mui-focused:not(:last-of-type)>fieldset': {
                    borderRightColor: 'primary.main',
                },
                '.Mui-focused:not(:last-of-type):hover>fieldset': {
                    borderRightColor: 'primary.main',
                },
                ...sx,
            }}
            {...boxProps}
        >
            {children}
        </Box>
    );
};
