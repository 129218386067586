import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { InlineDrawer, XerisSpinner } from '@xeris/components';
import { useDataLanguage } from '@xeris/hooks';
import { useAppSelector } from '@xeris/reducers';

import { variantGeneratorSelectors } from '../reducer';

import { useGetMasterProductForInfoBoxQuery } from './api/materialInfoBoxApi';
import MaterialInfoBoxContentOverlay from './MaterialInfoBoxContentOverlay';

import './index.scss';

const MaterialInfoBoxOverlay = (): ReactElement | null => {
    const { t } = useTranslation('product');
    const dataLanguage = useDataLanguage();

    const masterProductId = useAppSelector(
        variantGeneratorSelectors.selectMaterialInfoBoxMasterProductId
    );

    const { data, isLoading, isFetching, error, isError } =
        useGetMasterProductForInfoBoxQuery(
            { id: masterProductId ?? '', dataLanguage },
            { skip: !masterProductId }
        );

    return (
        <InlineDrawer
            show={Boolean(masterProductId)}
            disableScroll={isFetching}
        >
            {isLoading && (
                <div className="material-info-box-loader">
                    <XerisSpinner />
                </div>
            )}

            {isError && (
                <div className="material-info-box-error">
                    <div>{JSON.stringify(error)}</div>
                </div>
            )}

            {isFetching && <div className="material-info-box-blur" />}

            {!data?.masterProduct && !isLoading && (
                <div className="material-info-box-error">
                    {t('variantGenerator.noMasterProductFound', {
                        selectedMasterProductId: masterProductId,
                    })}
                </div>
            )}

            <MaterialInfoBoxContentOverlay
                masterProduct={data?.masterProduct}
            />
        </InlineDrawer>
    );
};

export default MaterialInfoBoxOverlay;
