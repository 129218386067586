import { type ReactElement } from 'react';

import { useDataLanguage } from '@xeris/hooks';

import { productHooks } from '../../hooks';
import ProductEntityList from '../ProductEntityList/ProductEntityList';

type ProductVariantsProps = {
    masterProductId: string;
};

const ProductVariants = ({
    masterProductId,
}: ProductVariantsProps): ReactElement => {
    const dataLanguage = useDataLanguage();

    const productListQueryResult =
        productHooks.api.queries.useGetAllProductsForMasterProduct({
            masterProductId,
            dataLanguage,
        });

    const isLoading =
        !productListQueryResult.data ||
        productListQueryResult.isFetching ||
        productListQueryResult.isLoading;

    return (
        <ProductEntityList
            isLoading={isLoading}
            minimalProductList={productListQueryResult.data}
        />
    );
};

export default ProductVariants;
