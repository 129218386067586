import { type ReactElement, useEffect, useState } from 'react';

import { TextField } from '@mui/material';

import {
    type Expression,
    type UnknownExpression,
} from '@xeris/pages/imports/types';
import { knownExpressions } from '@xeris/pages/imports/utilities/data';
import type { Path } from '@xeris/pages/imports/utilities/updateMapping';

import type { NewMapping } from './AttributeTypeField';

const isUnknownExpression = (
    expression: Expression | null
): expression is UnknownExpression =>
    !!expression && !knownExpressions.includes(expression.operator);

type AdvancedExpressionFieldProps = {
    expression: Expression | null;
    onChange: (expression: Expression) => void;
};

export const AdvancedExpressionField = ({
    onChange,
    expression,
}: AdvancedExpressionFieldProps): ReactElement | null => {
    const [value, setValue] = useState('');
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        setValue(JSON.stringify(expression, null, 2));
        setIsValid(true);
    }, [setValue, expression]);

    if (!isUnknownExpression(expression)) {
        return null;
    }

    return (
        <TextField
            sx={{ flexGrow: 1 }}
            value={value}
            error={!isValid}
            multiline
            onChange={(event) => setValue(event.target.value)}
            onBlur={() => {
                try {
                    const newValue = JSON.parse(value);

                    setIsValid(true);

                    if (value === JSON.stringify(expression)) return;

                    onChange(newValue);
                } catch (error) {
                    setIsValid(false);
                }
            }}
        />
    );
};

type AdvancedExpressionProps = {
    path: Path;
    expression: Expression | null;
    setNewMapping: (newMapping: NewMapping) => void;
};

export const AdvancedExpression = ({
    path,
    setNewMapping,
    expression,
}: AdvancedExpressionProps): ReactElement | null => {
    if (!isUnknownExpression(expression)) {
        return null;
    }

    return (
        <AdvancedExpressionField
            expression={expression}
            onChange={(newExpression) =>
                setNewMapping({
                    path: path,
                    expression: newExpression,
                })
            }
        />
    );
};
