import { type ReactElement, type ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, type ButtonProps, IconButton, Tooltip } from '@mui/material';

import { PlusIcon } from '@xeris/components/icons';
import { useIsMobile } from '@xeris/hooks';
import { useAppSelector } from '@xeris/reducers';

import { datasetSelectors } from '../../reducers';

import { CreateDatasetForm } from './CreateDatasetForm';

type CreateDatasetProps = ButtonProps & {
    icon?: ReactNode;
};

export const CreateDataset = ({
    icon,
    ...buttonProps
}: CreateDatasetProps): ReactElement => {
    const { t } = useTranslation('dataset');

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const datasetNames = useAppSelector(
        datasetSelectors.minimal.selectDatasetNameList
    );

    const isMobile = useIsMobile();

    return (
        <>
            {icon ? (
                <Tooltip
                    title={t('datasetList.createDataset')}
                    className={'menuElementHover'}
                    sx={{ opacity: isMobile ? 1 : 0 }}
                >
                    <IconButton
                        edge={'end'}
                        onClick={() => setIsDialogOpen(true)}
                    >
                        {icon}
                    </IconButton>
                </Tooltip>
            ) : (
                <Button
                    {...buttonProps}
                    onClick={() => setIsDialogOpen(true)}
                    startIcon={<PlusIcon fontSize="small" />}
                >
                    {t('datasetList.createDataset')}
                </Button>
            )}
            <CreateDatasetForm
                isOpen={isDialogOpen}
                handleClose={() => setIsDialogOpen(false)}
                datasetNames={datasetNames}
            />
        </>
    );
};
