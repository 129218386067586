import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Collapse,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';

import { type Batch } from '../types';

import { ImportError } from './ImportError';
import { ImportNumbers } from './ImportNumbers';

type ImportDetailsProps = {
    row: Batch;
    isOpen: boolean;
};

export const ImportDetails = ({
    row,
    isOpen,
}: ImportDetailsProps): ReactElement => {
    const { t } = useTranslation('administration');
    return (
        <>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={9}
                    sx={{ border: 0 }}
                >
                    <Collapse in={isOpen} unmountOnExit>
                        {row.status === 'PROCESSING' ? (
                            <>
                                <LinearProgress
                                    variant="determinate"
                                    value={
                                        row?.progress && row?.count
                                            ? (row.progress / row.count) * 100
                                            : 0
                                    }
                                />
                                <LinearProgress />
                            </>
                        ) : (
                            <></>
                        )}
                        <Table sx={{ marginBlock: 1 }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        sx={{ border: 0, paddingBlock: 0 }}
                                    >
                                        <Typography
                                            variant={'body1'}
                                            fontSize={'small'}
                                            paddingLeft={'60px'}
                                        >
                                            {t('imports.fileName')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{ border: 0, paddingBlock: 0 }}
                                    >
                                        <Typography>
                                            {row.filename ? (
                                                <a
                                                    href={
                                                        row.downloadLink || ''
                                                    }
                                                >
                                                    {row.filename}
                                                </a>
                                            ) : (
                                                '-'
                                            )}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell
                                        sx={{ border: 0, paddingTop: 0 }}
                                    >
                                        <Typography
                                            variant={'body1'}
                                            fontSize={'small'}
                                            paddingLeft={'60px'}
                                        >
                                            {t('imports.fullId')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{ border: 0, paddingTop: 0 }}
                                    >
                                        <Typography>{row.id}</Typography>
                                    </TableCell>
                                </TableRow>
                                {row.errors && Array.isArray(row.errors) ? (
                                    <ImportError
                                        error={row.errors.join(', ')}
                                    />
                                ) : (
                                    <ImportNumbers
                                        summary={row.summary}
                                        count={row.count}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
