import { type ReactElement } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as List } from 'react-window';

import { Box } from '@mui/material';

import { Typography } from '@xeris/components';
import { ChecboxMarkedCircleIcon } from '@xeris/components/icons';
import { useElementSize } from '@xeris/hooks';
import { useAppSelector } from '@xeris/reducers';

import { categorizationSelectors } from '../reducers/categorizationSelectors';

import ProductListItem, { ProductItem } from './ProductListItem';

const AllProductsIsCategorized = (): ReactElement => {
    const { t } = useTranslation('dataset');

    return (
        <Box
            sx={{
                paddingLeft: 2,
                paddingTop: 3,
                display: 'flex',
                gap: 1,
                alignItems: 'center',
            }}
        >
            <ChecboxMarkedCircleIcon fontSize={'large'} />
            <Typography variant={'h4'} component={'p'}>
                {t('categorization.nothingMoreToSort')}
            </Typography>
        </Box>
    );
};

const UncategorizedProducts = (): ReactElement => {
    const [ref, size] = useElementSize();

    const listElements = useAppSelector(categorizationSelectors.selectProducts);

    if (listElements.length === 0) {
        return <AllProductsIsCategorized />;
    }

    return (
        <Box
            sx={{
                padding: 1,
                height: '100%',
                maxHeight: '100%',
                overflow: 'hidden',
            }}
            ref={ref}
        >
            <Droppable
                droppableId={'unsorted'}
                mode={'virtual'}
                isDropDisabled
                renderClone={(provided, snapshot, rubric): ReactElement => (
                    <ProductItem
                        provided={provided}
                        isDragging={snapshot.isDragging}
                        listElement={listElements[rubric.source.index]}
                    />
                )}
            >
                {({ innerRef }): ReactElement => (
                    <List
                        height={Math.floor((size.height ?? 17) - 16)}
                        itemCount={listElements.length}
                        itemSize={40}
                        outerRef={innerRef}
                        itemData={listElements}
                        width={'100%'}
                    >
                        {ProductListItem}
                    </List>
                )}
            </Droppable>
        </Box>
    );
};

export default UncategorizedProducts;
