import { type ReactElement, type ReactNode } from 'react';

import { Box } from '@mui/material';

type MaterialSummaryProps = {
    children?: ReactNode;
};

export const MaterialSummary = ({
    children,
}: MaterialSummaryProps): ReactElement => {
    return <Box sx={{ marginBottom: 4, width: '100%' }}>{children}</Box>;
};
