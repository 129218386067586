import { createSelector } from '@reduxjs/toolkit';
import { JSFeature, JSOptionId } from 'js-feature-configuration';

import { getFeatureConfigurator } from '@xeris/pages/product/variantGenerator/utilities/getFeatureConfigurator';
import { type StateType } from '@xeris/types';

type Status = {
    isPreviewed: boolean;
    isSelected: boolean;
    isSelectable: boolean;
    isPreviewable: boolean;
    isVisible: boolean;
};

type Count = {
    selected: number;
    available: number;
};

const selectIsInitialized = createSelector(
    (state: StateType) => state.product.variantGenerator,
    (variantGenerator) => variantGenerator.isInitialized
);

const optionStatus = createSelector(
    (state: StateType) => state.product.variantGenerator.featureSelection,
    (state: StateType) => state.product.variantGenerator.previewSelection,
    (state: StateType) => state.product.variantGenerator.featureVisibility,
    (state: StateType) => state.product.variantGenerator.previewVisibility,
    (state: StateType, featureId: string) => featureId,
    (state: StateType, featureId: string, optionId: string) => optionId,
    (
        featureSelection,
        previewSelection,
        featureVisibility,
        previewVisibility,
        featureId,
        optionId
    ): Status => {
        const isSelected = !!featureSelection[featureId]?.[optionId];
        const isPreviewed = previewSelection[featureId] === optionId;
        const isSelectable = featureVisibility[featureId]?.includes(optionId);
        const isPreviewable = previewVisibility[featureId]?.includes(optionId);

        return {
            isSelected,
            isPreviewed,
            isSelectable,
            isPreviewable,
            isVisible: isPreviewable || isSelectable,
        };
    }
);

const optionStatuses = createSelector(
    (state: StateType) => state.product.variantGenerator.featureSelection,
    (state: StateType) => state.product.variantGenerator.previewSelection,
    (state: StateType) => state.product.variantGenerator.featureVisibility,
    (state: StateType) => state.product.variantGenerator.previewVisibility,
    (state: StateType, featureId: string) => featureId,
    (state: StateType, featureId: string, optionIds: string[]) => optionIds,
    (
        featureSelection,
        previewSelection,
        featureVisibility,
        previewVisibility,
        featureId,
        optionIds
    ): Status => {
        const isSelected = optionIds.some(
            (optionId) => !!featureSelection[featureId]?.[optionId]
        );
        const isPreviewed = optionIds.some(
            (optionId) => previewSelection[featureId] === optionId
        );
        const isSelectable = optionIds.some((optionId) =>
            featureVisibility[featureId]?.includes(optionId)
        );
        const isPreviewable = optionIds.some((optionId) =>
            previewVisibility[featureId]?.includes(optionId)
        );

        return {
            isSelected,
            isPreviewed,
            isSelectable,
            isPreviewable,
            isVisible: isPreviewable || isSelectable,
        };
    }
);

const featureStatus = createSelector(
    (state: StateType) => state.product.variantGenerator.featureSelection,
    (state: StateType) => state.product.variantGenerator.previewSelection,
    (state: StateType) => state.product.variantGenerator.featureVisibility,
    (state: StateType) => state.product.variantGenerator.previewVisibility,
    (state: StateType, featureId: string) => featureId,
    (
        featureSelection,
        previewSelection,
        featureVisibility,
        previewVisibility,
        featureId
    ): Status => {
        const isSelected = !!featureSelection[featureId];
        const isPreviewed = !!previewSelection[featureId];
        const isSelectable = !!featureVisibility[featureId];
        const isPreviewable = !!previewVisibility[featureId];

        return {
            isSelected,
            isPreviewed,
            isSelectable,
            isPreviewable,
            isVisible: isPreviewable || isSelectable,
        };
    }
);

const featureStatuses = createSelector(
    (state: StateType) => state.product.variantGenerator.featureSelection,
    (state: StateType) => state.product.variantGenerator.previewSelection,
    (state: StateType) => state.product.variantGenerator.featureVisibility,
    (state: StateType) => state.product.variantGenerator.previewVisibility,
    (state: StateType, featureIds: string[]) => featureIds,
    (
        featureSelection,
        previewSelection,
        featureVisibility,
        previewVisibility,
        featureIds
    ): Status => {
        const isSelected = featureIds.some(
            (featureId) => !!featureSelection[featureId]
        );
        const isPreviewed = featureIds.some(
            (featureId) => previewSelection[featureId]
        );
        const isSelectable = featureIds.some(
            (featureId) => !!featureVisibility[featureId]
        );
        const isPreviewable = featureIds.some(
            (featureId) => !!previewVisibility[featureId]
        );

        return {
            isSelected,
            isPreviewed,
            isSelectable,
            isPreviewable,
            isVisible: isPreviewable || isSelectable,
        };
    }
);

const selectedOptionCount = createSelector(
    (state: StateType) => state.product.variantGenerator.featureSelection,
    (state: StateType) => state.product.variantGenerator.featureVisibility,
    (state: StateType, featureId: string) => featureId,
    (
        featureSelection,
        featureVisibility,
        featureId
    ): { selected: number; available: number } => {
        return {
            selected: Object.keys(featureSelection[featureId] ?? {}).length,
            available: featureVisibility[featureId]?.length ?? 0,
        };
    }
);

const selectedOptionCountByIds = createSelector(
    (state: StateType) => state.product.variantGenerator.featureSelection,
    (state: StateType) => state.product.variantGenerator.featureVisibility,
    (state: StateType, featureId: string) => featureId,
    (state: StateType, featureId: string, optionIds: string[]) => optionIds,
    (featureSelection, featureVisibility, featureId, optionIds): Count => {
        return {
            selected: Object.keys(featureSelection[featureId] ?? {}).filter(
                (optionId) => optionIds.includes(optionId)
            ).length,
            available:
                featureVisibility[featureId]?.filter((optionId) =>
                    optionIds.includes(optionId)
                ).length ?? 0,
        };
    }
);

const selectedOptionsByFeatureCount = createSelector(
    (state: StateType) => state.product.variantGenerator.featureSelection,
    (state: StateType) => state.product.variantGenerator.featureVisibility,
    (state: StateType, featureIds: string[]) => featureIds,
    (
        featureSelection,
        featureVisibility,
        featureIds
    ): { selected: number; available: number } => {
        return {
            selected: featureIds
                .map(
                    (featureId) =>
                        Object.keys(featureSelection[featureId] ?? {}).length
                )
                .reduce((sum, value) => sum + value, 0),
            available: featureIds
                .map((featureId) => featureVisibility[featureId]?.length ?? 0)
                .reduce((sum, value) => sum + value, 0),
        };
    }
);

const selectMaterialInfoBoxMasterProductId = createSelector(
    (state: StateType) => state.product.variantGenerator,
    (variantGenerator) => variantGenerator.materialInfoBoxMasterProductId
);

const selectPrices = createSelector(
    (state: StateType) => state.product.variantGenerator.featureConfiguration,
    (state: StateType) => state.product.variantGenerator.previewSelection,
    (
        featureConfiguration,
        previewSelection
    ): { value: number; currency: string } | null => {
        if (!featureConfiguration) return null;

        const currentSelections = Object.entries(previewSelection)
            .filter(
                (feature): feature is [string, string] => feature[1] !== null
            )
            .map(([key, value]) => new JSOptionId(key, value));

        const price =
            getFeatureConfigurator(featureConfiguration).price(
                currentSelections
            );

        if (!price) return null;

        return {
            value: price.price,
            currency: price?.currency,
        };
    }
);

const selectVariantCount = createSelector(
    (state: StateType) => state.product.variantGenerator.featureConfiguration,
    (state: StateType) => state.product.variantGenerator.featureSelection,
    (featureConfiguration, featureSelection) => {
        if (!featureConfiguration) return 0;

        const currentSelections = Object.entries(featureSelection).map(
            ([key, value]) => new JSFeature(key, Object.keys(value))
        );

        // Backend treats no selection as everything being selected. We don't want that here, so we return 0
        if (currentSelections.length === 0) {
            return 0;
        }

        return getFeatureConfigurator(featureConfiguration).count(
            currentSelections
        ) as number;
    }
);

const selectFeatureSelection = createSelector(
    (state: StateType) => state.product.variantGenerator,
    (variantGenerator) => variantGenerator.featureSelection
);

const selectPreviewSelection = createSelector(
    (state: StateType) => state.product.variantGenerator,
    (variantGenerator) => variantGenerator.previewSelection
);

const selectMissingFeatures = createSelector(
    (state: StateType) => state.product.variantGenerator.features,
    (state: StateType) => state.product.variantGenerator.featureVisibility,
    (state: StateType) => state.product.variantGenerator.featureSelection,
    (features, featureVisibility, featureSelection) =>
        features.filter(
            (feature) =>
                !featureSelection[feature.id] && featureVisibility[feature.id]
        )
);

const selectFilteredOptions = createSelector(
    (state: StateType) => state.product.variantGenerator.features,
    (state: StateType) => state.product.variantGenerator.featureFilters,
    (state: StateType, featureId: string) => featureId,
    (features, featureFilters, featureId): string[] =>
        features
            .find(({ id }) => featureId === id)
            ?.optionIds.filter(
                ({ name }) =>
                    !featureFilters[featureId] ||
                    name
                        .toLowerCase()
                        .includes(featureFilters[featureId].toLowerCase())
            )
            .map(({ id }) => id) ?? []
);

export const variantGeneratorSelectors = {
    selectIsInitialized,
    optionStatus,
    optionStatuses,
    featureStatus,
    featureStatuses,
    selectedOptionCount,
    selectedOptionsByFeatureCount,
    selectMaterialInfoBoxMasterProductId,
    selectPrices,
    selectVariantCount,
    selectedOptionCountByIds,
    selectFeatureSelection,
    selectMissingFeatures,
    selectPreviewSelection,
    selectFilteredOptions,
};
