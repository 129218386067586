import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Toolbar, Typography } from '@mui/material';

import { CenteredPage, SearchBar } from '@xeris/components';
import { PlusIcon } from '@xeris/components/icons';
import {
    settingsActions,
    settingsSelectors,
} from '@xeris/pages/product/reducers/settingsSlice';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { CreateExportButton } from '../components/CreateExportButton';

import ExportJobListData from './components/ExportJobListData';

const Header = (): ReactElement => {
    const { t } = useTranslation('exports');
    const dispatch = useAppDispatch();

    const exportJobSearchTerm = useAppSelector(
        settingsSelectors.selectExportJobSearchTerm
    );

    const handleSetSearchTerm = (value: string): void => {
        dispatch(settingsActions.setExportJobSearchTerm(value));
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant={'h1'} gutterBottom>
                {t('common.exports')}
            </Typography>

            <Toolbar disableGutters>
                <Box sx={{ maxWidth: '550px' }}>
                    <SearchBar
                        filter={exportJobSearchTerm}
                        onChange={handleSetSearchTerm}
                        inputLabel={t('common.search')}
                    />
                </Box>
                <CreateExportButton startIcon={<PlusIcon fontSize="small" />} />
            </Toolbar>
        </Box>
    );
};

const ExportJobList = (): ReactElement => {
    return (
        <CenteredPage>
            <Header />
            <ExportJobListData />
        </CenteredPage>
    );
};

export default ExportJobList;
