import { type ReactElement } from 'react';

import { useSelectedCounts } from '@xeris/pages/dataset/hooks';
import { type MasterProductWithIds } from '@xeris/pages/product/Common';
import ProductAndVariantsCounter from '@xeris/pages/product/Common/MultipleMasterProductsSelector/ProductAndVariantsCounter';

type SectionCountsProps = {
    products: MasterProductWithIds[];
};

export const SectionCounts = ({
    products,
}: SectionCountsProps): ReactElement => {
    const counts = useSelectedCounts(products);

    return <ProductAndVariantsCounter {...counts} />;
};
