import { type ReactElement, type ReactNode, useState } from 'react';

import { Box } from '@mui/material';

import {
    MasterProductSearchCard,
    ProductSearchCard,
} from '@xeris/components/ProductSearchCard/ProductSearchCard';
import { useInfiniteScroll } from '@xeris/hooks';
import { DatasetStatusTag } from '@xeris/pages/dataset/common';
import { ActionButtons } from '@xeris/pages/product/Common/ProductEntityCard/ActionButtons/ActionButtons';
import { productHooks } from '@xeris/pages/product/hooks/productHooks';
import { type SearchMatchGroupType } from '@xeris/pages/product/types/search';

type ProductCardProps = {
    matches: SearchMatchGroupType[];
    productAction?: (productId: string) => ReactNode;
    lastExported?: string | null;
    masterProductAction?: (
        masterProductId: string,
        productIds: string[]
    ) => ReactNode;
};
const ProductCardBatch = ({
    matches,
    productAction,
    masterProductAction,
    lastExported,
}: ProductCardProps): ReactElement => {
    const [isVisible, setIsVisible] = useState(false);

    const loaderRef = useInfiniteScroll(() => setIsVisible(true), {
        initialized: true,
        canLoadMore: true,
    });

    const { data } = productHooks.api.queries.useSearchProductEntityData(
        {
            masterProductIds: matches.map((match) => match.masterProductId),
            productIds: matches.flatMap((match) => match.productIds),
        },
        { skip: !isVisible }
    );

    const masterProducts = Object.fromEntries(
        data?.masterProductList
            .filter((masterProduct) => !!masterProduct)
            .map((masterProduct) => [masterProduct.id, masterProduct]) ?? []
    );

    const products = Object.fromEntries(
        data?.productList
            .filter((masterProduct) => !!masterProduct)
            .map((product) => [product.id, product]) ?? []
    );

    const formattedData = matches.map((match) => {
        const masterProductData = masterProducts[match.masterProductId];

        if (masterProductData) {
            return {
                ...masterProductData,
                variantCount: match.productIds.length,
                products: match.productIds.map((productId) =>
                    products[productId]
                        ? {
                              ...products[productId],
                              masterProduct: {
                                  id: masterProductData.id,
                                  name: masterProductData.name,
                              },
                          }
                        : {
                              __typename: 'Product' as const,
                              id: productId,
                              name: '',
                              images: undefined,
                              lastUpdated: undefined,
                              brand: {
                                  id: '',
                                  name: '',
                              },
                              masterProduct: {
                                  __typename: 'MasterProduct' as const,
                                  id: match.masterProductId,
                                  name: '',
                              },
                          }
                ),
                isLoading: false,
            };
        }

        // Data not loaded yet
        return {
            __typename: 'MasterProduct' as const,
            id: match.masterProductId,
            name: '',
            brand: {
                id: '',
                name: '',
            },
            products: match.productIds.map((productId) => ({
                __typename: 'Product' as const,
                id: productId,
                name: '',
                images: undefined,
                lastUpdated: undefined,
                brand: {
                    id: '',
                    name: '',
                },
                masterProduct: {
                    id: match.masterProductId,
                    name: '',
                },
            })),
            images: undefined,
            variantCount: undefined,
            lastUpdated: undefined,
            isConfigurable: match.isConfigurable,
            isLoading: true,
            productIdsList: [],
        };
    });

    return (
        <Box
            ref={loaderRef}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
            {formattedData.map((masterProduct) => (
                <MasterProductSearchCard
                    key={masterProduct.id}
                    href={
                        masterProduct.isLoading
                            ? undefined
                            : `/Products/${masterProduct.brand.id}/MasterProducts/${masterProduct.id}`
                    }
                    brandName={masterProduct.brand.name}
                    productName={masterProduct.name}
                    variantCount={masterProduct.variantCount}
                    lastUpdated={masterProduct.lastUpdated}
                    isConfigurable={masterProduct.isConfigurable}
                    image={masterProduct.images?.[0]}
                    isLoading={masterProduct.isLoading}
                    Status={
                        masterProduct.lastUpdated &&
                        lastExported && (
                            <DatasetStatusTag
                                dataset={{
                                    lastExported: lastExported ?? null,
                                    lastProductUpdate:
                                        masterProduct.lastUpdated ?? null,
                                }}
                            />
                        )
                    }
                    Actions={
                        masterProductAction ? (
                            masterProductAction(
                                masterProduct.id,
                                masterProduct.products.map(
                                    (product) => product.id
                                )
                            )
                        ) : (
                            <ActionButtons masterProduct={masterProduct} />
                        )
                    }
                >
                    {masterProduct.products.map((product) => (
                        <ProductSearchCard
                            key={product.id}
                            productName={product.name}
                            image={product.images?.[0]}
                            lastUpdated={product.lastUpdated}
                            isLoading={masterProduct.isLoading}
                            href={
                                masterProduct.isLoading
                                    ? undefined
                                    : `/Products/${masterProduct.brand.id}/MasterProducts/${masterProduct.id}/Product/${product.id}`
                            }
                            Status={
                                product.lastUpdated &&
                                lastExported && (
                                    <DatasetStatusTag
                                        dataset={{
                                            lastExported: lastExported ?? null,
                                            lastProductUpdate:
                                                product.lastUpdated ?? null,
                                        }}
                                    />
                                )
                            }
                            Actions={
                                productAction ? (
                                    productAction(product.id)
                                ) : (
                                    <ActionButtons product={product} />
                                )
                            }
                        />
                    ))}
                </MasterProductSearchCard>
            ))}
        </Box>
    );
};

export default ProductCardBatch;
