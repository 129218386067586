import type { CSSProperties } from 'react';

import { alpha, createTheme, getContrastRatio, lighten } from '@mui/material';
import { amber, blue, deepOrange, lightBlue } from '@mui/material/colors';

import '@mui/x-data-grid/themeAugmentation';

/*
 * The theme is set up using MUIs web tool. Copy the content of the
 * createTheme in the page to preview.
 * MUI Theme editing tool: https://zenoo.github.io/mui-theme-creator/
 *
 * We should be able to provide more options using vars (for setting up
 * custom colors etc.). MUI have a page with some TypeScript considerations:
 * https://mui.com/material-ui/customization/theming/#custom-variables
 */

// declare modules
declare module '@mui/material/styles' {
    interface TypographyVariants {
        label: CSSProperties;
        id: CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        label?: CSSProperties;
        id?: CSSProperties;
    }
    interface ButtonVariants {
        outlinedSolid?: CSSProperties;
        outlinedPartial?: CSSProperties;
    }

    interface Palette {
        icons: Palette['primary'];
        pending: Palette['primary'];
        white: Palette['primary'];
    }

    interface PaletteOptions {
        icons: PaletteOptions['primary'];
        pending: PaletteOptions['primary'];
        white: PaletteOptions['primary'];
    }
    interface PaletteColor {
        soft: string;
    }

    interface SimplePaletteColorOptions {
        soft: string;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        label: true;
        id: true;
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        icons: true;
        pending: true;
        white: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        icons: true;
        pending: true;
        white: true;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        icons: true;
        pending: true;
    }
}

declare module '@mui/lab/TimelineDot' {
    interface TimelineDotPropsColorOverrides {
        icons: true;
        pending: true;
    }
}

declare module '@mui/material/Badge' {
    interface BadgePropsColorOverrides {
        icons: true;
        pending: true;
        white: true;
    }
}
declare module '@mui/material/Avatar' {
    interface AvatarPropsVariantOverrides {
        roundedOutline: true;
    }
}
declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        outlinedSolid: true;
        outlinedPartial: true;
    }
}

const xerisThemeBase = createTheme({
    palette: {
        icons: {
            main: '#798394',
            soft: '#5D677A',
        },
        pending: {
            main: amber[800],
            light: amber[400],
            dark: deepOrange[900],
            contrastText:
                getContrastRatio(amber[800], '#fff') > 4.5 ? '#fff' : '#111',
            soft: amber[100],
        },
        primary: {
            main: '#009688',
            soft: '#E5F4F3',
        },
        secondary: {
            main: '#1D2C48',
            dark: '#0F1624',
            soft: '#F5F7FA',
        },
        info: {
            main: blue[500],
            soft: lightBlue[100],
        },
        warning: {
            main: deepOrange[500],
            soft: deepOrange[100],
        },
        error: {
            main: '#B00020',
            soft: '#FFE5EA',
        },
        white: {
            main: '#FFFFFF',
            soft: '#FFFFFF',
        },
        text: {
            primary: '#0F1624',
            secondary: '#3B475E',
            disabled: '#B3BAC7',
        },
        common: { black: '#1D2C48' },
        divider: '#d5d9e0',
        background: {
            default: '#FAFBFC',
            paper: '#FFFFFF',
        },
        grey: {
            50: '#FAFBFC',
            100: '#F5F7FA',
            200: '#E4E7ED',
            300: '#D5D9E0',
            400: '#B3BAC7',
            500: '#798394',
            600: '#5D677A',
            700: '#3B475E',
            800: '#1D2C48',
            900: '#0F1624',
            A100: '#F5F7FA',
            A200: '#E4E7ED',
            A400: '#B3BAC7',
            A700: '#3B475E',
        },
        action: {
            active: 'rgba( 30, 44, 72, 0.54 )',
            activatedOpacity: 0.12,
            disabled: 'rgba( 30, 44, 72, 0.26 )',
            disabledOpacity: 0.38,
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
            focus: 'rgba( 30, 44, 72, 0.12 )',
            focusOpacity: 0.12,
            hover: 'rgba( 30, 44, 72, 0.04 )',
            hoverOpacity: 0.04,
            selected: 'rgba( 30, 44, 72, 0.08 )',
            selectedOpacity: 0.08,
        },
        tonalOffset: 0.3,
    },
    typography: (palette) => ({
        htmlFontSize: 16,
        fontSize: 14,
        fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        h1: {
            fontSize: '1.5rem' /* 24px */,
            lineHeight: 1.0833333333 /* 26px */,
            fontWeight: 500,
            letterSpacing: -1.02,
            color: palette.text.primary,
        },
        h2: {
            fontSize: '1.375rem' /* 22px */,
            lineHeight: 1.0909090909 /* 24px */,
            fontWeight: 500,
            letterSpacing: -1.02,
            color: palette.text.primary,
        },
        h3: {
            fontSize: '1.25rem' /* 20px */,
            lineHeight: 1.2 /* 24px */,
            fontWeight: 500,
            letterSpacing: -1.02,
            color: palette.text.primary,
        },
        h4: {
            fontSize: '1.125rem' /* 18px */,
            lineHeight: 1.3333333333 /* 24px */,
            fontWeight: 500,
            letterSpacing: -1.01,
            color: palette.text.primary,
        },
        h5: {
            fontSize: '1.0625rem' /* 17px */,
            lineHeight: 1.4117647059 /* 24px */,
            fontWeight: 500,
            letterSpacing: 0,
            color: palette.text.primary,
        },
        h6: {
            fontSize: '1rem' /* 16px */,
            lineHeight: 1.5 /* 24px */,
            fontWeight: 400,
            letterSpacing: 0,
            color: palette.text.primary,
        },
        subtitle1: {
            fontSize: '1rem' /* 16px */,
            fontWeight: 500,
            letterSpacing: 0,
            color: palette.text.primary,
        },
        subtitle2: {
            fontSize: '0.9375rem' /* 15px */,
            lineHeight: 1.6 /* 24px */,
            fontWeight: 400,
            letterSpacing: 0,
            color: palette.text.secondary,
        },
        body1: {
            fontSize: '0.875rem' /* 14px */,
            lineHeight: 1.2857142857 /* 18px */,
            fontWeight: 400,
            letterSpacing: 0,
            color: palette.text.primary,
        },
        body2: {
            fontSize: '0.875rem' /* 14px */,
            fontWeight: 400,
            letterSpacing: 0,
            color: palette.text.secondary,
        },
        button: {
            fontSize: '0.875rem' /* 14px */,
            lineHeight: '22px',
            fontWeight: 500,
            letterSpacing: 0,
            textTransform: 'none',
            color: palette.text.primary,
            whiteSpace: 'nowrap',
            flexShrink: 0,
        },
        overline: {
            fontSize: '0.75rem' /* 12px */,
            lineHeight: 2 /* 24px */,
            fontWeight: 500,
            letterSpacing: 0,
            textTransform: 'none',
            color: palette.text.primary,
        },
        caption: {
            fontSize: '0.8125rem' /* 13px */,
            lineHeight: 1.3846153846 /* 18px */,
            fontWeight: 400,
            textTransform: 'none',
            letterSpacing: 0,
            color: palette.text.secondary,
        },
        label: {
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: 1.4,
            letterSpacing: 0,
            color: palette.text.primary,
        },
    }),
    shape: {
        borderRadius: 6,
    },
});

export const defaultTheme = createTheme(
    {
        components: {
            MuiAlertTitle: {
                styleOverrides: {
                    root: { marginTop: '2px' },
                },
            },
            MuiAvatar: {
                variants: [
                    {
                        props: { variant: 'roundedOutline' },
                        style: {
                            borderRadius: 6,
                            backgroundColor:
                                xerisThemeBase.palette.background.paper,
                            border: '1px solid',
                            borderColor: xerisThemeBase.palette.divider,
                            svg: { color: xerisThemeBase.palette.icons.main },
                        },
                    },
                ],
            },
            MuiCardHeader: {
                styleOverrides: { avatar: { alignSelf: 'flex-start' } },
            },
            MuiChip: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => {
                        if (
                            !ownerState.color ||
                            ownerState.color === 'default'
                        ) {
                            return {};
                        }

                        return {
                            'backgroundColor':
                                theme.palette[ownerState.color].soft,
                            'color': theme.palette[ownerState.color].dark,
                            '& .MuiSvgIcon-root': {
                                color: theme.palette[ownerState.color].main,
                            },
                        };
                    },
                },
            },
            MuiDialogTitle: {
                defaultProps: {
                    variant: 'h2',
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    spacing: {
                        padding: xerisThemeBase.spacing(2, 3),
                    },
                },
            },
            MuiDrawer: {
                defaultProps: {
                    anchor: 'right',
                },
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                },
                styleOverrides: {
                    root: {
                        '& .MuiButton-outlined': {
                            backgroundColor:
                                xerisThemeBase.palette.common.white,
                        },
                    },
                },
                variants: [
                    {
                        props: { variant: 'outlinedPartial', disabled: false },
                        style: {
                            'color': xerisThemeBase.palette.primary.main,
                            'backgroundColor':
                                xerisThemeBase.palette.primary.soft,
                            'border': '1px solid',
                            'borderColor': xerisThemeBase.palette.primary.main,
                            '&:hover': {
                                color: xerisThemeBase.palette.primary.dark,
                                border: '1px solid',
                                borderColor:
                                    xerisThemeBase.palette.primary.dark,
                                backgroundColor: lighten(
                                    xerisThemeBase.palette.primary.main,
                                    0.8
                                ),
                            },
                        },
                    },
                    {
                        props: { variant: 'outlinedPartial', disabled: true },
                        style: {
                            color: xerisThemeBase.palette.action.disabled,
                            backgroundColor:
                                xerisThemeBase.palette.action
                                    .disabledBackground,
                            border: '1px solid',
                            borderColor:
                                xerisThemeBase.palette.action
                                    .disabledBackground,
                        },
                    },
                    {
                        props: { variant: 'outlinedSolid' },
                        style: {
                            'color': xerisThemeBase.palette.primary.main,
                            'backgroundColor':
                                xerisThemeBase.palette.common.white,
                            'border': '1px solid',
                            'borderColor': xerisThemeBase.palette.divider,
                            '&:hover': {
                                color: xerisThemeBase.palette.primary.dark,
                                border: '1px solid',
                                borderColor:
                                    xerisThemeBase.palette.primary.dark,
                                backgroundColor: lighten(
                                    xerisThemeBase.palette.primary.main,
                                    0.9
                                ),
                            },
                        },
                    },
                ],
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            backgroundColor: alpha(
                                xerisThemeBase.palette.secondary.main,
                                0.04
                            ),
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor: alpha(
                                xerisThemeBase.palette.secondary.main,
                                0.06
                            ),
                        },
                        '&.Mui-selected:active': {
                            backgroundColor: alpha(
                                xerisThemeBase.palette.secondary.main,
                                0.04
                            ),
                        },
                    },
                },
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        minWidth: '36px',
                    },
                },
            },
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        'border': 0,

                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        /* Because MUI-X gives the divider color 12% opacity for some reason. Remove if they change system for defining borderColor. */
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeader:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-withBorderColor': {
                            borderColor: xerisThemeBase.palette.divider,
                            outline: 'none',
                        },
                        '.MuiDataGrid-columnSeparator': {
                            display: 'none',
                        },
                    },
                    row: {
                        '&:hover': {
                            backgroundColor: xerisThemeBase.palette.grey[100],
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: { borderColor: xerisThemeBase.palette.divider },
                },
            },
            MuiMenu: {
                defaultProps: {
                    elevation: 4,
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: xerisThemeBase.palette.divider,
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            color: xerisThemeBase.palette.primary.dark,
                        },
                    },
                },
            },
            MuiStack: {
                defaultProps: {
                    direction: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                },
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        alignItems: 'flex-end',
                        backgroundColor:
                            xerisThemeBase.palette.background.paper,
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        'marginRight': xerisThemeBase.spacing(2),
                        'paddingInline': xerisThemeBase.spacing(0),
                        'paddingBlock': xerisThemeBase.spacing(2),
                        'minWidth': 0,
                        'minHeight': 4,
                        'maxHeight': '48px',
                        'fontWeight':
                            xerisThemeBase.typography.fontWeightMedium,
                        '&.MuiTab-labelIcon': {
                            minHeight: '48px',
                        },
                        '&:hover': {
                            color: xerisThemeBase.palette.primary.main,
                        },
                        '&.Mui-selected': {
                            color: xerisThemeBase.palette.primary.dark,
                            fontWeight:
                                xerisThemeBase.typography.fontWeightBold,
                        },
                    },
                },
            },
            MuiToolbar: {
                defaultProps: {
                    disableGutters: true,
                    variant: 'dense',
                },
                styleOverrides: {
                    root: {
                        justifyContent: 'space-between',
                    },
                },
            },
            MuiTypography: {
                defaultProps: {
                    variantMapping: {
                        label: 'p',
                    },
                },
                styleOverrides: {
                    gutterBottom: {
                        marginBottom: '0.75em',
                    },
                },
            },
            MuiTooltip: {
                defaultProps: {
                    enterDelay: 500,
                },
            },
        },
    },
    xerisThemeBase
);

/* The beginnings of darkmode */
/*
const darkmode = {
    primary: {
        main: '#009688',
        dark: '#00786d',
    },
    secondary: {
        main: '#5D677A',
    },
    divider: '#5d677a',
    warning: {
        main: '#FF5722',
    },
    background: {
        paper: '#1D2C48',
        default: '#172136',
    },
    text: {
        primary: '#ffffff',
        secondary: '#D5D9E0',
        disabled: '#798394',
    },
};
*/
