import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { storeLocalState } from '@xeris/utilities';

import { type DatasetTypes } from '../types';
import { type ActiveDatasetType } from '../types/datasetTypes';

import { ACTIVE_DATASET_ID } from './activeDatasetId';
import { datasetsAdapter } from './datasetAdapter';
import {
    datasetProductsExtraReducers,
    datasetsExtraReducers,
    sharedDatasetExtraReducers,
} from './extraReducers';
import { setActiveDataset } from './setActiveDataset';

const initialState: DatasetTypes.DatasetSliceType = {
    activeId: undefined,
    errorMessage: undefined,
    datasets: datasetsAdapter.getInitialState(),
    activeDataset: undefined,
};

export const datasetSlice = createSlice({
    name: 'dataset',
    initialState,
    reducers: {
        setActiveId(state, action: PayloadAction<{ activeId?: string }>) {
            state.activeId = storeLocalState(
                ACTIVE_DATASET_ID,
                action.payload
            ).activeId;
        },
        changeActiveDataset(state, action: PayloadAction<string | null>) {
            setActiveDataset(state, action.payload ?? undefined);
        },
        setActiveDataset(
            state,
            action: PayloadAction<ActiveDatasetType | undefined>
        ) {
            if (action.payload) {
                state.activeDataset = action.payload;
            } else {
                state.activeDataset = undefined;
            }
        },
        resetDatasetState() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        datasetProductsExtraReducers(builder);
        datasetsExtraReducers(builder);
        sharedDatasetExtraReducers(builder);
    },
});

export const datasetActions = datasetSlice.actions;

export const datasetReducer = datasetSlice.reducer;
