import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip, type ChipProps, CircularProgress } from '@mui/material';

import { CheckIcon, CircleIcon, CloseIcon, HistoryIcon } from '../icons';

export type StatusTagTypesType =
    | 'processing'
    | 'success'
    | 'error'
    | 'indeterminate'
    | 'neutral'
    | 'pending';

type StatusLookupType = Record<
    StatusTagTypesType,
    {
        icon?: ReactElement;
        translationKey:
            | 'processing'
            | 'success'
            | 'error'
            | 'indeterminate'
            | 'neutral'
            | 'pending';
        color: ChipProps['color'];
    }
>;

export type StatusTagProps = {
    type?: StatusTagTypesType;
    title?: string;
    icon?: ReactElement;
    hideIcon?: boolean;
};

const statusLookup: StatusLookupType = {
    processing: {
        icon: <CircularProgress size={18} />,
        translationKey: 'processing',
        color: 'info',
    },
    success: {
        icon: <CheckIcon fontSize="small" />,
        translationKey: 'success',
        color: 'primary',
    },
    error: {
        icon: <CloseIcon fontSize="small" />,
        translationKey: 'error',
        color: 'error',
    },
    indeterminate: {
        icon: <CircleIcon fontSize="small" />,
        translationKey: 'indeterminate',
        color: 'warning',
    },
    pending: {
        icon: <HistoryIcon fontSize="small" />,
        translationKey: 'pending',
        color: 'pending',
    },
    neutral: {
        icon: <CircleIcon fontSize="small" />,
        translationKey: 'neutral',
        color: 'default',
    },
} as const;

export const StatusTag = ({
    type,
    title,
    icon,
    hideIcon = false,
}: StatusTagProps): ReactElement | null => {
    const { t } = useTranslation('common');

    if (!type) {
        return null;
    }

    const status = statusLookup[type];

    return (
        <Chip
            size={'small'}
            icon={hideIcon ? undefined : icon ?? status.icon}
            color={status.color}
            label={title ?? t(`components.statusTag.${status.translationKey}`)}
        />
    );
};
