import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

export const BackToLoginButton = (): ReactElement => {
    const { t } = useTranslation('login');

    return (
        <Button component={Link} to="/login">
            {t('common.backToLogin')}
        </Button>
    );
};
