import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@mui/material';

import { SelectButton as SelectButtonComponent } from '@xeris/components';
import { PlusIcon } from '@xeris/components/icons';

import { type HandleSelectModalType } from '../MultipleMasterProductsSelector';

type SelectButtonProps = {
    className?: string;
    selectedCount: number;
    totalCount: number;
    handleSelectModal: HandleSelectModalType;
    showWhenSelectedButton?: boolean;
};

export const SelectButton = ({
    selectedCount,
    totalCount,
    handleSelectModal,
    showWhenSelectedButton,
}: SelectButtonProps): ReactElement => {
    const { t } = useTranslation('product');

    if (selectedCount === 0) {
        return (
            <Button
                onClick={() => handleSelectModal('add')}
                variant={'outlinedSolid'}
                startIcon={<PlusIcon />}
                sx={{ borderRadius: 99 }}
            >
                {t('dataSelector.addAll')}
            </Button>
        );
    }

    const isAllSelected = selectedCount === totalCount;

    return (
        <Box>
            {showWhenSelectedButton ? (
                <SelectButtonComponent
                    isSelected={isAllSelected}
                    isPartiallySelected={selectedCount > 0}
                    onToggleOption={() => handleSelectModal('remove')}
                    size={'large'}
                />
            ) : (
                <div />
            )}
        </Box>
    );
};
