import { type ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Dialog, Typography } from '@mui/material';

import { MutationSnackbars } from '@xeris/components';
import { useDataLanguage } from '@xeris/hooks';
import { accessApi } from '@xeris/pages/admin/Connections/api';
import { FilterChips } from '@xeris/pages/admin/Connections/pages/BrandAccess/components/FilterChips';
import { VariantDrawer } from '@xeris/pages/admin/Connections/pages/BrandAccess/components/VariantDrawer';
import { masterProductApi } from '@xeris/pages/product/api/masterProductApi/masterProductApi';
import { GroupedProductList } from '@xeris/pages/product/Common/GroupedProductList/GroupedProductList';

import { InactiveProductCard } from '../../components/InactiveProductCard';
import { InactiveProductList } from '../../components/InactiveProductList';
import {
    type Brand,
    type BrandGroupMarketAccessRule,
    type MasterProductWithIds,
} from '../../types';
import { filterProducts } from '../../utilities';

import { Header } from './components/Header';
import { ProductFilter } from './components/ProductFilter';
import { SplitDialogContent } from './components/SplitDialogContent';

type FilterSelectorProps = {
    open: boolean;
    handleClose: () => void;
    tradingPartner: { id: string; name: string };
    ruleIds: string[];
    rule: BrandGroupMarketAccessRule | null;
    brand: Brand;
};

export const FilterSelector = ({
    open,
    handleClose,
    tradingPartner,
    brand,
    ruleIds,
    rule,
}: FilterSelectorProps): ReactElement => {
    const { t } = useTranslation('administration');
    const dataLanguage = useDataLanguage();

    const [markets, setMarkets] = useState<string[]>([]);
    const [groups, setGroups] = useState<Record<string, string[]>>({});

    const [productToShow, setProductToShow] =
        useState<MasterProductWithIds | null>(null);
    const [showProductDrawer, setShowProductDrawer] = useState(false);

    const { data } = masterProductApi.useGetAllMasterProductsForBrandQuery({
        brandId: brand.id,
        dataLanguage,
    });

    const products = filterProducts(
        data?.brand?.masterProducts ?? [],
        markets,
        groups
    );

    const totalVariantCount = (data?.brand?.masterProducts ?? []).reduce(
        (count, masterProduct) => count + (masterProduct.products?.length || 1),
        0
    );

    const variantCount = products.reduce(
        (count, masterProduct) => count + (masterProduct.products?.length || 1),
        0
    );

    const [setBrandAccess, { isSuccess, isError, isLoading }] =
        accessApi.useSetBrandAccessMutation();

    useEffect(() => {
        setMarkets(rule?.filterMarkets ?? []);
        setGroups(
            Object.fromEntries(
                rule?.filterGroups.map((group) => [
                    group.groupType,
                    group.groups,
                ]) ?? []
            )
        );
    }, [rule]);

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullScreen>
                <Header
                    accessType={'BrandGroupMarketAccessRule'}
                    tradingPartnerName={tradingPartner.name}
                    brandName={brand.name}
                    handleClose={handleClose}
                    isLoading={isLoading}
                    handleSave={async () => {
                        await setBrandAccess({
                            organizationId: tradingPartner.id,
                            ruleIdsToDelete: ruleIds,
                            deleteRules: ruleIds.length > 0,
                            addRule: true,
                            rule: {
                                brandRule: null,
                                groupRule: null,
                                marketRule: null,
                                organizationRule: null,
                                brandProductRule: null,
                                brandGroupMarketRule: {
                                    filterGroups: Object.entries(groups).map(
                                        ([groupTypeId, groupIds]) => ({
                                            groupType: groupTypeId,
                                            groups: groupIds,
                                        })
                                    ),
                                    filterMarkets: markets,
                                    targetBrand: brand.id,
                                    action: 'ALLOW',
                                },
                            },
                        }).unwrap();

                        handleClose();
                    }}
                />
                <SplitDialogContent
                    columnOne={
                        <ProductFilter
                            groupTypes={brand.groupTypes}
                            markets={brand.markets}
                            selectedMarkets={markets}
                            selectedGroups={groups}
                            products={data?.brand?.masterProducts ?? []}
                            onChange={(selectedMarkets, selectedGroups) => {
                                setMarkets(selectedMarkets);
                                setGroups(selectedGroups);
                            }}
                        />
                    }
                    columnTwo={
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography
                                variant={'h2'}
                                component={'h3'}
                                gutterBottom
                            >
                                {t('connections.appliedDynamicFilters')}
                            </Typography>
                            <Typography gutterBottom>
                                {t('connections.activeFilters')}
                            </Typography>
                            {rule && <FilterChips rule={rule} brand={brand} />}
                            <Typography
                                variant={'h2'}
                                component={'h3'}
                                gutterBottom
                                marginTop={4}
                            >
                                {t('connections.productCount', {
                                    count: totalVariantCount,
                                    selected: variantCount,
                                })}
                            </Typography>
                            <Typography gutterBottom marginBottom={3}>
                                {t('connections.currentFilters')}
                            </Typography>
                            <GroupedProductList
                                brandId={brand.id}
                                cardHeight={380}
                                cardMinWidth={210}
                                products={products}
                                groupTypes={brand.groupTypes}
                                disableMarketFilter
                                card={(product) => (
                                    <InactiveProductCard
                                        product={product}
                                        onClick={() => {
                                            setProductToShow(product);
                                            setShowProductDrawer(true);
                                        }}
                                    />
                                )}
                                listItem={(product) => (
                                    <InactiveProductList
                                        product={product}
                                        onClick={() => {
                                            setProductToShow(product);
                                            setShowProductDrawer(true);
                                        }}
                                    />
                                )}
                                markets={brand.markets}
                            />
                        </Box>
                    }
                />
            </Dialog>
            {productToShow && (
                <VariantDrawer
                    masterProductWithIds={productToShow}
                    handleClose={() => setShowProductDrawer(false)}
                    open={showProductDrawer}
                />
            )}
            <MutationSnackbars
                isError={isError}
                errorText={t('connections.brandAccess.updateAccessFailed')}
                isSuccess={isSuccess}
                successText={t('connections.brandAccess.updateAccessSucceeded')}
            />
        </>
    );
};
