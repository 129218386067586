import { type ReactElement, type SyntheticEvent } from 'react';

import {
    Avatar,
    Badge,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Tooltip,
} from '@mui/material';

import { CloseIcon } from '@xeris/components/icons';
import { ensuredImageSource } from '@xeris/utilities';

type MaterialSummarySwatchProps = {
    name: string;
    image: string | null;
    removeOptionTooltip: string;
    removeOption: () => void;
    setPreviewOption: (event: SyntheticEvent<Element, Event>) => void;
    isPreviewed?: boolean;
    notPreviewable?: boolean;
};

export const MaterialSummarySwatch = ({
    name,
    image,
    removeOptionTooltip,
    removeOption,
    setPreviewOption,
    isPreviewed,
    notPreviewable,
}: MaterialSummarySwatchProps): ReactElement => {
    return (
        <ListItem
            sx={{
                borderRadius: '6px',
                backgroundColor: isPreviewed
                    ? 'primary.soft'
                    : 'background.paper',
            }}
            disablePadding
            secondaryAction={
                <Tooltip title={removeOptionTooltip}>
                    <IconButton
                        onClick={removeOption}
                        size={'medium'}
                        edge={'end'}
                        aria-label={removeOptionTooltip}
                    >
                        <CloseIcon fontSize={'small'} />
                    </IconButton>
                </Tooltip>
            }
        >
            <ListItemButton
                dense
                onClick={setPreviewOption}
                sx={{ borderRadius: '6px', paddingLeft: 1 }}
                disabled={notPreviewable}
            >
                <ListItemAvatar sx={{ minWidth: 42 }}>
                    <Badge
                        invisible={!isPreviewed}
                        color={'primary'}
                        overlap={'circular'}
                        variant={'dot'}
                        badgeContent={''}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        sx={{
                            '& .MuiBadge-badge': {
                                border: '1px solid',
                                borderColor: 'white',
                                borderRadius: 99,
                                padding: '0',
                                height: 12,
                                minWidth: 12,
                            },
                        }}
                    >
                        <Avatar
                            src={ensuredImageSource(image)}
                            alt={''}
                            sx={{ width: 30, height: 30 }}
                        />
                    </Badge>
                </ListItemAvatar>
                <ListItemText primary={name} />
            </ListItemButton>
        </ListItem>
    );
};
