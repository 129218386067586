import type { ReactElement } from 'react';

import { MutationSnackbars } from '@xeris/components';
import { FormDialog } from '@xeris/components/forms';
import { importsApi } from '@xeris/pages/imports/api/importsApi';

type DeleteMappingFormProps = {
    isOpen: boolean;
    handleClose: () => void;
    mapping: {
        mappingId: string;
    } | null;
};

export const DeleteMapping = ({
    isOpen,
    handleClose,
    mapping,
}: DeleteMappingFormProps): ReactElement => {
    const [deleteMapping, { isLoading, isSuccess, isError }] =
        importsApi.useDeleteMappingMutation();

    return (
        <>
            <FormDialog
                open={isOpen}
                onClose={handleClose}
                onSubmit={async (event) => {
                    event.preventDefault();

                    if (mapping) {
                        await deleteMapping({
                            mappingId: mapping.mappingId,
                        }).unwrap();
                    }

                    handleClose();
                }}
                title={'Delete mapping?'}
                cancelText={'Cancel'}
                saveText={'Delete'}
                isLoading={isLoading}
                buttonProps={{ color: 'error' }}
            >
                {'Delete mapping?'}
            </FormDialog>
            <MutationSnackbars
                isSuccess={isSuccess}
                successText={'Mapping deleted'}
                isError={isError}
                errorText={'Failed to delete mapping'}
            />
        </>
    );
};
