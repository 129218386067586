import { forwardRef, type ReactElement } from 'react';

import {
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from '@mui/material';

type FormType = 'email' | 'password' | 'text';
type LoginFormControlProps = {
    autoComplete?: string;
    autoFocus?: boolean;
    formId: string;
    formLabel: string;
    formType: FormType;
    formValue: string;
    name: string;
    required?: boolean;
    setFunction?: (value: string) => void;
    disabled?: boolean;
    showAdornment?: boolean;
    dataTestId?: string;
    adornment?: ReactElement;
};

export const LoginFormControl = forwardRef(function LoginFormControl(
    {
        autoComplete,
        autoFocus,
        formId,
        formLabel,
        formType,
        formValue,
        name,
        required,
        setFunction,
        disabled,
        showAdornment = true,
        dataTestId,
        adornment,
    }: LoginFormControlProps,
    ref
): ReactElement {
    const endAdornment = adornment ? (
        <InputAdornment position="end">{adornment}</InputAdornment>
    ) : null;

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor={formId}>{formLabel}</InputLabel>
            <OutlinedInput
                label={formLabel.substring(0, formLabel.length - 1)}
                fullWidth
                inputRef={ref}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                endAdornment={showAdornment && endAdornment}
                id={formId}
                name={name}
                onChange={(event): void => {
                    !disabled && setFunction && setFunction(event.target.value);
                }}
                required={required}
                type={formType}
                value={formValue}
                disabled={disabled}
                data-testid={dataTestId}
            />
        </FormControl>
    );
});
