import { baseRtkApi } from '@xeris/reducers/baseApi';

import {
    createDatasetMutation,
    deleteDatasetMutation,
    duplicateDatasetMutation,
    editDatasetQuery,
    getActiveDatasetQuery,
    getDatasetListQuery,
    getDatasetQuery,
    getFullDatasetQuery,
} from './datasetsQueries';
import {
    type ApiActiveDatasetQuery,
    type ApiActiveDatasetQueryVariables,
    type ApiCreateDatasetMutation,
    type ApiCreateDatasetMutationVariables,
    type ApiDatasetListQuery,
    type ApiDatasetListQueryVariables,
    type ApiDeleteDatasetMutation,
    type ApiDeleteDatasetMutationVariables,
    type ApiDuplicateDatasetMutation,
    type ApiDuplicateDatasetMutationVariables,
    type ApiEditDatasetMutation,
    type ApiEditDatasetMutationVariables,
    type ApiFullDatasetQuery,
    type ApiFullDatasetQueryVariables,
    type ApiGetDatasetQuery,
    type ApiGetDatasetQueryVariables,
} from './datasetsQueries.gql';

export const datasetsApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getDataset: builder.query<
            ApiGetDatasetQuery,
            ApiGetDatasetQueryVariables
        >({
            query: (variables) => ({
                document: getDatasetQuery,
                variables,
            }),
            providesTags: (data) => [
                {
                    type: 'dataset' as const,
                    id: data?.dataset?.id,
                },
            ],
        }),
        createDataset: builder.mutation<
            ApiCreateDatasetMutation,
            ApiCreateDatasetMutationVariables
        >({
            query: (variables) => ({
                document: createDatasetMutation,
                variables,
            }),
            invalidatesTags: ['dataset'],
        }),
        editDataset: builder.mutation<
            ApiEditDatasetMutation,
            ApiEditDatasetMutationVariables
        >({
            query: (variables) => ({
                document: editDatasetQuery,
                variables,
            }),
            invalidatesTags: ['dataset', 'exportJobs'],
        }),
        duplicateDataset: builder.mutation<
            ApiDuplicateDatasetMutation,
            ApiDuplicateDatasetMutationVariables
        >({
            query: (variables) => ({
                document: duplicateDatasetMutation,
                variables,
            }),
            invalidatesTags: ['dataset'],
        }),
        deleteDataset: builder.mutation<
            ApiDeleteDatasetMutation,
            ApiDeleteDatasetMutationVariables
        >({
            query: (variables) => ({
                document: deleteDatasetMutation,
                variables,
            }),
            invalidatesTags: ['dataset', 'exportJobs'],
        }),
        getDatasetList: builder.query<
            ApiDatasetListQuery,
            ApiDatasetListQueryVariables
        >({
            query: (variables) => ({
                document: getDatasetListQuery,
                variables,
            }),
            providesTags: (data) => [
                'dataset',
                ...(data?.datasets ?? []).map((dataset) => ({
                    type: 'dataset' as const,
                    id: dataset.id,
                })),
            ],
        }),
        getActiveDataset: builder.query<
            ApiActiveDatasetQuery,
            ApiActiveDatasetQueryVariables
        >({
            query: (variables) => ({
                document: getActiveDatasetQuery,
                variables,
            }),
            providesTags: (data) => [
                { type: 'dataset', id: data?.dataset?.id },
            ],
        }),
        getFullDataset: builder.query<
            ApiFullDatasetQuery,
            ApiFullDatasetQueryVariables
        >({
            query: (variables) => ({
                document: getFullDatasetQuery,
                variables,
            }),
            providesTags: (data) => [
                { type: 'dataset', id: data?.dataset?.id },
            ],
        }),
    }),
});
