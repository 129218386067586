import { userApi } from '@xeris/api/user/userApi';
import { useAppSelector } from '@xeris/reducers';

type UserInfo = {
    hasLoadedInfo: boolean;
    userId: string;
    name: string | null;
    username: string;
    phone: string | null;
    language: string;
    organizationId: string;
    organizationName: string;
    isAdmin: boolean;
    isSuperAdmin: boolean;
};

export const useWhoami = (): UserInfo => {
    const username = useAppSelector((state) => state.login.username);

    const { data } = userApi.useGetWhoamiQuery(
        { username: username ?? '' },
        { skip: !username }
    );

    if (!data) {
        return {
            hasLoadedInfo: false,
            userId: '0',
            username: '',
            name: '',
            phone: '',
            isAdmin: false,
            isSuperAdmin: false,
            organizationName: '',
            organizationId: '',
            language: '',
        };
    }

    return {
        hasLoadedInfo: true,
        userId: data.whoami.id,
        name: data.whoami.name,
        username: data.whoami.username,
        phone: data.whoami.phone,
        language: data.whoami.language,
        organizationId: data.whoami.organization.id,
        organizationName: data.whoami.organization.name,
        isAdmin: data.whoami.securityLevel === 'ADMIN',
        isSuperAdmin: data.whoami.securityLevel === 'SUPER_ADMIN',
    };
};
