import { type ReactElement } from 'react';

import { Button, Stack, Typography } from '@mui/material';

import { EyeIcon, EyeOffIcon } from '@xeris/components/icons';

type BrandVisibilityHeaderProps = {
    brands: { id: string; isVisible: boolean }[];
    handleSetVisibility: (ids: string[], newValue: boolean) => void;
};

const BrandVisibilityHeader = ({
    brands,
    handleSetVisibility,
}: BrandVisibilityHeaderProps): ReactElement => {
    const visibleBrandsCount = brands.filter((brand) => brand.isVisible).length;

    const totalBrandCount = brands.length;

    const handleHideShowAll = (): void => {
        const ids = brands.map((brand) => brand.id);
        const newValue = visibleBrandsCount !== totalBrandCount;
        handleSetVisibility(ids, newValue);
    };

    const Icon = visibleBrandsCount === totalBrandCount ? EyeOffIcon : EyeIcon;

    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'h1'} gutterBottom>
                Brand Visibility ({visibleBrandsCount}/{totalBrandCount})
            </Typography>

            <Button onClick={handleHideShowAll} startIcon={<Icon />}>
                Hide/show all
            </Button>
        </Stack>
    );
};

export default BrandVisibilityHeader;
