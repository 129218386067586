import {
    type FormEvent,
    type ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Skeleton, Stack, Tooltip, Typography } from '@mui/material';

import { LockPrivateIcon } from '@xeris/components/icons';
import { useQueryParams } from '@xeris/hooks';
import { useChangeLanguage } from '@xeris/i18n/useChangeLanguage';
import { userVerificationApi } from '@xeris/pages/admin/api';

import {
    BackToLoginButton,
    BrokenLinkMessage,
    PasswordInput,
    UserNameInput,
} from '../Common';
import { isValidPassword } from '../utillities';

const ResetPassword = (): ReactElement => {
    const { t } = useTranslation('login');

    const handleChangeLanguage = useChangeLanguage();

    const passwordInput = useRef<HTMLInputElement | null>(null);
    const form = useRef<HTMLFormElement | null>(null);

    const token = useQueryParams('token');

    const { data } = userVerificationApi.useGetUserInformationFromTokenQuery(
        { token: token ?? '' },
        {
            skip: !token,
        }
    );

    useEffect(() => {
        handleChangeLanguage(data?.language);
    }, [data, handleChangeLanguage]);

    const [password, setPassword] = useState('');

    const validatePassword = (): void => {
        const message = isValidPassword(password);
        passwordInput.current?.setCustomValidity(message);
    };

    if (!token) {
        return <BrokenLinkMessage variant="reset-password" />;
    }

    if (!data) {
        return <Skeleton height={300} />;
    }

    const handleSubmit = (event: FormEvent): void => {
        validatePassword();
        form.current?.reportValidity();
        //If the password is valid, let the form perform the request, otherwise, stop it
        if (!form.current?.checkValidity()) {
            event.preventDefault();
        }
    };

    return (
        <Stack direction={'column'} gap={4}>
            <Typography variant={'h3'} component={'h1'} fontWeight={'normal'}>
                {t('resetPassword.createNewPassword')}
            </Typography>
            <Stack
                component={'form'}
                direction={'column'}
                width={'100%'}
                gap={4}
                ref={form}
                action={`/login/reset-password?token=${token}`}
                method="POST"
                onSubmit={handleSubmit}
            >
                <UserNameInput
                    value={data.username}
                    disabled
                    showAdornment={true}
                    adornment={<LockPrivateIcon fontSize="small" />}
                />
                <PasswordInput
                    ref={passwordInput}
                    value={password}
                    handleChange={setPassword}
                    name="password"
                />
                <Tooltip
                    title={
                        password.length < 8
                            ? t('resetPassword.length')
                            : undefined
                    }
                >
                    <span>
                        <Button
                            variant={'contained'}
                            type={'submit'}
                            disabled={password.length < 8}
                        >
                            {t('resetPassword.createPassword')}
                        </Button>
                    </span>
                </Tooltip>
            </Stack>
            <BackToLoginButton />
        </Stack>
    );
};

export default ResetPassword;
