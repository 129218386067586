import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, Route, Routes, useLocation } from 'react-router-dom';

import { Box, Tab, Tabs, Typography } from '@mui/material';

import { CenteredPage } from '@xeris/components';
import { EditMapping } from '@xeris/pages/imports/pages/Mapping/EditMapping';
import { Source } from '@xeris/pages/imports/pages/Source/Source';

import { ActivityLog } from './pages/ActivityLog/ActivityLog';
import { CreateMapping } from './pages/Mapping/CreateMapping';
import { Mappings } from './pages/Mappings/Mappings';
import { Overview } from './pages/Overview/Overview';
import { Sources } from './pages/Sources/Sources';

function useRouteMatch(patterns: string[]): number {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            const currentTabIndex = patterns.findIndex(
                (path) => path === possibleMatch.pattern.path
            );

            if (currentTabIndex == -1) {
                return 0;
            }

            return currentTabIndex;
        }
    }

    return 0;
}

const tabs = [
    { path: '/', key: 'overview' },
    { path: '/source', key: 'sources' },
    { path: '/mapping', key: 'mappings' },
    { path: '/activityLog', key: 'activityLog' },
] as const;

const Header = (): ReactElement => {
    const { t } = useTranslation('imports');

    return <Typography variant={'h1'}>{t('common.imports')}</Typography>;
};

const PageTabs = (): ReactElement => {
    const { t } = useTranslation('imports');

    const currentTabIndex = useRouteMatch(
        tabs.map(({ path }) => '/import' + path)
    );

    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTabIndex}>
                {tabs.map(({ key, path }) => (
                    <Tab
                        key={key}
                        label={t(`importsPage.tabs.${key}`)}
                        component={Link}
                        to={'/import' + path}
                    />
                ))}
            </Tabs>
        </Box>
    );
};

export const ImportsPage = (): ReactElement => {
    return (
        <CenteredPage>
            <Routes>
                <Route
                    path={'/'}
                    element={
                        <>
                            <Header />
                            <PageTabs />
                            <Overview />
                        </>
                    }
                />
                <Route
                    path={'/source'}
                    element={
                        <>
                            <Header />
                            <PageTabs />
                            <Sources />
                        </>
                    }
                />
                <Route path={'/source/:sourceId'} element={<Source />} />
                <Route
                    path={'/mapping'}
                    element={
                        <>
                            <Header />
                            <PageTabs />
                            <Mappings />
                        </>
                    }
                />
                <Route
                    path={'/activityLog'}
                    element={
                        <>
                            <Header />
                            <PageTabs />
                            <ActivityLog />
                        </>
                    }
                />
                <Route
                    path={'/mapping/edit/:mappingId'}
                    element={<EditMapping />}
                />
                <Route path={'/mapping/new'} element={<CreateMapping />} />
            </Routes>
        </CenteredPage>
    );
};
