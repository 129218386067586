import { type ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Skeleton, Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import {
    DataGrid,
    ErrorPage,
    MutationSnackbars,
    SearchBar,
} from '@xeris/components';
import {
    ArrowRightIcon,
    DeleteIcon,
    EditIcon,
    PlusIcon,
} from '@xeris/components/icons';
import { DeleteMapping } from '@xeris/pages/imports/pages/Mappings/forms/DeleteMapping';
import { search } from '@xeris/utilities';

import { importsApi } from '../../api/importsApi';

import { CreateMapping } from './forms/CreateMapping';

export const Mappings = (): ReactElement => {
    const [showCreateMapping, setShowCreateMapping] = useState(false);
    const [mappingToDelete, setMappingToDelete] = useState<null | {
        mappingId: string;
    }>(null);

    const [filter, setFilter] = useState<string>('');

    const { data, isLoading, isError } =
        importsApi.useGetMappingConfigurationsQuery({});

    const [
        runMapping,
        { isSuccess: runMappingSuccess, isError: runMappingError },
    ] = importsApi.useRunMappingMutation();

    if (isLoading) {
        return <Skeleton height={200} />;
    }

    if (isError) {
        return <ErrorPage title={'An error occurred'} />;
    }

    if (!data) {
        return <ErrorPage title={'Not found'} />;
    }

    const rows = data.mappingConfigurations
        .map((mappingConfiguration) => ({
            id: mappingConfiguration.id,
            mappingId: mappingConfiguration.mapping.mappingId,
            targetEntityType:
                mappingConfiguration.mapping.targetEntityType.name,
            sourceType: `${mappingConfiguration.source.name} / ${mappingConfiguration.documentType}`,
            sourceId: mappingConfiguration.source.id,
            documentType: mappingConfiguration.documentType,
        }))
        .filter(({ sourceType }) => search(filter, sourceType));

    return (
        <Box sx={{ minHeight: 'calc(100vh - 186px)' }}>
            <Box
                sx={{
                    paddingBlock: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <SearchBar
                    inputLabel={'Filter'}
                    onChange={setFilter}
                    filter={filter}
                />
                <Button
                    startIcon={<PlusIcon />}
                    variant={'contained'}
                    onClick={() => setShowCreateMapping(true)}
                >
                    {'New mapping'}
                </Button>
            </Box>
            {data.mappingConfigurations.length === 0 ? (
                <Typography>{'No mappings yet'}</Typography>
            ) : (
                <Box sx={{ overflow: 'hidden' }}>
                    <DataGrid
                        hideFooterPagination
                        rowSelection={false}
                        autoHeight
                        columns={[
                            {
                                field: 'targetEntityType',
                                headerName: 'Object',
                                width: 200,
                            },
                            {
                                field: 'sourceType',
                                headerName: 'Main source',
                                flex: 1,
                            },
                            {
                                field: 'actions',
                                type: 'actions',
                                headerName: '',
                                getActions: (params) => [
                                    <GridActionsCellItem
                                        key={'runMapping'}
                                        icon={<ArrowRightIcon />}
                                        label="Run mapping"
                                        showInMenu
                                        onClick={() =>
                                            runMapping({
                                                sourceId: params.row.sourceId,
                                            })
                                        }
                                    />,
                                    <GridActionsCellItem
                                        key={'editMapping'}
                                        component={Link}
                                        // @ts-expect-error
                                        to={`edit/${params.id}`}
                                        icon={<EditIcon />}
                                        label="Edit mapping"
                                        showInMenu
                                    />,
                                    <GridActionsCellItem
                                        key={'deleteMapping'}
                                        icon={<DeleteIcon />}
                                        label="Delete mapping"
                                        showInMenu
                                        onClick={() =>
                                            setMappingToDelete(params.row)
                                        }
                                    />,
                                ],
                            },
                        ]}
                        rows={rows}
                    />
                </Box>
            )}
            <CreateMapping
                isOpen={showCreateMapping}
                handleClose={() => setShowCreateMapping(false)}
            />
            <DeleteMapping
                isOpen={!!mappingToDelete}
                handleClose={() => setMappingToDelete(null)}
                mapping={mappingToDelete}
            />
            <MutationSnackbars
                isSuccess={runMappingSuccess}
                successText={'Mapping started'}
                isError={runMappingError}
                errorText={'Failed to start mapping'}
            />
        </Box>
    );
};
