import { useMemo } from 'react';

import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import { useAppSelector } from '@xeris/reducers';

export const useProductListSettings = <Group extends { id: string }>(
    brandId: string,
    groupTypes: { id: string; groups: Group[] }[],
    markets: { id: string }[]
): {
    viewType: 'cards' | 'list';
    marketId: string | null;
    groupTypeId: string | null;
    groups: Group[];
    expanded: Record<string, boolean>;
    noneExpanded: boolean;
} => {
    const viewType =
        useAppSelector(settingsSelectors.selectListType) ?? 'cards';

    const groupType = useAppSelector((state) =>
        settingsSelectors.selectGroupType(state, brandId)
    );

    const selectedGroupType =
        groupType === null || groupTypes.some(({ id }) => id === groupType)
            ? groupType ?? null
            : groupTypes[0]?.id ?? null;

    const groups = useMemo(
        () =>
            groupTypes.find(({ id }) => id === selectedGroupType)?.groups ?? [],
        [groupTypes, selectedGroupType]
    );

    const groupIds = useMemo(
        () => [...groups.map((group) => group.id), '__ungrouped'],
        [groups]
    );

    const expanded = useAppSelector((state) =>
        settingsSelectors.selectGroupsExpanded(state, groupIds)
    );

    const marketId = useAppSelector((state) =>
        settingsSelectors.selectMarket(state, brandId)
    );

    const selectedMarketId = markets.some(({ id }) => id === marketId)
        ? marketId
        : null;

    const noneExpanded = groups.every((group) => !(expanded[group.id] ?? true));

    return useMemo(
        () => ({
            viewType: viewType,
            marketId: selectedMarketId,
            groupTypeId: selectedGroupType,
            groups: groups,
            expanded: expanded,
            noneExpanded: noneExpanded,
        }),
        [
            expanded,
            selectedGroupType,
            selectedMarketId,
            viewType,
            groups,
            noneExpanded,
        ]
    );
};
