import { useActiveDataset } from './useActiveDataset';

type Counts = {
    productCount: number;
    selectedProductCount: number;
    variantCount: number;
    selectedVariantCount: number;
};

export const useSelectedCounts = (
    products: { id: string; products: { id: string }[] }[]
): Counts => {
    const activeDataset = useActiveDataset();

    const selectedIds = new Set(
        activeDataset?.selectedProductData.nodeList.map(({ id }) => id) ?? []
    );

    const productCount = products.length;
    const variantCount = products.reduce(
        (count, product) => count + (product.products.length || 1),
        0
    );

    const selectedProductCount = products.filter(
        (product) =>
            selectedIds.has(product.id) ||
            product.products.some((variant) => selectedIds.has(variant.id))
    ).length;

    const selectedVariantCount = products
        .map((product) => {
            if (selectedIds.has(product.id)) return 1;

            return product.products.filter((variant) =>
                selectedIds.has(variant.id)
            ).length;
        })
        .reduce((count, value) => count + value, 0);

    return {
        productCount,
        selectedProductCount,
        variantCount,
        selectedVariantCount,
    };
};
