import { type ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box,
    Button,
    Container,
    DialogContent,
    DialogTitle,
    Divider,
    Pagination,
    Skeleton,
    Toolbar,
    Typography,
} from '@mui/material';

import { Dialog, IconButton } from '@xeris/components';
import { CloseIcon, DownloadIcon } from '@xeris/components/icons';
import { useDataLanguage } from '@xeris/hooks';
import { productHooks } from '@xeris/pages/product/hooks/productHooks';
import { productActions } from '@xeris/pages/product/reducers';
import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import { type MasterProduct, type Product } from '@xeris/pages/product/types';
import { isMasterProduct } from '@xeris/pages/product/utilities';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';
import { type ApiSourceData } from '@xeris/types/graphql';

const useGetProductEntitiesSourceData = (
    productEntityId: string,
    productIsMasterProduct: boolean
): { sourceDataList: ApiSourceData[]; isLoading: boolean } => {
    const dataLanguage = useDataLanguage();
    const showSourceData = useAppSelector(
        settingsSelectors.selectShowSourceData
    );

    const productSourceQueryResult =
        productHooks.api.queries.useGetProductSourceData(
            { productId: productEntityId, dataLanguage },
            { skip: productIsMasterProduct || !showSourceData }
        );

    const masterProductSourceQueryResult =
        productHooks.api.queries.useGetMasterProductSourceData(
            { masterProductId: productEntityId, dataLanguage },
            { skip: !productIsMasterProduct || !showSourceData }
        );

    return {
        sourceDataList: [
            ...(productSourceQueryResult.data ?? []),
            ...(masterProductSourceQueryResult.data ?? []),
        ],
        isLoading:
            productSourceQueryResult.isLoading ||
            productSourceQueryResult.isFetching ||
            masterProductSourceQueryResult.isLoading ||
            masterProductSourceQueryResult.isFetching,
    };
};

const getFormattedData = (data: string): string => {
    return JSON.stringify(JSON.parse(data), null, '\t');
};

type DownloadLinkProps = {
    data: string;
    filename: string;
};

const DownloadLink = ({ data, filename }: DownloadLinkProps): ReactElement => {
    const { t } = useTranslation('product');

    const [url, setUrl] = useState<string | undefined>();

    useEffect(() => {
        const objectUrl = URL.createObjectURL(
            new Blob([getFormattedData(data)], { type: 'application/json' })
        );

        setUrl(objectUrl);

        return () => {
            URL.revokeObjectURL(objectUrl);
        };
    }, [data]);

    return (
        <Button
            href={url}
            component={'a'}
            variant="outlined"
            startIcon={<DownloadIcon fontSize="small" />}
            download={`${filename}.json`}
        >
            {t('datasheet.toolsMenu.downloadFile')}
        </Button>
    );
};

type SourceDataProps = {
    productEntity: Product<'name'> | MasterProduct<'name'>;
};

const SourceData = ({ productEntity }: SourceDataProps): ReactElement => {
    const { t } = useTranslation('product');
    const dispatch = useAppDispatch();

    const showSourceData = useAppSelector(
        settingsSelectors.selectShowSourceData
    );

    const { sourceDataList, isLoading } = useGetProductEntitiesSourceData(
        productEntity.id,
        isMasterProduct(productEntity)
    );

    const [page, setPage] = useState(0);

    const handleSetPage = (value: number): void => {
        setPage(value - 1);
    };

    const handleClose = (): void => {
        dispatch(productActions.settings.toggleShowSourceData());
    };

    return (
        <Dialog
            onClose={handleClose}
            open={showSourceData}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                {t('datasheet.toolsMenu.rawCode', {
                    name: productEntity.name,
                })}
                <IconButton onClick={handleClose} edge={'end'}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <Divider />

            {sourceDataList.length === 0 && (
                <Container sx={{ justifyContent: 'center' }}>
                    {isLoading ? (
                        <Skeleton height={300} />
                    ) : (
                        <Typography paddingBlock={4}>
                            {t('datasheet.toolsMenu.noSourceData')}
                        </Typography>
                    )}
                </Container>
            )}

            {sourceDataList.length > 0 && (
                <DialogContent>
                    <Toolbar sx={{ mb: 2 }}>
                        <Pagination
                            count={sourceDataList.length}
                            page={page + 1}
                            onChange={(event, value): void =>
                                handleSetPage(value)
                            }
                        />

                        <DownloadLink
                            data={sourceDataList[page].data}
                            filename={`${productEntity.name}(${page + 1} - ${
                                sourceDataList.length
                            })`}
                        />
                    </Toolbar>
                    <Box
                        sx={{
                            backgroundColor: 'grey.100',
                            maxHeight: '60vh',
                            padding: 2,
                            borderRadius: 'shape.borderRadius',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                        }}
                    >
                        <pre>{getFormattedData(sourceDataList[page].data)}</pre>
                    </Box>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default SourceData;
