import { type ReactElement, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, type SxProps, Tooltip } from '@mui/material';

import { ChevronDownIcon, ChevronUpIcon } from '@xeris/components/icons';
import { type MasterProductWithIds } from '@xeris/pages/product/Common';

import { Typography } from '../../Typography/Typography';
import { type SectionListData } from '../types';

type SectionProps<MP extends MasterProductWithIds> = {
    data: SectionListData<MP>;
    isOpen: boolean;
    actions?: (section: SectionListData<MP>) => ReactNode;
    info?: (section: SectionListData<MP>) => ReactNode;
    toggleSectionOpen: () => void;
    sx?: SxProps;
};

export const Section = <MP extends MasterProductWithIds>({
    data,
    actions,
    info,
    toggleSectionOpen,
    isOpen,
    sx,
}: SectionProps<MP>): ReactElement => {
    const { t } = useTranslation('common');

    return (
        <Box
            sx={{
                'display': 'flex',
                'alignItems': 'center',
                'height': '64px',
                'border': 1,
                'borderColor': 'divider',
                '&:hover': { backgroundColor: 'action.hover' },
                ...sx,
            }}
        >
            <Box
                onClick={toggleSectionOpen}
                role={'button'}
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: { xs: 0.5, md: 1.5 },
                }}
            >
                <Tooltip
                    title={t(
                        `components.productList.${isOpen ? 'collapse' : 'expand'}`,
                        { sectionName: data.title }
                    )}
                >
                    <IconButton>
                        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    </IconButton>
                </Tooltip>
                <Box display={'flex'} flexGrow={1} flexBasis={'100%'}>
                    <Typography
                        variant={'subtitle1'}
                        fontWeight={'fontWeightMedium'}
                        component={'h2'}
                        maxLines={1}
                    >
                        {data.title}
                    </Typography>
                </Box>
                {info?.(data)}
            </Box>

            <Box flexShrink={0} paddingInlineEnd={{ xs: 0.5, md: 1.5 }}>
                {actions?.(data)}
            </Box>
        </Box>
    );
};
