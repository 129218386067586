import { type ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputAdornment, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { fileUploadApi } from '@xeris/api';
import { BrandCard, MutationSnackbars } from '@xeris/components';
import { FileInput, FormDrawer, TextInput } from '@xeris/components/forms';
import { LockPrivateIcon } from '@xeris/components/icons';
import { brandApi } from '@xeris/pages/admin/api';

const imageSchema = yup.mixed<File>().required();

const editBrandSchema = yup
    .object({
        id: yup.string().required('required'),
        name: yup.string().required('required'),
        logo: yup.array().of(imageSchema).required(),
        featureImage: yup.array().of(imageSchema).required(),
    })
    .required();

type EditBrandFormData = yup.InferType<typeof editBrandSchema>;

type EditBrandProps = {
    isOpen: boolean;
    brandData?: {
        id: string;
        name: string;
        theme: {
            logo: string | null;
            featureImage: string | null;
        } | null;
    } | null;
    handleClose: () => void;
};

export const EditBrand = ({
    isOpen,
    brandData,
    handleClose,
}: EditBrandProps): ReactElement | null => {
    const { t } = useTranslation('administration');

    const [editBrandMutation, editBrandResult] =
        brandApi.useEditBrandMutation();
    const [getSignedUploadBrandUrl, { isLoading: signedUrlLoading }] =
        fileUploadApi.useGetSignedUploadBrandUrlMutation();
    const [uploadFile, { isLoading: uploadImageLoading }] =
        fileUploadApi.useUploadFileMutation();

    const { handleSubmit, control, reset, watch } = useForm<EditBrandFormData>({
        resolver: yupResolver(editBrandSchema),
        defaultValues: {
            id: brandData?.id ?? '',
            name: brandData?.name ?? '',
            logo: [],
            featureImage: [],
        },
    });

    const handleSubmitBrand = async (
        editBrandData: EditBrandFormData
    ): Promise<void> => {
        const imageUrls: { logo: string | null; featureImage: string | null } =
            { logo: null, featureImage: null };

        if (editBrandData.logo[0]) {
            const { url, targetUrl } = await getSignedUploadBrandUrl({
                brandId: editBrandData.id,
                imageName: editBrandData.logo[0].name,
            }).unwrap();

            await uploadFile({
                url,
                file: editBrandData.logo[0],
            }).unwrap();

            imageUrls.logo = targetUrl;
        }

        if (editBrandData.featureImage[0]) {
            const { url, targetUrl } = await getSignedUploadBrandUrl({
                brandId: editBrandData.id,
                imageName: editBrandData.featureImage[0].name,
            }).unwrap();

            await uploadFile({
                url,
                file: editBrandData.featureImage[0],
            }).unwrap();

            imageUrls.featureImage = targetUrl;
        }

        await editBrandMutation({
            id: editBrandData.id,
            name: editBrandData.name,
            theme: {
                colors: null,
                logo: imageUrls.logo,
                feature_image: imageUrls.featureImage,
            },
            hasImages: !!(imageUrls.logo || imageUrls.featureImage),
        }).unwrap();

        reset({
            id: editBrandData.id,
            name: editBrandData.name,
            logo: [],
            featureImage: [],
        });

        handleClose();
    };

    useEffect(() => {
        reset({ ...brandData, logo: [], featureImage: [] });
    }, [reset, brandData]);

    const name = watch('name');
    const logo = watch('logo')[0];
    const featureImage = watch('featureImage')[0];

    const logoUrl = logo
        ? URL.createObjectURL(logo)
        : brandData?.theme?.logo ?? null;
    const featureImageUrl = featureImage
        ? URL.createObjectURL(featureImage)
        : brandData?.theme?.featureImage ?? null;

    return (
        <>
            <FormDrawer
                open={isOpen}
                onClose={handleClose}
                onSubmit={handleSubmit(handleSubmitBrand)}
                title={t(`brand.editBrand.editBrand`)}
                cancelText={t('common.cancel')}
                saveText={t('brand.common.save')}
                isLoading={
                    editBrandResult.isLoading ||
                    signedUrlLoading ||
                    uploadImageLoading
                }
            >
                <TextInput
                    label={t('brand.editBrand.id')}
                    fieldName={'id'}
                    control={control}
                    required
                    disabled
                    showError
                    placeholder={t('brand.editBrand.idPlaceholder')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LockPrivateIcon fontSize={'small'} />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextInput
                    label={t('brand.editBrand.name')}
                    fieldName={'name'}
                    control={control}
                    required
                    showError
                    autoFocus
                    placeholder={t('brand.editBrand.namePlaceholder')}
                />
                <FileInput
                    label={t('brand.common.logo')}
                    fieldName={'logo'}
                    control={control}
                    showError
                />
                <FileInput
                    label={t('brand.common.featureImage')}
                    fieldName={'featureImage'}
                    control={control}
                    showError
                />
                <Typography variant={'h3'} marginBlock={1.5}>
                    {t('brand.common.preview')}
                </Typography>
                <BrandCard
                    href={'.'}
                    name={name}
                    logo={logoUrl}
                    featureImage={featureImageUrl}
                    sx={{ height: '300px' }}
                />
            </FormDrawer>
            <MutationSnackbars
                isSuccess={editBrandResult.isSuccess}
                successText={t('brand.editBrand.editBrandSuccess')}
                isError={editBrandResult.isError}
                errorText={t('brand.editBrand.errorOnEdit')}
            />
        </>
    );
};
