import { type MutableRefObject, useRef, useState } from 'react';

import { isShallowEqual } from '@xeris/utilities';

import { useResizeObserver } from './useResizeObserver';

export const useElementRect = (): [
    DOMRect | null,
    MutableRefObject<HTMLDivElement | null>,
] => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [rect, setRect] = useState<DOMRect | null>(null);

    useResizeObserver(ref, () => {
        const elementRect = ref.current?.getBoundingClientRect() || null;

        if (elementRect) {
            if (!rect) {
                setRect(elementRect);
            } else {
                if (!isShallowEqual(elementRect, rect)) {
                    setRect(elementRect);
                }
            }
        }
    });

    return [rect, ref];
};
