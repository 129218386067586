import { type ReactElement } from 'react';

import { Box, Button, ButtonGroup, Typography } from '@mui/material';

import { SearchBar } from '@xeris/components';

import { type EcoSearchTypes } from '../types';

const ClassificationSelectorHeader = (): ReactElement => {
    return (
        <Box marginBottom={1}>
            <Typography variant={'h1'}>X-Eco search</Typography>
        </Box>
    );
};

type ClassificationSearchBarProps = {
    searchTerm: string;
    handleSetSearchTerm: (searchTerm: string) => void;
    activeFilter: EcoSearchTypes.FilterTypesType;
    handleSetActiveFilter: (
        newActiveFilter: EcoSearchTypes.FilterTypesType
    ) => void;
};

const ClassificationSearchBar = ({
    searchTerm,
    handleSetSearchTerm,
    activeFilter,
    handleSetActiveFilter,
}: ClassificationSearchBarProps): ReactElement => {
    //TODO_NOT_THIS_PR: Look into parsing the ts FilterType-type to this list instead of reconstructing it here,
    //and having two copies that will have to be updated independently when we change the amount of filters here
    //Consider moving this one to types-file as it's so closely related to the typing
    const types: EcoSearchTypes.FilterTypesType[] = [
        'show all',
        'segment',
        'family',
        'class',
        'brick',
    ];

    return (
        <Box
            sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 3,
                marginBottom: 2,
                padding: 3,
                backgroundColor: 'background.default',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                [theme.breakpoints.up('md')]: {
                    flexDirection: 'row',
                },
            })}
        >
            <Box flex={1}>
                <SearchBar
                    filter={searchTerm}
                    onChange={handleSetSearchTerm}
                    inputLabel="Search for segment, family, class or brick..."
                />
            </Box>
            <ButtonGroup variant="outlined">
                {types.map((type) => (
                    <Button
                        key={type}
                        variant={
                            activeFilter === type ? 'contained' : 'outlined'
                        }
                        color={activeFilter === type ? 'primary' : 'secondary'}
                        disableElevation
                        onClick={() => handleSetActiveFilter(type)}
                    >
                        {type}
                    </Button>
                ))}
            </ButtonGroup>
        </Box>
    );
};

export const ClassificationHeader = ({
    searchTerm,
    handleSetSearchTerm,
    activeFilter,
    handleSetActiveFilter,
}: ClassificationSearchBarProps): ReactElement => {
    return (
        <section>
            <ClassificationSelectorHeader />
            <ClassificationSearchBar
                activeFilter={activeFilter}
                searchTerm={searchTerm}
                handleSetActiveFilter={handleSetActiveFilter}
                handleSetSearchTerm={handleSetSearchTerm}
            />
        </section>
    );
};
