import { type ReactElement, type ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { type TFunction } from 'i18next';
import * as yup from 'yup';

import { RadioCard } from '@xeris/components/forms';

import { StepWrapper } from '../components/StepWrapper';
import { type StepProps } from '../types';

const sourceTypeSchema = yup
    .object({
        sourceType: yup.string().required(),
    })
    .required();

export type SourceTypeSchema = yup.InferType<typeof sourceTypeSchema>;

type DescriptionProps = {
    type: SourceType['id'];
};

const Description = ({ type }: DescriptionProps): ReactElement => {
    const { t } = useTranslation('imports');

    return (
        <>
            {t(`sources.sourceType.sourceTypes.${type}.descriptions`, {
                returnObjects: true,
            }).map((description) => (
                <Typography key={description} color={'inherit'}>
                    {description}
                </Typography>
            ))}
        </>
    );
};

const sourceTypeIds = ['file', 'ftp', 'googleDrive', 'shopifyApi'] as const;

const disabledSourceTypeIds = ['ftp', 'googleDrive', 'shopifyApi'];

type SourceType = {
    id: (typeof sourceTypeIds)[number];
    name: string;
    disabled?: boolean;
    description?: ReactNode;
};

const sourceTypes = (t: TFunction<'imports'>): SourceType[] =>
    sourceTypeIds.map((id) => ({
        id: id,
        name: t(`sources.sourceType.sourceTypes.${id}.title`),
        disabled: disabledSourceTypeIds.includes(id),
        description: <Description type={id} />,
    }));

type SelectSourceTypeProps = StepProps;

export const SelectSourceType = ({
    handleClose,
    activeStep,
    header,
    data,
    setData,
}: SelectSourceTypeProps): ReactElement => {
    const { t } = useTranslation('imports');

    const { handleSubmit, control, getValues } = useForm({
        resolver: yupResolver(sourceTypeSchema),
        defaultValues: { sourceType: data?.type?.sourceType || 'file' },
    });

    return (
        <StepWrapper
            title={t('sources.sourceType.selectSourceType')}
            handleClose={handleClose}
            activeStep={activeStep}
            header={header}
            onBack={() => setData({ type: getValues() }, -1)}
            onSubmit={handleSubmit(
                (newData) => setData({ type: newData }, 1),
                console.error
            )}
        >
            <Box display={'flex'} flexDirection={'column'} gap={1}>
                {sourceTypes(t).map(({ id, name, disabled, description }) => (
                    <RadioCard
                        key={id}
                        fieldName={'sourceType'}
                        value={id}
                        control={control}
                        title={name}
                        disabled={disabled}
                    >
                        <Typography
                            color={disabled ? 'text.disabled' : 'text.primary'}
                            component={'div'}
                        >
                            {description}
                        </Typography>
                    </RadioCard>
                ))}
            </Box>
        </StepWrapper>
    );
};
