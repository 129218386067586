import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MuiButton,
} from '@xeris/components';
import { DeleteIcon } from '@xeris/components/icons';
import { useWhoami } from '@xeris/hooks';
import { sharedDatasetsApi } from '@xeris/pages/dataset/api';

type RemoveSharedDatasetFormProps = {
    isOpen: boolean;
    dataset: {
        id: string;
        name: string;
    };
    handleClose: () => void;
};

export const RemoveSharedDatasetForm = ({
    isOpen,
    dataset,
    handleClose,
}: RemoveSharedDatasetFormProps): ReactElement => {
    const { t } = useTranslation('dataset');
    const { userId } = useWhoami();

    const [unshareMutation] = sharedDatasetsApi.useUnshareDatasetMutation();

    const handleUnshareDataset = async (): Promise<void> => {
        await unshareMutation({
            datasetId: dataset.id,
            userId,
            removeYourself: true,
        }).unwrap();

        handleClose();
    };

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('unshareDialog.title')}</DialogTitle>

            <DialogContent>
                {t('unshareDialog.content', {
                    datasetName: dataset.name,
                })}
            </DialogContent>

            <DialogActions>
                <MuiButton onClick={handleClose} color="secondary">
                    {t('common.cancel')}
                </MuiButton>
                <MuiButton
                    variant="contained"
                    color="error"
                    onClick={handleUnshareDataset}
                    startIcon={<DeleteIcon />}
                >
                    {t('common.remove')}
                </MuiButton>
            </DialogActions>
        </Dialog>
    );
};
