import { useTranslation } from 'react-i18next';

import { type MenuElementType } from '@xeris/components';
import { PollIcon } from '@xeris/components/icons';
import { useFeatureFlag, useWhoami } from '@xeris/hooks';

export const useAdminMenu = (): MenuElementType[] => {
    const { t } = useTranslation('navigation');
    const [brandVisibilityVisible] = useFeatureFlag('brandVisibility');
    const [connectionsVisible] = useFeatureFlag('connections');

    const { isSuperAdmin } = useWhoami();

    const submenu = [];

    if (isSuperAdmin) {
        submenu.push({
            id: 'organization',
            title: t('menu.administration.organizations'),
            link: '/Admin/Organizations',
            matching: [
                '/Admin/Organizations',
                '/Admin/Organizations/:brandId',
                '/Admin',
            ],
            tooltip: t('menu.administration.organizationTooltip'),
        });
        submenu.push({
            id: 'brands',
            title: t('menu.administration.brands'),
            link: '/Admin/Brands',
            matching: ['/Admin/Brands', '/Admin/Brands/:brandId'],
            tooltip: t('menu.administration.brandsTooltip'),
        });
    }

    submenu.push({
        id: 'users',
        title: t('menu.administration.users'),
        link: '/Admin/Users',
        tooltip: t('menu.administration.usersTooltip'),
    });

    if (isSuperAdmin) {
        if (connectionsVisible) {
            submenu.push({
                id: 'connections',
                title: t('menu.administration.connections'),
                link: '/Admin/Connections',
                tooltip: t('menu.administration.connectionsTooltip'),
            });
        }

        submenu.push({
            id: 'import',
            title: t('menu.administration.import'),
            link: '/Admin/Import',
            tooltip: t('menu.administration.importsTooltip'),
        });

        submenu.push({
            id: 'exports',
            title: t('menu.administration.exports'),
            link: '/Admin/Exports',
        });

        if (brandVisibilityVisible) {
            submenu.push({
                id: 'brandVisibility',
                title: t('menu.administration.brandVisibility'),
                link: '/Admin/BrandVisibility',
                tooltip: t('menu.administration.brandVisibilityTooltip'),
            });
        }
    }

    return [
        {
            id: 'admin',
            title: t('menu.administration.administration'),
            link: '/Admin',
            startAdornment: <PollIcon />,
            submenu,
        },
    ];
};
