import { type ReactElement, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { SearchIcon } from '@xeris/components/icons';

import { IconButton } from '../IconButton/IconButton';

type SearchBarProps = {
    filter?: string;
    onChange?: (value: string) => void;
    onSubmit?: (value: string) => void;
    inputLabel: string;
};

const newSearchSchema = yup.object({
    searchTerm: yup.string(),
});

type FormData = yup.InferType<typeof newSearchSchema>;

export const SearchBar = ({
    filter = '',
    onChange,
    onSubmit,
    inputLabel,
}: SearchBarProps): ReactElement | null => {
    const handleExternalSubmit = (data: FormData): void => {
        onSubmit && onSubmit(data.searchTerm ?? '');
    };

    const { control, handleSubmit, watch } = useForm<FormData>({
        resolver: yupResolver(newSearchSchema),
        defaultValues: {
            searchTerm: filter,
        },
    });

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'searchTerm') {
                onChange && onChange(value?.searchTerm ?? '');
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, onChange]);

    return (
        <form onSubmit={handleSubmit(handleExternalSubmit)}>
            <FormControl fullWidth size="small">
                <Controller
                    name={'searchTerm'}
                    control={control}
                    render={({
                        field: { onChange: onFormChange, ...field },
                    }): ReactElement => (
                        <>
                            <OutlinedInput
                                {...field}
                                id={'searchTerm'}
                                color="primary"
                                type="text"
                                placeholder={inputLabel}
                                onChange={onFormChange}
                                sx={{
                                    'input::placeholder': { opacity: 0.8 },
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            type="submit"
                                            aria-label="Search"
                                            size={'small'}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </>
                    )}
                />
            </FormControl>
        </form>
    );
};
