import type { ProductListProps } from '@xeris/components/ProductList/ProductList';
import { type MasterProductWithIds } from '@xeris/pages/product/Common';

export const groupProducts = <MP extends MasterProductWithIds>(
    products: MP[],
    groups: { id: string; name: string }[],
    expanded: Record<string, boolean>,
    groupType: string | null,
    ungroupedName: string
): ProductListProps<MP>['listData'] => {
    if (!groupType) {
        return products.map((product) => ({
            ...product,
            type: 'products' as const,
        }));
    }

    const grouped: Record<string, MP[]> = {};

    products.forEach((product) => {
        const productGroups = product.groups.filter(
            (group) => group.type.id === groupType
        );

        if (productGroups.length === 0) {
            grouped['__ungrouped'] = [
                ...(grouped['__ungrouped'] ?? []),
                product,
            ];

            return;
        }

        productGroups.forEach((group) => {
            grouped[group.id] = [...(grouped[group.id] ?? []), product];
        });
    });

    return Object.entries(grouped).flatMap(([groupId, groupedProducts]) => [
        {
            id: groupId,
            type: 'section' as const,
            title:
                groups.find((group) => group.id === groupId)?.name ??
                ungroupedName,
            isOpen: expanded[groupId] ?? true,
            products: groupedProducts,
        },
        ...(expanded[groupId] ?? true
            ? groupedProducts.map((product) => ({
                  ...product,
                  type: 'products' as const,
              }))
            : []),
    ]);
};
