import { memo, type ReactElement, useMemo } from 'react';

import {
    MaterialList,
    MaterialPriceGroup,
    MaterialPriceGroupHeader,
} from '@xeris/components';
import { type Material } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';

import { variantGeneratorSelectors } from '../../../reducer';

import MaterialWrapper from './MaterialWrapper';

type PriceGroupHeaderProps = {
    featureId: string;
    priceGroupId: string;
    materials: Material[];
};

const PriceGroupHeader = ({
    featureId,
    materials,
    priceGroupId,
}: PriceGroupHeaderProps): ReactElement | null => {
    const optionIds = useMemo(
        () =>
            materials.flatMap(
                (material) => material.options.map(({ id }) => id),
                [materials]
            ),
        [materials]
    );

    const { available } = useAppSelector((state) =>
        variantGeneratorSelectors.selectedOptionCountByIds(
            state,
            featureId,
            optionIds
        )
    );

    return (
        <MaterialPriceGroupHeader
            optionCount={available}
            priceGroup={{
                name: priceGroupId,
            }}
        />
    );
};

type PriceGroupWrapperProps = {
    priceGroupId: string;
    featureId: string;
    materials: Material[];
};

const PriceGroupWrapper = ({
    priceGroupId,
    featureId,
    materials,
}: PriceGroupWrapperProps): ReactElement | null => {
    return (
        <MaterialPriceGroup>
            <PriceGroupHeader
                priceGroupId={priceGroupId}
                materials={materials}
                featureId={featureId}
            />
            <MaterialList>
                {materials.map((material) => (
                    <MaterialWrapper
                        key={material.id}
                        material={material}
                        featureId={featureId}
                    />
                ))}
            </MaterialList>
        </MaterialPriceGroup>
    );
};

export default memo(PriceGroupWrapper);
