import { type ReactElement } from 'react';

import { Typography } from '@mui/material';

import { type DescriptionType } from '@xeris/pages/product/types';

type DescriptionsContentProps = {
    data: DescriptionType[];
};
export const DescriptionsContent = ({
    data: descriptionList,
}: DescriptionsContentProps): ReactElement => (
    <div>
        {descriptionList.map((description, index) => (
            <div key={index}>
                <Typography variant={'subtitle1'} component={'h3'} gutterBottom>
                    {description.name}
                </Typography>
                <Typography variant={'body2'} paragraph maxWidth={'100ch'}>
                    {description.value}
                </Typography>
            </div>
        ))}
    </div>
);
