import { type ReactElement, useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import type { FeatureGroup, MasterProduct } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';

import { BasicPanel, MaterialPanel } from '../Panels/Summary';
import { variantGeneratorSelectors } from '../reducer';

import InlineNavigation from './InlineNavigation';

type FeatureGroupPanelProps = {
    featureGroup: FeatureGroup;
};

const FeatureGroupPanel = ({
    featureGroup,
}: FeatureGroupPanelProps): ReactElement | null => {
    const featureIds = useMemo(
        () => featureGroup.features.map(({ id }) => id),
        [featureGroup.features]
    );

    const { isSelected } = useAppSelector((state) =>
        variantGeneratorSelectors.featureStatuses(state, featureIds)
    );

    if (!isSelected) {
        return null;
    }

    return (
        <Box
            key={featureGroup.id}
            marginBottom={3}
            padding={3}
            bgcolor={'background.paper'}
            borderRadius={1}
        >
            <Typography variant={'h6'} component={'p'} gutterBottom>
                {featureGroup.name}
            </Typography>

            {featureGroup.features.map((feature) => {
                if (feature.__typename === 'BasicFeature') {
                    return <BasicPanel key={feature.id} feature={feature} />;
                }
                if (feature.__typename === 'MaterialFeature') {
                    return <MaterialPanel key={feature.id} feature={feature} />;
                }
            })}
        </Box>
    );
};

type PanelsWrapperProps = {
    masterProduct: MasterProduct<'featureConfiguration'>;
};

const PanelsWrapper = ({ masterProduct }: PanelsWrapperProps): ReactElement => {
    return (
        <div>
            {masterProduct.featureConfiguration?.featureGroups.map(
                (featureGroup) => (
                    <FeatureGroupPanel
                        featureGroup={featureGroup}
                        key={featureGroup.id}
                    />
                )
            )}
            <InlineNavigation
                featureGroups={
                    masterProduct.featureConfiguration?.featureGroups ?? []
                }
            />
        </div>
    );
};

export default PanelsWrapper;
