import { type ReactElement, type ReactNode } from 'react';

import { AccordionDetails, Box } from '@mui/material';

import { InfoBox } from '../../../InfoBox/InfoBox';
import { ShowMore } from '../../../ShowMore/ShowMore';

type MaterialOptionListProps = {
    children: NonNullable<ReactNode>;
    description?: string | null;
};
export const MaterialOptionList = ({
    children,
    description,
}: MaterialOptionListProps): ReactElement => {
    return (
        <AccordionDetails
            sx={{
                borderTop: '1px solid',
                borderColor: 'divider',
            }}
        >
            {description && (
                <InfoBox>
                    <ShowMore text={description} maxCharacters={150} />
                </InfoBox>
            )}
            <Box
                sx={{
                    display: 'grid',
                    width: '100%',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(48px, 1fr))',
                    gap: 1.5,
                }}
            >
                {children}
            </Box>
        </AccordionDetails>
    );
};
