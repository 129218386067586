import { type ReactElement, type ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Box } from '@mui/material';

import { ProductList } from '@xeris/components/ProductList/ProductList';
import { type SectionListData } from '@xeris/components/ProductList/types';
import { useProductListSettings } from '@xeris/pages/product/Common/GroupedProductList/useProductListSettings';
import { settingsActions } from '@xeris/pages/product/reducers/settingsSlice';
import { useAppDispatch } from '@xeris/reducers';

import { Toolbar } from './components/Toolbar';
import { type MasterProductWithIds } from './types';
import { filterMasterProduct, groupProducts } from './utilities';

type GroupedProductListProps<MP extends MasterProductWithIds> = {
    brandId: string;
    products: MP[];
    card: (product: MP) => ReactNode;
    listItem: (product: MP) => ReactNode;
    cardHeight: number;
    cardMinWidth: number;
    sectionActions?: (section: SectionListData<MP>) => ReactNode;
    sectionInfo?: (section: SectionListData<MP>) => ReactNode;
    groupTypes: {
        id: string;
        name: string;
        groups: { id: string; name: string }[];
    }[];
    markets: { id: string; name: string; externalId: string }[];
    toolbar?: (products: MP[]) => ReactNode;
    disableMarketFilter?: boolean;
};

export const GroupedProductList = <MP extends MasterProductWithIds>({
    brandId,
    products,
    card,
    listItem,
    sectionActions,
    sectionInfo,
    groupTypes,
    cardMinWidth,
    cardHeight,
    toolbar,
    markets,
    disableMarketFilter = false,
}: GroupedProductListProps<MP>): ReactElement => {
    const { t } = useTranslation('products');
    const dispatch = useAppDispatch();

    const [filterSearch, setFilterSearch] = useState('');

    const { viewType, groupTypeId, groups, marketId, expanded } =
        useProductListSettings(brandId, groupTypes, markets);

    const filteredProducts = useMemo(
        () =>
            products.filter(
                (product) =>
                    filterMasterProduct(product, filterSearch) &&
                    (disableMarketFilter ||
                        !marketId ||
                        product.markets.some(
                            (market) => market.id === marketId
                        ))
            ),
        [disableMarketFilter, filterSearch, products, marketId]
    );

    const list = useMemo(() => {
        return groupProducts(
            filteredProducts,
            groups,
            expanded,
            groupTypeId,
            t('common.ungrouped')
        );
    }, [filteredProducts, groups, expanded, groupTypeId, t]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                height: '100%',
            }}
        >
            <Toolbar
                key={brandId}
                brandId={brandId}
                onFilterChange={setFilterSearch}
                searchFilter={filterSearch}
                toolbar={toolbar?.(filteredProducts)}
                groupTypes={groupTypes}
                markets={markets}
                disableMarketFilter={disableMarketFilter}
            />
            <Box
                sx={{
                    flexGrow: 1,
                    borderTop: 1,
                    borderColor: 'divider',
                }}
            >
                {list.length ? (
                    <ProductList
                        listData={list}
                        card={card}
                        listItem={listItem}
                        type={viewType}
                        sectionActions={sectionActions}
                        sectionInfo={sectionInfo}
                        cardMinWidth={cardMinWidth}
                        cardHeight={cardHeight}
                        toggleSectionOpen={(id) => {
                            dispatch(
                                settingsActions.setGroupsExpanded({
                                    groupIds: [id],
                                    newValue: !(expanded[id] ?? true),
                                })
                            );
                        }}
                    />
                ) : (
                    <Alert severity={'info'}>{t('brand.noProducts')}</Alert>
                )}
            </Box>
        </Box>
    );
};
