import type { ReactElement } from 'react';
import type { Control, FieldValues, Path } from 'react-hook-form';

import { Box } from '@mui/material';

import { AutocompleteMultipleInput } from '@xeris/components/forms';
import { useWhoami } from '@xeris/hooks';
import { sharedDatasetsApi } from '@xeris/pages/dataset/api';
import type { DatasetSharedWith } from '@xeris/pages/dataset/types/datasetTypes';

import type { ShareDatasetFormType } from './schema';

type UserFieldProps<T extends FieldValues> = {
    fieldName: Path<T>;
    label?: string;
    control: Control<T>;
    sharedWith: DatasetSharedWith[];
};

const UserField = ({
    fieldName,
    label,
    control,
    sharedWith,
}: UserFieldProps<ShareDatasetFormType>): ReactElement => {
    const { userId, organizationId } = useWhoami();

    const { data } = sharedDatasetsApi.useGetUsersToShareDatasetWithQuery({
        organizationId: organizationId,
    });

    const options = (data?.connectedUsers ?? [])
        .filter((user) => user.id !== userId)
        .filter((user) => sharedWith.every((d) => d.user.id !== user.id))
        .map((user) => ({
            id: user.id,
            label: user.name ?? user.username,
            email: user.username,
            organizationId: user.organization?.id ?? '',
            organizationName: user.organization?.name ?? '',
        }));

    return (
        <AutocompleteMultipleInput
            fieldName={fieldName}
            label={label}
            control={control}
            options={options}
            size={'small'}
            disableCloseOnSelect
            disablePortal={false}
            groupBy={(option): string => option.organizationName}
            renderOption={(props, option): ReactElement => (
                <Box
                    {...props}
                    title={option.email}
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    key={option.id}
                >
                    <span>{option.label}</span>
                </Box>
            )}
        />
    );
};

export default UserField;
