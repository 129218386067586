import { type ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Box, Skeleton } from '@mui/material';

import { useWhoami } from '@xeris/hooks';

import Brands from './Brands/Brands';
import BrandVisibility from './BrandVisibility/BrandVisibility';
import { Connections } from './Connections/Connections';
import { Exports } from './Exports/Exports';
import Organization from './Organization/Organization';
import Users from './Users/Users';
import Import from './Import';

const AdminPage = (): ReactElement => {
    const { hasLoadedInfo, isSuperAdmin, isAdmin } = useWhoami();

    if (!hasLoadedInfo) {
        return <Skeleton height={300} />;
    }

    if (!(isSuperAdmin || isAdmin)) {
        return (
            <Routes>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        );
    }

    return (
        <Box
            sx={{
                maxWidth: '1440px',
                margin: '0 auto',
                height: 'calc(100% - 64px)',
            }}
        >
            <Routes>
                <Route path="Users" element={<Users />} />
                {isSuperAdmin && (
                    <Route
                        path="BrandVisibility"
                        element={<BrandVisibility />}
                    />
                )}
                {isSuperAdmin && <Route path="Import" element={<Import />} />}
                {isSuperAdmin && <Route path="Exports" element={<Exports />} />}
                {isSuperAdmin && (
                    <Route path="Connections/*" element={<Connections />} />
                )}
                {isSuperAdmin && (
                    <Route path={'Brands/*'} element={<Brands />} />
                )}
                {isSuperAdmin && (
                    <Route
                        path={'Organizations/*'}
                        element={<Organization />}
                    />
                )}
                <Route path="*" element={<Navigate to="Users" replace />} />
            </Routes>
        </Box>
    );
};

export default AdminPage;
