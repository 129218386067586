import { type ReactElement } from 'react';

import { Box, styled } from '@mui/material';

import { XerisSpinner } from '../XerisSpinner/XerisSpinner';

const SpinnerWrapper = styled(Box)({
    zIndex: 999,
    position: 'fixed',
    top: ' 50%',
    left: ' 50%',
});

const SpinnerContainer = styled(Box)({
    width: '80px',
    height: '80px',
    marginTop: '-40px',
    marginLeft: '-40px',
});

export const Loading = (): ReactElement => {
    return (
        <SpinnerWrapper>
            <SpinnerContainer>
                <XerisSpinner />
            </SpinnerContainer>
        </SpinnerWrapper>
    );
};
