import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MuiButton,
} from '@xeris/components';
import { DeleteIcon } from '@xeris/components/icons';
import { datasetProductsApi } from '@xeris/pages/dataset/api';
import {
    useActiveDatasetId,
    useConfigurationSets,
} from '@xeris/pages/dataset/hooks';

type DeleteConfigurationDialogProps = {
    isOpen: boolean;
    configurationSetName: string;
    titleName?: string;
    masterProductId?: string;
    handleCloseDialog: () => void;
    selectedConfiguration?: {
        id: string;
        name: string;
    } | null;
    handleRemoveAllProducts?: () => void;
};

const DeleteConfigurationDialog = ({
    isOpen,
    configurationSetName,
    titleName,
    masterProductId,
    handleCloseDialog,
    selectedConfiguration,
    handleRemoveAllProducts,
}: DeleteConfigurationDialogProps): ReactElement => {
    const { t } = useTranslation('product');

    const activeDatasetId = useActiveDatasetId();
    const configurationSets = useConfigurationSets(masterProductId);

    const [removeProductsFromDataset] =
        datasetProductsApi.useRemoveProductsFromDatasetMutation();
    const [deleteConfigurationSet] =
        datasetProductsApi.useDeleteConfigurationSetMutation();

    const handleRemoveProduct = (): void => {
        if (activeDatasetId && masterProductId) {
            removeProductsFromDataset({
                datasetId: activeDatasetId,
                productIds: null,
                masterProductIds: [masterProductId],
            });
            handleCloseDialog();
        }
    };

    const handleRemoveConfigurationSet = (): void => {
        if (!masterProductId && handleRemoveAllProducts) {
            handleRemoveAllProducts();
            return handleCloseDialog();
        }

        if (configurationSets.length < 2) {
            return handleRemoveProduct();
        }

        if (!selectedConfiguration) {
            return;
        }

        deleteConfigurationSet({
            id: selectedConfiguration.id,
            datasetId: activeDatasetId ?? '',
        });
        handleCloseDialog();
    };

    return (
        <Dialog open={isOpen} onClose={handleCloseDialog}>
            <DialogTitle>
                {titleName ? titleName : t('common.deleteDialog.title')}
            </DialogTitle>

            <DialogContent>
                {t('common.deleteDialog.text', {
                    configurationSetName,
                })}
            </DialogContent>

            <DialogActions>
                <MuiButton onClick={handleCloseDialog} color="secondary">
                    {t('common.cancel')}
                </MuiButton>
                <MuiButton
                    onClick={handleRemoveConfigurationSet}
                    startIcon={<DeleteIcon />}
                    variant={'contained'}
                    color="error"
                >
                    {t('common.delete')}
                </MuiButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfigurationDialog;
