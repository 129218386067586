import { search } from '@xeris/utilities';

export const filterMasterProduct = (
    masterProduct: {
        name: string;
        idNumbers: { value: string }[];
        products: {
            name: string;
            idNumbers: { value: string }[];
        }[];
    },
    searchTerms: string
): boolean => {
    const names = [
        masterProduct.name,
        ...masterProduct.products.map((product) => product.name),
    ];

    const idNumbers = [
        ...masterProduct.idNumbers.map(({ value }) => value),
        ...masterProduct.products.flatMap((product) =>
            product.idNumbers.map(({ value }) => value)
        ),
    ];

    return search(searchTerms, ...names, ...idNumbers);
};
