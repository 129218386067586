import { differenceInMonths, format, formatDistanceStrict } from 'date-fns';

export const getDateAndTime = (
    time: string | number | Date,
    locale = 'no-No'
): string => {
    const options = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    } as const;

    return new Date(time).toLocaleString(locale, options);
};

export const formatDateAsDistanceIfRecent = (time: Date): string => {
    if (Math.abs(differenceInMonths(time, new Date())) < 3) {
        return formatDistanceStrict(time, new Date(), { addSuffix: true });
    }

    return format(time, 'PPP');
};
