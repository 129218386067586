import { type FormEvent, type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Stack, Typography } from '@mui/material';

import { userVerificationApi } from '@xeris/pages/admin/api';

import { BackToLoginButton, UserNameInput } from '../Common';

type PasswordSentConfirmationProps = {
    setIsSent: (isSent: boolean) => void;
};

const PasswordSentConfirmation = ({
    setIsSent,
}: PasswordSentConfirmationProps): ReactElement => {
    const { t } = useTranslation('login');

    return (
        <Stack direction={'column'}>
            <Typography variant={'h3'} component={'h1'} gutterBottom>
                {t('forgotPassword.checkEmail')}
            </Typography>
            <div>
                <Typography gutterBottom>
                    {t('forgotPassword.passwordResetLinkSent')}
                </Typography>
                <Typography gutterBottom>
                    {t('forgotPassword.needToRegisterToReceive')}
                </Typography>
                <BackToLoginButton />

                <Typography marginTop={2}>
                    {t('forgotPassword.checkSpamIfNotReceived')} <br />
                    <Link
                        to="/login/?action=forgot-password"
                        onClick={(): void => setIsSent(false)}
                    >
                        {t('forgotPassword.tryOtherEmail')}
                    </Link>
                </Typography>
            </div>
        </Stack>
    );
};

type ForgotPasswordFormProps = {
    setIsSent: (isSent: boolean) => void;
};

const ForgotPasswordForm = ({
    setIsSent,
}: ForgotPasswordFormProps): ReactElement => {
    const { t } = useTranslation('login');

    const [email, setEmail] = useState('');

    const [resetUserPasswordMutation] =
        userVerificationApi.useResetUserPasswordMutation();

    const handleResetPasswordClick = (event: FormEvent): void => {
        event.preventDefault();
        resetUserPasswordMutation(email);
        setIsSent(true);
    };

    return (
        <Stack direction={'column'} gap={4}>
            <div>
                <Typography
                    component={'h1'}
                    variant={'h3'}
                    fontWeight={'normal'}
                    gutterBottom
                >
                    {t('forgotPassword.forgotYourPassword')}
                </Typography>
                <Typography>{t('forgotPassword.enterMailBelow')}</Typography>
            </div>
            <Stack
                component={'form'}
                direction={'column'}
                onSubmit={handleResetPasswordClick}
                width={'100%'}
                gap={4}
            >
                <UserNameInput value={email} handleChange={setEmail} />

                <Button disabled={!email} type={'submit'} variant={'contained'}>
                    {t('forgotPassword.send')}
                </Button>
            </Stack>
            <BackToLoginButton />
        </Stack>
    );
};

const ForgotPassword = (): ReactElement => {
    const [isSent, setIsSent] = useState(false);

    if (isSent) {
        return <PasswordSentConfirmation setIsSent={setIsSent} />;
    }

    return <ForgotPasswordForm setIsSent={setIsSent} />;
};

export default ForgotPassword;
