import { Fragment, type ReactElement, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';

import { Image, Tooltip } from '@xeris/components';
import { CloseIcon } from '@xeris/components/icons';
import { lookup } from '@xeris/pages/product/Common/Datasheet/ProductData/ProductDataFactory/components/Environmental';
import { useAppDispatch } from '@xeris/reducers';

import { setMaterialInfo } from '../reducer';

import { type MasterProductForInfoBox } from './api/types';

type MaterialInfoBoxContentProps = {
    masterProduct?: MasterProductForInfoBox | null;
};

type MaterialCollapseBoxProps = {
    label: string;
    children?: ReactNode;
};

const MaterialCollapseBox = ({
    label,
    children,
}: MaterialCollapseBoxProps): ReactElement | null => {
    if (!children) return null;

    return (
        <Accordion defaultExpanded>
            <AccordionSummary>
                <Typography variant={'h4'} component={'p'}>
                    {label}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

const MaterialInfoBoxContentOverlay = ({
    masterProduct,
}: MaterialInfoBoxContentProps): ReactElement | null => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const handleCloseClick = (): void => {
        dispatch(setMaterialInfo({ masterProductId: null }));
    };

    if (!masterProduct) return null;

    return (
        <Box padding={2}>
            <Stack marginBottom={2}>
                <Typography variant={'h3'}>
                    {t('variantGenerator.information', {
                        name: masterProduct?.name ?? '',
                    })}
                </Typography>

                <IconButton onClick={handleCloseClick} edge={'end'}>
                    <CloseIcon />
                </IconButton>
            </Stack>

            <Box sx={{ marginBottom: 2 }}>
                <Typography>{masterProduct.brand.name}</Typography>
                <Typography variant={'h3'}>{masterProduct.name}</Typography>
            </Box>
            <Box
                display={'grid'}
                gap={1}
                gridTemplateColumns={'repeat(auto-fit, 40px)'}
                marginBlock={1}
            >
                {
                    !!masterProduct.certifications
                        ?.map(({ name }) => ({ name, image: lookup[name] }))
                        .filter((certificate) => !!certificate.image)
                        .map(({ name, image }) => (
                            <Tooltip title={name} key={name}>
                                <Image
                                    src={`/assets/img/certificates/${image}`}
                                    alt={name}
                                    height={'40px'}
                                    width={'40px'}
                                />
                            </Tooltip>
                        ))
                }
            </Box>
            <Box>
                <MaterialCollapseBox label={t('common.panelHeaders.idNumbers')}>
                    <Box
                        display={'grid'}
                        gridTemplateColumns={'150px 1fr'}
                        gap={1}
                    >
                        {masterProduct.idNumbers?.map((idNumber) => (
                            <Fragment key={idNumber.type}>
                                <Typography sx={{ wordBreak: 'break-word' }}>
                                    {idNumber.name}
                                </Typography>
                                <Typography sx={{ wordBreak: 'break-word' }}>
                                    {idNumber.value}
                                </Typography>
                            </Fragment>
                        ))}
                    </Box>
                </MaterialCollapseBox>
                <MaterialCollapseBox
                    label={t('common.panelHeaders.descriptions')}
                >
                    {masterProduct.descriptions?.map((description) => (
                        <Typography key={description.id}>
                            {description.value}
                        </Typography>
                    ))}
                </MaterialCollapseBox>
                <MaterialCollapseBox
                    label={t('common.panelHeaders.specifications')}
                >
                    <Box
                        display={'grid'}
                        gridTemplateColumns={'150px 1fr'}
                        gap={1}
                    >
                        {masterProduct.specifications?.map((specification) => (
                            <Fragment key={specification.type}>
                                <Typography sx={{ wordBreak: 'break-word' }}>
                                    {specification.name}
                                </Typography>
                                <Typography sx={{ wordBreak: 'break-word' }}>
                                    {specification.value}
                                </Typography>
                            </Fragment>
                        ))}
                    </Box>
                </MaterialCollapseBox>
            </Box>
        </Box>
    );
};

export default MaterialInfoBoxContentOverlay;
