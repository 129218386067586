import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Alert,
    AlertTitle,
    Box,
    Chip,
    CircularProgress,
    LinearProgress,
    Stack,
    Typography,
} from '@mui/material';

import { BackButton, StatusTag } from '@xeris/components';
import { AlertIcon, ArrowRightIcon } from '@xeris/components/icons';
import { type StatusTagProps } from '@xeris/components/StatusTag/StatusTag';
import { type ExportJob } from '@xeris/pages/exportJob/types';

type DurationProps = {
    exportJob: ExportJob;
};

const Duration = ({ exportJob }: DurationProps): ReactElement | null => {
    const { t } = useTranslation('exports');

    const durationInMilliseconds =
        new Date(exportJob.endTime ?? Date.now()).getTime() -
        new Date(exportJob.startTime).getTime();

    const hoursWithDecimal = durationInMilliseconds / 3600 / 1000;
    const hours = Math.floor(hoursWithDecimal);

    const minutesWithDecimal = (hoursWithDecimal - hours) * 60;
    const minutes = Math.floor(minutesWithDecimal);

    const seconds = Math.floor((minutesWithDecimal - minutes) * 60);

    const durationList = [];
    if (hours > 0) durationList.push(`${hours}h`);
    if (minutes > 0) durationList.push(`${minutes}m`);
    if (seconds > 0 && hours === 0) durationList.push(`${seconds}s`);

    if (durationList.length === 0) {
        return null;
    }

    return (
        <Typography variant={'h6'} component={'p'}>
            {t('exportJob.duration', { duration: durationList.join(' ') })}
        </Typography>
    );
};

type ExportStatusTagProps = {
    progress: ExportJob['productProgress'];
};

const ExportStatusTag = ({ progress }: ExportStatusTagProps): ReactElement => {
    const { t } = useTranslation('exports');

    const getStatus = (): StatusTagProps => {
        if (progress.processing > 0) {
            return { type: 'processing', title: t('common.processing') };
        }
        if (progress.failed === progress.total) {
            return { type: 'error', title: t('common.failed') };
        }
        if (progress.finished === progress.total) {
            return { type: 'success', title: t('common.completed') };
        }
        return { type: 'indeterminate', title: t('common.processing') };
    };

    const { type, title } = getStatus();

    return <StatusTag type={type} title={title} />;
};

type HeaderProps = {
    exportJob?: ExportJob | null;
};

const Header = ({ exportJob }: HeaderProps): ReactElement | null => {
    const { t } = useTranslation('exports');

    if (!exportJob) {
        return null;
    }

    const isComplete =
        exportJob.productProgress.finished === exportJob.productProgress.total;

    return (
        <Box marginBottom={2}>
            <BackButton to={'/Export'}>{t('exportJob.allExports')}</BackButton>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                marginBottom={2}
            >
                <Stack spacing={2} marginTop={1}>
                    <Typography variant={'h1'}>
                        {t('exportJob.exportNumber', { number: exportJob.id })}
                    </Typography>
                    <ExportStatusTag progress={exportJob.productProgress} />
                </Stack>
                <Duration exportJob={exportJob} />
            </Box>

            <Box
                display={'flex'}
                alignItems={'stretch'}
                justifyContent={'space-between'}
            >
                <Box
                    border={1}
                    borderColor={'divider'}
                    padding={3}
                    paddingLeft={6}
                    flexBasis={'50%'}
                >
                    <Typography color={'secondary.light'}>
                        {t('common.dataset')}
                    </Typography>
                    <Typography variant={'h6'} component={'p'}>
                        {exportJob.dataset.name}
                    </Typography>
                </Box>
                <Box flexBasis={'0px'} position={'relative'}>
                    <Box
                        position={'absolute'}
                        top={'calc(50% - 18px)'}
                        left={'-18px'}
                        width={'36px'}
                        height={'36px'}
                        sx={{
                            backgroundColor: 'background.default',
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <ArrowRightIcon fontSize={'small'} color={'icons'} />
                    </Box>
                </Box>
                <Box
                    border={1}
                    borderLeft={0}
                    borderColor={'divider'}
                    padding={3}
                    paddingLeft={6}
                    flexBasis={'50%'}
                >
                    <Typography color={'secondary.light'}>
                        {t('exportJob.destination')}
                    </Typography>
                    <Typography variant={'h6'} component={'p'}>
                        {exportJob.export.name}
                    </Typography>
                </Box>
            </Box>
            {!isComplete && (
                <Box position={'relative'}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '-10px',
                            textAlign: 'center',
                            width: '100%',
                            zIndex: 10,
                        }}
                    >
                        <Chip
                            icon={<CircularProgress size={16} />}
                            label={t('exportJob.percentCompleted', {
                                percent: exportJob.productProgress.percent,
                            })}
                            sx={{
                                backgroundColor: 'background.default',
                                border: '1px solid',
                                borderColor: 'divider',
                            }}
                        />
                    </Box>
                    <LinearProgress
                        variant="determinate"
                        color={'info'}
                        value={1}
                        sx={{ height: '12px', borderRadius: '0 0 5px 5px' }}
                    />
                </Box>
            )}
            {!!exportJob.message && (
                <Alert
                    severity={'error'}
                    icon={<AlertIcon />}
                    sx={{ marginTop: 2 }}
                >
                    <AlertTitle>{t('exportJob.exportFailed')}</AlertTitle>
                    {exportJob.message}
                </Alert>
            )}
        </Box>
    );
};

export default Header;
