import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Chip, Typography } from '@mui/material';

import { CircleIcon } from '@xeris/components/icons';
import { useWhoami } from '@xeris/hooks';
import { useActiveDatasetId } from '@xeris/pages/dataset/hooks';

import { generateDatasetUrl } from '../../utilities';

type DatasetNameProps = {
    dataset: {
        id: string;
        name: string;
        totalCount: number;
        owner: {
            id: string;
            name: string | null;
        };
    };
};

const DatasetName = ({ dataset }: DatasetNameProps): ReactElement => {
    const { t } = useTranslation('dataset');

    const { userId } = useWhoami();
    const activeDatasetId = useActiveDatasetId();

    const createdBy =
        userId === dataset.owner.id ? t('common.you') : dataset.owner.name;

    return (
        <Box
            to={generateDatasetUrl(dataset)}
            component={Link}
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 0.2,
            }}
        >
            <Typography fontWeight={'medium'}>{dataset.name}</Typography>
            <Typography
                variant={'caption'}
                lineHeight={1}
                sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}
            >
                {t('common.numberOfProducts', { count: dataset.totalCount })}
                <CircleIcon fontSize="inherit" color={'icons'} />
                {t('datasetList.createdBy', {
                    usernameOrYou: createdBy,
                })}
                {dataset.id === activeDatasetId && (
                    <Chip
                        color={'primary'}
                        size={'small'}
                        label={t('common.active')}
                    />
                )}
            </Typography>
        </Box>
    );
};

export default DatasetName;
