import { type BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { GraphQLClient, type RequestDocument } from 'graphql-request';

import { loginApi } from '@xeris/pages/login/api/loginApi';
import type { StateType } from '@xeris/types';

import { validateToken } from './token/validateToken';

const requestUrl = new URL('/graphql', window.location.origin);

export const client = new GraphQLClient(requestUrl.toString());

const graphQLBaseQuery = graphqlRequestBaseQuery({
    client,
    url: requestUrl.toString(),
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as StateType).login.token;

        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Accept', 'application/json');

        return headers;
    },
});

const baseQuery: BaseQueryFn<{
    document: RequestDocument;
    variables?: Record<string, unknown>;
}> = async (args, apiMethods, extraOptions) => {
    const { dispatch, getState } = apiMethods;

    const { expires, token: currentToken } = (getState() as StateType).login;

    const isValidToken = validateToken(currentToken, expires);

    if (!isValidToken) {
        await dispatch(loginApi.endpoints.refreshToken.initiate({}));
    }

    return graphQLBaseQuery(args, apiMethods, extraOptions);
};

export const baseRtkApi = createApi({
    baseQuery: baseQuery,
    tagTypes: [
        'dataset',
        'connections',
        'exports',
        'brands',
        'organizations',
        'exportJobs',
        'users',
        'sources',
        'mappingConfigurations',
    ],
    endpoints: () => ({}),
});
