import { useMemo } from 'react';

import { useActiveDataset } from './useActiveDataset';

type Status = {
    isSelected: boolean;
    isConfigured: boolean;
};

export const useConfigurableProductStatus = (
    configurableProductId: string | undefined
): Status => {
    const activeDataset = useActiveDataset();

    const node = useMemo(
        () =>
            activeDataset?.selectedProductData?.nodeList?.find(
                ({ id }) => id === configurableProductId
            ),
        [activeDataset, configurableProductId]
    );

    if (!configurableProductId) {
        return { isSelected: false, isConfigured: false };
    }

    if (!node) {
        return { isSelected: false, isConfigured: false };
    }

    return {
        isSelected: true,
        isConfigured:
            node.__typename === 'SelectedMasterProduct' &&
            node.configurationSets.length > 0,
    };
};
