import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@xeris/components';
import { ShareIcon } from '@xeris/components/icons';
import type { DatasetSharedWith } from '@xeris/pages/dataset/types/datasetTypes';

import { ShareDatasetForm } from './ShareDatasetForm';

type ShareDatasetProps = {
    dataset?: {
        id: string;
        name: string;
        sharedWith: DatasetSharedWith[];
    };
    handleClose?: () => void;
};

export const ShareDataset = ({
    dataset,
    handleClose: handleExternalClose,
}: ShareDatasetProps): ReactElement | null => {
    const { t } = useTranslation('dataset');
    const [isOpen, setIsOpen] = useState(!!handleExternalClose);

    const handleClose = (): void => {
        setIsOpen(false);
        handleExternalClose && handleExternalClose();
    };

    if (!dataset) {
        return null;
    }

    return (
        <>
            <IconButton
                rounded
                onClick={() => setIsOpen(true)}
                tooltipTitle={t('datasetSharingPopUp.shareDataset')}
            >
                <ShareIcon fontSize={'small'} />
            </IconButton>
            {isOpen && (
                <ShareDatasetForm
                    isOpen={isOpen}
                    handleClose={handleClose}
                    dataset={dataset}
                />
            )}
        </>
    );
};
