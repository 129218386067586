import { useTranslation } from 'react-i18next';

import { type MenuElementType } from '@xeris/components';
import { SearchIcon, ViewDashboardIcon } from '@xeris/components/icons';

export const useProductsMenu = (): MenuElementType[] => {
    const { t } = useTranslation('navigation');

    return [
        {
            id: 'ProductsSearch',
            title: t('menu.products.search'),
            link: '/Products?tab=search',
            matches: [],
            startAdornment: <SearchIcon />,
            isAlwaysShown: true,
            neverActive: true,
        },
        {
            id: 'Products',
            title: t('menu.products.products'),
            link: '/Products',
            matches: [
                '/Product',
                '/Products',
                '/MasterProduct',
                '/ProductList',
            ],
            startAdornment: <ViewDashboardIcon />,
            isAlwaysShown: true,
        },
    ];
};
