import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { MutationSnackbars } from '@xeris/components';
import { FormDialog } from '@xeris/components/forms';
import { userApi } from '@xeris/pages/admin/Users/api/userApi';

type DeleteUserDialogProps = {
    username: string | null;
    isOpen: boolean;
    handleClose: () => void;
};

export const DeleteUserForm = ({
    username,
    isOpen,
    handleClose,
}: DeleteUserDialogProps): ReactElement => {
    const { t } = useTranslation('administration');

    const [deleteUserMutation, { isLoading, isSuccess, isError }] =
        userApi.useDeleteUserMutation();

    return (
        <>
            <FormDialog
                open={isOpen}
                onClose={handleClose}
                onSubmit={async (event) => {
                    event.preventDefault();
                    if (username) {
                        await deleteUserMutation({ username }).unwrap();
                    }
                    handleClose();
                }}
                title={t('users.deleteUser?')}
                isLoading={isLoading}
                cancelText={t('brand.common.cancel')}
                saveText={t('brand.common.delete')}
                buttonProps={{ color: 'error' }}
            >
                <Typography>
                    {t('users.willDeleteDatasets', { username })}
                </Typography>
            </FormDialog>
            <MutationSnackbars
                isSuccess={isSuccess}
                successText={t('users.userDeleted')}
                isError={isError}
                errorText={t('users.deleteUserFailed')}
            />
        </>
    );
};
