import {
    type ReactElement,
    type ReactNode,
    type SyntheticEvent,
    useEffect,
    useState,
} from 'react';

import { Chip, type SxProps, Tab, Tabs, Toolbar } from '@mui/material';

export type TabProviderTabType<T extends string = string> = {
    id: T;
    Label: ReactNode;
    Component: ReactNode;
    count?: number;
};

export type TabsProviderProps<T extends string = string> = {
    tabList: TabProviderTabType<T>[];
    activeTabId?: T;
    handleChangeTabId?: (newActiveTabId: T) => void;
    toolbar?: ReactNode | null;
    sx?: SxProps;
};

export const TabsProvider = <T extends string = string>({
    tabList,
    activeTabId: externalActiveTabId,
    handleChangeTabId: externalHandleChangeTabId,
    toolbar = null,
    sx,
}: TabsProviderProps<T>): ReactElement => {
    const [activeTabId, setActiveTabId] = useState(
        externalActiveTabId ? externalActiveTabId : tabList[0]?.id
    );

    const [activeTabComponent, setActiveTabComponent] = useState<ReactNode>();

    useEffect(() => {
        if (externalActiveTabId) {
            setActiveTabId(externalActiveTabId);
        }
    }, [externalActiveTabId]);

    useEffect(() => {
        const newActiveComponent = tabList.find(
            (tab) => tab.id === activeTabId
        )?.Component;

        if (newActiveComponent) {
            setActiveTabComponent(newActiveComponent);
        } else {
            setActiveTabId(tabList[0]?.id);
        }
    }, [tabList, activeTabId]);

    const handleChangeTabId = (
        event: SyntheticEvent,
        newActiveTabId: T
    ): void => {
        if (externalHandleChangeTabId) {
            externalHandleChangeTabId(newActiveTabId);
        } else {
            setActiveTabId(newActiveTabId);
        }
    };

    return (
        <>
            <Toolbar
                sx={{
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    marginBottom: 2,
                    position: 'sticky',
                    top: '62px' /* the Header is 64px, but setting 64px causes a visual glitch that makes text show between tabs and Header  */,
                    backgroundColor: '#FFFFFF',
                    zIndex: 'speedDial',
                    ...sx,
                }}
            >
                <Tabs
                    value={
                        // MUI will complain if it for a moment have an invalid tab value
                        // This happens when the selected tab becomes unavailable/is removed
                        // This makes sure the value is correct until useEffect has run and set it
                        tabList.some(({ id }) => id === activeTabId)
                            ? activeTabId
                            : tabList[0]?.id
                    }
                    onChange={handleChangeTabId}
                    sx={{
                        'flexGrow': 1,
                        'minHeight': 4,
                        '& .MuiTabs-flexContainer': {
                            display: 'flex',
                            gap: 1,
                        },
                    }}
                >
                    {tabList.map((tab) => {
                        return (
                            <Tab
                                key={tab.id}
                                label={tab.Label}
                                value={tab.id}
                                iconPosition="end"
                                {...(tab.count !== undefined && {
                                    icon: (
                                        <Chip
                                            size={'small'}
                                            label={tab.count}
                                            color={
                                                tab.id === activeTabId
                                                    ? 'primary'
                                                    : 'secondary'
                                            }
                                        />
                                    ),
                                })}
                            />
                        );
                    })}
                </Tabs>

                {toolbar}
            </Toolbar>

            {activeTabComponent}
        </>
    );
};
