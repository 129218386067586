import { type ReactElement, type ReactNode } from 'react';

import { Box, type SxProps, Typography } from '@mui/material';

type EmptyStateProps = {
    title: string;
    children?: ReactNode;
    sx?: SxProps;
};

export const EmptyState = ({
    title,
    children,
    sx,
}: EmptyStateProps): ReactElement => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                flexGrow: 1,
                gap: 2,
                marginTop: '20vh',
                ...sx,
            }}
        >
            <Typography variant={'h1'} component={'p'}>
                {title}
            </Typography>
            {children}
        </Box>
    );
};
