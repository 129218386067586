import { type ReactElement, type ReactNode } from 'react';

import { Table, TableContainer } from '@mui/material';

type MaterialFeatureSummaryTableProps = {
    children: ReactNode;
};

export const MaterialSummaryTable = ({
    children,
}: MaterialFeatureSummaryTableProps): ReactElement => {
    return (
        <TableContainer sx={{ overflow: 'unset' }}>
            <Table
                sx={{
                    '& .MuiTableCell-root': { padding: 1 },
                    '& .MuiTableCell-paddingNone': { padding: 0 },
                }}
            >
                {children}
            </Table>
        </TableContainer>
    );
};
