import { type ReactElement, type ReactNode } from 'react';
import {
    type Control,
    type FieldValues,
    type Path,
    useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    FormControl,
    FormHelperText,
    TextField,
    type TextFieldProps,
} from '@mui/material';

import { InputLabelTop } from '../InputLabelTop/InputLabelTop';

type TextInputProps<T extends FieldValues> = {
    fieldName: Path<T>;
    label?: ReactNode;
    showError?: boolean;
    control: Control<T>;
} & TextFieldProps;

export const TextInput = <T extends FieldValues>({
    fieldName,
    label,
    showError = false,
    control,
    ...inputProps
}: TextInputProps<T>): ReactElement => {
    const { t } = useTranslation('common');

    const {
        field: { ...field },
        fieldState: { error },
    } = useController({
        name: fieldName,
        control,
    });

    return (
        <FormControl fullWidth error={!!error}>
            <InputLabelTop
                labelPlacement={'top'}
                label={label}
                required={false}
                disabled={inputProps.disabled || false}
                control={
                    <TextField
                        fullWidth
                        {...inputProps}
                        {...field}
                        value={field.value ?? ''}
                        error={!!error}
                    />
                }
            />
            {showError && (
                <FormHelperText>
                    {error?.type &&
                        t(`formErrors.${error.type}`, {
                            defaultValue: '',
                            fieldName: fieldName,
                            message: error.message,
                        })}
                </FormHelperText>
            )}
        </FormControl>
    );
};
