import { useTranslation } from 'react-i18next';

import { type MenuElementType } from '@xeris/components';
import { UploadOutlinedIcon } from '@xeris/components/icons';

export const useExportMenu = (): MenuElementType[] => {
    const { t } = useTranslation('navigation');

    const exportsMenuElement = {
        id: 'exportJob',
        title: t('menu.exports'),
        link: '/Export',
        matches: ['/Export', '/Dataset/Exports/'],
        startAdornment: <UploadOutlinedIcon />,
        isAlwaysShown: true,
        tooltip: t('menu.datasetExportTooltip'),
    };

    return [exportsMenuElement];
};
