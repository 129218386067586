import { type ChangeEventHandler, type ReactElement } from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';

type CheckboxFormControlProps = {
    checked: boolean;
    handleToggle: ChangeEventHandler;
    label: ReactElement | string;
    name: string;
};

export const CheckboxFormControl = ({
    checked,
    handleToggle,
    label,
    name,
}: CheckboxFormControlProps): ReactElement => {
    // In the case that the label contain a link, the checkbox should not
    // call the onChange handler.
    const handleChange: ChangeEventHandler = (event) => {
        if (event.target instanceof HTMLAnchorElement) {
            return;
        }

        handleToggle(event);
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    value={checked}
                    checked={checked}
                    indeterminate={false}
                    onChange={handleChange}
                    name={name}
                />
            }
            label={label}
            sx={{ width: '100%' }}
        />
    );
};
