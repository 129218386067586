import { type ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';

import type { MasterProduct } from '@xeris/pages/product/types';
import { NavButton } from '@xeris/pages/product/variantGenerator/Navigation/NavButton';
import { useAppSelector } from '@xeris/reducers';

import ConfigurableProductDataSelector from '../../ProductDataSelector/ConfigurableProductDataSelector';
import type { StageType } from '../Panels/Header';
import { variantGeneratorSelectors } from '../reducer';

type BackToSummaryProps = {
    previousStage: StageType;
};

const BackToSummary = ({ previousStage }: BackToSummaryProps): ReactElement => {
    const { t } = useTranslation('product');

    return (
        <Stack flexDirection={'column'} alignItems={'center'} paddingBlock={8}>
            <Typography variant={'h4'} component={'p'} gutterBottom>
                {t('variantGenerator.changeSomething')}
            </Typography>
            <NavButton
                back
                to={{}}
                state={{ page: previousStage.pathFraction }}
            >
                {t('variantGenerator.backToSummary')}
            </NavButton>
        </Stack>
    );
};

type ProductDataSelectorWrapperProps = {
    previousStage: StageType;
    handleSelectionClose: () => void;
    masterProduct: MasterProduct<
        | 'name'
        | 'brand'
        | 'images'
        | 'featureConfiguration'
        | 'selectedData'
        | 'documents'
        | 'models'
    >;
};

const ProductDataSelectorWrapper = ({
    previousStage,
    handleSelectionClose,
    masterProduct,
}: ProductDataSelectorWrapperProps): ReactElement => {
    const { datasetId, configurationSetId } = useParams();
    const configurationSet = useAppSelector(
        variantGeneratorSelectors.selectFeatureSelection
    );

    const features = useMemo(
        () =>
            Object.entries(configurationSet).map(([featureId, options]) => ({
                id: featureId,
                options: Object.keys(options).map((id) => ({
                    id,
                })),
            })),
        [configurationSet]
    );

    return (
        <ConfigurableProductDataSelector
            handleSelectionClose={handleSelectionClose}
            datasetId={datasetId}
            configurationSetId={configurationSetId}
            masterProduct={masterProduct}
            selectedFeatures={features}
            isInlineDialog={true}
            customContentHeader={true}
            customPanels={<BackToSummary previousStage={previousStage} />}
        />
    );
};

type SelectDataPanelProps = {
    previousStage: StageType;
    handleSelectionClose: () => void;
    masterProduct: MasterProduct<
        | 'name'
        | 'brand'
        | 'images'
        | 'featureConfiguration'
        | 'selectedData'
        | 'documents'
        | 'models'
    >;
};

const SelectDataPanel = ({
    previousStage,
    handleSelectionClose,
    masterProduct,
}: SelectDataPanelProps): ReactElement => {
    return (
        <ProductDataSelectorWrapper
            previousStage={previousStage}
            handleSelectionClose={handleSelectionClose}
            masterProduct={masterProduct}
        />
    );
};

export default SelectDataPanel;
