import { type ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { PlusIcon } from '@xeris/components/icons';
import NewConnectionForm from '@xeris/pages/admin/Connections/forms/NewConnectionForm';

import {
    type Connection,
    type ConnectionsTabVariantType,
    type UiConnectionType,
} from '../../../types/connectionsTypes';

type NewConnectionProps = {
    uiConnectionType: UiConnectionType;
    connectionType: ConnectionsTabVariantType;
    connectionList: Connection[];
};

export const NewConnection = ({
    uiConnectionType,
    connectionType,
    connectionList,
}: NewConnectionProps): ReactElement => {
    const { t } = useTranslation('administration');
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = useCallback((): void => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            <Button
                startIcon={<PlusIcon fontSize="small" />}
                onClick={() => setIsOpen(true)}
                variant={'contained'}
            >
                {t(`connections.new.${uiConnectionType}`)}
            </Button>
            <NewConnectionForm
                isOpen={isOpen}
                handleClose={handleClose}
                uiConnectionType={uiConnectionType}
                connectionType={connectionType}
                connectionList={connectionList}
            />
        </>
    );
};
