import { type ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { fileUploadApi } from '@xeris/api';
import {
    CheckboxInput,
    DropzoneInput,
    SelectInput,
} from '@xeris/components/forms';
import { importsApi } from '@xeris/pages/imports/api/importsApi';

import { StepWrapper } from '../components/StepWrapper';
import { TabularPreview } from '../components/TabularPreview';
import { type StepProps } from '../types';

const uploadFileSchema = yup
    .object({
        files: yup.array().of(yup.mixed<File>().required()).min(1).required(),
        fileID: yup.string().required(),
        headerRowPresent: yup.boolean().required(),
        delimiter: yup.string().required(),
        encoding: yup.string().required(),
        headerNames: yup.array().of(yup.string().required()).required(),
    })
    .required();

export type UploadFileSchema = yup.InferType<typeof uploadFileSchema>;

const defaultValues = {
    files: [],
    fileID: '',
    headerRowPresent: true,
    delimiter: ';',
    encoding: 'utf-8',
    headerNames: [],
};

type UploadFileProps = StepProps;

export const UploadFile = ({
    activeStep,
    header,
    handleClose,
    data,
    setData,
}: UploadFileProps): ReactElement => {
    const { t } = useTranslation('imports');

    const { handleSubmit, control, getValues, setValue, watch } = useForm({
        resolver: yupResolver(uploadFileSchema),
        defaultValues: data?.file || defaultValues,
    });

    const [getFileImportUploadUrl, getFileImportUploadUrlResponse] =
        importsApi.useGetFileImportUploadUrlMutation();

    const [uploadFile, uploadFileResponse] =
        fileUploadApi.useUploadFileMutation();

    const { files, fileID, ...options } = watch();

    const { data: headerData } = importsApi.useGetCSVHeadersQuery(
        { ...options, fileID, previewLines: options.headerRowPresent ? 6 : 5 },
        { skip: !fileID }
    );

    useEffect(() => {
        setValue(
            'headerNames',
            (headerData?.parseUploadedCsv?.headers ?? []).map(
                ({ columnName }) => columnName
            )
        );
    }, [headerData?.parseUploadedCsv?.headers, setValue]);

    return (
        <StepWrapper
            title={t('sources.uploadFile.uploadFile')}
            handleClose={handleClose}
            header={header}
            activeStep={activeStep}
            onBack={() => setData({ file: getValues() }, -1)}
            onSubmit={handleSubmit(
                (newData) => setData({ file: newData }, 1),
                console.error
            )}
        >
            <DropzoneInput
                label={t('sources.uploadFile.file')}
                value={files}
                showError
                error={
                    (!!getFileImportUploadUrlResponse.error &&
                        t('sources.uploadFile.failedToGetURL')) ||
                    (!!uploadFileResponse.error &&
                        t('sources.uploadFile.failedToUploadFile')) ||
                    undefined
                }
                onChange={async (newFiles) => {
                    setValue('files', newFiles);
                    setValue('fileID', '');
                    setValue('headerNames', []);

                    if (newFiles.length === 0) return;

                    const { createFileImportUploadUrl } =
                        await getFileImportUploadUrl({}).unwrap();

                    if (!createFileImportUploadUrl) return;

                    const { uploadUrl, id } = createFileImportUploadUrl;

                    await uploadFile({
                        url: uploadUrl,
                        file: newFiles[0],
                    }).unwrap();

                    setValue('fileID', id);
                }}
            />
            {!getFileImportUploadUrlResponse.isError &&
                uploadFileResponse.isSuccess && (
                    <Typography marginBlock={1} color={'success'}>
                        {t('sources.uploadFile.fileUploaded')}
                    </Typography>
                )}
            <CheckboxInput
                fieldName={'headerRowPresent'}
                control={control}
                label={t('sources.uploadFile.hasHeaderRow')}
            />
            <SelectInput
                fieldName={'delimiter'}
                control={control}
                options={[
                    { id: ';', label: 'Semicolon' },
                    { id: '\t', label: 'Tab' },
                    { id: ',', label: 'Comma' },
                ]}
                label={t('sources.uploadFile.delimiter')}
            />
            <SelectInput
                fieldName={'encoding'}
                control={control}
                options={[{ id: 'utf-8', label: 'UTF-8' }]}
                label={t('sources.uploadFile.encoding')}
            />

            {fileID && headerData?.parseUploadedCsv && (
                <>
                    <Typography variant={'h3'} gutterBottom>
                        {t('sources.uploadFile.preview')}
                    </Typography>
                    <Box sx={{ maxHeight: '500px', overflow: 'scroll' }}>
                        <TabularPreview
                            headerRowPresent={options.headerRowPresent}
                            headers={headerData?.parseUploadedCsv?.headers}
                        />
                    </Box>
                </>
            )}
        </StepWrapper>
    );
};
