import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { TipsBox } from '@xeris/components';

type TipContentProps = {
    title: string;
    body: string;
};

const TipContent = ({ title, body }: TipContentProps): ReactElement => {
    return (
        <Box>
            <Typography
                variant={'subtitle1'}
                component={'p'}
                fontWeight={'fontWeightMedium'}
            >
                {title}
            </Typography>
            <Typography lineHeight={1.4}>{body}</Typography>
        </Box>
    );
};

const SearchInfo = (): ReactElement => {
    const { t } = useTranslation('products');

    return (
        <Box
            sx={{
                textAlign: 'left',
                maxWidth: 440,
                margin: '0 auto',
            }}
        >
            <TipsBox title={t('search.productSearch')}>
                <TipContent
                    title={t('search.tips.minimumInputTitle')}
                    body={t('search.tips.minimumInputBody')}
                />
                <TipContent
                    title={t('search.tips.suggestionsTitle')}
                    body={t('search.tips.suggestionsBody')}
                />
                <TipContent
                    title={t('search.tips.multipleTitle')}
                    body={t('search.tips.multipleBody')}
                />
                <TipContent
                    title={t('search.tips.quotationTitle')}
                    body={t('search.tips.quotationBody')}
                />
            </TipsBox>
        </Box>
    );
};

export default SearchInfo;
