import {
    createSelector,
    createSlice,
    type PayloadAction,
} from '@reduxjs/toolkit';

import {
    type Expression,
    type MappedAttributeTypeWithChildren,
    type MappedNode,
} from '@xeris/pages/imports/types';
import {
    getMapping,
    getNode,
    type Path,
    removeMapping,
    setMapping,
    setNode,
} from '@xeris/pages/imports/utilities/updateMapping';
import { type StateType } from '@xeris/types';

export type ImportMappingSlice = {
    mapping: MappedAttributeTypeWithChildren;
};

const initialState: ImportMappingSlice = {
    mapping: {
        __typename: 'NodeMappingAttribute',
        attributeTypeId: 'root',
        attributes: [],
    },
};

export const importMappingSlice = createSlice({
    name: 'importMapping',
    initialState,
    reducers: {
        initializeMapping: (
            state,
            action: PayloadAction<MappedNode[] | undefined>
        ) => {
            state.mapping.attributes = action.payload ?? [];
        },
        // Set a leaf node to a given expression
        setAttributeMapping: (
            state,
            action: PayloadAction<{
                path: Path;
                expression: Expression | null;
            }>
        ) => {
            state.mapping = setMapping(
                state.mapping,
                action.payload.path,
                action.payload.expression
            );
        },
        // Set any node to an empty expression
        setAttributeNode: (
            state,
            action: PayloadAction<{
                path: Path;
            }>
        ) => {
            state.mapping = setNode(state.mapping, action.payload.path).mapping;
        },
        removeAttributeMapping: (
            state,
            action: PayloadAction<{
                path: Path;
            }>
        ) => {
            state.mapping = removeMapping(state.mapping, action.payload.path);
        },
    },
});

export const {
    setAttributeMapping,
    setAttributeNode,
    removeAttributeMapping,
    initializeMapping,
} = importMappingSlice.actions;

export const importMappingReducer = importMappingSlice.reducer;

export const getAttributeMapping = createSelector(
    (state: StateType) => state.importMapping.mapping,
    (state: StateType, attributePath: Path) => attributePath,
    (mapping, path) => getMapping(mapping, path)
);

export const getFullMapping = createSelector(
    (state: StateType) => state.importMapping.mapping,
    (mapping) => mapping.attributes
);

export const selectAttributeStatus = createSelector(
    (state: StateType) => state.importMapping.mapping,
    (state: StateType, path: Path) => path,
    (mapping, path) => {
        const isVisible = !!getNode(mapping, path);

        return { isVisible };
    }
);

export const selectSectionAttributeCount = createSelector(
    (state: StateType) => state.importMapping.mapping,
    (state: StateType, attributes: { id: string; required: boolean }[]) =>
        attributes,
    (mapping, attributes) => {
        const mappedCount = attributes.filter(
            (attribute) =>
                attribute.required ||
                mapping.attributes.some(
                    (a) => a.attributeTypeId === attribute.id
                )
        ).length;

        return {
            mappedCount,
            totalCount: attributes.length,
        };
    }
);
