import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Typography } from '@mui/material';

import { useWhoami } from '@xeris/hooks';
import { userVerificationApi } from '@xeris/pages/admin/api';

const ResetPassword = (): ReactElement => {
    const { t } = useTranslation('user');
    const { username } = useWhoami();
    const [
        resetUserPasswordMutation,
        { isSuccess, isError, isLoading, error },
    ] = userVerificationApi.useResetUserPasswordMutation();

    const handleResetPassword = (): void => {
        resetUserPasswordMutation(username);
    };

    return (
        <Box marginBlock={'12px'}>
            <Typography variant={'h3'} gutterBottom>
                {t('password.password')}
            </Typography>
            <Typography gutterBottom>
                {t('password.receiveInstructionsInEmail')}
            </Typography>
            <LoadingButton
                loading={isLoading}
                variant={'outlined'}
                color={'secondary'}
                onClick={handleResetPassword}
            >
                {t('password.changePassword')}
            </LoadingButton>

            {isSuccess && (
                <Alert sx={{ marginTop: 2 }}>
                    <AlertTitle>{t('password.sentEmail')}</AlertTitle>
                    <Typography gutterBottom>
                        {t('password.checkInbox')}
                    </Typography>
                    <Typography variant={'body2'}>
                        {t('password.noReceivedEmail')}
                    </Typography>
                </Alert>
            )}
            {isError && (
                <Alert sx={{ marginTop: 2 }} severity={'error'}>
                    <AlertTitle>{t('password.error')}</AlertTitle>
                    <Typography gutterBottom>
                        {error &&
                            'data' in error &&
                            typeof error.data === 'string' &&
                            error.data}
                    </Typography>
                </Alert>
            )}
        </Box>
    );
};

export default ResetPassword;
