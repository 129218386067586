import { type ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, IconButton } from '@mui/material';

import { ChevronLeftIcon, ChevronRightIcon } from '@xeris/components/icons';
import { type PreviewConfiguration3D } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';
import type { ApiVariant3D, ApiView3D } from '@xeris/types/graphql';

import { variantGeneratorSelectors } from '../../reducer';
import ImageTools from '../ImageTools';

import useModel3DEvents from './hooks/useModel3DEvents';
import useModel3DInstance from './hooks/useModel3DInstance';

import './index.scss';

type Viewer3DProps = {
    preview: PreviewConfiguration3D;
    options: {
        id: string;
        image: string | null;
    }[];
};

/*
 * Split the login in hooks in this component is not a solution that I love, but it's easier to be tested
 * and since the useEffect logics are a bit mind-blowing, some proper testing is mandatory here
 */
const Viewer3D = ({ preview, options }: Viewer3DProps): ReactElement => {
    const { t } = useTranslation('product');

    const FREE_CAMERA_VIEW: ApiView3D = {
        id: 'id',
        name: t('variantGenerator.freeCamera'),
        eye: null,
        target: null,
    };
    const canvasRef = useRef(null);
    const containerRef = useRef(null);

    const selectedPreview = useAppSelector(
        variantGeneratorSelectors.selectPreviewSelection
    );

    const [currentView, setCurrentView] = useState(0);
    const model3DInstance = useModel3DInstance(canvasRef);

    if (!preview) throw Error('Should not show viewer without preview options');

    const model = preview.model;
    const modelViews = preview.views;
    const views = [FREE_CAMERA_VIEW].concat([...modelViews]);

    const selectedVariantByFeature = preview.components.reduce(
        (acc, component) => {
            const selectedVariant = component.variants.find((variant) => {
                return component.features.every((featureId, index) => {
                    return (
                        variant.options[index] === '*' ||
                        selectedPreview[featureId] === variant.options[index]
                    );
                });
            });
            acc[component.id] = selectedVariant ?? null;
            return acc;
        },
        {} as Record<string, ApiVariant3D | null>
    );

    const disabledFeatures = Object.keys(selectedVariantByFeature).filter(
        (key) => !selectedVariantByFeature[key]
    );

    const materialsByFeature = Object.fromEntries(
        Object.entries(selectedVariantByFeature).map(([feature, variant]) => {
            if (!variant) return [feature, variant];

            const featureId =
                variant.texture?.match(/options\(([^)]*)\).*/)?.[1];

            if (!featureId) return [feature, variant.texture];

            const optionId = selectedPreview[featureId];

            if (!optionId) return [feature, null];

            const option = options.find(({ id }) => id === optionId);

            // Todo: Make the texture url change the type based on the original JSON specification. eg
            // Todo, cont: replace the /thumbnail/ into /original/ in order to give the highest level of detail for the texture
            return [feature, option?.image ?? null];
        })
    );

    const handleOnModuleReadyToLoad = async (
        renderStarted: boolean
    ): Promise<void> => {
        await model3DInstance?.loadModel(model);
        handleSelectionChange();
        if (!renderStarted) model3DInstance?.start();
    };

    const handleSelectionChange = (): void => {
        model3DInstance?.toggleComponent(disabledFeatures);
        model3DInstance?.changeMaterial(materialsByFeature);
    };

    const handleResize = (): void => {
        model3DInstance?.resize();
    };

    useModel3DEvents(
        containerRef,
        model3DInstance,
        model,
        handleOnModuleReadyToLoad,
        handleSelectionChange,
        handleResize,
        [disabledFeatures, materialsByFeature]
    );

    const handleViewChange = (nextIndex: number): void => {
        const view = views[nextIndex];
        if (!view.eye || !view.target) model3DInstance?.freeCamera();
        else model3DInstance?.setCamera(view.eye, view.target);

        setCurrentView(nextIndex);
    };

    const handleSnapshotClick = (): void => {
        model3DInstance?.takeScreenshot();
    };

    const handleClick = (): void => {
        handleViewChange(currentView - 1);
    };

    const canvasStyle = {
        cursor: currentView === 0 ? 'grab' : undefined,
    };

    return (
        <div className="viewer-3d">
            {!model3DInstance && <div>Loading</div>}

            <div style={canvasStyle} ref={containerRef}>
                <canvas ref={canvasRef} />
            </div>

            <Grid
                container
                className="view-selector"
                alignItems="center"
                justifyContent="space-between"
            >
                <Grid item>
                    <IconButton
                        onClick={handleClick}
                        disabled={currentView === 0}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                </Grid>
                <Grid item>{views[currentView].name}</Grid>
                <Grid item>
                    <IconButton
                        onClick={(): void => handleViewChange(currentView + 1)}
                        disabled={currentView >= views.length - 1}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <ImageTools handleSnapshotClick={handleSnapshotClick} />
        </div>
    );
};

export default Viewer3D;
