import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import {
    MuiButton,
    ProductCard,
    type SimpleImageType,
} from '@xeris/components';
import { RightPanelOpenIcon } from '@xeris/components/icons';

type InactiveProductCardProps = {
    product: {
        id: string;
        name: string;
        isConfigurable: boolean | null;
        images: SimpleImageType[] | null;
        products: {
            id: string;
        }[];
    };
    onClick?: () => void;
};

export const InactiveProductCard = ({
    product,
    onClick,
}: InactiveProductCardProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <Box
            key={product.id}
            sx={{
                height: '340px',
                width: '210px',
                flexGrow: 0,
                flexBasis: '220px',
            }}
        >
            <ProductCard
                images={product.images}
                actions={
                    !product.isConfigurable && (
                        <MuiButton
                            variant={'outlinedSolid'}
                            color={'secondary'}
                            size={'large'}
                            aria-label={t('connections.showVariants')}
                            round
                            onClick={() => {
                                if (!product.isConfigurable && onClick) {
                                    onClick();
                                }
                            }}
                        >
                            <RightPanelOpenIcon color={'secondary'} />
                        </MuiButton>
                    )
                }
            >
                <Typography>{product.name}</Typography>
                <Typography variant="body2">
                    {product.isConfigurable
                        ? t('connections.configurable')
                        : t('connections.numberOfVariants', {
                              count: product.products.length,
                          })}
                </Typography>
            </ProductCard>
        </Box>
    );
};
