import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Alert, Box, Typography } from '@mui/material';

import { ErrorPage, ProductCard, Skeleton } from '@xeris/components';
import { ProductListItem } from '@xeris/components/ProductListItem/ProductListItem';
import { useDataLanguage } from '@xeris/hooks';
import { masterProductApi } from '@xeris/pages/product/api/masterProductApi/masterProductApi';
import { SectionCounts } from '@xeris/pages/product/MasterProducts/SectionCounts';
import { masterProductUrl } from '@xeris/utilities/urls';

import { MultipleMasterProductsSelector, ProductListSkeleton } from '../Common';
import { GroupedProductList } from '../Common/GroupedProductList/GroupedProductList';
import { ActionButtons } from '../Common/ProductEntityCard/ActionButtons/ActionButtons';

const MasterProducts = (): ReactElement => {
    const { t } = useTranslation('product');

    const { brandId = '' } = useParams();
    const dataLanguage = useDataLanguage();

    const { data, isLoading, isError, refetch } =
        masterProductApi.useGetAllMasterProductsForBrandQuery({
            brandId,
            dataLanguage,
        });

    if (isLoading) {
        return (
            <div>
                <Skeleton height={100} />
                <ProductListSkeleton />
            </div>
        );
    }

    if (isError) {
        return (
            <ErrorPage
                title={t('errors.failedToLoad')}
                actionText={t('errors.tryAgain')}
                onClick={() => refetch()}
            />
        );
    }

    if (!data?.brand) {
        return <ErrorPage title={t('errors.brandNotFound', { brandId })} />;
    }

    if (data.brand.masterProducts.length === 0) {
        return (
            <Alert severity={'info'}>
                {t('productList.brandHasNoProducts', {
                    brandName: data.brand.name,
                })}
            </Alert>
        );
    }

    return (
        <GroupedProductList
            brandId={brandId}
            products={data.brand.masterProducts}
            groupTypes={data.brand.groupTypes}
            markets={data.brand.markets}
            cardHeight={380}
            cardMinWidth={210}
            listItem={(product) => (
                <ProductListItem
                    key={product.id}
                    images={product.images}
                    url={masterProductUrl(product)}
                    actions={
                        <ActionButtons
                            masterProduct={product}
                            isActionButtonsAlwaysVisible={false}
                            size={'medium'}
                        />
                    }
                >
                    <Typography>{product.name}</Typography>
                    <Typography variant="body2">
                        {product.isConfigurable
                            ? t('productCard.configurable')
                            : t('dataSelector.numberOfVariants', {
                                  count: product.products.length,
                              })}
                    </Typography>
                </ProductListItem>
            )}
            card={(product) => (
                <Box
                    key={product.id}
                    sx={{
                        height: '340px',
                        width: '210px',
                        flexGrow: 0,
                        flexBasis: '220px',
                    }}
                >
                    <ProductCard
                        images={product.images}
                        url={masterProductUrl(product)}
                        actions={
                            <ActionButtons
                                masterProduct={product}
                                isActionButtonsAlwaysVisible={false}
                                size={'large'}
                            />
                        }
                    >
                        <Typography>{product.name}</Typography>
                        <Typography variant="body2">
                            {product.isConfigurable
                                ? t('productCard.configurable')
                                : t('dataSelector.numberOfVariants', {
                                      count: product.products.length,
                                  })}
                        </Typography>
                    </ProductCard>
                </Box>
            )}
            sectionActions={(section) => (
                <Box sx={{ minWidth: '120px' }}>
                    <MultipleMasterProductsSelector
                        showCounters={false}
                        masterProductList={section.products}
                        showWhenSelectedButton={true}
                    />
                </Box>
            )}
            sectionInfo={(section) => (
                <SectionCounts products={section.products} />
            )}
            toolbar={(products) => (
                <MultipleMasterProductsSelector
                    showWhenSelectedButton={false}
                    showCounters={true}
                    showAmountInDataset={true}
                    masterProductList={products}
                />
            )}
        />
    );
};

export default MasterProducts;
