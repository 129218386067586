import { baseRtkApi } from '@xeris/reducers/baseApi';

import {
    addConfigurationSetMutation,
    addProductsMutation,
    deleteConfigurationSetMutation,
    getMasterProductWithSelectedDataQuery,
    getProductIdsQuery,
    getProductsWithSelectedDataQuery,
    removeMissingProductsMutation,
    removeProductsMutation,
    updateConfigurationSetMutation,
} from './datasetProductsQueries';
import {
    type ApiAddConfigurationSetMutation,
    type ApiAddConfigurationSetMutationVariables,
    type ApiAddProductsMutation,
    type ApiAddProductsMutationVariables,
    type ApiDeleteConfigurationSetMutation,
    type ApiDeleteConfigurationSetMutationVariables,
    type ApiDeleteMissingProductsMutation,
    type ApiDeleteMissingProductsMutationVariables,
    type ApiGetMasterProductWithSelectedDataQuery,
    type ApiGetMasterProductWithSelectedDataQueryVariables,
    type ApiGetProductIdsQuery,
    type ApiGetProductIdsQueryVariables,
    type ApiGetProductsWithSelectedDataQuery,
    type ApiGetProductsWithSelectedDataQueryVariables,
    type ApiRemoveProductsMutation,
    type ApiRemoveProductsMutationVariables,
    type ApiUpdateConfigurationSetMutation,
    type ApiUpdateConfigurationSetMutationVariables,
} from './datasetProductsQueries.gql';

export const datasetProductsApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        addProductsToDataset: builder.mutation<
            ApiAddProductsMutation,
            ApiAddProductsMutationVariables
        >({
            query: (variables) => ({
                document: addProductsMutation,
                variables,
            }),
            invalidatesTags: (_, __, arg) => [
                { type: 'dataset', id: arg.data.id ?? '' },
            ],
        }),
        removeProductsFromDataset: builder.mutation<
            ApiRemoveProductsMutation,
            ApiRemoveProductsMutationVariables
        >({
            query: (variables) => ({
                document: removeProductsMutation,
                variables,
            }),
            async onQueryStarted(
                { datasetId, ...patch },
                { dispatch, queryFulfilled }
            ) {
                try {
                    await queryFulfilled;
                    dispatch(
                        datasetProductsApi.util.updateQueryData(
                            'getProductIds',
                            { datasetId: datasetId },
                            (draft) => {
                                if (!draft.dataset) {
                                    return draft;
                                }

                                draft.dataset.selectedProducts =
                                    draft.dataset.selectedProducts.filter(
                                        (product) =>
                                            !(patch.productIds ?? []).includes(
                                                product.id
                                            )
                                    );
                                draft.dataset.selectedMasterProducts =
                                    draft.dataset.selectedMasterProducts.filter(
                                        (product) =>
                                            !(
                                                patch.masterProductIds ?? []
                                            ).includes(product.id)
                                    );
                            }
                        )
                    );
                } catch {
                    // Noop
                }
            },
        }),
        //Used to purge the references to non-existing products
        removeMissingProductsFromDataset: builder.mutation<
            ApiDeleteMissingProductsMutation,
            ApiDeleteMissingProductsMutationVariables
        >({
            query: (variables) => ({
                document: removeMissingProductsMutation,
                variables,
            }),
            invalidatesTags: (_, __, arg) => [{ type: 'dataset', id: arg.id }],
        }),
        addConfigurationSet: builder.mutation<
            ApiAddConfigurationSetMutation,
            ApiAddConfigurationSetMutationVariables
        >({
            query: (variables) => ({
                document: addConfigurationSetMutation,
                variables,
            }),
            invalidatesTags: (_, __, arg) => [
                { type: 'dataset', id: arg.datasetId },
            ],
        }),
        updateConfigurationSet: builder.mutation<
            ApiUpdateConfigurationSetMutation,
            ApiUpdateConfigurationSetMutationVariables & { datasetId: string }
        >({
            query: (variables) => ({
                document: updateConfigurationSetMutation,
                variables,
            }),
            invalidatesTags: (_, __, arg) => [
                { type: 'dataset', id: arg.datasetId },
            ],
        }),
        deleteConfigurationSet: builder.mutation<
            ApiDeleteConfigurationSetMutation,
            ApiDeleteConfigurationSetMutationVariables & { datasetId: string }
        >({
            query: (variables) => ({
                document: deleteConfigurationSetMutation,
                variables,
            }),
            invalidatesTags: (_, __, arg) => [
                { type: 'dataset', id: arg.datasetId },
            ],
        }),
        getMasterProductWithSelectedData: builder.query<
            ApiGetMasterProductWithSelectedDataQuery,
            ApiGetMasterProductWithSelectedDataQueryVariables
        >({
            query: (variables) => ({
                document: getMasterProductWithSelectedDataQuery,
                variables,
            }),
            providesTags: (_, __, arg) => [
                { type: 'dataset', id: arg.datasetId },
            ],
        }),
        getProductsWithSelectedData: builder.query<
            ApiGetProductsWithSelectedDataQuery,
            ApiGetProductsWithSelectedDataQueryVariables
        >({
            query: (variables) => ({
                document: getProductsWithSelectedDataQuery,
                variables,
            }),
            providesTags: (_, __, arg) => [
                { type: 'dataset', id: arg.datasetId },
            ],
        }),
        getProductIds: builder.query<
            ApiGetProductIdsQuery,
            ApiGetProductIdsQueryVariables
        >({
            query: (variables) => ({
                document: getProductIdsQuery,
                variables,
            }),
            providesTags: (_, __, arg) => [
                { type: 'dataset', id: arg.datasetId },
            ],
        }),
    }),
});
