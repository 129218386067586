import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import { useAppSelector } from '@xeris/reducers';

export const useFilterProductEntityListByMarket = <
    Product extends {
        markets: { id: string }[];
        products?: {
            markets: { id: string }[];
        }[];
    },
>(
    productEntityList: Product[],
    brandId: string
): Product[] => {
    const selectedMarketId = useAppSelector((state) =>
        settingsSelectors.selectMarket(state, brandId)
    );

    if (selectedMarketId) {
        return productEntityList.filter(({ markets, products }) =>
            [
                ...markets,
                ...(products?.flatMap((product) => product.markets) ?? []),
            ].some((market) => market.id === selectedMarketId)
        );
    }

    return productEntityList;
};
