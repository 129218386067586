import { storeLocalState } from '@xeris/utilities';

import { type DatasetSliceType } from '../types/datasetTypes';

import { ACTIVE_DATASET_ID } from './activeDatasetId';

export function setActiveDataset(
    state: DatasetSliceType,
    datasetId?: string
): void {
    if (!datasetId) {
        state.activeDataset = undefined;
        state.activeId = storeLocalState(ACTIVE_DATASET_ID, {
            activeId: undefined,
        }).activeId;

        return;
    }

    const newDataset = state.datasets.entities[datasetId];

    if (!newDataset) {
        state.activeDataset = undefined;
        state.activeId = storeLocalState(ACTIVE_DATASET_ID, {
            activeId: undefined,
        }).activeId;

        return;
    }

    state.activeId = storeLocalState(ACTIVE_DATASET_ID, {
        activeId: newDataset.id,
    }).activeId;
}
