import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography, useMediaQuery } from '@mui/material';

import { Tooltip } from '@xeris/components';
import { InformationOutlineIcon } from '@xeris/components/icons';

import AccessInfoContent from './AccessInfoContent';

import styles from './AccessInfo.module.scss';

export const AccessInfo = (): ReactElement => {
    const { t } = useTranslation('dataset');
    const matches = useMediaQuery('(min-width:1500px)');

    return (
        <div className={styles.wrapper}>
            <Typography>{t('datasetSharingPopUp.shareWithUsers')}</Typography>
            <Tooltip
                placement={matches ? 'right-start' : 'bottom-end'}
                describeChild
                leaveDelay={500}
                title={<AccessInfoContent />}
                enterDelay={500}
                enterTouchDelay={0}
                arrow={false}
                componentsProps={{
                    tooltip: {
                        sx: (theme) => ({
                            maxWidth: '500px',
                            padding: '1.4rem',
                            backgroundColor: 'white !important',
                            boxShadow: 3,
                            color: theme.palette.text.primary,
                        }),
                    },
                }}
            >
                <Button
                    className={styles.aboutShareBtn}
                    color={'secondary'}
                    endIcon={<InformationOutlineIcon fontSize="small" />}
                    disableRipple
                    sx={{ marginRight: '-4px' }}
                >
                    <Typography> {t('datasetSharingPopUp.about')}</Typography>
                </Button>
            </Tooltip>
        </div>
    );
};

export default AccessInfo;
