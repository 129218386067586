import { type ReactElement, type ReactNode } from 'react';

import { Box, Collapse, Divider, TableCell, TableRow } from '@mui/material';

type MaterialSummaryTableOptionsListProps = {
    children: ReactNode;
    isExpanded?: boolean;
};

export const MaterialSummaryTableOptionsList = ({
    children,
    isExpanded,
}: MaterialSummaryTableOptionsListProps): ReactElement => {
    return (
        <TableRow>
            <TableCell padding={'none'} colSpan={8} sx={{ borderBottom: 0 }}>
                <Collapse in={isExpanded} unmountOnExit>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns:
                                'repeat(auto-fit, minmax(250px, 1fr))',
                            rowGap: 0.5,
                            columnGap: 2,
                            paddingBlock: 2,
                        }}
                    >
                        {children}
                    </Box>
                    <Divider />
                </Collapse>
            </TableCell>
        </TableRow>
    );
};
