import { type ReactElement } from 'react';

import { Typography } from '@mui/material';

type AttributeNameProps = {
    attribute: {
        name: string;
        multivalue: boolean;
        required: boolean;
    };
};

export const AttributeName = ({
    attribute,
}: AttributeNameProps): ReactElement => {
    return (
        <>
            {attribute.name}
            {attribute.multivalue && (
                <Typography marginLeft={2} variant={'caption'}>
                    {'(Multiple)'}
                </Typography>
            )}
            {attribute.required && (
                <Typography marginLeft={2} variant={'caption'}>
                    {'(Required)'}
                </Typography>
            )}
        </>
    );
};
