import {
    type MouseEvent as ReactMouseEvent,
    type ReactElement,
    useMemo,
} from 'react';

import { IconButton, Tooltip } from '@mui/material';

import { MaterialHeader } from '@xeris/components';
import { InformationOutlineIcon } from '@xeris/components/icons';
import { type Material } from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import {
    selectOptions,
    setMaterialInfo,
    variantGeneratorSelectors,
} from '../../../reducer';

type MaterialDetailsProps = {
    featureId: string;
    material: Material;
};
export const MaterialDetails = ({
    featureId,
    material,
}: MaterialDetailsProps): ReactElement => {
    const dispatch = useAppDispatch();

    const optionIds = useMemo(
        () => material.options.map(({ id }) => id),
        [material.options]
    );

    const { isSelectable, isPreviewed } = useAppSelector((state) =>
        variantGeneratorSelectors.optionStatuses(state, featureId, optionIds)
    );

    const { available, selected } = useAppSelector((state) =>
        variantGeneratorSelectors.selectedOptionCountByIds(
            state,
            featureId,
            optionIds
        )
    );

    const handleInfoButtonClick = (
        event: ReactMouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
        event.stopPropagation();
        dispatch(
            setMaterialInfo({
                masterProductId: material.masterProduct?.id ?? null,
            })
        );
    };

    const handleToggleAllOptions = (): void => {
        dispatch(
            selectOptions({
                featureId: featureId,
                optionIds: material.options.map(({ id }) => id),
                newValue: selected === 0,
            })
        );
    };

    return (
        <MaterialHeader
            materialName={material.name}
            supplierName={material.supplier?.name}
            totalCount={available}
            selectedCount={selected}
            price={material.price}
            defaultPrice={'0'}
            isPreviewed={isPreviewed}
            isSelected={selected === available}
            isSelectable={isSelectable}
            isPartiallySelected={selected > 0}
            handleToggleMaterial={handleToggleAllOptions}
        >
            {material.masterProduct?.id && (
                <Tooltip title={`Open specification for ${material.name}`}>
                    <IconButton onClick={handleInfoButtonClick} color={'info'}>
                        <InformationOutlineIcon />
                    </IconButton>
                </Tooltip>
            )}
        </MaterialHeader>
    );
};
