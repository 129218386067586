import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { FormDrawer, SelectInput } from '@xeris/components/forms';
import { importsApi } from '@xeris/pages/imports/api/importsApi';

const mappingSchema = yup.object({
    sourceId: yup.string().required('required'),
    documentType: yup.string().required('required'),
    targetEntityTypeId: yup.string().required('required'),
});

export type CreateMappingFormData = yup.InferType<typeof mappingSchema>;

type CreateMappingFormProps = {
    isOpen: boolean;
    handleClose: () => void;
};

export const CreateMapping = ({
    isOpen,
    handleClose,
}: CreateMappingFormProps): ReactElement => {
    const navigate = useNavigate();

    const { data: sources } = importsApi.useGetSourcesQuery({});
    const { data: toplevelEntities } = importsApi.useGetToplevelEntitiesQuery(
        {}
    );

    const { handleSubmit, control, reset, watch } =
        useForm<CreateMappingFormData>({
            resolver: yupResolver(mappingSchema),
        });

    const sourceList =
        sources?.sources.map((source) => ({
            id: source.id,
            label: source.name,
        })) ?? [];

    const sourceId = watch('sourceId');

    const documentList =
        sources?.sources
            .find((source) => source.id === sourceId)
            ?.schema.map((schema) => ({
                id: schema.documentType,
                label: schema.documentType,
            })) ?? [];

    return (
        <>
            <FormDrawer
                open={isOpen}
                onClose={handleClose}
                onSubmit={handleSubmit(async (data) => {
                    navigate('/import/mapping/new', { state: data });
                    reset();
                    handleClose();
                })}
                title={'Create mapping'}
                cancelText={'Cancel'}
                saveText={'Continue'}
            >
                <SelectInput
                    label={'Source'}
                    fieldName={'sourceId'}
                    control={control}
                    options={sourceList}
                    showError
                />
                <SelectInput
                    label={'Document'}
                    fieldName={'documentType'}
                    control={control}
                    options={documentList}
                    showError
                />
                <SelectInput
                    label={'Target entity'}
                    fieldName={'targetEntityTypeId'}
                    control={control}
                    options={toplevelEntities?.toplevelEntities ?? []}
                    optionLabelField={'name'}
                    showError
                />
            </FormDrawer>
        </>
    );
};
