import { type ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { MaterialSummarySwatch } from '@xeris/components';
import { type MaterialOption } from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import {
    selectOptions,
    selectPreview,
    variantGeneratorSelectors,
} from '../../../reducer';

type MaterialOptionProps = {
    option: MaterialOption;
    featureId: string;
};

export const MaterialOptionCard = ({
    option,
    featureId,
}: MaterialOptionProps): ReactElement | null => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const { isPreviewed, isSelected, isPreviewable } = useAppSelector((state) =>
        variantGeneratorSelectors.optionStatus(state, featureId, option.id)
    );

    const handleSelectPreview = useCallback((): void => {
        dispatch(
            selectPreview({
                optionId: option.id,
                featureId: featureId,
            })
        );
    }, [dispatch, featureId, option.id]);

    const handleRemoveOption = useCallback((): void => {
        dispatch(
            selectOptions({
                optionIds: [option.id],
                featureId: featureId,
                newValue: false,
            })
        );
    }, [dispatch, featureId, option.id]);

    if (!isSelected) return null;

    return (
        <MaterialSummarySwatch
            name={option.name}
            image={option.image}
            removeOption={handleRemoveOption}
            setPreviewOption={handleSelectPreview}
            isPreviewed={isPreviewed}
            notPreviewable={!isPreviewable}
            removeOptionTooltip={t(
                'variantGenerator.materialSummary.removeOption'
            )}
        />
    );
};
