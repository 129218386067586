import { memo, type ReactElement } from 'react';

import { Box } from '@mui/material';

import type { MasterProduct } from '@xeris/pages/product/types';

import Header from '../Panels/Header';
import Preview from '../Preview/Preview';

import PanelsWrapper from './PanelsWrapper';

import styles from './Summary.module.scss';

type SummaryProps = {
    masterProduct: MasterProduct<
        'name' | 'brand' | 'images' | 'featureConfiguration'
    >;
};

const Summary = ({ masterProduct }: SummaryProps): ReactElement => {
    return (
        <>
            <Header type="summary" />
            <Box className={styles.summary} bgcolor={'background.default'}>
                <Preview stage="summary" masterProduct={masterProduct} />
                <div className={styles.content}>
                    <PanelsWrapper masterProduct={masterProduct} />
                </div>
            </Box>
        </>
    );
};

export default memo(Summary);
