import { Fragment, type ReactElement } from 'react';

import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    Skeleton,
} from '@mui/material';

import { useAppSelector } from '@xeris/reducers';

import { ecoSearchSelectors } from '../selectors';
import { type EcoSearchTypes } from '../types';

import { FilterFactory } from './FilterFactory';

type FilterDrawerProps = {
    isDrawerOpen: boolean;
    handleSetIsDrawerOpen: (newValue: boolean) => void;
    handleAddActiveFilter: (newFilter: EcoSearchTypes.FilterType) => void;
    handleRemoveActiveFilter: (filterId: string) => void;
    handleRemoveAllActiveFilters: () => void;
    filters: Record<string, EcoSearchTypes.FilterType>;
};

export const FilterDrawer = ({
    isDrawerOpen,
    handleSetIsDrawerOpen,
    handleAddActiveFilter,
    handleRemoveActiveFilter,
    handleRemoveAllActiveFilters,
    filters,
}: FilterDrawerProps): ReactElement => {
    const matchingProducts = useAppSelector(
        ecoSearchSelectors.selectProductCounts
    ).filteredProductCount;
    const hasFilters = Object.values(filters).length > 0;

    return (
        <Drawer
            open={isDrawerOpen}
            onClose={(): void => handleSetIsDrawerOpen(false)}
        >
            <DialogTitle>{'Filter products'}</DialogTitle>
            <DialogContent dividers sx={{ maxWidth: '500px', width: '100vw' }}>
                <Box display={'flex'} flexDirection={'column'} gap={4}>
                    {hasFilters ? (
                        Object.values(filters).map(
                            (filter, index, filterArray) => (
                                <Fragment key={filter.id}>
                                    <FilterFactory
                                        filter={filter}
                                        handleAddActiveFilter={
                                            handleAddActiveFilter
                                        }
                                        handleRemoveActiveFilter={
                                            handleRemoveActiveFilter
                                        }
                                    />
                                    {index + 1 < filterArray.length && (
                                        <Divider />
                                    )}
                                </Fragment>
                            )
                        )
                    ) : (
                        <Skeleton height={100} />
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={'text'}
                    color={'secondary'}
                    onClick={handleRemoveAllActiveFilters}
                >
                    Clear all filters
                </Button>
                <Button
                    variant={'contained'}
                    onClick={() => handleSetIsDrawerOpen(false)}
                >
                    Show products ({matchingProducts})
                </Button>
            </DialogActions>
        </Drawer>
    );
};
