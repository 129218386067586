import { type ReactElement, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, Grid, Link, Typography } from '@mui/material';

import errorImage from '@xeris/assets/img/oops_something_went_wrong.png';
import wavesImage from '@xeris/assets/img/waves.jpg';
import { RefreshIcon } from '@xeris/components/icons';

import { Image } from '../../Image/Image';

type ErrorBlockProps = {
    handleDismissError: () => void;
};

export const ErrorBlock = ({
    handleDismissError,
}: ErrorBlockProps): ReactElement => {
    const location = useLocation();
    const loaded = useRef(false);

    // When client-side navigation occurs, dismiss the error
    useEffect(() => {
        if (loaded.current) {
            handleDismissError();
        } else {
            loaded.current = true;
        }
    }, [handleDismissError, location.pathname]);

    const handleRefresh = (): void => {
        window.location.reload();
    };

    return (
        <Grid
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
                height: '100vh',
                textAlign: 'center',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${wavesImage})`,
            }}
        >
            <Grid item>
                <Image
                    src={errorImage}
                    alt=""
                    style={{ width: '100%', maxWidth: '200' }}
                />
            </Grid>
            <Grid item>
                <Typography variant={'h3'} gutterBottom>
                    Oops, something went wrong
                </Typography>
                <Typography>
                    Please refresh the page or navigate somewhere else.
                </Typography>
                <Typography>
                    If the problem persists, contact us at{' '}
                    <Link
                        href="mailto:support@xeris.no"
                        target="_blank"
                        rel="noreferrer"
                    >
                        support@xeris.no
                    </Link>
                </Typography>

                <Button
                    startIcon={<RefreshIcon fontSize="small" />}
                    color={'error'}
                    onClick={handleRefresh}
                    sx={{ marginTop: '40px' }}
                >
                    Refresh
                </Button>
            </Grid>
        </Grid>
    );
};
