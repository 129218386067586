import { type ReactElement } from 'react';

import { MenuItem, Select } from '@mui/material';

import type { NewMapping } from '@xeris/pages/imports/pages/Mapping/components/AttributeTypeField';
import { type GetExpression } from '@xeris/pages/imports/types';
import { type Path } from '@xeris/pages/imports/utilities/updateMapping';

type SelectPathProps = {
    path: Path;
    expression: GetExpression | null;
    fields: { label: string; path: string }[];
    setNewMapping: (newMapping: NewMapping) => void;
};

export const SelectPath = ({
    expression,
    fields,
    path,
    setNewMapping,
}: SelectPathProps): ReactElement => {
    return (
        <Select
            sx={{ flexGrow: 1 }}
            value={expression?.path ?? ''}
            onChange={(event) =>
                setNewMapping({
                    path: path,
                    expression: {
                        operator: 'get',
                        path: event.target.value,
                    } as const,
                })
            }
        >
            {fields.map((field) => (
                <MenuItem key={field.path} value={field.path}>
                    {field.label}
                </MenuItem>
            ))}
        </Select>
    );
};
