import {
    type MouseEvent,
    type ReactElement,
    type SyntheticEvent,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Switch,
    Toolbar,
} from '@mui/material';

import { ExpandButton } from '@xeris/components';
import { FileIcon, SettingsIcon } from '@xeris/components/icons';
import { DataLanguageSelector } from '@xeris/containers';
import { productActions } from '@xeris/pages/product/reducers';
import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import { type ProductData } from '@xeris/pages/product/types/product';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';
import {
    collapseSelectors,
    toggleAllPanels,
} from '@xeris/utilities/reducers/collapse';

import { getCollapsePanelListId } from './utilities';

const ShowSourceDataToggle = (): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const handleClick = (event: SyntheticEvent): void => {
        event.stopPropagation();
        event.preventDefault();
        dispatch(productActions.settings.toggleShowSourceData());
    };

    return (
        <MenuItem onClick={handleClick}>
            <ListItemText>
                {t('datasheet.toolsMenu.viewSourceData')}
            </ListItemText>
            <ListItemIcon>
                <FileIcon fontSize="small" />
            </ListItemIcon>
        </MenuItem>
    );
};

const ShowMissingDataToggle = (): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const showMissingData = useAppSelector(
        settingsSelectors.selectShowMissingProductData
    );

    const handleClick = (event: SyntheticEvent): void => {
        event.stopPropagation();
        event.preventDefault();
        dispatch(productActions.settings.toggleShowMissingProductData());
    };

    return (
        <MenuItem onClick={handleClick}>
            <ListItemText>{t('datasheet.toolsMenu.showMissing')}</ListItemText>
            <ListItemIcon sx={{ marginLeft: 2 }}>
                <Switch checked={showMissingData} size="small" />
            </ListItemIcon>
        </MenuItem>
    );
};

const ShowImportErrorsToggle = (): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const showDataErrors = useAppSelector(
        settingsSelectors.selectShowDataErrors
    );

    const handleClick = (event: SyntheticEvent): void => {
        event.stopPropagation();
        event.preventDefault();
        dispatch(productActions.settings.toggleShowDataErrors());
    };

    return (
        <MenuItem onClick={handleClick}>
            <ListItemText>
                {t('datasheet.toolsMenu.showImportErrors')}
            </ListItemText>
            <ListItemIcon sx={{ marginLeft: 2 }}>
                <Switch checked={showDataErrors} size="small" />
            </ListItemIcon>
        </MenuItem>
    );
};

type ToolBarProps = {
    productEntity: ProductData<'name'>;
};

const ToolBar = ({ productEntity }: ToolBarProps): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);

    const handleToggleMenu = (event: MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        if (menuAnchorEl) {
            setMenuAnchorEl(null);
        } else {
            setMenuAnchorEl(event.currentTarget);
        }
    };

    const panelListId = getCollapsePanelListId(
        productEntity.id,
        productEntity.name
    );

    const isAnyPanelsOpen = useAppSelector((state) =>
        collapseSelectors.selectIsAnyPanelOpen(state, panelListId)
    );

    const toggleAllPanelsHandler = (): void => {
        dispatch(
            toggleAllPanels({
                listId: panelListId,
                collapsed: isAnyPanelsOpen,
            })
        );
    };

    return (
        <Toolbar
            sx={{
                justifyContent: 'right',
                gap: 1,
            }}
        >
            <Button
                variant={'outlined'}
                color={'secondary'}
                sx={{ borderColor: 'divider', paddingBlock: '6.5px' }}
                startIcon={<SettingsIcon fontSize="small" color={'icons'} />}
                onClick={handleToggleMenu}
            >
                {t('datasheet.toolsMenu.tools')}
            </Button>
            <Menu
                open={!!menuAnchorEl}
                anchorEl={menuAnchorEl}
                onClose={(event: SyntheticEvent): void => {
                    event.stopPropagation();
                    setMenuAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <ShowMissingDataToggle />
                <ShowImportErrorsToggle />
                <ShowSourceDataToggle />
            </Menu>
            <DataLanguageSelector />
            <ExpandButton
                expanded={isAnyPanelsOpen}
                onClick={toggleAllPanelsHandler}
                openTooltip={t('common.openAll')}
                closeTooltip={t('common.closeAll')}
            />
        </Toolbar>
    );
};

export default ToolBar;
