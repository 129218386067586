import { type ComponentType, type ReactElement } from 'react';

import {
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    type SelectProps,
} from '@mui/material';

import { type IconFactoryProps } from '@xeris/components/icons/IconFactory';

type SelectShowIconProps = SelectProps & {
    options: {
        id: string;
        label: string;
        Icon: ComponentType<IconFactoryProps>;
    }[];
};

const iconStyling = { flexShrink: 0 };

export const SelectShowIcon = ({
    options,
    ...selectProps
}: SelectShowIconProps): ReactElement => {
    return (
        <Select
            sx={{
                '& > div > .MuiSvgIcon-root': {
                    color: 'secondary.light',
                    marginBlock: '-1.9375px',
                },
                '& > div': { display: 'flex' },
                'flexShrink': 0,
            }}
            {...selectProps}
            renderValue={(value) => {
                const Icon = options.find(
                    (option) => option.id === value
                )?.Icon;

                if (!Icon) return null;

                return <Icon sx={iconStyling} />;
            }}
        >
            {options.map(({ id, label, Icon }) => (
                <MenuItem key={id} value={id}>
                    <ListItemIcon sx={{ color: 'secondary.light' }}>
                        <Icon />
                    </ListItemIcon>
                    <ListItemText primary={label} />
                </MenuItem>
            ))}
        </Select>
    );
};
