import { type ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';

import { format } from 'date-fns';
import type { TFunction } from 'i18next';

import {
    DataGrid,
    ErrorPage,
    Skeleton,
    Tooltip,
    type TypeSafeColDef,
    Typography,
} from '@xeris/components';
import { DownloadFile } from '@xeris/pages/exportJob/components/DownloadFile';
import { NoExports } from '@xeris/pages/exportJob/ExportJobList/components/NoExports';
import { type ExportJobStatus } from '@xeris/pages/exportJob/types';
import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import { useAppSelector } from '@xeris/reducers';
import { formatDateAsDistanceIfRecent, search } from '@xeris/utilities';

import { exportJobApi } from '../../api/exportJobApi/exportJobApi';

import ExportStatusIcon from './ExportJobStatusIcon';

const SkeletonLoad = (): ReactElement => {
    const exportJobPlaceholderList = Array.from(Array(12).keys());
    return (
        <Box sx={{ marginTop: '24px' }}>
            {exportJobPlaceholderList.map((index) => (
                <Skeleton key={index} height={42} sx={{ margin: '16px 0' }} />
            ))}
        </Box>
    );
};

type LinkTextProps = {
    url: string;
    name: string;
};

const LinkText = ({ url, name }: LinkTextProps): ReactElement => {
    return (
        <Link to={url} style={{ height: '100%', width: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    height: '100%',
                    alignItems: 'center',
                }}
            >
                <Typography noWrap>{name}</Typography>
            </Box>
        </Link>
    );
};

type RowData = {
    id: string;
    dataset: {
        name: string;
    };
    export: {
        name: string;
    };
    user: {
        name: string | null;
    };
    startTime: string;
    downloadUri: string | null;
    status: ExportJobStatus;
    message: string | null;
};

const columns = (t: TFunction<'exports'>): TypeSafeColDef<RowData>[] => [
    {
        field: 'id',
        headerName: t('common.exportID'),
        flex: 1,
        maxWidth: 80,
        renderCell: (params): ReactElement => {
            return (
                <LinkText
                    url={`/Export/ExportJob/${params.row.id}`}
                    name={'#' + params.row.id}
                />
            );
        },
    },
    {
        field: 'dataset',
        headerName: t('common.dataset'),
        flex: 1,
        minWidth: 150,
        renderCell: (params): ReactElement => {
            return (
                <LinkText
                    url={`/Export/ExportJob/${params.row.id}`}
                    name={params.row.dataset.name}
                />
            );
        },
    },
    {
        field: 'export',
        headerName: t('exportStatusContent.type'),
        flex: 1,
        minWidth: 200,
        renderCell: (params): ReactElement => {
            return (
                <LinkText
                    url={`/Export/ExportJob/${params.row.id}`}
                    name={params.row.export.name}
                />
            );
        },
    },
    {
        field: 'user',
        headerName: t('exportStatusContent.user'),
        width: 250,
        renderCell: (params): ReactElement => {
            return (
                <LinkText
                    url={`/Export/ExportJob/${params.row.id}`}
                    name={params.row.user.name ?? ''}
                />
            );
        },
    },
    {
        field: 'startTime',
        headerName: t('exportStatusContent.time'),
        width: 150,
        type: 'dateTime',
        valueGetter: (value, row) => new Date(row.startTime),
        renderCell: ({ value }): ReactElement => {
            return (
                <Tooltip title={format(value, 'PPPppp')}>
                    <>{formatDateAsDistanceIfRecent(value)}</>
                </Tooltip>
            );
        },
    },
    {
        field: 'downloadUri',
        headerName: t('exportStatusContent.status'),
        width: 120,
        renderCell: (params): ReactElement | null => {
            if (!params.row.downloadUri) return null;

            return <DownloadFile href={params.row.downloadUri} />;
        },
    },
    {
        field: 'status',
        headerName: ' ',
        align: 'right',
        width: 80,
        renderCell: (params): ReactElement => {
            return (
                <Box display={'flex'} alignItems={'center'} height={'100%'}>
                    <ExportStatusIcon
                        status={params.row.status}
                        message={params.row.message ?? undefined}
                    />
                </Box>
            );
        },
    },
];

const ExportJobListData = (): ReactElement => {
    const { t } = useTranslation('exports');

    const [isProcessing, setIsProcessing] = useState(false);

    const exportJobSearchTerm = useAppSelector(
        settingsSelectors.selectExportJobSearchTerm
    );

    const { data, isLoading, isError, refetch } =
        exportJobApi.useGetExportJobsQuery(
            {},
            { pollingInterval: isProcessing ? 10_000 : 0 }
        );

    useEffect(() => {
        setIsProcessing(
            data?.exportJobs.some(
                (exportJob) => exportJob.status === 'STARTED'
            ) ?? false
        );
    }, [data?.exportJobs]);

    if (isLoading) {
        return <SkeletonLoad />;
    }

    if (isError) {
        return (
            <ErrorPage
                title={t('exportJobs.failedToLoad')}
                actionText={t('common.tryAgain')}
                onClick={() => refetch()}
            />
        );
    }

    if (!data?.exportJobs?.length) {
        return <NoExports />;
    }

    const exportJobs: RowData[] = data.exportJobs.filter((exportJob) =>
        search(
            exportJobSearchTerm,
            exportJob.id,
            exportJob.dataset.name,
            exportJob.user.username,
            exportJob.export.name
        )
    );

    return (
        <DataGrid
            columns={columns(t)}
            rows={exportJobs}
            autoHeight
            disableColumnMenu
            initialState={{
                sorting: {
                    sortModel: [{ field: 'startTime', sort: 'desc' }],
                },
            }}
        />
    );
};

export default ExportJobListData;
