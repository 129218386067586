import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';

import type { TFunction } from 'i18next';

import {
    DataGrid,
    type TypeSafeColDef,
    UserAccountAvatar,
} from '@xeris/components';
import type {
    DatasetSharedWith,
    SharingPrivilege,
} from '@xeris/pages/dataset/types/datasetTypes';
import type { SimpleUserWithOrgType } from '@xeris/types';
import { getSorting } from '@xeris/utilities';

import AccessInfoContent from '../AccessInfo/AccessInfoContent';

import SharedWithMenu from './SharedWithMenu';

type RowType = {
    id: string;
    user: SimpleUserWithOrgType;
    role: {
        privilege: SharingPrivilege;
        user: SimpleUserWithOrgType;
    };
};

const getColumns = (
    t: TFunction<'dataset'>,
    datasetId: string
): TypeSafeColDef<RowType>[] => [
    {
        field: 'user',
        headerName: t('datasetSharingPopUp.user'),
        flex: 1,
        sortComparator: getSorting('asc', 'name'),
        renderCell: ({ id, value: user }): ReactElement => (
            <ListItem
                dense
                sx={{ paddingInline: 0 }}
                key={id}
                component={'div'}
            >
                <ListItemAvatar>
                    <UserAccountAvatar label={user?.name ?? ''} />
                </ListItemAvatar>
                <ListItemText
                    primary={user?.name}
                    primaryTypographyProps={{ noWrap: true }}
                    secondaryTypographyProps={{ noWrap: true }}
                    secondary={
                        user?.organization?.name + ' • ' + user?.username
                    }
                ></ListItemText>
            </ListItem>
        ),
    },
    {
        field: 'role',
        headerName: t('datasetSharingPopUp.role'),
        width: 160,
        sortComparator: getSorting('asc', 'privilege'),
        renderCell: ({ id, value }): ReactElement => (
            <SharedWithMenu
                key={id}
                datasetId={datasetId}
                privilege={value?.privilege ?? 'READ'}
                user={value?.user}
            />
        ),
    },
];

type SharedWithListProps = {
    sharedWith: DatasetSharedWith[];
    datasetId: string;
};

const SharedWithList = ({
    sharedWith,
    datasetId,
}: SharedWithListProps): ReactElement => {
    const { t } = useTranslation('dataset');
    const rows: RowType[] = sharedWith.map(({ user, privilege }) => ({
        id: user.id,
        user: user,
        role: { user, privilege },
    }));

    const isNotShared = sharedWith.length === 0;

    if (isNotShared) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',

                    flexDirection: 'column',
                    gap: 2,
                    mt: 2,
                }}
            >
                <Typography variant={'h6'} component={'h3'}>
                    {t('datasetSharingPopUp.sharedWith')}
                </Typography>
                <Typography variant={'body1'}>
                    {t('datasetSharingPopUp.onlyYouHaveAccessToThisDataset')}
                </Typography>
                <Box
                    sx={{
                        borderTop: '1px solid',
                        borderColor: 'divider',
                        paddingTop: 4,
                        marginTop: 2,
                    }}
                >
                    <Typography component={'h3'} variant={'h4'} gutterBottom>
                        {t('datasetSharingPopUp.about')}
                    </Typography>
                    <AccessInfoContent />
                </Box>
            </Box>
        );
    }

    return (
        <>
            <Typography variant={'h6'} component={'h3'} gutterBottom>
                {t('datasetSharingPopUp.sharedWith')}
            </Typography>
            <DataGrid
                aria-label="Shared with"
                rows={rows}
                rowHeight={64}
                columns={getColumns(t, datasetId)}
                autoHeight
                hideFooter
                disableRowSelectionOnClick
            />
        </>
    );
};

export default SharedWithList;
