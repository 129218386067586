import { type ReactElement, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from '@mui/material';

import { IconButton } from '@xeris/components';
import { Filter, FilterSection } from '@xeris/components/Filter';
import { CloseIcon } from '@xeris/components/icons';
import { type MasterProductWithIds } from '@xeris/pages/product/Common';

import { filterName, filterProducts, sortByName } from '../../../utilities';

type ProductFilterProps = {
    groupTypes: {
        id: string;
        name: string;
        groups: {
            id: string;
            name: string;
        }[];
    }[];
    markets: {
        id: string;
        name: string;
    }[];
    selectedMarkets: string[];
    selectedGroups: Record<string, string[]>;
    products: MasterProductWithIds[];
    onChange: (
        selectedMarkets: string[],
        selectedGroups: Record<string, string[]>
    ) => void;
};

export const ProductFilter = ({
    markets,
    groupTypes,
    selectedMarkets,
    selectedGroups,
    products,
    onChange,
}: ProductFilterProps): ReactElement => {
    const { t } = useTranslation('administration');

    const id = useId();
    const [filterSearch, setFilterSearch] = useState('');

    const filteredMarkets = markets
        .filter((market) => filterName(market, filterSearch))
        .map((market) => ({
            ...market,
            count: filterProducts(products, [market.id], selectedGroups).length,
        }));

    const filteredGroups = groupTypes
        .filter(
            (groupType) =>
                groupType.groups.filter((group) =>
                    filterName(group, filterSearch)
                ).length > 0
        )
        .map((groupType) => ({
            ...groupType,
            groups: groupType.groups
                .filter((group) => filterName(group, filterSearch))
                .map((group) => ({
                    ...group,
                    count: filterProducts(products, selectedMarkets, {
                        ...selectedGroups,
                        [groupType.id]: [group.id],
                    }).reduce(
                        (sum, product) => sum + (product.products.length || 1),
                        0
                    ),
                })),
        }));

    return (
        <>
            <FormControl fullWidth size={'small'} sx={{ marginBlock: 1 }}>
                <InputLabel htmlFor={id}>
                    {t('connections.brandAccess.searchFilters')}
                </InputLabel>
                <OutlinedInput
                    id={id}
                    onChange={(event) => setFilterSearch(event.target.value)}
                    value={filterSearch}
                    label={t('connections.brandAccess.searchFilters')}
                    endAdornment={
                        !!filterSearch && (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label={t('common.search')}
                                    size={'small'}
                                    onClick={() => setFilterSearch('')}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                />
            </FormControl>
            <Filter>
                {filteredMarkets.length > 0 && (
                    <FilterSection
                        name={t('connections.brandAccess.markets')}
                        options={filteredMarkets
                            .sort(sortByName)
                            .map((market) => ({
                                id: market.id,
                                label: market.name,
                                isChecked: selectedMarkets.includes(market.id),
                                count: market.count,
                            }))}
                        onChange={(optionId, value) => {
                            if (value) {
                                onChange(
                                    [optionId, ...selectedMarkets],
                                    selectedGroups
                                );
                            } else {
                                onChange(
                                    selectedMarkets.filter(
                                        (market) => market !== optionId
                                    ),
                                    selectedGroups
                                );
                            }
                        }}
                    />
                )}
                {filteredGroups.sort(sortByName).map((groupType) => (
                    <FilterSection
                        key={groupType.id}
                        name={groupType.name}
                        options={groupType.groups
                            .sort(sortByName)
                            .map((group) => ({
                                id: group.id,
                                label: group.name,
                                isChecked:
                                    selectedGroups[groupType.id]?.includes(
                                        group.id
                                    ) ?? false,
                                count: group.count,
                            }))}
                        onChange={(optionId, value) => {
                            if (value) {
                                onChange(selectedMarkets, {
                                    ...selectedGroups,
                                    [groupType.id]: [
                                        ...(selectedGroups[groupType.id] ?? []),
                                        optionId,
                                    ],
                                });
                            } else {
                                onChange(selectedMarkets, {
                                    ...selectedGroups,
                                    [groupType.id]:
                                        selectedGroups[groupType.id]?.filter(
                                            (groupId) => groupId !== optionId
                                        ) ?? [],
                                });
                            }
                        }}
                    />
                ))}
            </Filter>
        </>
    );
};
