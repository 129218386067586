import { type ReactElement, type ReactNode } from 'react';
import {
    type Control,
    type FieldValues,
    type Path,
    useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DropzoneInput } from './DropzoneInput';

type FileInputProps<T extends FieldValues> = {
    fieldName: Path<T>;
    label?: ReactNode;
    showError?: boolean;
    control: Control<T>;
};

export const FileInput = <T extends FieldValues>({
    fieldName,
    control,
    label,
    showError,
}: FileInputProps<T>): ReactElement => {
    const { t } = useTranslation('common');

    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({
        name: fieldName,
        control,
    });

    return (
        <DropzoneInput
            label={label}
            showError={showError}
            onChange={onChange}
            value={value}
            error={
                error?.type &&
                t(`formErrors.${error.type}`, {
                    defaultValue: '',
                    fieldName: fieldName,
                    message: error.message,
                })
            }
        />
    );
};
