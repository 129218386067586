import {
    memo,
    type MutableRefObject,
    type ReactElement,
    type SyntheticEvent,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';

import { FeedbackCard } from '@xeris/components';
import { ChevronLeftIcon, ChevronRightIcon } from '@xeris/components/icons';
import { useElementSize } from '@xeris/hooks';
import { type PreviewConfigurationImage } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';

import { variantGeneratorSelectors } from '../../reducer';
import useImageNaturalSize, {
    type ImageInfoType,
} from '../hooks/useImageNaturalSize';

import { generateImageStack } from './generateImageStack';

import styles from './ImageStack.module.scss';

type ImageSizeType = {
    width: number;
    height: number;
};

type PreviewImageProps = {
    title: string;
    url: string;
    width: number;
    height: number;
    className?: string;
};

export const hasPreviewOptions = (
    components: {
        features: string[];
        variants: Record<string, unknown>[];
    }[]
): boolean => {
    if (!components || components.length <= 0) {
        return false;
    }

    return components.some(
        (component) =>
            component.features.length > 0 && component.variants.length > 0
    );
};

const getSize = (
    containerWidth = 0,
    imageInfo: ImageInfoType,
    containerRef: MutableRefObject<HTMLDivElement | null>
): ImageSizeType => {
    const containerRect = containerRef.current?.getBoundingClientRect();

    const imageMaxHeight = containerRect?.height ?? 100;
    const { width: imgWidth, height: imgHeight } = imageInfo;

    let width = containerWidth;
    let height = 0;
    if (imgWidth && imgHeight) {
        height = (containerWidth * imgHeight) / imgWidth;
    }

    if (height > imageMaxHeight) {
        height = imageMaxHeight;
        width = (imageMaxHeight * imgWidth) / imgHeight;
    }

    return { width, height };
};

type DirectionType = 'previous' | 'next';

const PreviewImage = memo(function PreviewImage({
    title,
    url,
    width,
    height,
    className,
}: PreviewImageProps): ReactElement | null {
    if (!url) return null;

    //TODO: should update this img tag to use image-component, but it wasn't trivial to handle the inline styling
    return (
        <img
            alt={title}
            src={url}
            style={{ width, height }}
            className={className}
        />
    );
});

type ImageStackProps = {
    preview: PreviewConfigurationImage | null;
    productImage?: { url: string; title: string | null };
};

const ImageStack = ({
    preview,
    productImage,
}: ImageStackProps): ReactElement => {
    const { t } = useTranslation('product');

    const [ref, size] = useElementSize();

    const [selectedView, setSelectedView] = useState(0);

    const [lastValidImageStack, setLastValidImageStack] = useState<{
        list: { url: string; title: string | null }[];
        hasPreview: boolean;
    }>({ list: [], hasPreview: false });

    const selectedPreview = useAppSelector(
        variantGeneratorSelectors.selectPreviewSelection
    );

    useEffect(() => {
        const {
            stack,
            hasPreviewImages,
            hasPreviewImagesForCurrentCombination,
        } = generateImageStack(
            selectedPreview,
            preview?.views ?? null,
            productImage,
            selectedView
        );

        if (hasPreviewImages && hasPreviewImagesForCurrentCombination) {
            setLastValidImageStack({ list: stack, hasPreview: true });
        } else {
            setLastValidImageStack((prev) => ({
                ...prev,
                // If no image is set, use the default
                list: prev.list.length > 0 ? prev.list : stack,
                hasPreview: false,
            }));
        }
    }, [preview?.views, productImage, selectedPreview, selectedView]);

    const imgInfo = useImageNaturalSize(lastValidImageStack?.list[0]?.url);

    const viewsCounter = lastValidImageStack.list.length || 0;

    const isChevronEnabled = (direction: DirectionType): boolean => {
        if (viewsCounter < 2) {
            return false;
        }

        if (direction === 'previous' && selectedView === 0) {
            return false;
        }

        if (direction === 'next' && selectedView === viewsCounter - 1) {
            return false;
        }

        return true;
    };

    const handleSwitchView = (
        event: SyntheticEvent,
        direction: DirectionType
    ): void => {
        event.stopPropagation();

        if (isChevronEnabled(direction)) {
            if (direction === 'previous') setSelectedView(selectedView - 1);
            else if (direction === 'next') setSelectedView(selectedView + 1);
        }
    };

    if (viewsCounter === 0 && lastValidImageStack?.list.length === 0) {
        return (
            <div className={styles.imageBox}>
                <div ref={ref} className={styles.imagesContainer} />
            </div>
        );
    }

    // TODO: Refactor to use a real button with an accessible name
    const chevronLeft = isChevronEnabled('previous') && (
        <div
            className={`${styles.arrow} ${styles.left}`}
            role={'button'}
            aria-label={'Previous'}
            onClick={(event): void => handleSwitchView(event, 'previous')}
        >
            <ChevronLeftIcon />
        </div>
    );

    // TODO: Refactor to use a real button with an accessible name
    const chevronRight = isChevronEnabled('next') && (
        <div
            className={`${styles.arrow} ${styles.right}`}
            role={'button'}
            aria-label={'Next'}
            onClick={(event): void => handleSwitchView(event, 'next')}
        >
            <ChevronRightIcon />
        </div>
    );

    const imgStyle = getSize(size.width, imgInfo, ref);

    const showAlert = !lastValidImageStack.hasPreview;

    const overlayClassNames = [styles.whiteOverlay];

    // If there are no preview, don't show the white overlay
    if (showAlert && preview) {
        overlayClassNames.push(styles.visible);
    }

    return (
        <div className={styles.imageBox}>
            <div ref={ref} className={styles.imagesContainer}>
                {chevronLeft}
                {chevronRight}
                <div className={styles.imageStackContainer}>
                    {lastValidImageStack?.list.map((image, index) => {
                        return (
                            <PreviewImage
                                key={index}
                                title={image.title ?? ''}
                                url={image.url}
                                width={imgStyle.width}
                                height={imgStyle.height}
                            />
                        );
                    })}
                    <div
                        className={overlayClassNames.join(' ')}
                        style={{
                            width: imgStyle.width,
                            height: imgStyle.height,
                        }}
                    />
                </div>
            </div>
            <div className={styles.alertBoxContainer}>
                <Transition in={showAlert} timeout={150}>
                    {(state): ReactElement => (
                        <div className={`${styles.alertBox} ${styles[state]}`}>
                            <FeedbackCard isDisplayed severity="info">
                                {`${t(
                                    'variantGenerator.noPreviewImgAvailable'
                                )} ${
                                    !hasPreviewOptions(
                                        preview?.views.flatMap(
                                            (view) => view.components
                                        ) ?? []
                                    )
                                        ? t('variantGenerator.product')
                                        : t('variantGenerator.variant')
                                }`}
                            </FeedbackCard>
                        </div>
                    )}
                </Transition>
            </div>
        </div>
    );
};

export default ImageStack;
