import { type ReactElement } from 'react';

import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Typography,
} from '@mui/material';

import { DownloadIcon } from '@xeris/components/icons';
import { type ModelType } from '@xeris/pages/product/types';

import './index.scss';

type ModelsProps = {
    data: ModelType[];
};

const Models = ({ data: modelList }: ModelsProps): ReactElement => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: 2,
        }}
    >
        {modelList.map((model) => {
            const title = model.title || model.fileName || '';

            return (
                <Card key={model.id} variant={'outlined'}>
                    <CardActionArea
                        title={title}
                        href={model.url}
                        download
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    maxWidth: 'calc(100% - 42px)',
                                }}
                            >
                                <Typography variant={'label'}>
                                    {model.type}
                                </Typography>
                                <Typography
                                    variant={'body2'}
                                    color={'text.secondary'}
                                    noWrap
                                >
                                    {title}
                                </Typography>
                            </Box>
                            <Box>
                                <DownloadIcon color={'icons'} />
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
            );
        })}
    </Box>
);

export default Models;
