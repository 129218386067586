import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Alert, Box, Slide, Snackbar } from '@mui/material';

import { BackButton, Typography } from '@xeris/components';
import { exportJobApi } from '@xeris/pages/exportJob/api/exportJobApi/exportJobApi';
import { useCategorizeProductsMutation } from '@xeris/pages/exportJob/Categorization/api/categorizationApi';
import SaveCategorizationButton from '@xeris/pages/exportJob/Categorization/Header/SaveCategorizationButton';
import { useAppSelector } from '@xeris/reducers';

import { generateDatasetUrl } from '../../../dataset/utilities';
import { categorizationSelectors } from '../reducers/categorizationSelectors';

import CreateExportButton from './CreateExportButton';
import { Tutorial } from './Tutorial';

const Header = (): ReactElement => {
    const { datasetId = '' } = useParams<{
        datasetId: string;
    }>();

    const { t } = useTranslation('dataset');

    const { uncategorizedCount } = useAppSelector(
        categorizationSelectors.selectProductCounts
    );

    const [, categorizeProductsResult] = useCategorizeProductsMutation({
        fixedCacheKey: 'useCategorizeProductsMutation',
    });

    const [, createExportJobResult] = exportJobApi.useCreateExportJobMutation({
        fixedCacheKey: 'useCreateExportJobMutation',
    });

    return (
        <>
            <Snackbar
                open={categorizeProductsResult.isError}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={Slide}
            >
                <Alert
                    severity={'error'}
                    elevation={2}
                    onClose={() => categorizeProductsResult.reset()}
                >
                    {t('categorization.saveFailed')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={createExportJobResult.isError}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={Slide}
            >
                <Alert
                    severity={'error'}
                    elevation={2}
                    onClose={() => createExportJobResult.reset()}
                >
                    {t('categorization.exportCreationFailed')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={categorizeProductsResult.isSuccess}
                autoHideDuration={4000}
                onClose={() => categorizeProductsResult.reset()}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={Slide}
            >
                <Alert severity={'success'} elevation={2}>
                    {t('categorization.categorizationSaved')}
                </Alert>
            </Snackbar>
            <Box>
                <BackButton to={generateDatasetUrl({ id: datasetId })} sx={{}}>
                    {t('categorization.backToDataset')}
                </BackButton>
                <Box sx={{ marginBottom: 2 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 0.25,
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant={'h1'}>
                            {t('categorization.categorizeDatasetProducts')}
                        </Typography>
                        <Tutorial />
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Box>
                                <SaveCategorizationButton />
                            </Box>
                            <Box data-testid="createExportButton">
                                <CreateExportButton
                                    isDisabled={uncategorizedCount > 0}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Header;
