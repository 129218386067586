import { type ReactElement } from 'react';

import { Box, Typography } from '@mui/material';

import { languageApi } from '@xeris/containers/LanguageSelector/api/languageApi';
import { AttributeName } from '@xeris/pages/imports/pages/Mapping/components/AttributeName';
import {
    getAttributeMapping,
    setAttributeMapping,
} from '@xeris/pages/imports/reducers/importMappingSlice';
import {
    type AttributeType,
    type Expression,
    type ReferenceAttributeType,
} from '@xeris/pages/imports/types';
import { type Path } from '@xeris/pages/imports/utilities/updateMapping';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { BooleanField } from './attributeTypes/BooleanField';
import { DecimalField } from './attributeTypes/DecimalField';
import { IntField } from './attributeTypes/IntField';
import { LocalizedTextField } from './attributeTypes/LocalizedTextField';
import { StringField } from './attributeTypes/StringField';

export type NewMapping = {
    path: Path;
    expression: Expression | null;
};

type AttributeTypeFieldProps = {
    attribute: Exclude<AttributeType, ReferenceAttributeType>;
    fields: { label: string; path: string }[];
    path: Path;
};

export const AttributeTypeField = ({
    attribute,
    fields,
    path,
}: AttributeTypeFieldProps): ReactElement | null => {
    const dispatch = useAppDispatch();

    const mapping = useAppSelector((state) => getAttributeMapping(state, path));

    const { data } = languageApi.useGetDataLanguagesQuery({});
    const languages = data?.languages ?? [];

    const setNewMapping = (newMapping: NewMapping): void => {
        dispatch(setAttributeMapping(newMapping));
    };

    const getField = (): ReactElement => {
        const commonProps = {
            fields,
            path,
            expression: mapping?.expression ?? null,
            setNewMapping,
        };

        switch (attribute.__typename) {
            case 'BooleanAttributeType': {
                const props = { attribute, ...commonProps };

                return <BooleanField {...props} />;
            }
            case 'DecimalAttributeType': {
                const props = { attribute, ...commonProps };

                return <DecimalField {...props} />;
            }
            case 'IntAttributeType': {
                const props = { attribute, ...commonProps };

                return <IntField {...props} />;
            }
            case 'StringAttributeType': {
                const props = { attribute, ...commonProps };

                return <StringField {...props} />;
            }
            case 'LocalizedTextAttributeType': {
                const props = { attribute, languages, ...commonProps };

                return <LocalizedTextField {...props} />;
            }
        }
    };

    return (
        <Box>
            <Typography
                variant={'h6'}
                component={'p'}
                sx={{ flexGrow: 1, marginBottom: 1 }}
            >
                <AttributeName attribute={attribute} />
            </Typography>
            {getField()}
        </Box>
    );
};
