import { type ReactElement } from 'react';

import {
    IconButton as MaterialIconButton,
    type IconButtonProps as MuiIconButtonProps,
    styled,
} from '@mui/material';

import { Tooltip } from '../Tooltip';

export type IconButtonProps = MuiIconButtonProps & {
    tooltipTitle?: string;
    rounded?: boolean;
};

const MuiIconButton = styled(MaterialIconButton, {
    shouldForwardProp: (property) => property !== 'rounded',
})<
    MuiIconButtonProps & {
        rounded?: boolean;
    }
>(({ theme, rounded }) => ({
    ...(rounded && {
        'borderRadius': theme.shape.borderRadius,
        '& .MuiTouchRipple-root .MuiTouchRipple-child': {
            borderRadius: theme.shape.borderRadius,
        },
    }),
}));

export const IconButton = ({
    tooltipTitle,
    ...iconButtonProps
}: IconButtonProps): ReactElement => {
    return (
        <Tooltip title={tooltipTitle}>
            <MuiIconButton {...iconButtonProps} />
        </Tooltip>
    );
};
