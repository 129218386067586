import { type ReactElement } from 'react';

import { Box, styled } from '@mui/material';

import { Skeleton } from '@xeris/components';

const Wrapper = styled(Box)(() => ({
    display: 'grid',
    gap: '2rem 1.5rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    margin: '24px',
}));

export const ProductListSkeleton = (): ReactElement => {
    const productCardPlaceholderList = Array.from(Array(12).keys());

    return (
        <Wrapper>
            {productCardPlaceholderList.map((index) => (
                <Skeleton key={index} height={312} />
            ))}
        </Wrapper>
    );
};
