import {
    createSelector,
    createSlice,
    type PayloadAction,
} from '@reduxjs/toolkit';

import { type ApiLanguage, type StateType } from '@xeris/types';

import { type SettingsSliceType } from '../types/productTypes';

type MarketPayload = PayloadAction<{
    brandId: string;
    market: string | null;
    externalMarketId: string | null;
}>;

type GroupPayload = PayloadAction<{
    brandId: string;
    groupType: string | null;
}>;

const setOne = <T extends keyof SettingsSliceType>(
    state: SettingsSliceType,
    key: T,
    value: SettingsSliceType[T]
): void => {
    localStorage.setItem(
        'settings',
        JSON.stringify({ ...state, [key]: value })
    );

    state[key] = value;
};

const initialState: SettingsSliceType = {
    searchTerm: '',
    exportJobSearchTerm: '',
    showDataErrors: false,
    showSourceData: false,
    showMissingData: false,
    ...JSON.parse(localStorage.getItem('settings') ?? '{}'),
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSearchTerm(state, action: PayloadAction<string>) {
            setOne(state, 'searchTerm', action.payload);
        },
        setExportJobSearchTerm(state, action: PayloadAction<string>) {
            setOne(state, 'exportJobSearchTerm', action.payload);
        },
        setListType(state, action: PayloadAction<'cards' | 'list'>) {
            setOne(state, 'listType', action.payload);
        },
        setMarket(state, action: MarketPayload) {
            const { brandId, market, externalMarketId } = action.payload;

            setOne(state, `brands/${brandId}/market`, market);
            setOne(state, `brands/${brandId}/externalMarket`, externalMarketId);
        },
        setGroupType(state, action: GroupPayload) {
            const { brandId, groupType } = action.payload;

            setOne(state, `brands/${brandId}/groupType`, groupType);
        },
        toggleShowDataErrors(state) {
            setOne(state, 'showDataErrors', !state.showDataErrors);
        },
        setImpersonatedOrganizationId(
            state,
            action: PayloadAction<string | null>
        ) {
            setOne(state, 'impersonatedOrganizationId', action.payload);
        },
        toggleShowSourceData(state) {
            setOne(state, 'showSourceData', !state.showSourceData);
        },
        toggleFeatureFlag(state, actions: PayloadAction<string>) {
            setOne(
                state,
                `featureFlags/${actions.payload}`,
                !state[`featureFlags/${actions.payload}`]
            );
        },
        setFeatureFlags(
            state,
            actions: PayloadAction<{ featureIds: string[]; value: boolean }>
        ) {
            actions.payload.featureIds.forEach((featureId) => {
                setOne(
                    state,
                    `featureFlags/${featureId}`,
                    actions.payload.value
                );
            });
        },
        toggleShowMissingProductData(state) {
            setOne(state, 'showMissingData', !state.showMissingData);
        },
        setBrandVisibility(
            state,
            action: PayloadAction<{ ids: string[]; newValue: boolean }>
        ) {
            const { ids, newValue } = action.payload;

            ids.forEach((id) => {
                setOne(state, `brands/${id}/isVisible`, newValue);
            });
        },
        setGroupsExpanded(
            state,
            action: PayloadAction<{ groupIds: string[]; newValue: boolean }>
        ) {
            const { groupIds, newValue } = action.payload;

            groupIds.forEach((groupId) => {
                setOne(state, `groups/${groupId}/isExpanded`, newValue);
            });
        },
        setDataLanguage(state, action: PayloadAction<ApiLanguage>) {
            state.dataLanguage = action.payload;
        },
    },
});

const selectSearchTerm = createSelector(
    (state: StateType) => state.product.settings,
    (settings) => settings.searchTerm
);

const selectExportJobSearchTerm = createSelector(
    (state: StateType) => state.product.settings,
    (settings) => settings.exportJobSearchTerm
);

const selectListType = createSelector(
    (state: StateType) => state.product.settings,

    (settings) => settings.listType
);

const selectShowDataErrors = createSelector(
    (state: StateType) => state.product.settings,
    (settings) => settings.showDataErrors
);

const selectShowSourceData = createSelector(
    (state: StateType) => state.product.settings,
    (settings) => settings.showSourceData
);

const selectShowMissingProductData = createSelector(
    (state: StateType) => state.product.settings,
    (settings) => settings.showMissingData
);

const selectImpersonatedOrganizationId = createSelector(
    (state: StateType) => state.product.settings,
    (settings) => settings.impersonatedOrganizationId
);

const selectIsBrandVisible = createSelector(
    [
        (state: StateType) => state.product.settings,
        (state: StateType, brandId: string) => brandId,
    ],
    (settings, brandId) => settings[`brands/${brandId}/isVisible`] ?? true
);

const selectIsBrandsVisible = createSelector(
    [
        (state: StateType) => state.product.settings,
        (state: StateType, brandIds: string[]) => brandIds,
    ],
    (settings, brandIds) =>
        Object.fromEntries(
            brandIds.map((brandId) => [
                brandId,
                settings[`brands/${brandId}/isVisible`] ?? true,
            ])
        )
);

const selectGroupsExpanded = createSelector(
    [
        (state: StateType) => state.product.settings,
        (state: StateType, groupIds: string[]) => groupIds,
    ],
    (settings, groupIds) =>
        Object.fromEntries(
            groupIds.map((groupId) => [
                groupId,
                settings[`groups/${groupId}/isExpanded`] ?? true,
            ])
        )
);

const selectGroupType = createSelector(
    [
        (state: StateType) => state.product.settings,
        (state: StateType, brandId: string) => brandId,
    ],
    (settings, brandId) => settings[`brands/${brandId}/groupType`]
);

const selectMarket = createSelector(
    [
        (state: StateType) => state.product.settings,
        (state: StateType, brandId: string) => brandId,
    ],
    (settings, brandId) => settings[`brands/${brandId}/market`] ?? null
);

const selectExternalMarket = createSelector(
    [
        (state: StateType) => state.product.settings,
        (state: StateType, brandId: string) => brandId,
    ],
    (settings, brandId) => settings[`brands/${brandId}/externalMarket`] ?? null
);

const selectFeatureFlag = createSelector(
    [
        (state: StateType) => state.product.settings,
        (state: StateType, featureFlagId: string) => featureFlagId,
    ],
    (settings, featureFlagId) =>
        settings[`featureFlags/${featureFlagId}`] ?? false
);

const selectAllFeatureFlags = createSelector(
    [
        (state: StateType) => state.product.settings,
        (state: StateType, featureFlagIds: string[]) => featureFlagIds,
    ],
    (settings, featureFlagIds) =>
        Object.fromEntries(
            featureFlagIds.map((featureFlagId) => [
                featureFlagId,
                settings[`featureFlags/${featureFlagId}`] ?? false,
            ])
        )
);

const selectDataLanguage = createSelector(
    (state: StateType) => state.product.settings,
    (settings) => settings.dataLanguage ?? 'EN'
);

export const settingsSelectors = {
    selectSearchTerm,
    selectExportJobSearchTerm,
    selectShowDataErrors,
    selectShowSourceData,
    selectShowMissingProductData,
    selectGroupType,
    selectMarket,
    selectListType,
    selectExternalMarket,
    selectIsBrandVisible,
    selectIsBrandsVisible,
    selectGroupsExpanded,
    selectImpersonatedOrganizationId,
    selectFeatureFlag,
    selectAllFeatureFlags,
    selectDataLanguage,
};

export const settingsActions = settingsSlice.actions;
