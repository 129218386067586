import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { EmptyState } from '@xeris/components';
import { useWhoami } from '@xeris/hooks';

import {
    type Connection,
    type ConnectionsTabVariantType,
    type UiConnectionType,
} from '../../../../../types/connectionsTypes';
import { NewConnection } from '../../NewConnection';

type NoConnectionsProps = {
    connectionType: ConnectionsTabVariantType;
    uiConnectionType: UiConnectionType;
    connectionList: Connection[];
};

const NoConnections = ({
    uiConnectionType,
    connectionType,
    connectionList,
}: NoConnectionsProps): ReactElement => {
    const { t } = useTranslation('administration');

    const { isSuperAdmin } = useWhoami();

    return (
        <EmptyState title={t(`connections.noConnections.${uiConnectionType}`)}>
            <Box textAlign={'center'}>
                <Typography>
                    {t(`connections.noConnectedPartners.${connectionType}`)}
                </Typography>
                {isSuperAdmin && (
                    <Typography>
                        {t(`connections.addConnection.${connectionType}`)}
                    </Typography>
                )}
            </Box>
            {isSuperAdmin && (
                <NewConnection
                    connectionType={connectionType}
                    uiConnectionType={uiConnectionType}
                    connectionList={connectionList}
                />
            )}
        </EmptyState>
    );
};

export default NoConnections;
