import { type ReactElement } from 'react';

import { IconButton, Stack, TableCell, TableRow, Tooltip } from '@mui/material';

import { CloseIcon, EyeIcon } from '@xeris/components/icons';

import { FormattedPrice } from '../../../FormattedPrice/FormattedPrice';
import { Typography } from '../../../Typography/Typography';

type MaterialSummaryTableRowProps = {
    materialName: string;
    supplierName: string | null;
    selectedCount: number;
    priceValue: number | null;
    removeMaterialTooltip: string;
    handleRemove: () => void;
    handleClick: () => void;
    isPreviewed?: boolean;
};

export const MaterialSummaryTableRow = ({
    materialName,
    supplierName,
    selectedCount,
    priceValue,
    removeMaterialTooltip,
    handleRemove,
    handleClick,
    isPreviewed,
}: MaterialSummaryTableRowProps): ReactElement => {
    return (
        <TableRow
            hover
            role={'button'}
            aria-expanded={'true'}
            tabIndex={0}
            onClick={handleClick}
            sx={{ cursor: 'pointer' }}
        >
            <TableCell sx={{ overflow: 'hidden', maxWidth: '200px' }}>
                <Stack
                    spacing={1}
                    justifyContent={'flex-start'}
                    overflow={'hidden'}
                    maxWidth={'100%'}
                >
                    <Typography variant={'body1'} noWrap>
                        {materialName}
                    </Typography>
                    {isPreviewed && (
                        <EyeIcon color={'primary'} fontSize={'inherit'} />
                    )}
                </Stack>
            </TableCell>
            <TableCell sx={{ overflow: 'hidden', maxWidth: '100px' }}>
                <Typography variant={'caption'} maxLines={1}>
                    {supplierName}
                </Typography>
            </TableCell>

            <TableCell align={'center'} width={80}>
                <Typography variant={'caption'}>{selectedCount}</Typography>
            </TableCell>
            <TableCell align={'right'} width={80}>
                <FormattedPrice value={priceValue} />
            </TableCell>
            <TableCell align={'right'} width={52}>
                <Tooltip title={removeMaterialTooltip}>
                    <IconButton
                        onClick={handleRemove}
                        aria-label={removeMaterialTooltip}
                        sx={{ marginRight: '-4px' }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};
