import { type ElementType, type ReactElement } from 'react';

import {
    Typography as MuiTypography,
    type TypographyProps,
} from '@mui/material';

type StyledTypographyProps<C extends ElementType> = TypographyProps<
    C,
    { component?: C }
> & {
    maxLines?: number;
};

export const Typography = <C extends ElementType>({
    maxLines,
    ...props
}: StyledTypographyProps<C>): ReactElement => {
    return (
        <MuiTypography
            {...props}
            sx={{
                ...(maxLines && {
                    display: '-webkit-box',
                    WebkitLineClamp: maxLines.toString(),
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                }),
                ...props.sx,
            }}
        />
    );
};
