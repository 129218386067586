import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandButton } from '@xeris/components';
import { settingsActions } from '@xeris/pages/product/reducers/settingsSlice';
import { useAppDispatch } from '@xeris/reducers';

type ExpandProps = {
    noneExpanded: boolean;
    groups: { id: string; name: string }[];
};

export const Expand = ({ noneExpanded, groups }: ExpandProps): ReactElement => {
    const { t } = useTranslation('products');
    const dispatch = useAppDispatch();

    return (
        <ExpandButton
            expanded={!noneExpanded}
            openTooltip={t('brand.openAll')}
            closeTooltip={t('brand.closeAll')}
            onClick={() => {
                dispatch(
                    settingsActions.setGroupsExpanded({
                        groupIds: [
                            ...groups.map((group) => group.id),
                            '__ungrouped',
                        ],
                        newValue: noneExpanded,
                    })
                );
            }}
        />
    );
};
