import { useActiveDataset } from './useActiveDataset';

type Counts = {
    variantCount: number;
    selectedVariantCount: number;
};

export const useSelectedVariantsCounts = (
    products: { id: string }[]
): Counts => {
    const activeDataset = useActiveDataset();

    const selectedIds = new Set(
        activeDataset?.selectedProductData.nodeList.map(({ id }) => id) ?? []
    );

    const variantCount = products.length;

    const selectedVariantCount = products.filter(({ id }) =>
        selectedIds.has(id)
    ).length;

    return {
        variantCount,
        selectedVariantCount,
    };
};
