import { baseRtkApi } from '@xeris/reducers/baseApi';

import { getMasterProductForInfoBoxQuery } from './materialInfoBoxQueries';
import {
    type ApiGetMasterProductForInfoBoxQuery,
    type ApiGetMasterProductForInfoBoxQueryVariables,
} from './materialInfoBoxQueries.gql';

export const materialInfoBoxApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getMasterProductForInfoBox: builder.query<
            ApiGetMasterProductForInfoBoxQuery,
            ApiGetMasterProductForInfoBoxQueryVariables
        >({
            query: (variables) => ({
                document: getMasterProductForInfoBoxQuery,
                variables,
            }),
        }),
    }),
});

export const { useGetMasterProductForInfoBoxQuery } = materialInfoBoxApi;
