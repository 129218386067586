import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { type ConnectionCounters } from '../../../types/connectionsTypes';

type HeaderProps = {
    counters: ConnectionCounters;
};

const Header = ({ counters }: HeaderProps): ReactElement => {
    const { t } = useTranslation('administration');

    const { totalUsed, totalAvailable } = counters;

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Typography variant="h1">{t('connections.connections')}</Typography>
            <Typography>
                {t('connections.usedConnectionsCount', {
                    usedConnectionsCount: totalUsed,
                    availableConnectionsCount: totalAvailable,
                })}
            </Typography>
        </Box>
    );
};

export default Header;
