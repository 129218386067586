import { type ReactElement, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes } from 'react-router-dom';

import { Box, Stack, Typography } from '@mui/material';

import darkerWaves from '@xeris/assets/img/darker_waves.jpg';
import { useQueryParams } from '@xeris/hooks';
import LoginForm from '@xeris/pages/login/LoginForm/LoginForm';

import AcceptInviteForm from './AcceptInviteForm/AcceptInviteForm';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import Logo from './svgs/logo';
import { type LoginPageType } from './reducers';

type LoginWrapperProps = {
    children: ReactNode;
};

const LoginWrapper = ({ children }: LoginWrapperProps): ReactElement => {
    const { t } = useTranslation('login');

    return (
        <Box
            sx={{
                backgroundImage: `url(${darkerWaves})`,
                backgroundSize: 'auto 100%',
                backgroundPosition: 'center 50%',
                backgroundRepeat: 'no-repeat',
                backgroundColor: 'background.default',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    margin: 2,
                }}
            >
                <a href={'/login/google'}>
                    <Typography
                        sx={{
                            'color': '#cdebe8',
                            '&:hover': { color: 'primary.main' },
                        }}
                    >
                        {t('internalLogin')}
                    </Typography>
                </a>
            </Box>

            <Box
                sx={(theme) => ({
                    maxWidth: '96vw',
                    width: '560px',
                    background: 'white',
                    padding: '48px 96px',
                    borderRadius: 1,
                    boxShadow: '0 10px 15px -3px rgb(0 0 0 / 10%)',
                    [theme.breakpoints.down('sm')]: {
                        padding: '24px 36px',
                    },
                })}
            >
                <Box
                    sx={{
                        'width': '100%',
                        'marginBottom': 2,
                        'display': 'flex',
                        'justifyContent': 'center',
                        '> svg': {
                            maxWidth: '200px',
                        },
                    }}
                >
                    <Logo />
                </Box>
                <Box sx={{ textAlign: 'center' }}>{children}</Box>
            </Box>
            <Stack component={'footer'}>
                <Stack
                    component={'ul'}
                    sx={{
                        listStyleType: 'none',
                        padding: 0,
                        marginTop: 6,
                        width: '100%',
                        justifyContent: 'center',
                        gap: 4,
                        color: 'secondary.light',
                    }}
                >
                    <Box component={'li'}>
                        <Typography
                            component={Link}
                            to={'https://no.xeris.no/contact'}
                            target="_blank"
                            color={'secondary.light'}
                        >
                            {t('support')}
                        </Typography>
                    </Box>
                    <Box component={'li'}>
                        <Typography
                            component={Link}
                            to={'https://www.xeris.no/legal/privacy-policy'}
                            target="_blank"
                            color={'secondary.light'}
                        >
                            {t('privacy')}
                        </Typography>
                    </Box>
                    <Box component={'li'}>
                        <Typography
                            component={Link}
                            to={
                                'https://www.xeris.no/legal/terms-and-conditions'
                            }
                            target="_blank"
                            color={'secondary.light'}
                        >
                            {t('legal')}
                        </Typography>
                    </Box>
                </Stack>
            </Stack>
            <Typography textAlign={'center'} color={'secondary.light'}>
                © Xeris AS {new Date().getFullYear()}
            </Typography>
        </Box>
    );
};

export const ResetPasswordPage = (): ReactElement => {
    return (
        <LoginWrapper>
            <ResetPassword />
        </LoginWrapper>
    );
};

export const AcceptInvitation = (): ReactElement => {
    return (
        <LoginWrapper>
            <AcceptInviteForm />
        </LoginWrapper>
    );
};

const Login = (): ReactElement => {
    const action = useQueryParams<LoginPageType>('action');

    if (action === 'invite') {
        return <AcceptInvitation />;
    }

    if (action === 'reset-password') {
        return <ResetPasswordPage />;
    }

    if (action === 'forgot-password') {
        return (
            <LoginWrapper>
                <ForgotPassword />
            </LoginWrapper>
        );
    }

    return (
        <LoginWrapper>
            <Routes>
                <Route path="*" element={<LoginForm />} />
            </Routes>
        </LoginWrapper>
    );
};

export default Login;
