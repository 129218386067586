import { createListenerMiddleware } from '@reduxjs/toolkit';

import { loginApi } from '@xeris/pages/login/api/loginApi';
import { type AppDispatch } from '@xeris/reducers';
import { baseRtkApi } from '@xeris/reducers/baseApi';
import { type StateType } from '@xeris/types';

export const listenerMiddleware = createListenerMiddleware<
    StateType,
    AppDispatch
>();

// Make sure that we remove all cached data when a user is impersonated
listenerMiddleware.startListening({
    matcher: loginApi.endpoints.impersonate.matchFulfilled,
    effect: async (action, listenerApi) => {
        listenerApi.dispatch(baseRtkApi.util.resetApiState());
    },
});
