import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
    Avatar,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import { IconButton, StatusTag, UpDownChevronIcon } from '@xeris/components';
import { type StatusTagProps } from '@xeris/components/StatusTag/StatusTag';
import { masterProductUrl, productUrl } from '@xeris/utilities/urls';

import { type ProductEntity, type ProductExportStatus } from '../../types';

const Header = (): ReactElement => {
    const { t } = useTranslation('exports');

    return (
        <TableHead>
            <TableRow>
                <TableCell>{t('common.name')}</TableCell>
                <TableCell>{t('common.brand')}</TableCell>
                <TableCell align={'right'} sx={{ paddingRight: 3.5 }}>
                    {t('common.status')}
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

type ProductEntityRowProps = {
    productEntity: ProductEntity;
};

const ProductEntityRow = ({
    productEntity,
}: ProductEntityRowProps): ReactElement => {
    const { t } = useTranslation('exports');

    const [isDropDownClosed, setIsDropDownClosed] = useState(true);

    const statusLookupId = {
        PROCESSING: { type: 'processing', title: t('common.processing') },
        SUCCESSFUL: { type: 'success', title: t('common.completed') },
        FAILED: { type: 'error', title: t('common.failed') },
        SKIPPED: { type: 'indeterminate', title: t('common.skipped') },
    } satisfies Record<ProductExportStatus, StatusTagProps>;

    const product =
        productEntity.type === 'MasterProduct'
            ? productEntity.masterProduct
            : productEntity.product;

    const productEntityStatus = statusLookupId[productEntity.status];
    const showErrorMessage =
        productEntity.status === 'FAILED' && productEntity.message;
    const image = product.images?.[0];

    const productLink =
        productEntity.type === 'MasterProduct'
            ? masterProductUrl(productEntity.masterProduct)
            : productUrl(productEntity.product);

    return (
        <>
            <TableRow>
                <TableCell sx={{ padding: 1.5 }}>
                    <Box
                        component={Link}
                        to={productLink}
                        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                    >
                        <Avatar
                            src={image?.url}
                            alt={image?.title ?? t('common.illustration')}
                            variant={'roundedOutline'}
                        />
                        <Typography>{product.name}</Typography>
                    </Box>
                </TableCell>
                <TableCell>{product.brand.name}</TableCell>
                <TableCell align={'right'}>
                    <StatusTag
                        type={productEntityStatus.type}
                        title={productEntityStatus.title}
                    />

                    {showErrorMessage && (
                        <IconButton
                            size="large"
                            onClick={(): void =>
                                setIsDropDownClosed(!isDropDownClosed)
                            }
                        >
                            <UpDownChevronIcon isCollapsed={isDropDownClosed} />
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>
            {showErrorMessage && !isDropDownClosed && (
                <TableRow>
                    <TableCell colSpan={3}>
                        <Box bgcolor={'background.default'}>
                            <Typography color={'error'} padding={2}>
                                {productEntity.message}
                            </Typography>
                        </Box>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

type ProductEntityStatusProps = {
    productEntityList: ProductEntity[];
};

const ProductEntityStatuses = ({
    productEntityList,
}: ProductEntityStatusProps): ReactElement => {
    return (
        <Table>
            <Header />
            <TableBody>
                {productEntityList.map((productEntity) => (
                    <ProductEntityRow
                        key={
                            productEntity.type === 'MasterProduct'
                                ? productEntity.masterProduct.id
                                : productEntity.product.id
                        }
                        productEntity={productEntity}
                    />
                ))}
            </TableBody>
        </Table>
    );
};

export default ProductEntityStatuses;
