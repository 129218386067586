import { type MutableRefObject, useEffect, useRef, useState } from 'react';

import { useWindowResizeListener } from './useWindowResizeListener';

type SizeType = {
    width: number | undefined;
    height: number | undefined;
};

export const useElementSize = (): [
    MutableRefObject<HTMLDivElement | null>,
    SizeType,
] => {
    const ref = useRef<HTMLDivElement>(null);

    const [size, setSize] = useState<SizeType>({
        width: undefined,
        height: undefined,
    });

    const calculateSize = (): void => {
        if (!ref.current) return;

        const rect = ref.current.getBoundingClientRect();

        if (rect.width !== size.width || rect.height !== size.height) {
            setSize({ width: rect.width, height: rect.height });
        }
    };

    useEffect(() => {
        calculateSize();
    });

    useWindowResizeListener(() => {
        calculateSize();
    });

    useEffect(() => {
        if (!ref.current) {
            return;
        }
        const resizeObserver = new ResizeObserver(() => {
            calculateSize();
        });

        resizeObserver.observe(ref.current);

        return (): void => {
            resizeObserver.disconnect();
        };
    });

    return [ref, size];
};
