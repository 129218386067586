import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableRow, Typography } from '@mui/material';

type ImportNumbersProps = {
    summary: Record<string, string>;
    count: number | null;
};

export const ImportNumbers = ({
    summary,
    count,
}: ImportNumbersProps): ReactElement => {
    const { t } = useTranslation('administration');
    return (
        <>
            {summary ? (
                Object.keys(summary).map((item, index) => {
                    return (
                        <TableRow key={index}>
                            <TableCell sx={{ border: 0, paddingBlock: 0 }}>
                                <Typography
                                    variant={'body1'}
                                    fontSize={'small'}
                                    paddingLeft={'60px'}
                                >
                                    {item}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ border: 0, paddingBlock: 0 }}>
                                <Typography
                                    variant={'body1'}
                                    fontSize={'small'}
                                >
                                    {summary[item]}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    );
                })
            ) : (
                <></>
            )}
            <TableRow>
                <TableCell>
                    <Typography
                        variant={'body2'}
                        fontSize={'small'}
                        paddingLeft={'60px'}
                    >
                        {t('imports.total')}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant={'body2'} fontSize={'small'}>
                        {count ? count : '-'}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    );
};
