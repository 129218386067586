import { type ReactElement, type ReactNode } from 'react';

import {
    Card,
    CardContent,
    CardHeader,
    type SxProps,
    type Theme,
    Typography,
} from '@mui/material';

import { TipsIcon } from '@xeris/components/icons';

type TipsBoxProps = {
    title: string;
    children: ReactNode;
    sx?: SxProps<Theme>;
};

export const TipsBox = ({
    title,
    children,
    sx,
}: TipsBoxProps): ReactElement => {
    return (
        <Card
            variant={'outlined'}
            sx={{
                ...sx,
                backgroundColor: 'background.default',
            }}
        >
            <CardHeader
                sx={{
                    'paddingLeft': 4,
                    'flexDirection': 'row-reverse',
                    'justifyContent': 'space-between',
                    '& .MuiCardHeader-avatar': { marginRight: 0 },
                }}
                avatar={<TipsIcon color={'primary'} fontSize={'large'} />}
                title={
                    <Typography variant={'h3'} component={'p'}>
                        {title}
                    </Typography>
                }
            />
            <CardContent
                sx={{
                    paddingTop: 0,
                    paddingInline: 4,
                    gap: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {children}
            </CardContent>
        </Card>
    );
};
