import { useMemo } from 'react';

import { useActiveDataset } from './useActiveDataset';

type ConfigurationSet = {
    id: string;
    name: string;
};

export const useConfigurationSets = (
    configurableProductId: string | undefined
): ConfigurationSet[] => {
    const activeDataset = useActiveDataset();

    const node = useMemo(
        () =>
            activeDataset?.selectedProductData?.nodeList?.find(
                ({ id }) => id === configurableProductId
            ),
        [activeDataset, configurableProductId]
    );

    return node && node.__typename === 'SelectedMasterProduct'
        ? node.configurationSets
        : [];
};
