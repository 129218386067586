import { type ReactElement, type ReactNode, useId } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import {
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';

import {
    CheckIcon,
    CloseIcon,
    ErrorOutlineIcon,
    UploadIcon,
} from '@xeris/components/icons';

const statusIcons = {
    loading: <CircularProgress size={24} color={'info'} />,
    success: <CheckIcon color={'primary'} />,
    error: <ErrorOutlineIcon color={'error'} />,
} as const;

type DropzoneInputProps = {
    label?: ReactNode;
    error?: string;
    showError?: boolean;
    onChange: (files: File[]) => void;
    value: File[];
    status?: keyof typeof statusIcons;
};

export const DropzoneInput = ({
    label,
    error,
    showError,
    onChange,
    value,
    status,
}: DropzoneInputProps): ReactElement => {
    const { t } = useTranslation('common');
    const id = useId();

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: false,
        onDrop: onChange,
    });

    return (
        <FormControl fullWidth error={!!error}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                sx={{ marginBlock: 1.5, gap: 0.5 }}
            >
                <Typography id={id} fontWeight={500} component={'label'}>
                    {label}
                </Typography>
                <Box
                    sx={{
                        'border': 1,
                        'borderRadius': 1,
                        'borderColor': error ? 'error.main' : 'divider',
                        'height': '53.13px',
                        'display': 'flex',
                        'flexDirection': 'row',
                        'alignItems': 'center',
                        ':hover': {
                            borderColor: error ? 'error.dark' : 'action.active',
                            backgroundColor: 'action.hover',
                        },
                    }}
                >
                    <Box
                        {...getRootProps({ role: 'button' })}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            flexGrow: 1,
                            height: '100%',
                            paddingInlineStart: 2,
                            paddingInlineEnd: value?.length ? 0 : 1,
                            cursor: 'pointer',
                            focus: 'primary',
                        }}
                    >
                        <input {...getInputProps()} aria-labelledby={id} />
                        <Box flexGrow={1}>
                            {isDragActive ? (
                                <>
                                    <Typography color={'text.secondary'}>
                                        {t('components.fileInput.dropHere')}
                                    </Typography>
                                </>
                            ) : value?.length ? (
                                <Typography>{value[0]?.name}</Typography>
                            ) : (
                                <Typography color={'text.secondary'}>
                                    {t('components.fileInput.dragOrClick')}
                                </Typography>
                            )}
                        </Box>
                        {(!value || value.length === 0) && (
                            <Box width={'40px'} height={'40px'} padding={1}>
                                <UploadIcon color={'icons'} />
                            </Box>
                        )}
                        {status && (
                            <Box width={'40px'} height={'40px'} padding={1}>
                                {statusIcons[status]}
                            </Box>
                        )}
                    </Box>
                    {value?.length > 0 && (
                        <Stack marginRight={1}>
                            <IconButton onClick={() => onChange([])}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    )}
                </Box>
            </Box>
            {showError && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};
