import { type ReactElement, type SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box,
    Table,
    TablePagination,
    type TablePaginationProps,
    Toolbar,
    Typography,
} from '@mui/material';

import { SearchBar } from '@xeris/components';
import { search } from '@xeris/utilities';

import { importApi } from './api/importApi';
import { CreateImportJob } from './forms/CreateImportJob/CreateImportJob';
import { ImportTableBody, ImportTableHead } from './SupportComponents';

export type OrderBy = 'id' | 'source' | 'start' | 'duration' | 'status';
export type Order = 'asc' | 'desc';

const Import = (): ReactElement => {
    const { t } = useTranslation('administration');

    const { data: importList } = importApi.useGetImportBatchesQuery(
        {},
        {
            pollingInterval: 2500,
        }
    );

    const rowsPerPageOptions = [100, 500, 2000];

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<OrderBy>('start');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

    const [searchTerm, setSearchTerm] = useState('');

    const rows =
        importList?.batches?.filter((row) =>
            search(searchTerm, row.id, row.source)
        ) ?? [];

    const handleRequestSort = (
        event: SyntheticEvent,
        property: OrderBy
    ): void => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSetSearchTerm = (value: string): void => {
        setSearchTerm(value);
    };

    const handleChangePage: TablePaginationProps['onPageChange'] = (
        event,
        newPage
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage: TablePaginationProps['onRowsPerPageChange'] =
        (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

    return (
        <div>
            <Typography variant={'h1'} gutterBottom>
                {t('imports.importFiles')}
            </Typography>
            <Toolbar disableGutters>
                <Box sx={{ maxWidth: '550px' }}>
                    <SearchBar
                        filter={searchTerm}
                        onChange={handleSetSearchTerm}
                        inputLabel={t('imports.searchForImport')}
                    />
                </Box>
                <CreateImportJob />
            </Toolbar>
            <Table>
                <ImportTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />

                <ImportTableBody
                    importList={rows}
                    order={order}
                    orderBy={orderBy}
                    page={page}
                    rowsPerPage={rowsPerPage}
                />
            </Table>

            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('imports.rowsPerPage')}
            />
        </div>
    );
};

export default Import;
