import type { ReactElement } from 'react';

import LightBox from 'yet-another-react-lightbox';

import 'yet-another-react-lightbox/styles.css';

type ImageGalleryProps = {
    images: {
        url: string;
    }[];
    show?: boolean;
    currentImageUrl: string | null;
    close: (imageUrl: string | null) => void;
};

export const ImageGallery = ({
    images,
    show,
    currentImageUrl,
    close,
}: ImageGalleryProps): ReactElement | null => {
    if (!show) {
        return null;
    }

    if (images.length === 0) {
        return null;
    }

    return (
        <LightBox
            open={show}
            index={Math.max(
                images.findIndex((s) => s.url === currentImageUrl),
                0
            )}
            close={(): void => close(null)}
            slides={images.map((image) => ({
                src: image.url,
            }))}
        />
    );
};
