import { type ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox, FormControlLabel, Stack } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { FormDialog } from '@xeris/components/forms';
import { CheckboxCard } from '@xeris/components/forms/CheckboxCard/CheckboxCard';
import {
    CubeOutlineIcon,
    FileOutlineIcon,
    InsertPhotoIcon,
} from '@xeris/components/icons';
import { useActiveDatasetId } from '@xeris/pages/dataset/hooks';
import { datasetSelectors } from '@xeris/pages/dataset/reducers';
import { useAppSelector } from '@xeris/reducers';

import SelectDataset from '../SelectDataset/SelectDataset';

import { type SelectionType } from './MultipleMasterProductsSelector';
import ProductAndVariantsCounter from './ProductAndVariantsCounter';

const schema = yup.object({
    selectImages: yup.boolean().required('required'),
    selectDocuments: yup.boolean().required('required'),
    selectModels: yup.boolean().required('required'),
    dataset: yup
        .object({
            id: yup.string().nullable(),
            name: yup.string().required('required'),
        })
        .required('required'),
});

type Schema = yup.InferType<typeof schema>;

type SelectProductDataDialogProps = {
    isDialogOpen: boolean;
    handleCloseDialog: () => void;
    handleSelectProducts: (
        selection: SelectionType,
        dataset: { id?: string | null; name: string }
    ) => void;
    productCount: number;
    variantCount: number;
    disabledDatasetMenu?: boolean;
    isLoading: boolean;
};

const SelectProductDataDialog = ({
    isDialogOpen,
    handleCloseDialog,
    handleSelectProducts,
    productCount,
    variantCount,
    disabledDatasetMenu,
    isLoading,
}: SelectProductDataDialogProps): ReactElement => {
    const { t } = useTranslation('product');

    const activeDatasetId = useActiveDatasetId();

    const datasetList = useAppSelector(datasetSelectors.minimal.selectAll);

    const { control, handleSubmit, setValue, setError, watch } =
        useForm<Schema>({
            resolver: yupResolver(schema),
            defaultValues: {
                selectImages: true,
                selectDocuments: true,
                selectModels: true,
                dataset: {
                    id: activeDatasetId,
                    name: ' ',
                },
            },
        });

    useEffect(() => {
        setValue('dataset.id', activeDatasetId);
    }, [activeDatasetId, setValue]);

    const images = watch('selectImages');
    const documents = watch('selectDocuments');
    const models = watch('selectModels');

    const allSelected = images && documents && models;
    const someSelected = images || documents || models;

    return (
        <FormDialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            cancelText={t('common.cancel')}
            saveText={t('dataSelector.save')}
            title={t('dataSelector.selectProductData')}
            isLoading={isLoading}
            onSubmit={handleSubmit((data) => {
                if (
                    data.dataset.id === null &&
                    datasetList.some((d) => d.name === data.dataset.name)
                ) {
                    return setError('dataset', {
                        type: 'unique',
                        message: data.dataset.name,
                    });
                }

                handleSelectProducts(data, data.dataset);
            })}
            actions={
                <SelectDataset
                    sx={{ flexBasis: '300px' }}
                    disabled={disabledDatasetMenu}
                    control={control}
                />
            }
        >
            <Stack marginBottom={1}>
                <ProductAndVariantsCounter
                    productCount={productCount}
                    variantCount={variantCount}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            onClick={(): void => {
                                setValue('selectImages', !allSelected);
                                setValue('selectDocuments', !allSelected);
                                setValue('selectModels', !allSelected);
                            }}
                            checked={allSelected}
                            indeterminate={someSelected && !allSelected}
                        />
                    }
                    label={t('dataSelector.allData')}
                    labelPlacement="start"
                    sx={{ marginRight: 1 }}
                />
            </Stack>
            <Stack
                direction={'column'}
                spacing={1}
                alignItems={'stretch'}
                width={'100%'}
            >
                <CheckboxCard
                    fieldName={'selectImages'}
                    control={control}
                    title={t('dataSelector.images')}
                    subheader={t('dataSelector.everyAvailableOfType.images')}
                    icon={<InsertPhotoIcon />}
                />
                <CheckboxCard
                    fieldName={'selectDocuments'}
                    control={control}
                    title={t('dataSelector.documents')}
                    subheader={t('dataSelector.everyAvailableOfType.documents')}
                    icon={<FileOutlineIcon />}
                />
                <CheckboxCard
                    fieldName={'selectModels'}
                    control={control}
                    title={t('dataSelector.3dObjects')}
                    subheader={t('dataSelector.everyAvailableOfType.models')}
                    icon={<CubeOutlineIcon />}
                />
            </Stack>
        </FormDialog>
    );
};

export default SelectProductDataDialog;
