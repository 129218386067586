import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconButton, Snackbar, Stack } from '@mui/material';

import { CloseIcon } from '@xeris/components/icons';
import { useHasUpdate } from '@xeris/hooks';

export const Update = (): ReactElement => {
    const { t } = useTranslation('common');
    const { hasUpdate, isDismissed, setIsDismissed } = useHasUpdate();

    return (
        <Snackbar
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
            open={hasUpdate && !isDismissed}
            message={t('components.update.newVersion')}
            sx={{
                '& > div': {
                    backgroundColor: 'background.default',
                    color: 'text.primary',
                },
            }}
            action={
                <Stack gap={1}>
                    <Button
                        variant={'contained'}
                        onClick={() => window.location.reload()}
                    >
                        {t('components.update.updateXtrade')}
                    </Button>
                    <IconButton
                        color={'icons'}
                        onClick={() => setIsDismissed(true)}
                        aria-label={t('components.update.close')}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            }
        />
    );
};
