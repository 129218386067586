import { baseRtkApi } from '@xeris/reducers/baseApi';

import {
    createMappingConfigurationMutation,
    createSourceMutation,
    deleteMappingMutation,
    getCSVHeadersQuery,
    getFileImportUploadUrlMutation,
    getMappingConfigurationQuery,
    getMappingConfigurationsQuery,
    getOntologyDefinitionQuery,
    getSourcesQuery,
    getToplevelEntitiesQuery,
    runMappingMutation,
    updateMappingMutation,
} from './importsQueries';
import {
    type ApiCreateMappingConfigurationMutation,
    type ApiCreateMappingConfigurationMutationVariables,
    type ApiCreateSourceMutation,
    type ApiCreateSourceMutationVariables,
    type ApiDeleteMappingMutation,
    type ApiDeleteMappingMutationVariables,
    type ApiGetCsvHeadersQuery,
    type ApiGetCsvHeadersQueryVariables,
    type ApiGetFileImportUploadUrlMutation,
    type ApiGetFileImportUploadUrlMutationVariables,
    type ApiGetMappingConfigurationQuery,
    type ApiGetMappingConfigurationQueryVariables,
    type ApiGetMappingConfigurationsQuery,
    type ApiGetMappingConfigurationsQueryVariables,
    type ApiGetOntologyDefinitionQuery,
    type ApiGetOntologyDefinitionQueryVariables,
    type ApiGetSourcesQuery,
    type ApiGetSourcesQueryVariables,
    type ApiGetToplevelEntitiesQuery,
    type ApiGetToplevelEntitiesQueryVariables,
    type ApiRunMappingMutation,
    type ApiRunMappingMutationVariables,
    type ApiUpdateMappingMutation,
    type ApiUpdateMappingMutationVariables,
} from './importsQueries.gql';

export const importsApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getOntologyDefinition: builder.query<
            ApiGetOntologyDefinitionQuery,
            ApiGetOntologyDefinitionQueryVariables
        >({
            query: (variables) => ({
                document: getOntologyDefinitionQuery,
                variables,
            }),
        }),
        createSource: builder.mutation<
            ApiCreateSourceMutation,
            ApiCreateSourceMutationVariables
        >({
            query: (variables) => ({
                document: createSourceMutation,
                variables,
            }),
            invalidatesTags: ['sources'],
        }),
        getSources: builder.query<
            ApiGetSourcesQuery,
            ApiGetSourcesQueryVariables
        >({
            query: (variables) => ({
                document: getSourcesQuery,
                variables,
            }),
            providesTags: ['sources'],
            transformResponse(data: ApiGetSourcesQuery): ApiGetSourcesQuery {
                return {
                    sources: data.sources.map((source) => ({
                        ...source,
                        // TODO: Remove once we get data
                        schema: [
                            {
                                documentType: 'Products',
                                properties: [
                                    {
                                        type: 'string',
                                        path: `$['Product Name']`,
                                        name: 'Product name',
                                        examples: ['My chair'],
                                        hasNull: false,
                                    },
                                ],
                            },
                        ],
                    })),
                };
            },
        }),
        getToplevelEntities: builder.query<
            ApiGetToplevelEntitiesQuery,
            ApiGetToplevelEntitiesQueryVariables
        >({
            query: (variables) => ({
                document: getToplevelEntitiesQuery,
                variables,
            }),
        }),
        getMappingConfigurations: builder.query<
            ApiGetMappingConfigurationsQuery,
            ApiGetMappingConfigurationsQueryVariables
        >({
            query: (variables) => ({
                document: getMappingConfigurationsQuery,
                variables,
            }),
            providesTags: ['mappingConfigurations'],
        }),
        getMappingConfiguration: builder.query<
            ApiGetMappingConfigurationQuery,
            ApiGetMappingConfigurationQueryVariables
        >({
            query: (variables) => ({
                document: getMappingConfigurationQuery,
                variables,
            }),
            providesTags: ['mappingConfigurations'],
        }),
        createMappingConfiguration: builder.mutation<
            ApiCreateMappingConfigurationMutation,
            ApiCreateMappingConfigurationMutationVariables
        >({
            query: (variables) => ({
                document: createMappingConfigurationMutation,
                variables,
            }),
            invalidatesTags: ['mappingConfigurations'],
        }),
        updateMapping: builder.mutation<
            ApiUpdateMappingMutation,
            ApiUpdateMappingMutationVariables
        >({
            query: (variables) => ({
                document: updateMappingMutation,
                variables,
            }),
            invalidatesTags: ['mappingConfigurations'],
        }),
        deleteMapping: builder.mutation<
            ApiDeleteMappingMutation,
            ApiDeleteMappingMutationVariables
        >({
            query: (variables) => ({
                document: deleteMappingMutation,
                variables,
            }),
            invalidatesTags: ['mappingConfigurations'],
        }),
        getFileImportUploadUrl: builder.mutation<
            ApiGetFileImportUploadUrlMutation,
            ApiGetFileImportUploadUrlMutationVariables
        >({
            query: (variables) => ({
                document: getFileImportUploadUrlMutation,
                variables,
            }),
        }),
        getCSVHeaders: builder.query<
            ApiGetCsvHeadersQuery,
            ApiGetCsvHeadersQueryVariables
        >({
            query: (variables) => ({
                document: getCSVHeadersQuery,
                variables,
            }),
        }),
        runMapping: builder.mutation<
            ApiRunMappingMutation,
            ApiRunMappingMutationVariables
        >({
            query: (variables) => ({
                document: runMappingMutation,
                variables,
            }),
        }),
    }),
});
