import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgress } from '@mui/material';

import { Tooltip } from '@xeris/components';
import { AlertIcon, CheckIcon } from '@xeris/components/icons';

import { type ExportJobStatus } from '../../types';

type StatusIconProps = {
    message?: string;
};

const OkIcon = (): ReactElement => <CheckIcon color="primary" />;

const FailedIcon = ({ message }: StatusIconProps): ReactElement => {
    const { t } = useTranslation('exports');
    return (
        <Tooltip
            title={t('common.exportFailed', { message })}
            style={{ display: 'flex' }}
        >
            <AlertIcon />
        </Tooltip>
    );
};

const LoadingIcon = (): ReactElement => (
    <CircularProgress color="primary" size={24} />
);

const NoIcon = (): ReactElement | null => null;

const exportStatsIconLookup = {
    COMPLETED: OkIcon,
    STARTED: LoadingIcon,
    FAILED: FailedIcon,
    FILENAME_SET: NoIcon,
    PRODUCT_TRANSFER_FAILED: NoIcon,
    QUEUED: NoIcon,
    PRODUCT_TRANSFER_SKIPPED: NoIcon,
    PRODUCT_TRANSFERRED: NoIcon,
} as const satisfies Record<
    ExportJobStatus,
    (props: StatusIconProps) => ReactElement | null
>;

type ExportJobStatusIconProps = {
    status: ExportJobStatus;
    message?: string;
};

const ExportJobStatusIcon = ({
    status,
    message,
}: ExportJobStatusIconProps): ReactElement | null => {
    const Icon = exportStatsIconLookup[status];

    return <Icon message={message} />;
};

export default ExportJobStatusIcon;
