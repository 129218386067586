import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { AccordionSummary } from '@mui/material';

import { ChevronDownIcon } from '@xeris/components/icons';

import { Typography } from '../../../Typography/Typography';

type GroupHeaderProps = {
    optionCount: number;
    priceGroup: {
        name: string;
    };
};

export const MaterialPriceGroupHeader = ({
    optionCount,
    priceGroup,
}: GroupHeaderProps): ReactElement => {
    const { t } = useTranslation('product');

    return (
        <AccordionSummary
            sx={{
                flexDirection: 'row-reverse',
                paddingLeft: 0,
                paddingRight: 1,
                gap: 1,
            }}
            expandIcon={<ChevronDownIcon />}
        >
            <Typography variant={'h4'} sx={{ flexGrow: 1 }}>
                {priceGroup.name}
            </Typography>
            <Typography
                variant={'overline'}
                sx={(theme) => ({
                    fontWeight: 400,
                    color: theme.palette.text.secondary,
                    flexGrow: 1,
                })}
            >
                {t('variantGenerator.options', { optionCount })}
            </Typography>
        </AccordionSummary>
    );
};
