import { sharedDatasetsApi } from '../../api';
import { type DatasetTypes } from '../../types';
import { datasetsAdapter } from '../datasetAdapter';

export const sharedDatasetExtraReducers = (
    builder: DatasetTypes.ReducerBuilderType
): void => {
    builder.addMatcher(
        sharedDatasetsApi.endpoints.shareDataset.matchFulfilled,
        (state, action) => {
            const { data, variables } = action.payload;

            if (!data) {
                return;
            }

            datasetsAdapter.upsertOne(state.datasets, {
                id: variables.datasetId,
                sharedWith: data.dataset.sharedWith,
            } as DatasetTypes.MinimalDatasetType);
        }
    );
    builder.addMatcher(
        sharedDatasetsApi.endpoints.unshareDataset.matchFulfilled,
        (state, action) => {
            const { data, variables } = action.payload;

            if (!data) {
                return;
            }

            if (variables.removeYourself) {
                datasetsAdapter.removeOne(state.datasets, variables.datasetId);
            } else {
                datasetsAdapter.upsertOne(state.datasets, {
                    id: variables.datasetId,
                    sharedWith: data.dataset.unshare,
                } as DatasetTypes.MinimalDatasetType);
            }
        }
    );
};
