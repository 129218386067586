import { type ReactElement, type ReactNode } from 'react';

import { Stack } from '@mui/material';

type MaterialPriceGroupListProps = {
    children?: ReactNode;
};

export const MaterialPriceGroupList = ({
    children,
}: MaterialPriceGroupListProps): ReactElement => {
    return (
        <Stack direction={'column'} spacing={1} alignContent={'flex-start'}>
            {children}
        </Stack>
    );
};
