import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Skeleton, Toolbar, Typography } from '@mui/material';
import { GridActionsCellItem, type GridRowParams } from '@mui/x-data-grid';

import {
    CellLink,
    DataGrid,
    EmptyState,
    SearchBar,
    type TypeSafeColDef,
} from '@xeris/components';
import { DeleteIcon, EditIcon } from '@xeris/components/icons';
import { useLocale } from '@xeris/hooks';
import { brandApi } from '@xeris/pages/admin/api';
import { useActiveOrganization } from '@xeris/pages/admin/hooks';
import { search } from '@xeris/utilities';

import { AddBrand } from '../forms/AddBrand/AddBrand';
import { DeleteBrand } from '../forms/DeleteBrand/DeleteBrand';
import { EditBrand } from '../forms/EditBrand/EditBrand';

type RowData = {
    id: string;
    name: string;
    masterProductsCount: number;
    brand: Brand;
};

type Brand = {
    id: string;
    name: string;
    theme: {
        logo: string | null;
        featureImage: string | null;
    } | null;
};

const BrandsList = (): ReactElement | null => {
    const { t } = useTranslation('administration');
    const { t: tCommon } = useTranslation('common');
    const locale = useLocale();

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedBrand, setSelectedBrand] = useState<Brand | undefined>(
        undefined
    );
    const [selectedDeleteBrand, setSelectedDeleteBrand] = useState<
        Brand | undefined
    >(undefined);

    const activeOrganization = useActiveOrganization();

    const { data, isFetching } = brandApi.useGetAdminBrandsForOrganizationQuery(
        {
            organizationId: activeOrganization?.id ?? '',
        }
    );

    const brandList = data?.organization?.brands ?? [];

    const rows: RowData[] = brandList
        .map((brand) => ({
            id: brand.id,
            name: brand.name,
            masterProductsCount: brand.productCounts.masterProducts ?? 0,
            brand: brand,
        }))
        .filter((brand) => search(searchTerm, brand.name, brand.id));

    const columns: TypeSafeColDef<RowData>[] = [
        {
            field: 'id',
            headerName: t('brand.common.brandID'),
            width: 200,
            renderCell: ({ row }): ReactElement => (
                <CellLink to={`/Products/${row.id}`} noWrap>
                    {row.id}
                </CellLink>
            ),
        },
        {
            field: 'name',
            headerName: t('brand.common.name'),
            flex: 6,
            renderCell: ({ row }): ReactElement => (
                <CellLink
                    to={`/Products/${row.id}`}
                    noWrap
                    fontWeight={'medium'}
                >
                    {row.name}
                </CellLink>
            ),
        },
        {
            field: 'masterProductsCount',
            headerName: t('brand.common.products'),
            width: 100,
            headerAlign: 'right',
            align: 'right',
            valueFormatter: (value) => Intl.NumberFormat(locale).format(value),
        },
        {
            field: 'actions',
            type: 'actions',
            align: 'right',
            getActions: ({ row }: GridRowParams<RowData>) => [
                <GridActionsCellItem
                    key={'editBrand'}
                    label={t('brand.editBrand.editBrand')}
                    onClick={() => setSelectedBrand(row.brand)}
                    icon={<EditIcon fontSize={'small'} />}
                    showInMenu
                />,
                <GridActionsCellItem
                    key={'deleteBrand'}
                    label={t('brand.deleteBrand.deleteBrand')}
                    onClick={() => setSelectedDeleteBrand(row.brand)}
                    icon={<DeleteIcon fontSize={'small'} />}
                    showInMenu
                />,
            ],
        },
    ];

    return (
        <>
            <Typography variant={'h1'} gutterBottom>
                {t('brand.brands')}
            </Typography>
            <Toolbar disableGutters>
                <SearchBar
                    filter={searchTerm}
                    onChange={(value) => setSearchTerm(value)}
                    inputLabel={tCommon('search')}
                />
                <Box sx={{ flexGrow: 1 }} />
                <AddBrand />
            </Toolbar>
            {isFetching && <Skeleton height={300} />}
            {!isFetching && brandList.length === 0 && (
                <EmptyState title={t('brand.organizationHasNoBrands')} />
            )}
            {!isFetching && brandList.length > 0 && (
                <DataGrid
                    rows={rows}
                    columns={columns}
                    autoHeight
                    pagination={undefined}
                    hideFooter
                    disableRowSelectionOnClick
                />
            )}

            <EditBrand
                isOpen={!!selectedBrand}
                brandData={selectedBrand}
                handleClose={(): void => setSelectedBrand(undefined)}
            />

            <DeleteBrand
                isOpen={!!selectedDeleteBrand}
                brand={selectedDeleteBrand}
                handleCloseDialog={(): void =>
                    setSelectedDeleteBrand(undefined)
                }
            />
        </>
    );
};

export default BrandsList;
