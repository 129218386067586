import { type ReactElement, type ReactNode } from 'react';

import { AccordionSummary, Stack } from '@mui/material';

import { numberOrDash } from '@xeris/components/utilities';

import { FormattedPrice } from '../../../FormattedPrice/FormattedPrice';
import { SelectButton } from '../../../SelectButton/SelectButton';
import { Typography } from '../../../Typography/Typography';
import { PreviewButton } from '../../PreviewButton/PreviewButton';

type MaterialHeaderProps = {
    materialName: string;
    supplierName?: string;
    totalCount: number;
    selectedCount: number;
    price: {
        currency: string;
        value: number;
    } | null;
    defaultPrice?: string;
    isPreviewed?: boolean;
    isPartiallySelected?: boolean;
    isSelected?: boolean;
    isSelectable?: boolean;
    handleToggleMaterial: () => void;
    children: ReactNode;
};

export const MaterialHeader = ({
    materialName,
    supplierName,
    totalCount,
    selectedCount,
    price,
    defaultPrice,
    isPreviewed,
    isPartiallySelected,
    isSelected,
    isSelectable,
    handleToggleMaterial,
    children,
}: MaterialHeaderProps): ReactElement => {
    return (
        <AccordionSummary
            sx={{
                'gap': 1,
                'width': '100%',
                '& .MuiAccordionSummary-content': {
                    maxWidth: '100%',
                    gap: '4vw',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                },
            }}
        >
            <Stack
                direction={'column'}
                alignItems={'flex-start'}
                gap={0}
                overflow={'hidden'}
                width={'100%'}
                minWidth={0}
            >
                <Typography
                    variant={'body1'}
                    fontWeight={'fontWeightMedium'}
                    maxLines={1}
                >
                    {materialName}
                </Typography>

                <Typography variant={'caption'} maxLines={1}>
                    {supplierName}
                </Typography>
            </Stack>
            {children}
            <Stack
                spacing={0.25}
                alignContent={'flex-end'}
                width={70}
                flexShrink={0}
                justifyContent={'end'}
            >
                <Typography
                    variant={'caption'}
                    color={'text.primary'}
                    fontWeight={'fontWeightMedium'}
                >
                    {numberOrDash(selectedCount)}
                </Typography>
                <Typography variant={'caption'}>{' / '}</Typography>
                <Typography variant={'caption'}>
                    {numberOrDash(totalCount)}
                </Typography>
            </Stack>
            <FormattedPrice
                value={price?.value ?? null}
                currency={price?.currency}
                defaultValue={defaultPrice}
                typographyProps={{ sx: { minWidth: '80px' } }}
            />
            <Stack spacing={1} minWidth={72} justifyContent={'flex-end'}>
                {isPreviewed && (
                    <PreviewButton
                        onSelectPreview={() => {}}
                        isPreviewActive={isPreviewed}
                    />
                )}
                <SelectButton
                    size={'medium'}
                    onToggleOption={(event) => {
                        event.stopPropagation();
                        handleToggleMaterial();
                    }}
                    disabled={!isSelectable}
                    isSelected={isSelected}
                    isPartiallySelected={isPartiallySelected}
                />
            </Stack>
        </AccordionSummary>
    );
};
