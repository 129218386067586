import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from '@mui/material';

import { UnfoldLessIcon, UnfoldMoreIcon } from '@xeris/components/icons';

type ExpandSectionsProps = {
    noneExpanded: boolean;
    onClick: () => void;
};

export const ExpandSections = ({
    noneExpanded,
    onClick,
}: ExpandSectionsProps): ReactElement => {
    const { t } = useTranslation('products');

    return (
        <Tooltip
            title={noneExpanded ? t('brand.openAll') : t('brand.closeAll')}
        >
            <Button
                sx={{
                    marginBlock: 1,
                    paddingInline: '5px',
                    minWidth: 0,
                    borderColor: 'divider',
                }}
                color={'secondary'}
                variant={'outlined'}
                onClick={onClick}
            >
                {noneExpanded ? (
                    <UnfoldMoreIcon color={'icons'} />
                ) : (
                    <UnfoldLessIcon color={'icons'} />
                )}
            </Button>
        </Tooltip>
    );
};
