import { type ReactElement } from 'react';

import { Button, Tooltip } from '@mui/material';

import { UnfoldLessIcon, UnfoldMoreIcon } from '@xeris/components/icons';

type ExpandProps = {
    expanded: boolean;
    onClick: () => void;
    openTooltip: string;
    closeTooltip: string;
};

export const ExpandButton = ({
    expanded,
    onClick,
    openTooltip,
    closeTooltip,
}: ExpandProps): ReactElement => {
    return (
        <Tooltip title={expanded ? closeTooltip : openTooltip}>
            <Button
                sx={{
                    marginBlock: 1,
                    paddingInline: '5px',
                    minWidth: 0,
                    borderColor: 'divider',
                }}
                color={'secondary'}
                variant={'outlined'}
                onClick={onClick}
            >
                {expanded ? (
                    <UnfoldLessIcon color={'icons'} />
                ) : (
                    <UnfoldMoreIcon color={'icons'} />
                )}
            </Button>
        </Tooltip>
    );
};
