import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@xeris/components';
import { CloseIcon } from '@xeris/components/icons';
import { useIsMobile } from '@xeris/hooks';
import type { DatasetSharedWith } from '@xeris/pages/dataset/types/datasetTypes';

import AccessInfo from './AccessInfo/AccessInfo';
import SharedWithList from './SharedWithList/SharedWithList';
import ShareForm from './ShareForm/ShareForm';

type ShareDatasetFormProps = {
    isOpen?: boolean;
    handleClose: () => void;
    dataset: {
        id: string;
        name: string;
        sharedWith: DatasetSharedWith[];
    };
};

export const ShareDatasetForm = ({
    isOpen = false,
    handleClose,
    dataset,
}: ShareDatasetFormProps): ReactElement => {
    const { t } = useTranslation('dataset');
    const isMobile = useIsMobile();

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth={'sm'}
            fullScreen={isMobile}
            onClose={handleClose}
            sx={{ minHeight: '750px' }}
        >
            <DialogTitle
                component={'span'}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '12px',
                }}
            >
                <Typography variant={'h2'} maxLines={1}>
                    {t('datasetSharingPopUp.dialogTitle', {
                        datasetName: dataset.name,
                    })}
                </Typography>
                <IconButton onClick={handleClose} edge={'end'}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                <AccessInfo />
                <ShareForm dataset={dataset} />
                <SharedWithList
                    sharedWith={dataset.sharedWith}
                    datasetId={dataset.id}
                />
            </DialogContent>
        </Dialog>
    );
};
