import { type ApiGetOntologyDefinitionQuery } from '../api/importsQueries.gql';
import { type EntityType, type SectionLayout } from '../types';

const getEntityTreeRecursive = (
    root: ApiGetOntologyDefinitionQuery['entitySchema'][number],
    entities: ApiGetOntologyDefinitionQuery['entitySchema'],
    sections: SectionLayout[],
    path: string[] = []
): EntityType => {
    return {
        ...root,
        attributes: root.attributes.map((attribute) => {
            const attributePath = [...path, attribute.id];

            const section =
                sections.find((s) => s.id === attribute.sectionId) ?? null;

            if (attribute.__typename === 'ReferenceAttributeType') {
                const entity = entities.find(
                    (e) => e.id === attribute.entityTypeId
                );

                if (entity) {
                    return {
                        ...attribute,
                        section,
                        path: attributePath,
                        pathString: attributePath.join('/'),
                        entity: getEntityTreeRecursive(
                            entity,
                            entities,
                            sections,
                            attributePath
                        ),
                    };
                }

                throw new Error('Entity not found');
            }

            return {
                ...attribute,
                path: attributePath,
                pathString: attributePath.join('/'),
                section,
            };
        }),
    };
};

export const getEntityTree = (
    rootEntityId?: string,
    entities?: ApiGetOntologyDefinitionQuery['entitySchema'],
    sections?: SectionLayout[]
): EntityType | null => {
    if (!rootEntityId || !entities || !sections) return null;

    const root = entities.find((entity) => entity.id === rootEntityId);

    if (!root) return null;

    return getEntityTreeRecursive(root, entities, sections);
};
