import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
    List,
    ListItem,
    ListItemButton,
    Paper,
    Stack,
    Typography,
} from '@mui/material';

import CenteredIconCard from '@xeris/components/CenteredIconCard/CenteredIconCard';
import { ListAddIcon, ListCheckIcon } from '@xeris/components/icons';
import { type FeatureGroup } from '@xeris/pages/product/types';
import { useURLState } from '@xeris/pages/product/variantGenerator/hooks';
import { NavButton } from '@xeris/pages/product/variantGenerator/Navigation/NavButton';
import { useAppSelector } from '@xeris/reducers';

import { variantGeneratorSelectors } from '../reducer';

type NextFeatureButtonProps = {
    featureGroup: FeatureGroup;
};

const NextFeatureButton = ({
    featureGroup,
}: NextFeatureButtonProps): ReactElement => {
    const { t } = useTranslation('product');

    return (
        <Stack paddingBlock={4} justifyContent={'center'}>
            <NavButton
                to={{ hash: featureGroup.features[0]?.id }}
                state={{ page: 'configure', featureGroup: featureGroup.id }}
                next
            >
                {t('variantGenerator.stages.next')}
            </NavButton>
        </Stack>
    );
};

type InlineNavigationProps = {
    featureGroups: FeatureGroup[];
};

const InlineNavigation = ({
    featureGroups,
}: InlineNavigationProps): ReactElement => {
    const { t } = useTranslation('product');
    const { featureGroupId } = useURLState();

    const missingFeatures = useAppSelector(
        variantGeneratorSelectors.selectMissingFeatures
    );

    const currentFeatureIndex = Math.max(
        featureGroups.findIndex(
            (featureGroup) => featureGroup.id === featureGroupId
        ),
        0
    );

    if (currentFeatureIndex < featureGroups.length - 1) {
        return (
            <NextFeatureButton
                featureGroup={featureGroups[currentFeatureIndex + 1]}
            />
        );
    }

    if (missingFeatures.length === 0) {
        return (
            <Paper
                variant={'outlined'}
                sx={{
                    marginInline: 'auto',
                    marginBlock: 2,
                    borderColor: 'background.paper',
                }}
            >
                <CenteredIconCard
                    title={t('variantGenerator.happyWithOptions')}
                    icon={<ListCheckIcon />}
                    color={'primary'}
                    variant={'contained'}
                    primaryAction={
                        <NavButton next to={{}} state={{ page: 'summary' }}>
                            {t('variantGenerator.summary')}
                        </NavButton>
                    }
                    secondaryAction={
                        currentFeatureIndex > 0 && (
                            <NavButton
                                back
                                to={{}}
                                state={{
                                    page: 'configure',
                                    featureGroup:
                                        featureGroups[
                                            Math.max(currentFeatureIndex - 1, 0)
                                        ]?.id,
                                }}
                            >
                                {t('variantGenerator.stages.back')}
                            </NavButton>
                        )
                    }
                >
                    <Typography>
                        {t('variantGenerator.checkOutSummary')}
                    </Typography>
                </CenteredIconCard>
            </Paper>
        );
    }

    return (
        <Paper
            variant={'outlined'}
            sx={{
                marginInline: 'auto',
                marginBlock: 2,
                borderColor: 'background.paper',
            }}
        >
            <CenteredIconCard
                title={t('variantGenerator.missingSomething')}
                icon={<ListAddIcon />}
                color={'icons'}
                variant={'outlined'}
                primaryAction={
                    <NavButton to={{}} state={{ page: 'summary' }} next>
                        {t('variantGenerator.summary')}
                    </NavButton>
                }
                secondaryAction={
                    currentFeatureIndex > 0 && (
                        <NavButton
                            to={{}}
                            state={{
                                page: 'configure',
                                featureGroup:
                                    featureGroups[
                                        Math.max(currentFeatureIndex - 1, 0)
                                    ],
                            }}
                            back
                        >
                            {t('variantGenerator.stages.back')}
                        </NavButton>
                    )
                }
            >
                <List>
                    {missingFeatures.map((missingFeature) => {
                        return (
                            <ListItem dense key={missingFeature.id}>
                                <ListItemButton
                                    component={Link}
                                    to={{ hash: missingFeature.id }}
                                    state={{
                                        page: 'configure',
                                        featureGroup:
                                            missingFeature.featureGroupId,
                                    }}
                                    sx={{ borderRadius: 1 }}
                                >
                                    {missingFeature.name}
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </CenteredIconCard>
        </Paper>
    );
};

export default InlineNavigation;
