import { gql } from 'graphql-request';

import {
    baseProductEntityWithoutImagesFields,
    productEntityDataFields,
} from '../productFragments';

export const getAllMasterProductsForBrandQuery = gql`
    query GetAllMasterProductsForBrand(
        $brandId: String!
        $dataLanguage: Language!
    ) {
        brand(id: $brandId, language: $dataLanguage) {
            name
            groupTypes: group_types {
                id
                name
                externalId: external_id
                groups {
                    id
                    name
                    description
                    externalId: external_id
                }
            }
            markets {
                id
                name
                externalId: external_id
            }
            masterProducts: master_products {
                __typename
                id
                name
                description
                lastUpdated: last_updated
                externalId: external_id
                images {
                    id
                    title
                    objectFit
                    url(sizeFormat: THUMBNAIL)
                    type
                }
                brand {
                    id
                    name
                }
                markets {
                    id
                    name
                }
                idNumbers: id_numbers {
                    name
                    value
                    type
                }
                isConfigurable: is_configurable
                groups {
                    name
                    id
                    description
                    type {
                        id
                    }
                }
                products {
                    id
                    name
                    idNumbers: id_numbers {
                        value
                    }
                    images {
                        id
                        title
                        objectFit
                        url(sizeFormat: THUMBNAIL)
                        type
                    }
                    markets {
                        id
                        name
                        externalId: external_id
                    }
                    groups {
                        name
                        id
                        description
                        type {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const getMasterProductQuery = gql`
    query GetMasterProduct(
        $masterProductId: String!
        $dataLanguage: Language!
    ) {
        master_product(id: $masterProductId, language: $dataLanguage) {
            __typename
            ...BaseProductEntityWithoutImagesFields
            ...ProductEntityDataFields
            isConfigurable: is_configurable
            source
            price {
                value
                currency
                priceUnit: price_unit
            }
            prices {
                value
                currency
                markets
                priceUnit: price_unit
            }
            products {
                id
                idNumbers: id_numbers {
                    value
                }
                markets {
                    id
                    name
                    externalId: external_id
                }
            }
            relatedMasterProducts: related_master_products {
                id
            }
            groups {
                id
                name
                description
            }
        }
    }
    ${baseProductEntityWithoutImagesFields}
    ${productEntityDataFields}
`;

export const getConfigurableMasterProductQuery = gql`
    query GetConfigurableMasterProduct(
        $datasetId: String!
        $hasDatasetId: Boolean!
        $masterProductId: String!
        $marketId: String
        $dataLanguage: Language!
    ) {
        master_product(id: $masterProductId, language: $dataLanguage) {
            __typename
            id
            name
            isConfigurable: is_configurable
            brand {
                id
                name
            }
            descriptions {
                id
                name
                value
            }
            documents {
                id
                fileName
                title
                type {
                    id
                    label
                }
                url
            }
            images {
                id
                title
                type
                objectFit
                url(sizeFormat: FULLSIZE)
                id
            }
            models {
                id
                fileName
                type
                title
                url
            }
            price {
                value
                currency
                priceUnit: price_unit
                from_date
                to_date
                markets
            }
            prices {
                value
                currency
                markets
                priceUnit: price_unit
                from_date
                to_date
                markets
            }
            featureConfiguration: feature_configuration_v2 {
                __typename
                variantCount: variant_count
                featureClusters: feature_clusters
                conditionalPrice: conditional_price(market: $marketId) {
                    conditionalValues: conditional_values {
                        value
                        condition
                    }
                    conditional_values {
                        value
                        condition
                    }
                    currency
                    markets
                    priceUnit: price_unit
                    price_unit
                }
                conditional_price(market: $marketId) {
                    conditional_values {
                        value
                        condition
                    }
                    currency
                    markets
                    price_unit
                }
                conditional_prices {
                    conditional_values {
                        value
                        condition
                    }
                    currency
                    markets
                    price_unit
                }
                basePrice: base_price(market: $marketId) {
                    currency
                    value
                    from_date
                    to_date
                    markets
                }
                basePrices: base_prices {
                    currency
                    value
                    from_date
                    to_date
                    markets
                }
                featureGroups: feature_groups {
                    id
                    name
                    features {
                        id
                        name
                        description
                        __typename
                        ... on MaterialFeature {
                            description
                            condition
                            materials {
                                id
                                name
                                description
                                priceGroup: price_group
                                price_group
                                supplier {
                                    id
                                    name
                                }
                                price(market: $marketId) {
                                    value
                                    currency
                                    from_date
                                    to_date
                                    markets
                                }
                                prices {
                                    value
                                    currency
                                    from_date
                                    to_date
                                    markets
                                }
                                options {
                                    id
                                    name
                                    description
                                    image
                                    colorCode: color_code
                                    condition
                                }
                                masterProduct: master_product {
                                    id
                                }
                            }
                        }
                        ... on BasicFeature {
                            description
                            condition
                            options {
                                __typename
                                id
                                name
                                description
                                condition
                                image
                                price(market: $marketId) {
                                    value
                                    currency
                                    from_date
                                    to_date
                                    markets
                                }
                                prices {
                                    value
                                    currency
                                    from_date
                                    to_date
                                    markets
                                }
                            }
                        }
                    }
                }
                preview {
                    ... on PreviewConfigurationImage {
                        __typename
                        views {
                            __typename
                            id
                            name
                            components {
                                __typename
                                id
                                features
                                variants {
                                    options
                                    image
                                }
                            }
                        }
                    }
                    ... on PreviewConfiguration3D {
                        __typename
                        views {
                            __typename
                            id
                            name
                            eye {
                                x
                                y
                                z
                            }
                            target {
                                x
                                y
                                z
                            }
                        }
                        model
                        components {
                            id
                            features
                            variants {
                                options
                                texture
                            }
                        }
                    }
                }
            }
            selectedData: selected_data(data_set_id: $datasetId)
                @include(if: $hasDatasetId) {
                images {
                    id
                }
                documents {
                    id
                }
                models {
                    id
                }
                configurationSets: configuration_sets {
                    id
                    name
                    images {
                        id
                    }
                    documents {
                        id
                    }
                    models {
                        id
                    }
                    views
                    selectedData: selected_data {
                        images {
                            id
                        }
                        documents {
                            id
                        }
                        models {
                            id
                        }
                    }
                    features {
                        id
                        options {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const getAllProductsForMasterProductQuery = gql`
    query GetAllProductsForMasterProduct(
        $masterProductId: String!
        $dataLanguage: Language!
    ) {
        master_product(id: $masterProductId, language: $dataLanguage) {
            __typename
            products {
                __typename
                id
                name
                description
                gtin
                lastUpdated: last_updated
                brand {
                    id
                    name
                }
                masterProduct: master_product {
                    id
                    name
                }
                images {
                    id
                    type
                    title
                    objectFit
                    url(sizeFormat: THUMBNAIL)
                }
                markets {
                    id
                    name
                    externalId: external_id
                }
                idNumbers: id_numbers {
                    value
                }
                distinguishingFeatures: distinguishing_features {
                    type
                    value
                    name
                }
            }
        }
    }
`;

export const getMasterProductSourceDataQuery = gql`
    query GetMasterProductSourceData(
        $masterProductId: String!
        $dataLanguage: Language!
    ) {
        master_product(id: $masterProductId, language: $dataLanguage) {
            __typename
            source_data {
                type
                data
            }
        }
    }
`;

export const getMasterProductDataErrorsQuery = gql`
    query GetMasterProductDataErrors(
        $masterProductId: String!
        $dataLanguage: Language!
    ) {
        master_product(id: $masterProductId, language: $dataLanguage) {
            __typename
            data_errors {
                type
                message
            }
        }
    }
`;

export const getRelatedMasterProductsQuery = gql`
    query GetRelatedMasterProducts(
        $masterProductId: String!
        $dataLanguage: Language!
    ) {
        master_product(id: $masterProductId, language: $dataLanguage) {
            __typename
            id
            relatedMasterProducts: related_master_products {
                __typename
                id
                name
                description
                isConfigurable: is_configurable
                lastUpdated: last_updated
                brand {
                    id
                    name
                }
                markets {
                    id
                    name
                    externalId: external_id
                }
                images {
                    title
                    url(sizeFormat: THUMBNAIL)
                    id
                    type
                    objectFit
                }
                idNumbers: id_numbers {
                    value
                }
                groups {
                    id
                    name
                    description
                }
                products {
                    id
                    idNumbers: id_numbers {
                        value
                    }
                    markets {
                        id
                        name
                        externalId: external_id
                    }
                }
            }
        }
    }
`;
