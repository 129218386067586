import type { ReactElement, ReactNode } from 'react';

import { Avatar, Stack, styled, Typography } from '@mui/material';

type Variant = 'contained' | 'outlined';
type Color =
    | 'primary'
    | 'secondary'
    | 'warning'
    | 'error'
    | 'icons'
    | 'info'
    | 'success';

const CircleAvatar = styled(Avatar, {
    shouldForwardProp: (prop) => prop !== 'colorVariant' && prop !== 'color',
})<{ colorVariant: Variant; color: Color }>(
    ({ theme, colorVariant, color }) => ({
        '& .MuiSvgIcon-root': {
            color:
                colorVariant === 'contained'
                    ? theme.palette.common.white
                    : theme.palette[color].main,
            fontSize: '2rem',
        },
        'backgroundColor':
            colorVariant === 'contained'
                ? theme.palette[color].main
                : theme.palette.common.white,
        'border': '1px solid',
        'borderColor': theme.palette[color].main,
        'width': 56,
        'height': 56,
    })
);

type CenteredIconCardProps = {
    icon: ReactNode;
    title: string;
    children?: ReactNode;
    primaryAction?: ReactNode;
    secondaryAction?: ReactNode;
    color?: Color;
    variant?: Variant;
};

const CenteredIconCard = ({
    icon,
    title,
    children,
    primaryAction,
    secondaryAction,
    color = 'primary',
    variant = 'contained',
}: CenteredIconCardProps): ReactElement => {
    return (
        <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={2}
            paddingBlock={8}
            paddingInline={'20%'}
        >
            <CircleAvatar color={color} colorVariant={variant}>
                {icon}
            </CircleAvatar>
            <Typography variant={'h3'}>{title}</Typography>
            {children}
            <Stack gap={2} justifyContent={'center'} paddingTop={1}>
                {secondaryAction}
                {primaryAction}
            </Stack>
        </Stack>
    );
};

export default CenteredIconCard;
