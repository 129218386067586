import { type ReactElement, useState } from 'react';

import {
    Box,
    Button,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';

import { ChevronDownIcon, ChevronUpIcon } from '@xeris/components/icons';

type FilterSectionProps = {
    name: string;
    options: {
        id: string;
        label: string;
        isChecked: boolean;
        count: number;
    }[];
    onChange: (optionId: string, value: boolean) => void;
};

export const FilterSection = ({
    name,
    options,
    onChange,
}: FilterSectionProps): ReactElement => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [isShowAll, setIsShowAll] = useState(false);

    return (
        <>
            <ListItem
                disableGutters
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <ListItemButton
                    onClick={() => {
                        setIsExpanded((value) => !value);
                        setIsShowAll(false);
                    }}
                    sx={{
                        display: 'flex',
                        gap: 2,
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography>{name}</Typography>
                    {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </ListItemButton>
            </ListItem>
            {isExpanded && (
                <li>
                    <List sx={{ padding: 0 }}>
                        {options
                            .slice(0, isShowAll ? Infinity : 4)
                            .map((option) => (
                                <ListItem
                                    key={option.id}
                                    disableGutters
                                    dense
                                    sx={{ padding: 0 }}
                                >
                                    <ListItemButton
                                        role={undefined}
                                        disabled={
                                            option.count === 0 &&
                                            !option.isChecked
                                        }
                                        onClick={() =>
                                            onChange(
                                                option.id,
                                                !option.isChecked
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                tabIndex={-1}
                                                disableRipple
                                                checked={option.isChecked}
                                                inputProps={{
                                                    'aria-labelledby':
                                                        option.id,
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            secondary={option.count}
                                            primaryTypographyProps={{
                                                noWrap: true,
                                                id: option.id,
                                            }}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 2,
                                            }}
                                        >
                                            {option.label}
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        {options.length > 4 && (
                            <li>
                                <Button
                                    onClick={() =>
                                        setIsShowAll((value) => !value)
                                    }
                                    sx={{ marginInline: 0.75 }}
                                >
                                    {isShowAll ? 'Show less' : 'Show more'}
                                </Button>
                            </li>
                        )}
                    </List>
                </li>
            )}
            <Box
                component={'li'}
                sx={{ 'marginBlock': 1, '&:last-child': { display: 'none' } }}
            >
                <Divider />
            </Box>
        </>
    );
};
