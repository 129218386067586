import { useWhoami } from '@xeris/hooks';
import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import { useAppSelector } from '@xeris/reducers';

export const useActiveOrganizationId = (): string => {
    const impersonatedOrganizationId = useAppSelector(
        settingsSelectors.selectImpersonatedOrganizationId
    );

    const { organizationId: userOrganizationId } = useWhoami();

    return impersonatedOrganizationId ?? userOrganizationId;
};
