type MasterProduct = {
    id: string;
    brand: { id: string };
};

type Product = {
    id: string;
    brand: { id: string };
    masterProduct: { id: string };
};

export const datasheetUrl = (
    masterProduct?: MasterProduct | null,
    product?: Product | null
): string => {
    if (masterProduct) {
        return masterProductUrl(masterProduct);
    }
    if (product) {
        return productUrl(product);
    }

    return '';
};

export const masterProductUrl = (masterProduct: MasterProduct): string =>
    `/Products/${masterProduct.brand.id}/MasterProducts/${masterProduct.id}`;

export const productUrl = (product: Product): string =>
    `/Products/${product.brand.id}/MasterProducts/${product.masterProduct.id}/Product/${product.id}`;
