import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box,
    ButtonBase,
    Link,
    styled,
    type SxProps,
    type Theme,
    Typography,
} from '@mui/material';

import { Image, ImageGallery } from '@xeris/components';
import { FullscreenIcon, OpenInNewIcon } from '@xeris/components/icons';
import {
    type ImageType,
    type MediaDocumentType,
    type VideoType,
} from '@xeris/pages/product/types';
import { type ApiImageType } from '@xeris/types';

const SectionWrapper = styled(Box)(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: 'repeat(auto-fill, 100px)',
    gridAutoRows: '100px',
}));

const Overlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    placeItems: 'center',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    backgroundColor: theme.palette.action.disabled,
}));

const imageWrapperSx: SxProps<Theme> = (theme) => ({
    'position': 'relative',
    '&:hover > div': { opacity: 1 },
    'border': 1,
    'borderColor': 'divider',
    'borderRadius': `${theme.shape.borderRadius}px`,
    'overflow': 'hidden',
});

const productImageTypes: ApiImageType[] = [
    'Studio',
    'Lifestyle',
    'Detail',
    'Group',
    'Packaging',
    'Process',
    'Scale',
    'Technical',
    'Other',
    'Uncategorized',
];

type ImageSectionProps = {
    title: string;
    images: ImageType[];
    onClick: (url: string | null) => void;
};

const ImageSection = ({
    title,
    images,
    onClick,
}: ImageSectionProps): ReactElement | null => {
    if (images.length === 0) return null;

    return (
        <div>
            <Typography
                variant={'subtitle1'}
                component={'h3'}
                sx={{ marginBottom: 2 }}
            >
                {title}
            </Typography>
            <SectionWrapper>
                {images.map((image) => (
                    <ButtonBase
                        key={image.id}
                        onClick={() => onClick(image.url)}
                        disableRipple
                        sx={imageWrapperSx}
                    >
                        <Overlay>
                            <FullscreenIcon color={'white'} />
                        </Overlay>
                        <Image
                            src={image.url}
                            objectFit={image.objectFit}
                            loading="lazy"
                            width={'100%'}
                            height={'100%'}
                        />
                    </ButtonBase>
                ))}
            </SectionWrapper>
        </div>
    );
};

type VideoSectionProps = {
    title: string;
    videos: VideoType[];
};

const VideoSection = ({
    videos,
    title,
}: VideoSectionProps): ReactElement | null => {
    if (videos.length === 0) return null;

    return (
        <div>
            <Typography
                variant={'subtitle1'}
                component={'h3'}
                sx={{ marginBottom: 2 }}
            >
                {title}
            </Typography>
            <SectionWrapper>
                {videos.map((video) => (
                    <Link
                        href={video.url}
                        target={'_blank'}
                        rel="noreferrer"
                        key={video.url}
                        sx={imageWrapperSx}
                    >
                        <Overlay>
                            <OpenInNewIcon color={'white'} />
                        </Overlay>
                        <Image
                            src={video.thumbnail}
                            loading="lazy"
                            width={'100%'}
                            height={'100%'}
                        />
                    </Link>
                ))}
            </SectionWrapper>
        </div>
    );
};

type MediaProps = {
    data: MediaDocumentType;
};

const Media = ({ data: media }: MediaProps): ReactElement => {
    const { t } = useTranslation('product');

    const [imageToView, setImageToView] = useState<null | string>(null);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <ImageGallery
                show={!!imageToView}
                images={media.images}
                currentImageUrl={imageToView}
                close={setImageToView}
            />
            {productImageTypes.map((type) => (
                <ImageSection
                    key={type}
                    images={media.images.filter((image) => image.type === type)}
                    title={t(`datasheet.images.${type}`)}
                    onClick={setImageToView}
                />
            ))}
            <VideoSection videos={media.videos} title={t('datasheet.videos')} />
        </Box>
    );
};

export default Media;
