import { type ReactElement, type SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

import { type Order, type OrderBy } from '@xeris/pages/admin/Import';

type ImportTableHeadProps = {
    order: Order;
    onRequestSort: (event: SyntheticEvent, property: OrderBy) => void;
    orderBy: OrderBy;
};

type LabelType = 'id' | 'source' | 'start' | 'duration' | 'status';

const headCells: { id: OrderBy; label: LabelType }[] = [
    { id: 'id', label: 'id' },
    { id: 'source', label: 'source' },
    { id: 'start', label: 'start' },
    { id: 'duration', label: 'duration' },
    { id: 'status', label: 'status' },
];

export const ImportTableHead = ({
    order,
    orderBy,
    onRequestSort,
}: ImportTableHeadProps): ReactElement => {
    const { t } = useTranslation('administration');
    const createSortHandler =
        (property: OrderBy) =>
        (event: SyntheticEvent): void => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={index}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                            aria-label={
                                orderBy === headCell.id
                                    ? order === 'desc'
                                        ? t('imports.sortedDescending')
                                        : t('imports.sortedAscending')
                                    : ''
                            }
                        >
                            {t(`imports.label.${headCell.label}`)}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
    );
};
