import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Skeleton, Toolbar } from '@mui/material';
import { GridActionsCellItem, type GridRowParams } from '@mui/x-data-grid';

import { type TFunction } from 'i18next';

import {
    DataGrid,
    ErrorPage,
    MutationSnackbars,
    SearchBar,
    type TypeSafeColDef,
} from '@xeris/components';
import {
    AccountRemoveIcon,
    EmailIcon,
    ResetPasswordIcon,
} from '@xeris/components/icons';
import { useWhoami } from '@xeris/hooks';
import { userApi, userVerificationApi } from '@xeris/pages/admin/api';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';
import { loginApi } from '@xeris/pages/login/api/loginApi';
import type { ApiSecurityLevel } from '@xeris/types';
import { search } from '@xeris/utilities';

import { InviteUserButton } from '../components/InviteUserButton';
import { DeleteUserForm } from '../forms/DeleteUserDialog';
import { ResendInviteForm } from '../forms/ResendInviteForm';

import RegistrationStatusChips from './RegistrationStatusChips';

type RowData = {
    id: string;
    name: string | null;
    username: string;
    registrationStatus: string;
    securityLevel: ApiSecurityLevel | null;
    language: string;
};

const getColumns = (
    t: TFunction<'administration'>,
    userId: string,
    isSuperAdmin: boolean,
    resetUserPassword: (username: string) => void,
    setResendInviteUser: (user: RowData) => void,
    setUsernameToDelete: (username: string | null) => void,
    impersonateUser: (userId: string) => void
): TypeSafeColDef<RowData>[] => [
    {
        field: 'name',
        headerName: t('users.name'),
        valueGetter: (value, row) => row.name?.trim() || row.username,
        flex: 2,
    },
    {
        field: 'username',
        headerName: t('users.email'),
        flex: 1,
    },
    {
        field: 'registrationStatus',
        headerName: t('users.status'),
        width: 120,
        renderCell: (params): ReactElement => {
            return (
                <RegistrationStatusChips registrationStatus={params.value} />
            );
        },
    },
    {
        field: 'securityLevel',
        headerName: t('users.role'),
        width: 70,
        renderCell: (params): ReactElement => {
            return <Role securityLevel={params.value} />;
        },
    },
    {
        field: 'actions',
        type: 'actions',
        align: 'right',
        getActions: ({ row }: GridRowParams<RowData>) => {
            const menuItems = [];

            if (
                row.registrationStatus === 'Invited' ||
                row.registrationStatus === 'Added'
            ) {
                menuItems.push(
                    <GridActionsCellItem
                        key={'resendInvite'}
                        label={t('users.resendInvite')}
                        onClick={() => setResendInviteUser(row)}
                        icon={<EmailIcon fontSize={'small'} />}
                        showInMenu
                    />
                );
            } else {
                menuItems.push(
                    <GridActionsCellItem
                        key={'resetPassword'}
                        label={t('users.resetPassword')}
                        onClick={() => resetUserPassword(row.username)}
                        icon={<ResetPasswordIcon fontSize={'small'} />}
                        showInMenu
                    />
                );
            }

            if (isSuperAdmin && userId !== row.id) {
                menuItems.push(
                    <GridActionsCellItem
                        key={'impersonateUser'}
                        label={t('users.impersonateUser')}
                        onClick={() => impersonateUser(row.id)}
                        icon={<ResetPasswordIcon fontSize={'small'} />}
                        showInMenu
                    />
                );
            }

            menuItems.push(
                <GridActionsCellItem
                    key={'deleteUser'}
                    label={t('users.deleteUser')}
                    onClick={() => setUsernameToDelete(row.username)}
                    icon={<AccountRemoveIcon fontSize={'small'} />}
                    showInMenu
                />
            );

            return menuItems;
        },
    },
];
type RoleProps = {
    securityLevel: ApiSecurityLevel;
};

const Role = ({ securityLevel }: RoleProps): ReactElement => {
    const { t } = useTranslation('administration');
    return <span>{t(`users.securityLevel.${securityLevel}`)}</span>;
};

const UserList = (): ReactElement => {
    const { t } = useTranslation(['administration', 'common']);

    const [searchTerm, setSearchTerm] = useState('');
    const [usernameToDelete, setUsernameToDelete] = useState<string | null>(
        null
    );

    const [resetUserPassword, resetUserPasswordResult] =
        userVerificationApi.useResetUserPasswordMutation();

    const [impersonate] = loginApi.useImpersonateMutation();

    const activeOrganizationId = useActiveOrganizationId();
    const { userId, isSuperAdmin } = useWhoami();

    const [resendInviteUser, setResendInviteUser] = useState<RowData | null>(
        null
    );

    const { data, isLoading, isError, refetch } = userApi.useGetUsersQuery({
        organizationId: activeOrganizationId,
    });

    const handleImpersonateUser = (userIdToImpersonate: string): void => {
        impersonate({ userId: userIdToImpersonate });
    };

    if (isLoading) {
        return <Skeleton height={300} />;
    }

    if (isError) {
        return (
            <ErrorPage
                title={t('apiErrors.anErrorOccurred', { ns: 'common' })}
                onClick={refetch}
                actionText={t('apiErrors.tryAgain', { ns: 'common' })}
            />
        );
    }

    if (!data) {
        return <ErrorPage title={t('apiErrors.notFound', { ns: 'common' })} />;
    }

    const userList: RowData[] = data.users.filter((user) =>
        search(searchTerm, user.name, user.username)
    );

    return (
        <>
            <Toolbar disableGutters>
                <SearchBar
                    filter={searchTerm}
                    onChange={(value) => setSearchTerm(value)}
                    inputLabel={t('search', { ns: 'common' })}
                />
                <Box sx={{ flexGrow: 1 }} />
                <InviteUserButton />
            </Toolbar>
            <DataGrid
                rows={userList}
                columns={getColumns(
                    t,
                    userId,
                    isSuperAdmin,
                    resetUserPassword,
                    setResendInviteUser,
                    setUsernameToDelete,
                    handleImpersonateUser
                )}
                pageSizeOptions={[25, 50, 75, 100]}
                autoHeight
                disableRowSelectionOnClick
            />
            <DeleteUserForm
                username={usernameToDelete}
                isOpen={!!usernameToDelete}
                handleClose={() => setUsernameToDelete(null)}
            />
            <ResendInviteForm
                isOpen={!!resendInviteUser}
                handleClose={() => setResendInviteUser(null)}
                user={resendInviteUser}
            />
            <MutationSnackbars
                isSuccess={resetUserPasswordResult.isSuccess}
                successText={t('users.resetPasswordSent')}
                isError={resetUserPasswordResult.isError}
                errorText={t('users.resetPasswordFailed')}
            />
        </>
    );
};

export default UserList;
