import { type ReactElement, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Typography,
} from '@mui/material';

type StepWrapperProps = {
    title: string;
    handleClose: () => void;
    children?: ReactNode;
    header: ReactNode;
    activeStep: number;
    isLoading?: boolean;
    onSubmit: () => void;
    onBack: () => void;
};

export const StepWrapper = ({
    title,
    handleClose,
    children,
    activeStep,
    isLoading,
    header,
    onSubmit,
    onBack,
}: StepWrapperProps): ReactElement => {
    const { t } = useTranslation('imports');

    return (
        <Box
            display={'flex'}
            height={'100%'}
            flexDirection={'column'}
            component={'form'}
            onSubmit={onSubmit}
        >
            {header}
            <DialogContent dividers sx={{ maxWidth: '650px', width: '100vw' }}>
                <Typography variant={'h3'} gutterBottom>
                    {title}
                </Typography>
                {children}
            </DialogContent>
            <DialogActions>
                <Button color={'secondary'} onClick={handleClose}>
                    {t('common.cancel')}
                </Button>
                <Box flexGrow={1} />
                {activeStep > 0 && (
                    <Button color={'secondary'} onClick={onBack}>
                        {t('common.back')}
                    </Button>
                )}
                <LoadingButton
                    loading={isLoading}
                    variant={'contained'}
                    type={'submit'}
                >
                    {activeStep + 1 === 3 ? t('common.save') : t('common.next')}
                </LoadingButton>
            </DialogActions>
        </Box>
    );
};
