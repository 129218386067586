import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { loginApi } from '@xeris/pages/login/api/loginApi';

const Logout = (): null => {
    const navigate = useNavigate();
    const [logout] = loginApi.useLogoutMutation();

    useEffect(() => {
        logout({});
        navigate('/', { replace: true });
    }, [logout, navigate]);

    return null;
};

export default Logout;
