const desc = <
    Element extends { [key in Key]?: number | string | null },
    Key extends string | symbol | number = string,
>(
    a: Element,
    b: Element,
    orderBy: Key
): number => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (typeof aValue === 'number' && typeof bValue === 'number') {
        return bValue - aValue;
    }

    return (bValue ?? '').toString().localeCompare((aValue ?? '').toString());
};

export const stableSort = <Element>(
    array: Element[],
    cmp: (a: Element, b: Element) => number
): Element[] => {
    const stabilizedThis = array.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
};

//order is one of {desc, asc}, orderBy is the key in each object to use when sorting
export const getSorting = <
    Element extends { [key in Key]?: number | string | null },
    Key extends string | number | symbol = string,
>(
    order: 'desc' | 'asc',
    orderBy: Key
): ((a: Element, b: Element) => number) => {
    return order === 'desc'
        ? (a, b): number => desc(a, b, orderBy)
        : (a, b): number => -1 * desc(a, b, orderBy);
};

export const SORT_ASCENDING = 'Ascending';
export const SORT_DESCENDING = 'Descending';

type DirectionLookupType = {
    true: 1 | -1;
    false: 1 | -1;
};

type SortOrderLookupType = {
    Ascending: DirectionLookupType;
    Descending: DirectionLookupType;
};

const sortOrderLookup: SortOrderLookupType = {
    [SORT_ASCENDING]: {
        true: 1,
        false: -1,
    },
    [SORT_DESCENDING]: {
        true: -1,
        false: 1,
    },
};

export const simpleSortBy = <
    Element extends { [key in Key]: number | string },
    Key extends string = string,
>(
    array: Element[],
    property: Key,
    order: 'Ascending' | 'Descending' = SORT_ASCENDING
): Element[] => {
    const sortOrder = sortOrderLookup[order];

    return [...array].sort((a, b) => {
        const isFirstElementMajor =
            a[property] > b[property] ? 'true' : 'false';
        return sortOrder[isFirstElementMajor];
    });
};
