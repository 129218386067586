import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { type ModalTypesType } from '../MultipleMasterProductsSelector';
import ProductAndVariantsCounter from '../ProductAndVariantsCounter';

import { EditMenu } from './EditMenu';
import { SelectButton } from './SelectButton';

type SelectionActionsProps = {
    handleSelectModal: (modal: ModalTypesType) => void;
    productCount: number;
    selectedProductsCount: number;
    selectedVariantCount: number;
    variantCount: number;
    showCounters: boolean;
    showAmountInDataset: boolean;
    showWhenSelectedButton: boolean;
};

const SelectionActions = ({
    handleSelectModal,
    productCount,
    selectedProductsCount,
    selectedVariantCount,
    variantCount,
    showCounters,
    showAmountInDataset,
    showWhenSelectedButton,
}: SelectionActionsProps): ReactElement => {
    const { t } = useTranslation('product');
    const isAnyProductOrMasterProductSelected = selectedVariantCount > 0;

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
        >
            {showCounters ? (
                <ProductAndVariantsCounter
                    productCount={productCount}
                    variantCount={variantCount}
                    fontWeight={'medium'}
                />
            ) : (
                <div />
            )}
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                {showAmountInDataset && selectedVariantCount > 0 ? (
                    <>
                        <Typography
                            fontWeight={'medium'}
                            paddingInlineEnd={{ xs: 0, md: 2 }}
                            noWrap
                        >
                            {t('productList.inActiveDataset')}
                        </Typography>
                        <ProductAndVariantsCounter
                            productCount={selectedProductsCount}
                            variantCount={selectedVariantCount}
                            fontWeight={'medium'}
                            sx={{ gap: 1 }}
                        />
                    </>
                ) : (
                    <div />
                )}
                <SelectButton
                    className={
                        isAnyProductOrMasterProductSelected
                            ? ''
                            : 'visibleOnHover'
                    }
                    handleSelectModal={handleSelectModal}
                    totalCount={variantCount}
                    selectedCount={selectedVariantCount}
                    showWhenSelectedButton={showWhenSelectedButton}
                />
                {isAnyProductOrMasterProductSelected && (
                    <EditMenu
                        className={'visibleOnHover'}
                        handleSelectModal={handleSelectModal}
                        unselectedVariantsCount={
                            variantCount - selectedVariantCount
                        }
                    />
                )}
            </Box>
        </Box>
    );
};

export default SelectionActions;
