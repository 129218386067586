import {
    type Dispatch,
    type ReactElement,
    type SetStateAction,
    Suspense,
} from 'react';
import { Link } from 'react-router-dom';

import { Box, IconButton, styled, Toolbar } from '@mui/material';
import { GridMenuIcon } from '@mui/x-data-grid';

import { useWhoami } from '@xeris/hooks';

import OrganizationSelector from './OrganizationSelector/OrganizationSelector';
import XTradeLogo from './svg/XTradeLogo';
import UserMenu from './UserMenu';

const HamburgerMenu = styled(IconButton)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'none',
    },
}));

type HeaderProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
};
const Header = ({ setOpen }: HeaderProps): ReactElement => {
    const { isSuperAdmin } = useWhoami();

    return (
        <Toolbar
            disableGutters
            sx={{
                flexGrow: 1,
                justifyContent: 'space-between',
                gap: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <HamburgerMenu
                    edge={'start'}
                    onClick={(): void => setOpen((open) => !open)}
                >
                    <GridMenuIcon />
                </HamburgerMenu>
                <Link
                    to="/"
                    style={{ display: 'flex', alignItems: 'center' }}
                    aria-label={'Home'}
                >
                    <XTradeLogo />
                </Link>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 1,
                }}
            >
                <Suspense fallback={null}>
                    {isSuperAdmin && <OrganizationSelector />}
                    <UserMenu />
                </Suspense>
            </Box>
        </Toolbar>
    );
};

export default Header;
