import { type ReactElement } from 'react';

import { Box } from '@mui/material';

import type { MasterProduct } from '@xeris/pages/product/types';

import MaterialInfoBoxOverlay from '../MaterialInfoBoxOverlay/MaterialInfoBoxOverlay';
import Preview from '../Preview/Preview';

type RightPanelProps = {
    masterProduct: MasterProduct<
        'name' | 'brand' | 'images' | 'featureConfiguration'
    >;
};

const RightPanel = ({ masterProduct }: RightPanelProps): ReactElement => {
    return (
        <Box gridArea={'preview'}>
            <MaterialInfoBoxOverlay />
            <Preview stage={'configure'} masterProduct={masterProduct} />
        </Box>
    );
};

export default RightPanel;
