import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '@mui/material';

import { ErrorPage } from '@xeris/components';
import { connectionsApi } from '@xeris/pages/admin/api';
import { useActiveOrganization } from '@xeris/pages/admin/hooks';

import { type ConnectionsTabVariantType } from '../../types/connectionsTypes';
import {
    getConnectionType,
    getUiConnectionType,
} from '../../utilities/getConnectionType';

import ConnectionDataList from './components/ConnectionList/ConnectionList';
import ConnectionTabs from './components/ConnectionTabs';
import Header from './components/Header';
import ListHeader from './components/ListHeader';

export const ConnectionList = (): ReactElement => {
    const { t } = useTranslation('administration');
    const [selectedConnectionType, setSelectedConnectionType] =
        useState<ConnectionsTabVariantType>('channels');

    const organization = useActiveOrganization();

    const { data, isLoading, isError, refetch } =
        connectionsApi.useGetConnectionsQuery({
            organizationId: organization?.id ?? null,
        });

    const feedCount = data?.feeds.length ?? 0;
    const channelCount = data?.channels.length ?? 0;

    const counters = {
        channels: channelCount,
        feeds: feedCount,
        totalUsed: channelCount + feedCount,
        totalAvailable:
            organization?.subscriptionPlan?.totalConnectionsCount ?? 0,
    };

    const connectionType = getConnectionType(
        selectedConnectionType,
        organization?.type
    );

    const uiConnectionType = getUiConnectionType(
        selectedConnectionType,
        organization?.type
    );

    return (
        <>
            <Header counters={counters} />
            {isLoading && <Skeleton width={'100%'} height={100} />}
            {!isLoading && isError && (
                <ErrorPage
                    title={t('connections.errors.failedToLoad')}
                    actionText={t('connections.errors.tryAgain')}
                    onClick={() => refetch()}
                />
            )}
            {!isLoading && !isError && (
                <>
                    <ConnectionTabs
                        counters={counters}
                        selectedConnectionType={selectedConnectionType}
                        setSelectedConnectionType={setSelectedConnectionType}
                    />
                    <ListHeader
                        connectionType={connectionType}
                        uiConnectionType={uiConnectionType}
                        connectionList={data?.[connectionType] ?? []}
                        connectionCount={counters[connectionType]}
                    />
                    <ConnectionDataList
                        connectionType={connectionType}
                        uiConnectionType={uiConnectionType}
                        connectionList={data?.[connectionType] ?? []}
                    />
                </>
            )}
        </>
    );
};
