import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Link as MuiLink, Stack, Typography } from '@mui/material';

type VariantType = 'reset-password' | 'invite';

type BrokenLinkMessageProps = {
    variant: VariantType;
};

export const BrokenLinkMessage = ({
    variant,
}: BrokenLinkMessageProps): ReactElement => {
    const { t } = useTranslation('login');

    const informationMessageLookup: Record<VariantType, string> = {
        'reset-password': t('common.tryResettingPassword'),
        'invite': '',
    };

    const contactMessageLookup: Record<VariantType, string | ReactElement> = {
        'reset-password': t('resetPassword.contactSupport'),
        'invite': t('invite.contactSupport'),
    };

    return (
        <Stack direction={'column'} gap={2}>
            <Typography component={'h1'} variant={'h3'} fontWeight={'normal'}>
                {t('common.somethingWentWrong')}
            </Typography>

            <Typography>
                {t('common.linkIsInvalidOrExpired')}{' '}
                {informationMessageLookup[variant]}
            </Typography>

            {variant === 'reset-password' && (
                <Button
                    component={Link}
                    to="/login/?action=forgot-password"
                    variant={'contained'}
                >
                    {t('common.tryAgain')}
                </Button>
            )}

            <Typography>
                {contactMessageLookup[variant]}{' '}
                <MuiLink
                    href="mailto:support@xeris.no"
                    target="_blank"
                    rel="noreferrer"
                >
                    support@xeris.no
                </MuiLink>
            </Typography>
        </Stack>
    );
};
