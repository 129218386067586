import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import { DeleteIcon, EditIcon } from '@xeris/components/icons';
import { NoExports } from '@xeris/pages/admin/Exports/components/NoExports';
import { EditExport } from '@xeris/pages/admin/Exports/forms/EditExport';
import { type ExportData } from '@xeris/pages/admin/Exports/forms/types';

import { DeleteExport } from '../forms/DeleteExport';

type ExportsListData = {
    exports: ExportData[];
};

export const ExportsList = ({ exports }: ExportsListData): ReactElement => {
    const { t } = useTranslation('administration');

    const [exportToDelete, setExportToDelete] = useState<ExportData>();
    const [exportToEdit, setExportToEdit] = useState<ExportData>();

    if (exports.length === 0) {
        return <NoExports />;
    }

    return (
        <>
            <DataGrid
                rows={exports}
                disableRowSelectionOnClick
                columns={[
                    {
                        field: 'name',
                        headerName: t('exports.list.header.name'),
                        disableColumnMenu: true,
                        flex: 3,
                    },
                    {
                        field: 'type',
                        headerName: t('exports.list.header.type'),
                        disableColumnMenu: true,
                        flex: 1,
                        valueGetter: (value, row) => {
                            return row.type.name;
                        },
                    },
                    {
                        field: 'actions',
                        type: 'actions',
                        align: 'right',
                        getActions: ({ row }) => [
                            <GridActionsCellItem
                                key={'edit'}
                                label={t('exports.edit.editExport')}
                                onClick={() => setExportToEdit(row)}
                                icon={<EditIcon fontSize={'small'} />}
                                showInMenu
                            />,
                            <GridActionsCellItem
                                key={'delete'}
                                label={t('exports.delete.deleteExport')}
                                onClick={() => setExportToDelete(row)}
                                icon={<DeleteIcon fontSize={'small'} />}
                                showInMenu
                            />,
                        ],
                    },
                ]}
            />
            <EditExport
                isOpen={!!exportToEdit}
                handleCloseDialog={() => setExportToEdit(undefined)}
                exportData={exportToEdit}
                exportNames={exports
                    // We want to allow it to have the same name as itself
                    .filter(({ id }) => id !== exportToEdit?.id)
                    .map(({ name }) => name)}
            />
            <DeleteExport
                isOpen={!!exportToDelete}
                handleCloseDialog={() => setExportToDelete(undefined)}
                exportData={exportToDelete}
            />
        </>
    );
};
