import { type ReactElement } from 'react';

import { Skeleton, Stack, Toolbar } from '@mui/material';

import { useFeatureFlag, useWhoami } from '@xeris/hooks';
import { useActiveDatasetId } from '@xeris/pages/dataset/hooks';
import { useAppSelector } from '@xeris/reducers';

import { ActiveDatasetButton, DatasetActionsMenu } from '../../common';
import { EditDataset, ShareDataset } from '../../forms';
import { datasetSelectors } from '../../reducers';
import type { DatasetSharedWith } from '../../types/datasetTypes';

type ContentHeaderProps = {
    dataset?: {
        id: string;
        name: string;
        description: string | null;
        totalCount: number;
        owner: {
            username: string;
        };
        sharedWith: DatasetSharedWith[];
    };
};

const DatasetToolbar = ({
    dataset,
}: ContentHeaderProps): ReactElement | null => {
    const [shareDatasetVisible] = useFeatureFlag('shareDataset');
    const { username } = useWhoami();

    const activeDatasetId = useActiveDatasetId();

    const datasetList = useAppSelector(datasetSelectors.minimal.selectAll);

    return (
        <Toolbar sx={{ gap: 1, alignSelf: 'flex-end' }}>
            {dataset ? (
                <>
                    <Stack
                        sx={{
                            display: { md: 'flex', sm: 'none', xs: 'none' },
                            gap: 1,
                        }}
                    >
                        <ActiveDatasetButton
                            isDatasetActive={activeDatasetId === dataset.id}
                            datasetId={dataset.id}
                        />
                        <EditDataset
                            datasetNames={datasetList.map(({ name }) => name)}
                            dataset={dataset}
                            showButton
                            sx={{ minWidth: 0 }}
                        />
                        {shareDatasetVisible &&
                            dataset.owner.username === username && (
                                <ShareDataset dataset={dataset} />
                            )}
                    </Stack>
                    <DatasetActionsMenu dataset={dataset} />
                </>
            ) : (
                <>
                    <Skeleton variant={'rounded'} height={36} width={100} />
                    <Skeleton variant={'rounded'} height={36} width={36} />
                    <Skeleton variant={'rounded'} height={36} width={36} />
                </>
            )}
        </Toolbar>
    );
};

export default DatasetToolbar;
