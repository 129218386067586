import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { SnapshotIcon } from '@xeris/components/icons';

type ImageToolsProps = {
    handleSnapshotClick?: () => void;
};

const ImageTools = ({ handleSnapshotClick }: ImageToolsProps): ReactElement => {
    const { t } = useTranslation('product');

    return (
        <Button
            onClick={handleSnapshotClick}
            startIcon={<SnapshotIcon />}
            color={'secondary'}
            size={'small'}
            sx={{ marginBlock: 1 }}
        >
            {t('variantGenerator.snapshot')}
        </Button>
    );
};

export default ImageTools;
