import { type Dispatch, type ReactElement, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import { EditIcon } from '@xeris/components/icons';

import type { AccessRuleType } from '../types';

type TypeInfoProps = {
    activeType: AccessRuleType | null;
    tradingPartnerName: string;
    setShowChangeType: Dispatch<SetStateAction<boolean>>;
};

export const TypeInfo = ({
    activeType,
    tradingPartnerName,
    setShowChangeType,
}: TypeInfoProps): ReactElement => {
    const { t } = useTranslation('administration');

    const type = activeType ?? 'None';

    return (
        <>
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <Typography variant={'subtitle1'}>
                    {t(`connections.accessTypes.${type}.accessTitle`)}
                </Typography>
                <Button
                    startIcon={<EditIcon />}
                    size={'small'}
                    onClick={() => setShowChangeType(true)}
                >
                    {t('common.change')}
                </Button>
            </Box>
            <Typography marginBottom={2}>
                {t(`connections.accessTypes.${type}.description`, {
                    tradingPartnerName,
                })}
            </Typography>
        </>
    );
};
