import { type ReactElement, type ReactNode } from 'react';

import { Accordion } from '@mui/material';

type MaterialPriceGroupProps = {
    children: NonNullable<ReactNode>;
};

export const MaterialPriceGroup = ({
    children,
}: MaterialPriceGroupProps): ReactElement => {
    return (
        <Accordion
            defaultExpanded={true}
            elevation={0}
            slotProps={{ transition: { unmountOnExit: true } }}
            sx={{
                'width': '100%',
                '&:before': {
                    display: 'none',
                },
                '&:not(:last-child)': {
                    borderBottom: 0,
                },
            }}
        >
            {children}
        </Accordion>
    );
};
