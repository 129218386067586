const refreshGracePeriodMS = 30000;

export const validateToken = (token?: string, expires?: string): boolean => {
    if (!expires || !token) return false;

    const currentDate: number = new Date().valueOf();
    const expiryDate: number = new Date(expires).valueOf();

    const millisecondsToExpiry = expiryDate - currentDate;

    return millisecondsToExpiry > refreshGracePeriodMS;
};
