import { type ReactElement } from 'react';
import { Link } from 'react-router-dom';

import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    styled,
    type SxProps,
} from '@mui/material';

import { Image } from '../Image/Image';
import { Typography } from '../Typography/Typography';

const Content = styled(CardContent)(() => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.38)',
    backdropFilter: 'blur(3px)',
    height: '84px',
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
}));

const ActionArea = styled(CardActionArea)(() => ({
    display: 'block',
    height: '100%',
    backgroundColor: 'background.default',
})) as typeof CardActionArea;

type BrandCardProps = {
    name: string;
    featureImage?: string | null;
    logo?: string | null;
    href: string;
    sx?: SxProps;
};

export const BrandCard = ({
    name,
    featureImage,
    logo,
    href,
    sx,
}: BrandCardProps): ReactElement => {
    return (
        <Card sx={{ 'height': '100%', ':hover': { boxShadow: 4 }, ...sx }}>
            <ActionArea to={href} component={Link}>
                {featureImage && (
                    <CardMedia
                        image={featureImage}
                        component={'img'}
                        height={'100%'}
                        width={'100%'}
                    />
                )}
                <Content>
                    {logo ? (
                        <Image
                            src={logo}
                            alt={name}
                            loading={'lazy'}
                            style={{ maxWidth: '64%', maxHeight: '100%' }}
                        />
                    ) : (
                        <Typography
                            variant={'h4'}
                            component={'p'}
                            marginRight={2}
                            maxLines={2}
                            textAlign={'end'}
                        >
                            {name}
                        </Typography>
                    )}
                </Content>
            </ActionArea>
        </Card>
    );
};
