import { type ReactElement } from 'react';

import { FormattedPrice } from '@xeris/components';

type PriceProps = {
    price: {
        value: number;
        currency: string;
    } | null;
};

const Price = ({ price }: PriceProps): ReactElement => {
    return (
        <FormattedPrice
            value={price?.value ?? null}
            currency={price?.currency}
            defaultValue=""
        />
    );
};

export default Price;
