import { useAppSelector } from '@xeris/reducers';
import { search } from '@xeris/utilities';

import { settingsSelectors } from '../reducers/settingsSlice';

export const useFilterProductEntityListBySearchTerm = <
    Product extends {
        name: string;
        distinguishingFeatures?: { name: string; value: string }[] | null;
        idNumbers: { value: string }[];
        products?: {
            idNumbers: { value: string }[];
        }[];
    },
>(
    productEntityList?: Product[]
): Product[] => {
    const searchTerm = useAppSelector(settingsSelectors.selectSearchTerm);

    if (!productEntityList) {
        return [];
    }

    if (searchTerm.length === 0) {
        return productEntityList;
    }

    return productEntityList.filter((productEntity) =>
        search(
            searchTerm,
            productEntity.name,
            ...(productEntity.distinguishingFeatures ?? []).map(
                (d) => d.name + d.value
            ),
            ...productEntity.idNumbers.map(({ value }) => value),
            ...(productEntity.products ?? []).flatMap((product) =>
                product.idNumbers.map(({ value }) => value)
            )
        )
    );
};
