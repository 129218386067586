import { type ReactElement, type ReactNode } from 'react';
import { Link, type LinkProps } from 'react-router-dom';

import { Button, type ButtonProps } from '@mui/material';

import { ArrowLeftIcon, ArrowRightIcon } from '@xeris/components/icons';

type NavButtonProps = ButtonProps & {
    children: ReactNode;
    back?: boolean;
    next?: boolean;
    state?: LinkProps['state'];
    to: LinkProps['to'];
};

export const NavButton = ({
    next = false,
    back = false,
    children,
    state,
    to,
    ...buttonProps
}: NavButtonProps): ReactElement => {
    return (
        <Button
            to={to}
            state={state}
            component={Link}
            variant={back ? 'outlinedSolid' : 'contained'}
            color={back ? 'secondary' : 'primary'}
            sx={{ borderRadius: 99, width: '100%', maxWidth: 200 }}
            startIcon={back && <ArrowLeftIcon />}
            endIcon={next && <ArrowRightIcon />}
            {...buttonProps}
        >
            {children}
        </Button>
    );
};
