import {
    type MouseEvent,
    type ReactElement,
    type ReactNode,
    type SyntheticEvent,
    useState,
} from 'react';

import { Menu, MenuItem, type MenuItemTypeMap } from '@mui/material';

import { IconButton } from '../IconButton/IconButton';
import { DotsHorizontalIcon } from '../icons';

export type SelectMenuItemType<Value> = MenuItemTypeMap['props'] & {
    key: string | number;
    // Use 'value' if no handleClick is given
    value?: Value;
    content: ReactNode;
    className?: string;
    isDisabled?: boolean;
    handleClick?: (event: SyntheticEvent) => void;
    dataTestId?: string;
};

type SelectMenuProps<Value> = {
    closedClassName?: string;
    menuItemList: SelectMenuItemType<Value>[];
    // handleSelect is required to return the 'value' of the menuItem
    handleSelect?: (event: SyntheticEvent, value?: Value) => void;
    CustomButton?: ReactElement;
    dataTestId?: string;
    ariaLabel?: string;
};

export const SelectMenu = <Value,>({
    closedClassName,
    menuItemList,
    handleSelect,
    CustomButton,
    dataTestId,
    ariaLabel,
}: SelectMenuProps<Value>): ReactElement => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);

    const handleToggleMenu = (event: MouseEvent<HTMLDivElement>): void => {
        event.stopPropagation();
        if (menuAnchorEl) {
            setMenuAnchorEl(null);
        } else {
            setMenuAnchorEl(event.currentTarget);
        }
    };

    return (
        <>
            <div
                className={menuAnchorEl ? '' : closedClassName}
                onClick={handleToggleMenu}
                data-testid={dataTestId}
            >
                {CustomButton ? (
                    CustomButton
                ) : (
                    <IconButton
                        edge={'end'}
                        size={'small'}
                        rounded
                        aria-label={ariaLabel}
                        sx={{
                            '&:hover': {
                                opacity: 1,
                            },
                        }}
                    >
                        <DotsHorizontalIcon />
                    </IconButton>
                )}
            </div>

            <Menu
                open={!!menuAnchorEl}
                anchorEl={menuAnchorEl}
                onClose={(event: SyntheticEvent): void => {
                    event.stopPropagation();
                    setMenuAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {menuItemList.map((menuElement) => {
                    const classNames = [];
                    if (menuElement.className) {
                        classNames.push(menuElement.className);
                    }
                    return (
                        <MenuItem
                            className={classNames.join(' ')}
                            onClick={(event): void => {
                                setMenuAnchorEl(null);
                                if (menuElement.handleClick) {
                                    menuElement.handleClick(event);
                                } else if (handleSelect) {
                                    handleSelect(event, menuElement.value);
                                }
                            }}
                            disabled={menuElement.isDisabled}
                            data-testid={menuElement.dataTestId}
                            key={menuElement.key}
                        >
                            {menuElement.content}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};
