import { type ReactElement, type ReactNode } from 'react';

import { AccordionDetails, Stack } from '@mui/material';

type MaterialListProps = {
    children: ReactNode;
};
export const MaterialList = ({ children }: MaterialListProps): ReactElement => {
    return (
        <AccordionDetails sx={{ paddingInline: 0, width: '100%' }}>
            <Stack direction={'column'} alignContent={'flex-start'}>
                {children}
            </Stack>
        </AccordionDetails>
    );
};
