import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogTitle, Typography } from '@mui/material';

import { ChevronRightIcon } from '@xeris/components/icons';

import { type AccessRuleType } from '../../../types';

type HeaderProps = {
    handleClose: () => void;
    handleSave: () => void;
    tradingPartnerName: string;
    brandName: string;
    accessType: AccessRuleType;
    isLoading: boolean;
};

export const Header = ({
    handleClose,
    handleSave,
    brandName,
    tradingPartnerName,
    accessType,
    isLoading,
}: HeaderProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <DialogTitle
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <Typography variant={'h2'} component={'span'} noWrap>
                    {`${t(`connections.accessTypes.${accessType}.accessTitle`)}: ${tradingPartnerName}`}
                </Typography>
                <ChevronRightIcon color={'icons'} />
                <Typography variant={'h2'} component={'span'} noWrap>
                    {brandName}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Button color={'secondary'} onClick={handleClose}>
                    {t('common.cancel')}
                </Button>
                <LoadingButton
                    variant={'contained'}
                    loading={isLoading}
                    onClick={handleSave}
                >
                    {t('common.save')}
                </LoadingButton>
            </Box>
        </DialogTitle>
    );
};
