import { loadLocalState } from '@xeris/utilities';

import { datasetsApi } from '../../api';
import { type DatasetTypes } from '../../types';
import { ACTIVE_DATASET_ID } from '../activeDatasetId';
import { datasetsAdapter } from '../datasetAdapter';
import { setActiveDataset } from '../setActiveDataset';

const handleCreateDataset = (
    datasetFromApi: DatasetTypes.MinimalDatasetType,
    state: DatasetTypes.DatasetSliceType
): void => {
    const dataset = datasetFromApi;

    datasetsAdapter.setOne(state.datasets, dataset);

    setActiveDataset(state, dataset.id);
};

export const datasetsExtraReducers = (
    builder: DatasetTypes.ReducerBuilderType
): void => {
    builder.addMatcher(
        datasetsApi.endpoints.createDataset.matchFulfilled,
        (state, action) => {
            const dataset = action.payload?.dataset.create;

            if (!dataset) {
                return;
            }

            handleCreateDataset(dataset, state);
        }
    );
    builder.addMatcher(
        datasetsApi.endpoints.duplicateDataset.matchFulfilled,
        (state, action) => {
            const dataset = action.payload?.dataset.copy;

            if (!dataset) {
                return;
            }

            handleCreateDataset(dataset, state);
        }
    );
    builder.addMatcher(
        datasetsApi.endpoints.editDataset.matchFulfilled,
        (state, action) => {
            const data = action.payload;

            const dataset = data?.dataset.update;

            if (!dataset) {
                return;
            }

            datasetsAdapter.upsertOne(state.datasets, dataset);

            if (state.activeDataset && dataset.id === state.activeDataset.id) {
                state.activeDataset = {
                    ...state.activeDataset,
                    ...dataset,
                };
            }
        }
    );
    builder.addMatcher(
        datasetsApi.endpoints.deleteDataset.matchFulfilled,
        (state, action) => {
            const { id, deleted } = action.payload?.dataset?.delete ?? {};

            if (deleted && id) {
                datasetsAdapter.removeOne(state.datasets, id);
                if (state.activeDataset?.id === id) {
                    state.activeDataset = undefined;
                    const newActiveId = state.datasets.ids[0];

                    setActiveDataset(state, newActiveId?.toString());
                }
            }
        }
    );
    builder.addMatcher(
        datasetsApi.endpoints.getDatasetList.matchFulfilled,
        (state, action) => {
            const datasetList = action.payload?.datasets;

            if (!datasetList) {
                return;
            }

            datasetsAdapter.setAll(state.datasets, datasetList);

            const { activeId } = loadLocalState(ACTIVE_DATASET_ID, {
                activeId: undefined,
            });

            if (datasetList.length > 0) {
                state.activeId = datasetList.some(
                    (dataset) => dataset.id === activeId
                )
                    ? activeId
                    : datasetList[0].id;
            } else {
                state.activeId = undefined;
            }
        }
    );
};
