import { type FormEventHandler, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogContent, MutationSnackbars } from '@xeris/components';
import { FormDialog } from '@xeris/components/forms';
import { DeleteIcon } from '@xeris/components/icons';
import { brandApi } from '@xeris/pages/admin/api';

type DeleteBrandDialogProps = {
    isOpen: boolean;
    brand?: {
        id: string;
        name: string;
    };
    handleCloseDialog: () => void;
};

export const DeleteBrand = ({
    isOpen,
    brand,
    handleCloseDialog,
}: DeleteBrandDialogProps): ReactElement => {
    const { t } = useTranslation('administration');

    const [deleteBrandMutation, { isLoading, isSuccess, isError }] =
        brandApi.useDeleteBrandMutation();

    const handleDeleteBrand: FormEventHandler<HTMLDivElement> = async (
        event
    ): Promise<void> => {
        event.preventDefault();

        if (!brand) {
            return;
        }

        await deleteBrandMutation({ id: brand.id }).unwrap();

        handleCloseDialog();
    };

    return (
        <>
            <FormDialog
                open={isOpen}
                onClose={handleCloseDialog}
                onSubmit={handleDeleteBrand}
                title={t('brand.deleteBrand.deleteNamedBrand', {
                    brandName: brand?.name,
                })}
                isLoading={isLoading}
                cancelText={t('brand.common.cancel')}
                saveText={t('brand.common.delete')}
                buttonProps={{ startIcon: <DeleteIcon />, color: 'error' }}
            >
                <DialogContent>
                    {t('brand.deleteBrand.deletingBrandWillDeleteProducts')}
                </DialogContent>
            </FormDialog>
            <MutationSnackbars
                isSuccess={isSuccess}
                successText={t('brand.deleteBrand.brandDeleted')}
                isError={isError}
                errorText={t('brand.deleteBrand.deleteBrandFailed')}
            />
        </>
    );
};
