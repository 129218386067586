import { type ReactElement, type ReactNode } from 'react';

import { Accordion } from '@mui/material';

type MaterialWrapperProps = {
    children: NonNullable<ReactNode>;
    isPreviewed?: boolean;
};
export const MaterialWrapper = ({
    children,
    isPreviewed,
}: MaterialWrapperProps): ReactElement => {
    return (
        <Accordion
            disableGutters
            slotProps={{ transition: { unmountOnExit: true } }}
            sx={{
                'width': '100%',
                '&:before': {
                    display: 'none',
                },
                '&:not(:last-child)': {
                    borderBottom: 0,
                },
                'overflow': 'hidden',
                '& .MuiAccordionSummary-root': {
                    'backgroundColor': isPreviewed
                        ? 'primary.soft'
                        : 'background.paper',
                    '&:hover ': {
                        backgroundColor: 'background.default',
                    },
                },
            }}
            elevation={0}
            variant={'outlined'}
        >
            {children}
        </Accordion>
    );
};
