import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Tooltip, Typography } from '@xeris/components';
import { useAppSelector } from '@xeris/reducers';

import { variantGeneratorSelectors } from '../../reducer';

type VariantsCounterProps = {
    totalVariantCount: string | null;
};

const VariantsCounter = ({
    totalVariantCount,
}: VariantsCounterProps): ReactElement => {
    const { t, i18n } = useTranslation('product');
    const variantCount = useAppSelector(
        variantGeneratorSelectors.selectVariantCount
    );

    const hasSelectedMinimumConfiguration = variantCount > 0;

    return (
        <Box
            padding={2}
            border={'1px solid'}
            borderColor={'divider'}
            borderRadius={1}
        >
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                marginBottom={2}
            >
                <Typography variant={'h5'} component={'p'}>
                    {t('variantGenerator.variants')}
                </Typography>
                {hasSelectedMinimumConfiguration ? (
                    <Typography
                        variant={'h5'}
                        component={'p'}
                        color={'primary'}
                    >
                        {variantCount.toLocaleString(i18n.language)}
                    </Typography>
                ) : (
                    <Tooltip title={t('variantGenerator.variantCount')}>
                        <Typography
                            variant={'h5'}
                            component={'p'}
                            color={'primary'}
                        >
                            {t('variantGenerator.noValidVariants')}
                        </Typography>
                    </Tooltip>
                )}
            </Box>
            <Typography
                variant={'body2'}
                display={'flex'}
                justifyContent={'space-between'}
            >
                <span> {t('variantGenerator.allVariants')}</span>
                <span>
                    /
                    {BigInt(totalVariantCount ?? 0).toLocaleString(
                        i18n.language
                    )}
                </span>
            </Typography>
        </Box>
    );
};

export default VariantsCounter;
