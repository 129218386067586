import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { useWhoami } from '@xeris/hooks';
import { useActiveOrganization } from '@xeris/pages/admin/hooks';

import {
    type Connection,
    type ConnectionsTabVariantType,
    type UiConnectionType,
} from '../../../types/connectionsTypes';

import { NewConnection } from './NewConnection';

type ListHeaderProps = {
    connectionType: ConnectionsTabVariantType;
    uiConnectionType: UiConnectionType;
    connectionCount: number;
    connectionList: Connection[];
};

const ListHeader = ({
    connectionType,
    uiConnectionType,
    connectionCount,
    connectionList,
}: ListHeaderProps): ReactElement | null => {
    const { t } = useTranslation('administration');

    const { isSuperAdmin } = useWhoami();

    const activeOrganization = useActiveOrganization();

    if (connectionCount === 0) return null;

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBlock: 2,
            }}
        >
            <Typography>
                {connectionType === 'channels'
                    ? t('connections.listOfConnectedTradingPartners', {
                          organizationName: activeOrganization?.name,
                      })
                    : t('connections.connectedTradingPartners')}
            </Typography>
            {(isSuperAdmin || connectionType === 'channels') && (
                <NewConnection
                    connectionType={connectionType}
                    uiConnectionType={uiConnectionType}
                    connectionList={connectionList}
                />
            )}
        </Box>
    );
};

export default ListHeader;
