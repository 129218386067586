import type { ReactElement, ReactNode } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import type { MasterProductWithIds } from '@xeris/pages/product/Common';

import { ProductCardList } from './components/ProductCardList';
import { ProductListView } from './components/ProductListView';
import { type ListData, type SectionListData } from './types';

export type ProductListProps<MP extends MasterProductWithIds> = {
    type?: 'cards' | 'list';
    listData: ListData<MP>[];
    card: (product: MP) => ReactNode;
    listItem: (product: MP) => ReactNode;
    cardHeight: number;
    cardMinWidth: number;
    sectionActions?: (section: SectionListData<MP>) => ReactNode;
    sectionInfo?: (section: SectionListData<MP>) => ReactNode;
    toggleSectionOpen: (id: string) => void;
};

export const ProductList = <MP extends MasterProductWithIds>(
    props: ProductListProps<MP>
): ReactElement => {
    return (
        <AutoSizer>
            {({ width, height }) =>
                props.type === 'cards' ? (
                    <ProductCardList {...props} width={width} height={height} />
                ) : (
                    <ProductListView {...props} width={width} height={height} />
                )
            }
        </AutoSizer>
    );
};
