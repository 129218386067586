import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';

import { Image, Tooltip } from '@xeris/components';

import '../index.scss';

export const lookup: Record<string, string> = {
    'Basta': 'BASTA_logo.svg',
    'Byggvarubedömningen': 'byggvarubedomningen_logo_icon.svg',
    'CARB': 'carb_logo.png',
    'CE Mark': 'ce_logo.png',
    'Ecolabel': 'eu_ecolabel_logo.jpg',
    'EPD': 'epd-logo.svg',
    'Floorscore': 'floorscore_logo.jpg',
    'FSC': 'FSC.svg',
    'Greenguard Gold': 'greenguard.svg',
    'HPD': 'hpd v2.2.png',
    'IAG': 'indoorAdvantage 2.png',
    'M1': 'm1_logo.png',
    'Møbelfakta': 'mobelfakta.svg',
    'OEKO-TEX Standard 100':
        'oeko_control_gruen_verbandoekologischereinrichtungshaeuser.png',
    'Sunda Hus': 'sundahus_logo.svg',
    'SundaHus': 'sundahus_logo.svg',
    'Svanemerket': 'svanen_logo.svg',
    'Svanen': 'svanen_logo.svg',
    'VOC': 'voc_a+_logo.jpg',
};

type EnvironmentalProps = {
    data: string[];
    showMissingData: boolean;
};

const Environmental = ({
    data: certificateList,
    showMissingData,
}: EnvironmentalProps): ReactElement | null => {
    const { t } = useTranslation('product');
    if (certificateList.length === 0 && !showMissingData) {
        return null;
    }

    return (
        <section className="environmental-certificates-container">
            <Button className="environmental-header" color="primary">
                <Typography component={'h2'} variant={'h3'}>
                    {t('common.panelHeaders.environmentalCertificates')}
                </Typography>
            </Button>
            <ul className="environmental-certificates-list">
                {certificateList.map((ceritificate, index) => {
                    const ceritificateImage = lookup[ceritificate];

                    if (!ceritificateImage) {
                        return null;
                    }

                    return (
                        <li
                            key={index}
                            className="environmental-certificates-list-item mt-2"
                        >
                            <Tooltip title={ceritificate}>
                                <Image
                                    className="res-img environmental-certificates-image"
                                    src={`/assets/images/certificates/${ceritificateImage}`}
                                    alt={ceritificate}
                                />
                            </Tooltip>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
};

export default Environmental;
