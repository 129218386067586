import { type ReactElement } from 'react';

import { Avatar } from '@mui/material';

import { getInitialsFromName } from '@xeris/utilities/avatar';
import { stringToColor } from '@xeris/utilities/colors';

import { Tooltip } from '../Tooltip';

type UserAccountAvatarProps = { label: string; tooltipTitle?: string };

export const UserAccountAvatar = ({
    label,
    tooltipTitle,
}: UserAccountAvatarProps): ReactElement => (
    <Avatar
        sx={{
            backgroundColor: stringToColor(label),
            width: 36,
            height: 36,
            fontWeight: 600,
            fontSize: '1.1rem',
        }}
    >
        <Tooltip title={tooltipTitle}>
            <>{getInitialsFromName(label)}</>
        </Tooltip>
    </Avatar>
);
