import { type ReactElement, type ReactNode } from 'react';
import {
    type Control,
    type FieldValues,
    type Path,
    useController,
} from 'react-hook-form';

import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    type SelectProps,
} from '@mui/material';

export type CheckboxCardProps<TForm extends FieldValues> = {
    fieldName: Path<TForm>;
    control: Control<TForm>;
    size?: SelectProps['size'];
    disabled?: boolean;
    children?: ReactNode;
    title: string;
    subheader?: string;
    icon: ReactElement;
};

export const CheckboxCard = <TForm extends FieldValues>({
    fieldName,
    control,
    children,
    disabled,
    title,
    subheader,
    icon,
}: CheckboxCardProps<TForm>): ReactElement => {
    const {
        field: { ...field },
    } = useController({
        name: fieldName,
        control,
    });

    return (
        <label>
            <Card
                variant={'outlined'}
                sx={(theme) => ({
                    'backgroundColor': theme.palette.background.paper,
                    ...(!disabled && {
                        'cursor': 'pointer',
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                    }),
                    '&:has(input:checked)': {
                        'border': '1px solid',
                        'borderColor': theme.palette.primary.light,
                        '.title': {
                            color: theme.palette.primary.dark,
                        },
                        'svg': { color: theme.palette.primary.main },
                    },
                })}
            >
                <CardHeader
                    avatar={<Avatar variant={'roundedOutline'}>{icon}</Avatar>}
                    title={title}
                    subheader={subheader}
                    titleTypographyProps={{
                        className: 'title',
                        variant: 'h5',
                        component: 'p',
                        lineHeight: 1,
                    }}
                    action={
                        <Checkbox
                            {...field}
                            checked={field.value}
                            disabled={disabled}
                            inputProps={{
                                'aria-label': title,
                            }}
                        />
                    }
                    sx={{ '.MuiCardHeader-action': { alignSelf: 'center' } }}
                />
                {children && (
                    <CardContent sx={{ paddingTop: 0 }}>{children}</CardContent>
                )}
            </Card>
        </label>
    );
};
