import { type ReactElement } from 'react';

import { MenuItem, Select } from '@mui/material';

import { type ApiLanguage } from '@xeris/types';

type SelectPathProps = {
    value: string;
    usedLanguages: string[];
    languages: { id: string; language: string }[];
    onChange: (value: ApiLanguage) => void;
};

export const SelectLanguage = ({
    value,
    usedLanguages,
    languages,
    onChange,
}: SelectPathProps): ReactElement => {
    return (
        <Select
            sx={{ flexGrow: 1, flexBasis: '30%' }}
            value={value}
            onChange={(event) => onChange(event.target.value as ApiLanguage)}
        >
            {languages
                .filter(({ id }) => value === id || !usedLanguages.includes(id))
                .map((language) => (
                    <MenuItem key={language.id} value={language.id}>
                        {language.language}
                    </MenuItem>
                ))}
        </Select>
    );
};
