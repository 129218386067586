import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Chip } from '@mui/material';

import { type BrandGroupMarketAccessRule } from '../types';

type FilterChipsProps = {
    brand: {
        markets: {
            id: string;
            name: string;
        }[];
        groupTypes: {
            id: string;
            name: string;
            groups: {
                id: string;
                name: string;
            }[];
        }[];
    };
    rule: BrandGroupMarketAccessRule;
};

export const FilterChips = ({
    brand,
    rule,
}: FilterChipsProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <Box sx={{ display: 'flex', gap: 1 }}>
            {rule.filterMarkets && (
                <Chip
                    key={'markets'}
                    variant={'outlined'}
                    label={`${t('connections.brandAccess.markets')}: ${rule.filterMarkets.map((marketId) => brand.markets.find((market) => market.id === marketId)?.name)?.join(', ')}`}
                />
            )}
            {rule.filterGroups
                .map(({ groupType, groups }) => {
                    const brandGroupType = brand.groupTypes.find(
                        ({ id }) => groupType === id
                    );

                    if (!brandGroupType) return null;

                    return `${brandGroupType.name}: ${groups.map((groupId) => brandGroupType?.groups?.find(({ id }) => groupId === id)?.name).join(', ')}`;
                })
                .filter((filter) => !!filter)
                .map((filter) => (
                    <Chip key={filter} variant={'outlined'} label={filter} />
                ))}
        </Box>
    );
};
