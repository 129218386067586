import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';

type IsOutdated = {
    hasUpdate: boolean;
    isDismissed: boolean;
    setIsDismissed: Dispatch<SetStateAction<boolean>>;
};

export const useHasUpdate = (): IsOutdated => {
    const [hasUpdate, setHasUpdate] = useState(false);
    const [isDismissed, setIsDismissed] = useState(false);

    const listener = (event: MessageEvent): void => {
        if (event.data.isExpired) {
            setHasUpdate(true);
        }
    };

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', listener);
        }

        return () => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.removeEventListener(
                    'message',
                    listener
                );
            }
        };
    });

    return { hasUpdate, isDismissed, setIsDismissed };
};
