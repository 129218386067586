import {
    type Expression,
    type MappedAttributeType,
    type MappedAttributeTypeWithChildren,
    type MappedNode,
} from '@xeris/pages/imports/types';

export type Path = { attributeId: string; index: number }[];

export const getNode = (
    mapping: MappedAttributeTypeWithChildren,
    path: Path
): MappedNode | null => {
    let currentNode = mapping;

    for (let i = 0; i < path.length; i++) {
        const isLast = i === path.length - 1;
        const pathSegment = path[i];

        const node = currentNode.attributes.filter(
            ({ attributeTypeId }) => attributeTypeId === pathSegment.attributeId
        )?.[pathSegment.index];

        if (!node) {
            return null;
        }

        if (isLast) {
            return node;
        }

        if (node.__typename === 'LeafMappingAttribute') {
            return null;
        }

        currentNode = node;
    }

    return currentNode;
};

export const getMapping = (
    mapping: MappedAttributeTypeWithChildren,
    path: Path
): MappedAttributeType | null => {
    let currentNode = mapping;

    for (let i = 0; i < path.length; i++) {
        const pathSegment = path[i];

        const node = currentNode.attributes.filter(
            ({ attributeTypeId }) => attributeTypeId === pathSegment.attributeId
        )?.[pathSegment.index];

        if (!node) {
            return null;
        }

        if (node.__typename === 'LeafMappingAttribute') {
            return node;
        }

        currentNode = node;
    }

    return null;
};

export const setMapping = (
    mapping: MappedAttributeTypeWithChildren,
    path: Path,
    value: Expression | null
): MappedAttributeTypeWithChildren => {
    const { node: parent } = setNode(mapping, path.slice(0, -1));

    const lastPath = path.at(-1);

    if (!lastPath) return mapping;

    const current = parent.attributes.filter(
        ({ attributeTypeId }) => attributeTypeId === lastPath.attributeId
    )?.[lastPath.index];

    if (current && current.__typename === 'LeafMappingAttribute') {
        current.expression = value;
    } else {
        parent.attributes.push({
            __typename: 'LeafMappingAttribute',
            attributeTypeId: lastPath.attributeId,
            expression: value,
        });
    }

    return mapping;
};

export const setNode = (
    mapping: MappedAttributeTypeWithChildren,
    path: Path
): {
    mapping: MappedAttributeTypeWithChildren;
    node: MappedAttributeTypeWithChildren;
} => {
    let currentNode = mapping;

    for (let i = 0; i < path.length; i++) {
        const pathSegment = path[i];

        const node = currentNode.attributes.filter(
            ({ attributeTypeId }) => attributeTypeId === pathSegment.attributeId
        )?.[pathSegment.index];

        if (!node) {
            const newNode: MappedAttributeTypeWithChildren = {
                __typename: 'NodeMappingAttribute',
                attributeTypeId: pathSegment.attributeId,
                attributes: [],
            };

            currentNode.attributes.push(newNode);
            currentNode = newNode;
        } else {
            if (node.__typename === 'NodeMappingAttribute') {
                currentNode = node;
            } else {
                throw new Error('Invalid leaf node encountered');
            }
        }
    }

    return { mapping, node: currentNode };
};

export const removeMapping = (
    mapping: MappedAttributeTypeWithChildren,
    path: Path
): MappedAttributeTypeWithChildren => {
    const parent = getNode(mapping, path.slice(0, -1));
    if (!parent) return mapping;

    if (parent.__typename !== 'NodeMappingAttribute') return mapping;

    const lastPath = path.at(-1);

    if (!lastPath) return mapping;

    const attributeToRemove = parent.attributes.filter(
        (attribute) => attribute.attributeTypeId === lastPath.attributeId
    )[lastPath.index];

    if (!attributeToRemove) return mapping;

    parent.attributes = parent.attributes.filter(
        (attribute) => attribute !== attributeToRemove
    );

    return mapping;
};
