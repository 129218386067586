import { type ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Divider } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';

import { MutationSnackbars } from '@xeris/components';
import { FormDrawer, SelectInput } from '@xeris/components/forms';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';
import { datasetsApi } from '@xeris/pages/dataset/api/datasets/datasetsApi';
import { useActiveDatasetId } from '@xeris/pages/dataset/hooks';

import { exportJobApi } from '../api/exportJobApi/exportJobApi';

import { DatasetField } from './fields/DatasetField';
import { OptionField } from './fields/OptionField';
import {
    type ExportJobFormData,
    exportJobSchema,
} from './utilities/exportJobSchema';
import { formatDefaultValues } from './utilities/formatDefaultValues';
import { formatOptionsForApi } from './utilities/formatOptionsForApi';

type CreateExportJobProps = {
    isOpen: boolean;
    handleCloseDialog: (exportJobId?: string) => void;
    datasetId?: string;
};

const CreateExportJob = ({
    isOpen,
    handleCloseDialog,
    datasetId = '',
}: CreateExportJobProps): ReactElement => {
    const { t } = useTranslation('exports');
    const navigate = useNavigate();

    const activeOrganizationId = useActiveOrganizationId();
    const activeDatasetId = useActiveDatasetId();

    const { data } = exportJobApi.useGetExportsQuery(
        {
            organizationId: activeOrganizationId,
        },
        { skip: !isOpen }
    );
    const { data: datasetData } = datasetsApi.useGetDatasetListQuery(
        {},
        {
            skip: !isOpen,
        }
    );

    const [createExportJob, { isSuccess, isError, isLoading }] =
        exportJobApi.useCreateExportJobMutation();

    const {
        handleSubmit,
        control,
        reset,
        resetField,
        setValue,
        watch,
        setError,
    } = useForm<ExportJobFormData>({
        resolver: yupResolver(exportJobSchema),
        defaultValues: {
            dataset: '',
            type: '',
            options: [],
        },
    });

    const selectedDataset = watch('dataset');
    const selectedExportType = watch('type');

    const exportType = data?.exports.find(
        ({ id }) => id === selectedExportType
    );

    const hasCategories = !!exportType && exportType.categories.length > 0;

    const handleSubmitExportJob = async (
        formData: ExportJobFormData
    ): Promise<void> => {
        const dataset = datasetData?.datasets.find(
            ({ id }) => id === formData.dataset
        );

        if (!dataset?.totalCount) {
            return setError('dataset', { type: 'emptyDataset' });
        }

        if (hasCategories) {
            reset();
            handleCloseDialog();

            return navigate(
                `/Export/Categorization/${formData.dataset}/${formData.type}`,
                {
                    state: {
                        optionsState: formatOptionsForApi(formData.options),
                    },
                }
            );
        }

        const { export: createdExport } = await createExportJob({
            exportId: formData.type,
            datasetId: formData.dataset,
            options: formatOptionsForApi(formData.options),
        }).unwrap();

        reset();
        handleCloseDialog();
        navigate(`/Export/ExportJob/${createdExport.create.id}`);
    };

    useEffect(() => {
        // Make sure the dataset is selected by default
        const defaultDatasetId = datasetId || activeDatasetId;

        if (
            !selectedDataset &&
            defaultDatasetId &&
            datasetData?.datasets.some(
                (dataset) => dataset.id === defaultDatasetId
            )
        ) {
            setValue('dataset', defaultDatasetId ?? '');
        }

        // Make sure the first export is selected by default
        if (!selectedExportType && data?.exports[0]) {
            setValue('type', data.exports[0].id);
        }

        // Make sure the options match the selected export
        resetField('options', {
            defaultValue: formatDefaultValues(
                data?.exports
                    .find(({ id }) => id === selectedExportType)
                    ?.options?.filter((option) => option.askUser) ?? []
            ),
        });
    }, [
        data?.exports,
        resetField,
        setValue,
        selectedExportType,
        selectedDataset,
        datasetId,
        activeDatasetId,
        datasetData?.datasets,
    ]);

    const options =
        exportType?.options?.filter((option) => option.askUser) ?? [];

    return (
        <>
            <FormDrawer
                open={isOpen}
                onClose={handleCloseDialog}
                title={t('createExportDialog.exportDataset')}
                cancelText={t('common.cancel')}
                onSubmit={handleSubmit(handleSubmitExportJob)}
                isLoading={isLoading}
                saveText={
                    hasCategories
                        ? t('exportButton.continue')
                        : t('exportButton.beginExport')
                }
            >
                <DatasetField
                    control={control}
                    datasets={datasetData?.datasets ?? []}
                />
                <SelectInput
                    control={control}
                    fieldName={'type'}
                    label={t('exportJob.destination')}
                    optionLabelField={'name'}
                    options={data?.exports ?? []}
                    showError
                />
                {!!options.length && <Divider sx={{ marginY: 2 }} />}
                {options.map((option, index) => (
                    <OptionField
                        key={option.key}
                        option={option}
                        control={control}
                        index={index}
                    />
                ))}
            </FormDrawer>
            <MutationSnackbars
                isSuccess={isSuccess}
                successText={t('exportJob.success')}
                isError={isError}
                errorText={t('exportJob.error')}
            />
        </>
    );
};

export default CreateExportJob;
