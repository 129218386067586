import { PureComponent, type ReactElement, type ReactNode } from 'react';

import { ErrorBlock } from './ErrorBlock/ErrorBlock';

type ErrorBoundaryProps = {
    children: ReactNode;
};

type ErrorBoundaryState = {
    hasErrors: boolean;
    error: string;
};

/**
 * Important! this does not catch:
 * - event handlers errors (click for example)
 * - async errors
 * https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries
 *
 * About the render, we have to skip children render if there is an error,
 * else, every error not caught will cause the entire tree to unmount, see here
 * https://reactjs.org/docs/error-boundaries.html#new-behavior-for-uncaught-errors
 *
 * This means that every error will cause the page to go blank, but still you can
 * navigate if the error is in the main section for example, and most of all some
 * errors you can dismiss the error message and everything will still work.
 *
 * It uses a class component because we need the componentDidCatch method
 */
export class ErrorBoundary extends PureComponent<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasErrors: false,
            error: '',
        };
    }

    componentDidCatch(error: Error): void {
        this.setState({ hasErrors: true, error: error.message });
    }

    handleDismissError = (): void => {
        this.setState({ hasErrors: false, error: '' });
    };

    render(): ReactElement {
        if (this.state.hasErrors) {
            return <ErrorBlock handleDismissError={this.handleDismissError} />;
        }

        return <>{this.props.children}</>;
    }
}
