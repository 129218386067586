import { forwardRef, type ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@mui/material';

import { EyeIcon, EyeOffIcon } from '@xeris/components/icons';

import { LoginFormControl } from '../LoginFormControl/LoginFormControl';

type PasswordIconProps = {
    handleClick: () => void;
    showPassword: boolean;
};

const PasswordIcon = ({
    handleClick,
    showPassword,
}: PasswordIconProps): ReactElement => (
    <IconButton
        aria-label="toggle password visibility"
        onClick={handleClick}
        edge="end"
        size="large"
    >
        {showPassword ? (
            <EyeIcon color="primary" />
        ) : (
            <EyeOffIcon color="icons" />
        )}
    </IconButton>
);

type PasswordInputProps = {
    id?: string;
    value: string;
    handleChange: (newValue: string) => void;
    name?: string;
};

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
    function PasswordInput({ id, value, handleChange, name }, ref) {
        const { t } = useTranslation('login');

        const [showPassword, setShowPassword] = useState(false);

        const resetCustomValidity = (): void => {
            if (ref && 'current' in ref && ref.current) {
                ref.current.setCustomValidity('');
            }
        };

        useEffect(() => {
            if (!ref || !('current' in ref) || !ref.current) return;

            const input = ref.current;
            input?.addEventListener('input', resetCustomValidity);

            return (): void => {
                input?.removeEventListener('input', resetCustomValidity);
            };
        });

        const handleShowPassword = (): void => {
            setShowPassword(!showPassword);
        };

        return (
            <LoginFormControl
                ref={ref}
                autoComplete="current-password"
                autoFocus={false}
                formId={id ? id : 'current-password'}
                formLabel={t('common.password')}
                formValue={value}
                formType={showPassword ? 'text' : 'password'}
                adornment={
                    <PasswordIcon
                        handleClick={handleShowPassword}
                        showPassword={showPassword}
                    />
                }
                name={name || 'current-password'}
                required={true}
                setFunction={handleChange}
                dataTestId="password-input"
            />
        );
    }
);
