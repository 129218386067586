import { gql } from 'graphql-request';

export const getOntologyDefinitionQuery = gql`
    query GetOntologyDefinition($entityId: String!) {
        entitySchema(entityId: $entityId) {
            __typename
            id
            name
            toplevel
            attributes {
                __typename
                id
                name
                required
                multivalue
                sectionId
                ... on ReferenceAttributeType {
                    entityTypeId
                }
                ... on DecimalAttributeType {
                    allowedDecimals
                }
                ... on IntAttributeType {
                    allowedInts
                }
                ... on StringAttributeType {
                    allowedStrings
                }
            }
        }
        sectionLayouts(entityId: $entityId) {
            id
            name
        }
    }
`;

export const createSourceMutation = gql`
    mutation CreateSource(
        $id: String!
        $name: String!
        $organization: String!
    ) {
        addSource(id: $id, name: $name, organization: $organization)
    }
`;

export const getSourcesQuery = gql`
    query GetSources {
        sources {
            id
            name
            schema {
                documentType
                properties {
                    type
                    name
                    examples
                    hasNull
                }
            }
        }
    }
`;

export const getMappingConfigurationsQuery = gql`
    query GetMappingConfigurations {
        mappingConfigurations {
            id
            documentType
            mapping {
                mappingId
                targetEntityType {
                    id
                    name
                }
            }
            source {
                id
                name
            }
        }
    }
`;

export const getMappingConfigurationQuery = gql`
    query GetMappingConfiguration($id: String!) {
        mappingConfiguration(id: $id) {
            documentType
            source {
                id
                name
            }
            mapping {
                mappingId
                targetEntityType {
                    id
                    name
                }
                attributes {
                    __typename
                    attributeId
                    attributeTypeId
                    ... on NodeMappingAttribute {
                        attributes
                    }
                    ... on LeafMappingAttribute {
                        expression
                    }
                }
            }
        }
    }
`;

export const getToplevelEntitiesQuery = gql`
    query GetToplevelEntities {
        toplevelEntities {
            id
            name
        }
    }
`;

export const createMappingConfigurationMutation = gql`
    mutation CreateMappingConfiguration(
        $mappingInput: MappingConfigurationInput!
        $sourceId: String!
        $runMapping: Boolean!
    ) {
        mappingConfiguration {
            create(mapper: $mappingInput) {
                __typename
            }
        }
        runMapping(source: $sourceId, transaction: null)
            @include(if: $runMapping)
    }
`;

export const updateMappingMutation = gql`
    mutation UpdateMapping(
        $mappingId: String!
        $mappingInput: MappingInput!
        $sourceId: String!
        $runMapping: Boolean!
    ) {
        mapping {
            update(mappingId: $mappingId, mapping: $mappingInput) {
                __typename
            }
        }
        runMapping(source: $sourceId, transaction: null)
            @include(if: $runMapping)
    }
`;

export const deleteMappingMutation = gql`
    mutation DeleteMapping($mappingId: String!) {
        mapping {
            delete(mappingId: $mappingId)
        }
    }
`;

export const getFileImportUploadUrlMutation = gql`
    mutation GetFileImportUploadUrl {
        createFileImportUploadUrl {
            id
            uploadUrl
        }
    }
`;

export const getCSVHeadersQuery = gql`
    query GetCSVHeaders(
        $fileID: String!
        $delimiter: String!
        $encoding: String!
        $headerRowPresent: Boolean!
        $previewLines: Int!
    ) {
        parseUploadedCsv(
            uploadId: $fileID
            delimiter: $delimiter
            encoding: $encoding
            headerRowPresent: $headerRowPresent
            previewLines: $previewLines
        ) {
            headers {
                columnName
                values
            }
        }
    }
`;

export const runMappingMutation = gql`
    mutation RunMapping($sourceId: String!) {
        runMapping(source: $sourceId, transaction: null)
    }
`;
