import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { type TFunction } from 'i18next';

import { type TabProviderTabType, TabsProvider } from '@xeris/components';
import { useActiveOrganization } from '@xeris/pages/admin/hooks';

import {
    type ConnectionCounters,
    type ConnectionsTabVariantType,
} from '../../../types/connectionsTypes';

const getTabList = (
    t: TFunction<'administration'>,
    counters: ConnectionCounters
): TabProviderTabType<ConnectionsTabVariantType>[] => [
    {
        id: 'channels',
        Label: t(`connections.channels`),
        count: counters.channels,
        Component: <></>,
    },
    {
        id: 'feeds',
        Label: t(`connections.feeds`),
        count: counters.feeds,
        Component: <></>,
    },
];

type ConnectionTabsProps = {
    counters: ConnectionCounters;
    selectedConnectionType: ConnectionsTabVariantType;
    setSelectedConnectionType: (type: ConnectionsTabVariantType) => void;
};

const ConnectionTabs = ({
    counters,
    selectedConnectionType,
    setSelectedConnectionType,
}: ConnectionTabsProps): ReactElement | null => {
    const { t } = useTranslation('administration');

    const organization = useActiveOrganization();

    if (organization?.type !== 'PRODUCER_CONSUMER') return null;

    return (
        <TabsProvider
            activeTabId={selectedConnectionType}
            tabList={getTabList(t, counters)}
            handleChangeTabId={(newActiveTabId): void =>
                setSelectedConnectionType(newActiveTabId)
            }
        />
    );
};

export default ConnectionTabs;
