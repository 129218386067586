import { type ReactElement } from 'react';
import {
    type Control,
    type FieldValues,
    type Path,
    useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    Autocomplete,
    createFilterOptions,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    ListItemIcon,
    ListItemText,
    MenuItem,
    type SxProps,
    TextField,
} from '@mui/material';

import { CircleIcon, CloseIcon, PlusIcon } from '@xeris/components/icons';
import { useWhoami } from '@xeris/hooks';
import { datasetSelectors } from '@xeris/pages/dataset/reducers';
import { type MinimalDatasetType } from '@xeris/pages/dataset/types/datasetTypes';
import { useAppSelector } from '@xeris/reducers';

const filter = createFilterOptions<Option>();

type Form = FieldValues & {
    dataset: {
        id?: string | null;
        name?: string | null;
    };
};

type Option =
    | { id: null; isNew: true }
    | (MinimalDatasetType & { isNew: false });

type SelectDatasetProps<T extends Form> = {
    disabled?: boolean;
    control: Control<T>;
    sx?: SxProps;
};

const SelectDataset = <T extends Form>({
    disabled,
    control,
    sx,
}: SelectDatasetProps<T>): ReactElement => {
    const { t } = useTranslation(['dataset', 'common']);
    const { userId } = useWhoami();

    const datasetList = useAppSelector(datasetSelectors.minimal.selectAll);

    const {
        field: { ref, onChange, ...field },
        fieldState: { error },
    } = useController({
        name: 'dataset' as Path<T>,
        control,
    });

    const options: Option[] = [
        { id: null, isNew: true },
        ...datasetList.map((dataset) => ({
            ...dataset,
            isNew: false as const,
        })),
    ];

    if (field.value?.id === null || datasetList.length === 0) {
        return (
            <FormControl error={!!error} sx={{ display: 'flex', ...sx }}>
                <TextField
                    {...field}
                    error={!!error}
                    label={t('datasetPopUp.datasetName')}
                    autoFocus
                    value={field.value?.name ?? ''}
                    inputRef={ref}
                    onChange={(event) =>
                        onChange({ id: null, name: event.target.value })
                    }
                    InputProps={
                        datasetList.length > 0
                            ? {
                                  endAdornment: (
                                      <InputAdornment position="start">
                                          <IconButton
                                              onClick={() => {
                                                  onChange(null);
                                              }}
                                              sx={{ flexShrink: 0 }}
                                          >
                                              <CloseIcon />
                                          </IconButton>
                                      </InputAdornment>
                                  ),
                              }
                            : {}
                    }
                />
                <FormHelperText>
                    {error?.type &&
                        t(`formErrors.${error.type}`, {
                            ns: 'common',
                            defaultValue: '',
                            fieldName: 'dataset',
                            message: error.message,
                        })}
                </FormHelperText>
            </FormControl>
        );
    }

    return (
        <FormControl fullWidth sx={sx} error={!!error}>
            <Autocomplete
                value={field.value}
                onChange={(event, value) => {
                    if (Array.isArray(value)) return;

                    if (value?.isNew) {
                        onChange({ id: null, name: '' });
                    }

                    onChange(value);
                }}
                filterOptions={(optionsToFilter, params) => {
                    const filtered = filter(optionsToFilter, params);

                    if (!filtered.some((option) => option.isNew)) {
                        filtered.unshift({ isNew: true, id: null });
                    }

                    return filtered;
                }}
                disabled={disabled}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={options}
                fullWidth
                renderInput={(params) => (
                    <>
                        <TextField
                            {...params}
                            {...field}
                            error={!!error}
                            inputRef={ref}
                            label={t('datasetPopUp.selectDataset')}
                        />
                    </>
                )}
                getOptionLabel={(value) =>
                    datasetList.find((d) => d.id === value.id)?.name ?? ''
                }
                renderOption={(props, option) =>
                    option.isNew ? (
                        <MenuItem
                            {...props}
                            value={'new'}
                            key={option.id}
                            sx={{
                                '&:not(:last-of-type)': {
                                    borderBottom: 1,
                                    borderColor: 'divider',
                                },
                            }}
                        >
                            <ListItemIcon>
                                <PlusIcon color={'primary'} />
                            </ListItemIcon>
                            <ListItemText>
                                {t('datasetPopUp.newDataset')}
                            </ListItemText>
                        </MenuItem>
                    ) : (
                        <MenuItem {...props} key={option.id} value={option.id}>
                            <ListItemText
                                primary={option.name}
                                primaryTypographyProps={{
                                    noWrap: true,
                                    fontWeight: '450',
                                }}
                                secondaryTypographyProps={{
                                    variant: 'caption',
                                    color: 'text.secondary',
                                    noWrap: true,
                                }}
                                secondary={
                                    <>
                                        {t('common.numberOfProducts', {
                                            count: option.totalCount,
                                        })}
                                        {option.owner.id !== userId && (
                                            <>
                                                <CircleIcon
                                                    fontSize="inherit"
                                                    color={'icons'}
                                                />
                                                {t('datasetList.createdBy', {
                                                    usernameOrYou:
                                                        option.owner.name,
                                                })}
                                            </>
                                        )}
                                    </>
                                }
                            />
                        </MenuItem>
                    )
                }
            />
        </FormControl>
    );
};

export default SelectDataset;
