import { type ReactElement, type ReactNode } from 'react';

import { Alert, type AlertProps } from '@mui/material';

type FeedbackCardProps = {
    isDisplayed?: boolean;
    children: ReactNode;
    severity: AlertProps['severity'];
};

export const FeedbackCard = ({
    isDisplayed = true,
    children,
    severity,
}: FeedbackCardProps): ReactElement | null => {
    if (!isDisplayed) {
        return null;
    }

    return <Alert severity={severity}>{children}</Alert>;
};
