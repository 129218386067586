import {
    type ReactElement,
    type SyntheticEvent,
    useMemo,
    useState,
} from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    List,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material';

import { CheckIcon, ChevronDownIcon, PlusIcon } from '@xeris/components/icons';
import { AttributeListItem } from '@xeris/pages/imports/pages/Mapping/components/AttributeListItem';
import { ExpandSections } from '@xeris/pages/imports/pages/Mapping/components/ExpandSections';
import {
    removeAttributeMapping,
    selectAttributeStatus,
    selectSectionAttributeCount,
    setAttributeMapping,
    setAttributeNode,
} from '@xeris/pages/imports/reducers/importMappingSlice';
import type { AttributeType } from '@xeris/pages/imports/types';
import { groupAttributes } from '@xeris/pages/imports/utilities/groupAttributes';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

type AttributeMenuItemProps = {
    attribute: AttributeType;
    setIsOpen: (value: boolean) => void;
};

const AttributeMenuItem = ({
    attribute,
    setIsOpen,
}: AttributeMenuItemProps): ReactElement => {
    const dispatch = useAppDispatch();

    const path = useMemo(
        () => [{ attributeId: attribute.id, index: 0 }],
        [attribute.id]
    );

    const { isVisible } = useAppSelector((state) =>
        selectAttributeStatus(state, path)
    );

    return (
        <MenuItem
            key={attribute.id}
            onClick={(event) => {
                event.stopPropagation();
                setIsOpen(true);

                if (isVisible) {
                    return dispatch(
                        removeAttributeMapping({
                            path: [
                                {
                                    attributeId: attribute.id,
                                    index: 0,
                                },
                            ],
                        })
                    );
                }

                if (attribute.__typename === 'ReferenceAttributeType') {
                    dispatch(
                        setAttributeNode({
                            path: [
                                {
                                    attributeId: attribute.id,
                                    index: 0,
                                },
                            ],
                        })
                    );
                } else {
                    dispatch(
                        setAttributeMapping({
                            path: [
                                {
                                    attributeId: attribute.id,
                                    index: 0,
                                },
                            ],
                            expression: null,
                        })
                    );
                }
            }}
        >
            <ListItemText
                primary={attribute.name}
                primaryTypographyProps={{ lineHeight: '20px' }}
            />

            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                {isVisible ? (
                    <CheckIcon fontSize={'small'} color={'primary'} />
                ) : (
                    <PlusIcon fontSize={'small'} color={'icons'} />
                )}
            </ListItemIcon>
        </MenuItem>
    );
};

type SectionProps = {
    section: { id: string; name: string };
    attributes: AttributeType[];
    handleSelectType: (entityType: AttributeType | null) => void;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
};

const Section = ({
    section,
    attributes,
    handleSelectType,
    isOpen,
    setIsOpen,
}: SectionProps): ReactElement => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);

    const { mappedCount, totalCount } = useAppSelector((state) =>
        selectSectionAttributeCount(state, attributes)
    );

    return (
        <Accordion
            expanded={isOpen}
            onClick={() => setIsOpen(!isOpen)}
            variant={'outlined'}
            disableGutters
            sx={{
                '&:not(:last-child)': {
                    borderBottom: 0,
                },
            }}
        >
            <Stack minWidth={'100%'} paddingRight={1} gap={1}>
                <AccordionSummary
                    expandIcon={<ChevronDownIcon />}
                    sx={{
                        'flexDirection': 'row-reverse',
                        'width': '100%',
                        'paddingRight': 0,
                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',
                        },
                    }}
                >
                    <Typography variant={'h4'} component={'p'} flex={'1 1 70%'}>
                        {section.name}
                    </Typography>
                    <Typography variant={'body2'} sx={{ flex: '0 2 30%' }}>
                        {`${mappedCount} / ${totalCount}`} attribute types
                    </Typography>
                </AccordionSummary>
                <IconButton
                    onClick={(event) => {
                        event.stopPropagation();
                        if (menuAnchorEl) {
                            setMenuAnchorEl(null);
                        } else {
                            setMenuAnchorEl(event.currentTarget);
                        }
                    }}
                >
                    <PlusIcon />
                </IconButton>
                <Menu
                    disableScrollLock
                    open={!!menuAnchorEl}
                    anchorEl={menuAnchorEl}
                    onClose={(event: SyntheticEvent) => {
                        event.stopPropagation();
                        setMenuAnchorEl(null);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {attributes
                        .filter((attribute) => !attribute.required)
                        .map((attribute) => (
                            <AttributeMenuItem
                                key={attribute.id}
                                attribute={attribute}
                                setIsOpen={setIsOpen}
                            />
                        ))}
                </Menu>
            </Stack>

            <AccordionDetails
                sx={(theme) => ({
                    borderTop: 1,
                    borderColor: 'divider',
                    paddingBlock: 1,
                    backgroundColor: theme.palette.background.default,
                })}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns:
                            'repeat(auto-fill, minmax(min(100%, 500px),1fr))',
                        rowGap: 1,
                        columnGap: 1,
                    }}
                    component={List}
                >
                    {attributes.map((attribute) => (
                        <AttributeListItem
                            key={attribute.id}
                            attribute={attribute}
                            handleSelectType={handleSelectType}
                        />
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

type EntityTypeListProps = {
    attributes: AttributeType[];
    handleSelectType: (entityType: AttributeType | null) => void;
};

export const AttributeList = ({
    attributes,
    handleSelectType,
}: EntityTypeListProps): ReactElement | null => {
    const grouped = groupAttributes(attributes);

    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});

    const noneExpanded = grouped.every(
        ({ section, attributes: attributesInGroup }) =>
            !(expanded[section.id] ?? attributesInGroup.some((a) => a.required))
    );

    return (
        <Box sx={{ width: '100%' }}>
            <ExpandSections
                noneExpanded={noneExpanded}
                onClick={() => {
                    setExpanded(
                        Object.fromEntries(
                            grouped.map(({ section }) => [
                                section.id,
                                noneExpanded,
                            ])
                        )
                    );
                }}
            />
            {grouped.map(({ section, attributes: attributesInGroup }) => (
                <Section
                    key={section.id}
                    section={section}
                    attributes={attributesInGroup}
                    handleSelectType={handleSelectType}
                    isOpen={
                        expanded[section.id] ??
                        attributesInGroup.some((a) => a.required)
                    }
                    setIsOpen={(newValue) =>
                        setExpanded((current) => ({
                            ...current,
                            [section.id]: newValue,
                        }))
                    }
                />
            ))}
        </Box>
    );
};
