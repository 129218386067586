import { type ReactElement } from 'react';

import { Tooltip as MuiTooltip, type TooltipProps } from '@mui/material';

export const Tooltip = (props: TooltipProps): ReactElement => {
    if (!props.title) {
        return props.children;
    }

    return (
        <MuiTooltip {...props}>
            <div>{props.children}</div>
        </MuiTooltip>
    );
};
