import { type ReactElement } from 'react';

import { MaterialOptionList } from '@xeris/components';
import { type Material } from '@xeris/pages/product/types';

import { MaterialSwatchWrapper } from './MaterialSwatchWrapper';

type MaterialOptionsProps = {
    material: Material;
    featureId: string;
};

export const MaterialOptions = ({
    featureId,
    material,
}: MaterialOptionsProps): ReactElement | null => {
    return (
        <MaterialOptionList description={material.description}>
            {material.options.map((option) => (
                <MaterialSwatchWrapper
                    key={option.id}
                    option={option}
                    featureId={featureId}
                />
            ))}
        </MaterialOptionList>
    );
};
