import {
    type MutableRefObject,
    type ReactElement,
    type ReactNode,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
    Avatar,
    Box,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
} from '@mui/material';

import CreateNewDataset, {
    type NewDatasetType,
} from '../../../Common/CreateNewDataset/CreateNewDataset';
import type {
    SelectedDataRefType,
    SelectedDocumentsDataType,
    SelectedImagesDataType,
    SelectedModelsDataType,
} from '../../types';

import DocumentationSection from './DocumentationSection/DocumentationSection';
import MediaSection from './MediaSection/MediaSection';
import ModelSection from './ModelSection/ModelSection';
import Header from './Header';

type ContentProps = {
    selectedDataRef: MutableRefObject<SelectedDataRefType>;
    products: {
        id: string;
        name: string;
        images:
            | {
                  url: string;
                  title: string | null;
              }[]
            | null;
        brand: {
            name: string;
        } | null;
    }[];
    data: {
        [key: string]: {
            isSelected: boolean;
            images: {
                list: SelectedImagesDataType[];
                primaryImageId: string | null;
            };
            documents: SelectedDocumentsDataType[];
            models: SelectedModelsDataType[];
        };
    };
    customContentHeader?: ReactNode;
    newDataset: NewDatasetType;
    handleSetNewDataset: (newDataset: NewDatasetType) => void;
};

export const ContentForm = ({
    selectedDataRef,
    data,
    products,
    customContentHeader,
    newDataset,
    handleSetNewDataset,
}: ContentProps): ReactElement | null => {
    const { t } = useTranslation('product');
    const [productId, setProductId] = useState(products[0]?.id ?? null);
    const [currentData, setCurrentData] = useState<SelectedDataRefType>({});

    useEffect(() => {
        setCurrentData((d) => ({ ...d, ...data }));
        setProductId(products[0]?.id ?? null);
    }, [data, products]);

    useEffect(() => {
        selectedDataRef.current = currentData;
    }, [currentData, selectedDataRef]);

    const product = products.find(({ id }) => id === productId);
    const selectedCount = Object.values(currentData).filter(
        ({ isSelected }) => isSelected
    ).length;

    return (
        <Box sx={{ display: 'flex', maxHeight: '100%' }}>
            {products.length > 1 && (
                <Box
                    sx={{
                        borderRight: 1,
                        borderColor: 'divider',
                        maxHeight: '100%',
                        overflowY: 'scroll',
                        minWidth: '350px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            backgroundColor: 'background.paper',
                            paddingInlineStart: 3,
                            paddingInlineEnd: 0.5,
                            paddingBlock: 1,
                            position: 'sticky',
                            top: 0,
                            borderBottom: 1,
                            borderColor: 'divider',
                            zIndex: 1,
                        }}
                    >
                        <Typography variant={'h3'}>
                            {t('dataSelector.selectVariants')}
                        </Typography>
                        <Checkbox
                            indeterminate={
                                selectedCount > 0 &&
                                selectedCount < products.length
                            }
                            checked={selectedCount === products.length}
                            onChange={(event, value) => {
                                setCurrentData((d) =>
                                    Object.fromEntries(
                                        Object.entries(d).map(
                                            ([id, selectedData]) => [
                                                id,
                                                {
                                                    ...selectedData,
                                                    isSelected: value,
                                                },
                                            ]
                                        )
                                    )
                                );
                            }}
                        />
                    </Box>
                    <List>
                        {products.map((p) => (
                            <ListItem
                                key={p.id}
                                disablePadding
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={(_, value) => {
                                            if (value) {
                                                setProductId(p.id);
                                            }
                                            setCurrentData((d) => ({
                                                ...d,
                                                [p.id]: {
                                                    ...d[p.id],
                                                    isSelected: value,
                                                },
                                            }));
                                        }}
                                        checked={
                                            currentData[p.id]?.isSelected ??
                                            false
                                        }
                                    />
                                }
                            >
                                <ListItemButton
                                    selected={p.id === product?.id}
                                    onClick={() => setProductId(p.id)}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            src={p.images?.[0]?.url}
                                            variant={'rounded'}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={p.name}
                                        primaryTypographyProps={{
                                            color:
                                                p.id === product?.id
                                                    ? 'primary.dark'
                                                    : 'text',
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
            <Box
                sx={{
                    padding: '34px',
                    flexGrow: 1,
                    maxHeight: '100%',
                    overflowY: 'scroll',
                }}
            >
                {product && (
                    <>
                        <Header
                            product={product}
                            customContentHeader={customContentHeader}
                        />
                        <MediaSection
                            images={
                                currentData[product.id]?.images ?? {
                                    list: [],
                                    primaryImageId: null,
                                }
                            }
                            setData={(images) =>
                                setCurrentData((d) => ({
                                    ...d,
                                    [product.id]: {
                                        ...d[product.id],
                                        images,
                                    },
                                }))
                            }
                        />
                        <DocumentationSection
                            documents={currentData[product.id]?.documents ?? []}
                            setData={(documents) =>
                                setCurrentData((d) => ({
                                    ...d,
                                    [product.id]: {
                                        ...d[product.id],
                                        documents,
                                    },
                                }))
                            }
                        />
                        <ModelSection
                            models={currentData[product.id]?.models ?? []}
                            setData={(models) =>
                                setCurrentData((d) => ({
                                    ...d,
                                    [product.id]: {
                                        ...d[product.id],
                                        models,
                                    },
                                }))
                            }
                        />
                    </>
                )}
                {newDataset.showForm && <Divider />}
                <CreateNewDataset
                    newDataset={newDataset}
                    handleSetNewDataset={handleSetNewDataset}
                />
            </Box>
        </Box>
    );
};
