import {
    type ReactElement,
    type ReactNode,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import { VariableSizeList as List, type VariableSizeList } from 'react-window';

import type { MasterProductWithIds } from '@xeris/pages/product/Common';

import { type ColumnData, type ListData, type SectionListData } from '../types';
import { createColumns } from '../utilities/createColumns';

import { CardRow } from './CardRow';
import { Section } from './Section';

export type ListViewProps<MP extends MasterProductWithIds> = {
    listData: ListData<MP>[];
    card: (product: MP) => ReactNode;
    cardHeight: number;
    cardMinWidth: number;
    sectionActions?: (section: SectionListData<MP>) => ReactNode;
    sectionInfo?: (section: SectionListData<MP>) => ReactNode;
    toggleSectionOpen: (id: string) => void;
    width: number;
    height: number;
};

export const ProductCardList = <MP extends MasterProductWithIds>({
    listData,
    card,
    cardHeight,
    cardMinWidth,
    sectionActions,
    sectionInfo,
    toggleSectionOpen,
    width,
    height,
}: ListViewProps<MP>): ReactElement => {
    const ref = useRef<VariableSizeList<ColumnData<MP>[]>>(null);

    // Make sure we don't get 0 columns, and account for card spacing
    const columns = Math.max(Math.floor((width - 20) / (cardMinWidth + 20)), 1);

    const data = useMemo(
        () => createColumns(listData, columns),
        [listData, columns]
    );

    const hasSections = useMemo(
        () => listData.some(({ type }) => type === 'section'),
        [listData]
    );

    // Hack to make the height of each element recalculate when the data changes
    useEffect(() => {
        ref.current?.resetAfterIndex(0);
    }, [data]);

    return (
        <List
            ref={ref}
            width={width}
            height={height}
            itemData={data}
            itemSize={(itemIndex) =>
                data[itemIndex].type === 'section'
                    ? 64
                    : cardHeight -
                      (data[itemIndex - 1] &&
                      data[itemIndex - 1].type === 'products'
                          ? 20
                          : 0)
            }
            itemCount={data.length}
            estimatedItemSize={hasSections ? 180 : cardHeight}
        >
            {({ style, data: columnData, index }) => {
                const element = columnData[index];
                const previous = columnData[index - 1];

                if (element.type === 'section') {
                    const isFirst = index === 0;
                    const isAfterPreviousSection =
                        previous?.type === 'section' && !previous.isOpen;

                    return (
                        <div key={element.id} style={style}>
                            <Section
                                data={element}
                                actions={sectionActions}
                                info={sectionInfo}
                                isOpen={element.isOpen}
                                toggleSectionOpen={() =>
                                    toggleSectionOpen(element.id)
                                }
                                sx={{
                                    borderTopWidth:
                                        isFirst || isAfterPreviousSection
                                            ? 0
                                            : 1,
                                }}
                            />
                        </div>
                    );
                }

                const isAfterRow = previous && previous?.type === 'products';

                return (
                    <div key={element.id} style={style}>
                        <CardRow
                            data={element.data}
                            card={card}
                            hasSections={hasSections}
                            width={cardMinWidth}
                            isAfterRow={isAfterRow}
                        />
                    </div>
                );
            }}
        </List>
    );
};
