import { type ReactElement } from 'react';

import { Stack } from '@mui/material';

import { Typography } from '../../../Typography/Typography';

type MaterialFeatureSummaryHeaderProps = {
    name: string;
};
export const MaterialSummaryHeader = ({
    name,
}: MaterialFeatureSummaryHeaderProps): ReactElement => {
    return (
        <Stack spacing={1} marginBottom={1} justifyContent={'start'}>
            <Typography variant={'h3'} gutterBottom>
                {name}
            </Typography>
        </Stack>
    );
};
