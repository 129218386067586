import { forwardRef, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, type NavLinkProps } from 'react-router-dom';

import {
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    type SxProps,
    type Theme,
    Tooltip,
} from '@mui/material';

import { ChevronDownIcon, ChevronUpIcon } from '@xeris/components/icons';
import { useIsMobile } from '@xeris/hooks';

import { type MenuElementWithLevelType } from '../types';

type CollapseElementProps = {
    element: MenuElementWithLevelType;
    isOpen: boolean;
    setIsOpen: (update: (previous: boolean) => boolean) => void;
};

const borderRadius = 0.75;

const listItemStyle =
    (isSubmenu: boolean, isMobile: boolean): SxProps<Theme> =>
    (theme) => ({
        'borderRadius': borderRadius,
        '&:active': {
            '.menuElementHover, .activeIconPlaceholder': {
                opacity: 1,
                borderRadius: borderRadius,
            },
        },
        '& .MuiListItemButton-root': {
            paddingInlineEnd: isMobile ? 6 : 1,
            borderRadius: borderRadius,
        },
        '&:hover': {
            '.menuElementHover, .activeIconPlaceholder': {
                opacity: 1,
                borderRadius: borderRadius,
            },
            '& .MuiListItemButton-dense': {
                borderRadius: theme.spacing(
                    borderRadius,
                    borderRadius,
                    isSubmenu ? 0 : borderRadius,
                    isSubmenu ? 0 : borderRadius
                ),
            },
            '& .MuiListItemButton-root': {
                paddingInlineEnd: 6,
            },
        },
        '.Mui-selected': {
            'borderRadius': theme.spacing(
                borderRadius,
                borderRadius,
                isSubmenu ? 0 : borderRadius,
                isSubmenu ? 0 : borderRadius
            ),
            '& .MuiListItemIcon-root': {
                color: 'primary.main',
            },
            '& .MuiListItemText-primary': {
                color: 'primary.dark',
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        '.MuiIconButton-root': {
            padding: 0.5,
            borderRadius: borderRadius,
        },
    });

const CustomNavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
    function CustomNavLink(props, ref) {
        return (
            <NavLink
                ref={ref}
                {...props}
                className={({ isActive }) =>
                    isActive && props.to !== '/Products?tab=search'
                        ? props.className?.toString() + ' Mui-selected'
                        : props.className?.toString()
                }
            />
        );
    }
);

export const CollapseElement = ({
    element,
    isOpen,
    setIsOpen,
}: CollapseElementProps): ReactElement => {
    const { t } = useTranslation('navigation');

    const isMobile = useIsMobile();

    return (
        <ListItem
            disablePadding
            dense
            sx={listItemStyle(
                !!element.submenu && !!element.isAlwaysShown,
                isMobile
            )}
            secondaryAction={
                <Tooltip title={t('menu.toggleAdministrationMenu')}>
                    <IconButton
                        edge={'end'}
                        onClick={(e): void => {
                            e.stopPropagation();
                            setIsOpen((o: boolean) => !o);
                        }}
                    >
                        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    </IconButton>
                </Tooltip>
            }
        >
            <ListItemButton
                onClick={(e): void => {
                    e.stopPropagation();
                    setIsOpen((o: boolean) => !o);
                }}
                sx={{
                    p: 1,
                }}
            >
                {element.startAdornment && (
                    <ListItemIcon>{element.startAdornment}</ListItemIcon>
                )}
                <ListItemText
                    primary={element.title}
                    primaryTypographyProps={{
                        variant: element.typographyVariant ?? 'h6',
                        fontWeight: 500,
                        component: 'span',
                    }}
                    secondary={element.secondaryText}
                    sx={{ m: 0, paddingLeft: element.startAdornment ? 0 : 2 }}
                />
            </ListItemButton>
        </ListItem>
    );
};

type ElementProps = {
    element: MenuElementWithLevelType;
};

export const Element = ({ element }: ElementProps): ReactElement => {
    const isMobile = useIsMobile();

    return (
        <ListItem
            dense
            disablePadding
            secondaryAction={element.secondaryAction}
            sx={listItemStyle(
                !!element.submenu && !!element.isAlwaysShown,
                isMobile
            )}
        >
            <ListItemButton
                component={CustomNavLink}
                to={element.link}
                sx={{ padding: 1, paddingInlineEnd: 1 }}
            >
                {element.startAdornment && (
                    <ListItemIcon>{element.startAdornment}</ListItemIcon>
                )}
                <ListItemText
                    primary={element.title}
                    primaryTypographyProps={{
                        variant: element.typographyVariant ?? 'h6',
                        component: 'span',
                        fontWeight: 500,
                        noWrap: true,
                    }}
                    secondary={element.secondaryText}
                    secondaryTypographyProps={{
                        noWrap: true,
                        variant: 'caption',
                    }}
                    sx={{ m: 0, paddingLeft: element.startAdornment ? 0 : 2 }}
                />
            </ListItemButton>
        </ListItem>
    );
};
