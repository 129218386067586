import { memo, type ReactElement, useMemo } from 'react';

import { Box } from '@mui/material';

import { ChevronUpIcon } from '..';

export type ChevronProps = {
    isCollapsed?: boolean;
};

export const UpDownChevronIcon = memo(
    ({ isCollapsed }: ChevronProps): ReactElement => {
        const styles = useMemo(() => {
            return {
                transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform .2s',
                display: 'flex',
            };
        }, [isCollapsed]);

        return (
            <Box sx={styles}>
                <ChevronUpIcon color={'icons'} />
            </Box>
        );
    }
);
