import { Fragment, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';

import { CloseIcon } from '@xeris/components/icons';
import { useDataLanguage } from '@xeris/hooks';
import { productHooks } from '@xeris/pages/product/hooks/productHooks';
import { productActions } from '@xeris/pages/product/reducers';
import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import {
    type MasterProduct,
    type Product,
} from '@xeris/pages/product/types/product';
import { isMasterProduct } from '@xeris/pages/product/utilities';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';
import { type ApiDataError } from '@xeris/types/graphql';

const useGetDataErrors = (
    productEntity: Product<'id'> | MasterProduct<'id'>
): ApiDataError[] => {
    const dataLanguage = useDataLanguage();
    const showDataErrors = useAppSelector(
        settingsSelectors.selectShowDataErrors
    );

    const productDataErrors = productHooks.api.queries.useGetProductDataErrors(
        { productId: productEntity.id, dataLanguage },
        {
            skip: isMasterProduct(productEntity) || !showDataErrors,
        }
    );

    const masterProductDataErrors =
        productHooks.api.queries.useGetMasterProductDataErrors(
            { masterProductId: productEntity.id, dataLanguage },
            {
                skip: !isMasterProduct(productEntity) || !showDataErrors,
            }
        );
    return [
        ...(productDataErrors.data ?? []),
        ...(masterProductDataErrors.data ?? []),
    ].filter((error) => !!error);
};

type DataErrorsProps = {
    productEntity: MasterProduct<'name'> | Product<'name'>;
};

export const DataErrors = ({
    productEntity,
}: DataErrorsProps): ReactElement | null => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');
    const showDataErrors = useAppSelector(
        settingsSelectors.selectShowDataErrors
    );

    const importErrorsList = useGetDataErrors(productEntity);

    const handleToggleShowDataErrors = (): void => {
        dispatch(productActions.settings.toggleShowDataErrors());
    };

    if (!showDataErrors) {
        return null;
    }

    return (
        <Box marginBottom={6}>
            <Stack>
                <Typography variant={'h3'} component={'h2'}>
                    {t('datasheet.importErrors')}
                </Typography>
                <IconButton onClick={handleToggleShowDataErrors} edge={'end'}>
                    <CloseIcon />
                </IconButton>
            </Stack>
            <Divider />
            {importErrorsList.length > 0 ? (
                <Box
                    display={'grid'}
                    gridTemplateColumns={'auto 1fr'}
                    rowGap={1}
                    columnGap={2}
                    alignItems={'center'}
                    marginBlock={1}
                >
                    <Typography>{t('datasheet.type')}</Typography>
                    <Typography>{t('datasheet.message')}</Typography>
                    {importErrorsList.map((dataError, index) => (
                        <Fragment key={index}>
                            <Divider />
                            <Divider />
                            <Typography>{dataError.type}</Typography>
                            <Typography>{dataError.message}</Typography>
                        </Fragment>
                    ))}
                </Box>
            ) : (
                <Typography marginBlock={1}>
                    {t('datasheet.noErrors')}
                </Typography>
            )}
        </Box>
    );
};
