import { useTranslation } from 'react-i18next';

import { Box, ListItemAvatar } from '@mui/material';

import { type TFunction } from 'i18next';

import { type MenuElementType } from '@xeris/components';
import { FolderIcon, PlusIcon } from '@xeris/components/icons';
import { useIsMobile, useWhoami } from '@xeris/hooks';
import { useAppSelector } from '@xeris/reducers';

import Preview from './DatasetList/components/Preview';
import type { DatasetSharedWith } from './types/datasetTypes';
import { DatasetActionsMenu } from './common';
import { CreateDataset } from './forms';
import { datasetSelectors } from './reducers';
import { generateDatasetUrl } from './utilities';

const getDatasetMenuItem = (
    t: TFunction<'navigation'>,
    dataset: {
        id: string;
        name: string;
        description: string | null;
        totalCount: number;
        previewImages: string[];
        owner: {
            id: string;
            name: string | null;
            username: string;
        };
        sharedWith: DatasetSharedWith[];
    },
    userId: string,
    isMobile: boolean
): MenuElementType => {
    return {
        id: dataset.id,
        title: dataset.name,
        link: generateDatasetUrl(dataset),
        startAdornment: (
            <ListItemAvatar>
                <Preview dataset={dataset} size={36} />
            </ListItemAvatar>
        ),

        secondaryText:
            t('menu.dataset.productCount', {
                count: dataset.totalCount,
            }) +
            (userId !== dataset.owner.id
                ? t('menu.dataset.by', {
                      usernameOrYou: dataset.owner.name,
                  })
                : ''),
        typographyVariant: 'body1',
        secondaryAction: (
            <Box
                className="menuElementHover"
                sx={{ opacity: isMobile ? 1 : 0 }}
            >
                <DatasetActionsMenu dataset={dataset} />
            </Box>
        ),
    };
};

export const useDatasetMenu = (): MenuElementType[] => {
    const { t } = useTranslation('navigation');

    const datasetList = useAppSelector(datasetSelectors.minimal.selectAll);
    const { userId } = useWhoami();

    const isMobile = useIsMobile();

    return [
        {
            id: 'datasetList',
            title: t('menu.datasets'),
            link: '/Dataset',
            startAdornment: <FolderIcon />,
            isAlwaysShown: true,
            submenu: datasetList.map((dataset) =>
                getDatasetMenuItem(t, dataset, userId, isMobile)
            ),
            secondaryAction: <CreateDataset icon={<PlusIcon />} />,
        },
    ];
};
