import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';

import { MutationSnackbars } from '@xeris/components';
import { DeleteIcon } from '@xeris/components/icons';

import { exportsApi } from '../api/exportsApi';

type DeleteExportDialogProps = {
    isOpen: boolean;
    handleCloseDialog: () => void;
    exportData?: { id: string; name: string };
};

export const DeleteExport = ({
    isOpen,
    exportData,
    handleCloseDialog,
}: DeleteExportDialogProps): ReactElement => {
    const { t } = useTranslation('administration');

    const [deleteExport, { isLoading, isSuccess, isError }] =
        exportsApi.useDeleteExportMutation();

    const handleDeleteExport = async (): Promise<void> => {
        if (!exportData) return;

        await deleteExport({ exportId: exportData.id }).unwrap();

        handleCloseDialog();
    };

    return (
        <>
            <Dialog open={isOpen} onClose={handleCloseDialog}>
                <DialogTitle>{t('exports.delete.deleteExport')}</DialogTitle>
                <DialogContent>
                    {t('exports.delete.willBeDeleted', {
                        name: exportData?.name ?? '',
                    })}
                </DialogContent>
                <DialogActions>
                    <Button color={'secondary'} onClick={handleCloseDialog}>
                        {t('common.cancel')}
                    </Button>
                    <Button
                        variant={'contained'}
                        onClick={handleDeleteExport}
                        startIcon={<DeleteIcon />}
                        color={'error'}
                        disabled={!!isLoading}
                    >
                        {t('common.delete')}
                    </Button>
                </DialogActions>
            </Dialog>
            <MutationSnackbars
                isSuccess={isSuccess}
                successText={t('exports.delete.success')}
                isError={isError}
                errorText={t('exports.delete.error')}
            />
        </>
    );
};
