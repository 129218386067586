import { type ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';

import {
    BackButton,
    CenteredPage,
    ErrorPage,
    MutationSnackbars,
} from '@xeris/components';
import {
    getFullMapping,
    initializeMapping,
} from '@xeris/pages/imports/reducers/importMappingSlice';
import { useAppSelector } from '@xeris/reducers';

import { importsApi } from '../../api/importsApi';
import { type AttributeType } from '../../types';
import { getEntityTree } from '../../utilities/getEntityTree';

import { AttributeList } from './components/AttributeList';
import { AttributeForm } from './forms/Attribute/AttributeForm';

export const EditMapping = (): ReactElement => {
    const { mappingId = '' } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selectedType, setSelectedType] = useState<AttributeType | null>(
        null
    );

    const {
        data,
        isLoading: isLoadingMapping,
        isError: isMappingError,
    } = importsApi.useGetMappingConfigurationQuery({
        id: mappingId,
    });

    useEffect(() => {
        if (data?.mappingConfiguration?.mapping) {
            dispatch(
                initializeMapping(data.mappingConfiguration.mapping.attributes)
            );
        }
    }, [data, dispatch]);

    const {
        data: productDefinition,
        isLoading,
        isError,
    } = importsApi.useGetOntologyDefinitionQuery(
        {
            entityId:
                data?.mappingConfiguration?.mapping.targetEntityType.id ?? '',
        },
        { skip: !data?.mappingConfiguration }
    );

    const { data: sourcesData } = importsApi.useGetSourcesQuery({});

    const fields =
        sourcesData?.sources
            ?.find(({ id }) => id === data?.mappingConfiguration?.source.id)
            ?.schema.find(
                (schema) =>
                    schema.documentType ===
                    data?.mappingConfiguration?.documentType
            )
            ?.properties.map((property) => ({
                label: property.name,
                path: property.type,
            })) ?? [];

    const [
        updateMapping,
        {
            isLoading: isSavingMapping,
            isSuccess: editMappingSuccess,
            isError: editMappingError,
        },
    ] = importsApi.useUpdateMappingMutation();

    const productSchema = useMemo(
        () =>
            getEntityTree(
                data?.mappingConfiguration?.mapping.targetEntityType.id,
                productDefinition?.entitySchema,
                productDefinition?.sectionLayouts
            ),
        [productDefinition, data]
    );

    const mapping = useAppSelector(getFullMapping);

    if (isLoading || isLoadingMapping) {
        return <Skeleton height={200} />;
    }

    if (isError || isMappingError) {
        return <ErrorPage title={'An error occurred'} />;
    }

    if (!productSchema || !data?.mappingConfiguration) {
        return <ErrorPage title={'Not found'} />;
    }

    const handleUpdateMapping = async (runMapping: boolean): Promise<void> => {
        if (!data.mappingConfiguration) return;

        await updateMapping({
            sourceId: data.mappingConfiguration.source.id,
            runMapping: runMapping,
            mappingId: data.mappingConfiguration.mapping.mappingId,
            mappingInput: {
                supplementarySources: [],
                targetEntityTypeId:
                    data.mappingConfiguration.mapping.targetEntityType.id,
                attributes: mapping,
            },
        }).unwrap();

        navigate('../mapping');
    };

    return (
        <>
            <CenteredPage>
                <BackButton to={'../mapping'}>{'Mappings'}</BackButton>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    sx={{ paddingBottom: 1 }}
                >
                    <Typography variant={'h1'}>
                        {'Mapping: ' + productSchema.name}
                    </Typography>
                    <Stack gap={1}>
                        <LoadingButton
                            loading={isSavingMapping}
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={() => handleUpdateMapping(false)}
                        >
                            {'Save'}
                        </LoadingButton>
                        <LoadingButton
                            loading={isSavingMapping}
                            variant={'contained'}
                            onClick={() => handleUpdateMapping(true)}
                        >
                            {'Save and run'}
                        </LoadingButton>
                    </Stack>
                </Box>
                <Divider />
                <AttributeList
                    attributes={productSchema.attributes}
                    handleSelectType={setSelectedType}
                />
            </CenteredPage>
            <AttributeForm
                isOpen={!!selectedType}
                handleClose={() => setSelectedType(null)}
                type={selectedType}
                fields={fields}
            />
            <MutationSnackbars
                isSuccess={editMappingSuccess}
                successText={'Mapping updated'}
                isError={editMappingError}
                errorText={'Failed to update mapping'}
            />
        </>
    );
};
