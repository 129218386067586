import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    FormControl,
    InputLabel,
    ListItemIcon,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';

import { useDataLanguage } from '@xeris/hooks';
import { settingsActions } from '@xeris/pages/product/reducers/settingsSlice';
import { useAppDispatch } from '@xeris/reducers';
import { type ApiLanguage } from '@xeris/types';

import { languageApi } from './api/languageApi';

export const DataLanguageSelector = (): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('common');

    const { data } = languageApi.useGetDataLanguagesQuery({});

    const dataLanguage = useDataLanguage();

    const dataLanguages =
        data?.languages.map((language) => ({
            ...language,
            name: `${language.flag} ${language.language}`,
        })) ?? [];

    return (
        <FormControl
            size={'small'}
            sx={{ marginBlock: 1, flexBasis: '200px', flexShrink: 1 }}
        >
            <InputLabel>
                {t('components.languageSelector.dataLanguage')}
            </InputLabel>
            <Select
                label={t('components.languageSelector.dataLanguage')}
                inputProps={{
                    'aria-label': t('components.languageSelector.dataLanguage'),
                }}
                value={
                    // Makes sure the current language is one of the allowed options
                    dataLanguages.some(({ id }) => id === dataLanguage)
                        ? dataLanguage
                        : ''
                }
                aria-label={t('components.languageSelector.selectDataLanguage')}
                renderValue={(value): ReactElement => (
                    <Typography variant="inherit" noWrap>
                        {
                            dataLanguages.find(
                                (language) => language.id === value
                            )?.language
                        }
                    </Typography>
                )}
                onChange={(event): void => {
                    dispatch(
                        settingsActions.setDataLanguage(
                            event.target.value as ApiLanguage
                        )
                    );
                }}
            >
                <Typography
                    variant={'overline'}
                    sx={{ paddingInline: 1.5 }}
                    noWrap
                >
                    {t('components.languageSelector.selectDataLanguage')}
                </Typography>
                {dataLanguages.map((language) => (
                    <MenuItem key={language.id} value={language.id}>
                        <ListItemIcon>{`${language.flag}`}</ListItemIcon>

                        <Typography variant="inherit" noWrap>
                            {language.language}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
