import { type Dispatch, type ReactElement, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import { EditIcon } from '@xeris/components/icons';

import type { BrandGroupMarketAccessRule } from '../types';

import { FilterChips } from './FilterChips';

type FilterInfoProps = {
    setShowEdit: Dispatch<SetStateAction<'filter' | 'products' | null>>;
    brand: {
        markets: {
            id: string;
            name: string;
        }[];
        groupTypes: {
            id: string;
            name: string;
            groups: {
                id: string;
                name: string;
            }[];
        }[];
    };
    rule: BrandGroupMarketAccessRule | null;
};

export const FilterInfo = ({
    setShowEdit,
    brand,
    rule,
}: FilterInfoProps): ReactElement | null => {
    const { t } = useTranslation('administration');

    if (!rule) {
        return null;
    }

    return (
        <Box sx={{ marginBottom: 2 }}>
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <Typography variant={'subtitle1'}>
                    {t('connections.brandAccess.filters')}
                </Typography>
                <Button
                    startIcon={<EditIcon />}
                    size={'small'}
                    onClick={() => setShowEdit('filter')}
                >
                    {t('connections.brandAccess.editFilters')}
                </Button>
            </Box>
            <FilterChips rule={rule} brand={brand} />
        </Box>
    );
};
