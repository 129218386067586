import { memo, type ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    FeedbackCard,
    MaterialFeatureHeader,
    MaterialFeaturePanel,
    MaterialList,
    MaterialPriceGroupList,
} from '@xeris/components';
import { type MaterialFeature } from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { setMaterialFilter, variantGeneratorSelectors } from '../../../reducer';
import { groupMaterialsByPriceGroup } from '../../../utilities/groupMaterialsByPriceGroup';

import MaterialWrapper from './MaterialWrapper';
import PriceGroupWrapper from './PriceGroupWrapper';

type FeatureHeaderProps = {
    feature: MaterialFeature;
};

type MaterialPanelProps = {
    feature: MaterialFeature;
};

const FeatureHeader = ({ feature }: FeatureHeaderProps): ReactElement => {
    const dispatch = useAppDispatch();

    return (
        <MaterialFeatureHeader
            hash={feature.id}
            name={feature.name}
            optionSearchFilter={''}
            handleSetFilter={(value) =>
                dispatch(
                    setMaterialFilter({
                        featureId: feature.id,
                        filter: value,
                    })
                )
            }
        />
    );
};

const MaterialPanel = ({
    feature,
}: MaterialPanelProps): ReactElement | null => {
    const { t } = useTranslation('product');

    const { isVisible } = useAppSelector((state) =>
        variantGeneratorSelectors.featureStatus(state, feature.id)
    );

    const hasPriceGroups = useMemo(
        () => feature.materials.some((material) => material.priceGroup),
        [feature.materials]
    );

    const visibleOptionIds = useAppSelector((state) =>
        variantGeneratorSelectors.selectFilteredOptions(state, feature.id)
    );

    if (!isVisible) {
        return null;
    }

    const groups = groupMaterialsByPriceGroup(
        feature.materials,
        visibleOptionIds
    );

    return (
        <MaterialFeaturePanel>
            <FeatureHeader feature={feature} />
            <FeedbackCard isDisplayed={groups.length === 0} severity={'info'}>
                {t('variantGenerator.noMatchingResults')}
            </FeedbackCard>
            <MaterialPriceGroupList>
                {groups.map(({ priceGroupId, materials }) =>
                    hasPriceGroups ? (
                        <PriceGroupWrapper
                            key={priceGroupId}
                            priceGroupId={priceGroupId}
                            featureId={feature.id}
                            materials={materials}
                        />
                    ) : (
                        <MaterialList key={priceGroupId}>
                            {materials.map((material) => (
                                <MaterialWrapper
                                    key={material.id}
                                    material={material}
                                    featureId={feature.id}
                                />
                            ))}
                        </MaterialList>
                    )
                )}
            </MaterialPriceGroupList>
        </MaterialFeaturePanel>
    );
};

export default memo(MaterialPanel);
