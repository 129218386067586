import { type MouseEvent, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Badge, ButtonBase, Tooltip } from '@mui/material';

import {
    CheckCircleEmptyIcon,
    CheckCircleIcon,
    FolderOutlinedIcon,
} from '@xeris/components/icons';
import { useActiveDatasetId } from '@xeris/pages/dataset/hooks';
import { useAppDispatch } from '@xeris/reducers';

import { datasetActions } from '../../reducers';

type DatasetPreviewProps = {
    dataset: {
        id: string;
        previewImages: string[];
    };
    size?: number;
};
type ActiveIconProps = {
    isActive: boolean;
};

const ActiveIcon = ({ isActive }: ActiveIconProps): ReactElement => {
    if (isActive) {
        return <CheckCircleIcon color={'primary'} />;
    }

    return (
        <CheckCircleEmptyIcon
            opacity={0}
            color={'icons'}
            sx={{ '&:hover ': { opacity: 1 } }}
            className={'activeIconPlaceholder'}
        />
    );
};

const Preview = ({ dataset, size = 40 }: DatasetPreviewProps): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('dataset');
    const previewImage = dataset.previewImages[0];
    const activeDatasetId = useActiveDatasetId();
    const handleSelectDataset = (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(datasetActions.changeActiveDataset(dataset.id));
    };
    const isActive = dataset.id === activeDatasetId;

    return (
        <Tooltip title={!isActive && t('common.setAsActive')} enterDelay={800}>
            <ButtonBase
                onClick={handleSelectDataset}
                aria-label={t('common.setAsActive')}
                sx={{
                    'borderRadius': '6px',
                    '&:hover ': { '.activeIconPlaceholder': { opacity: 1 } },
                }}
            >
                <Badge
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={<ActiveIcon isActive={isActive} />}
                >
                    {previewImage ? (
                        <Avatar
                            src={previewImage}
                            alt={''}
                            sx={{
                                width: `${size}px`,
                                height: `${size}px`,
                            }}
                            variant="roundedOutline"
                        />
                    ) : (
                        <Avatar
                            alt={''}
                            sx={{
                                width: `${size}px`,
                                height: `${size}px`,
                            }}
                            variant="roundedOutline"
                        >
                            <FolderOutlinedIcon />
                        </Avatar>
                    )}
                </Badge>
            </ButtonBase>
        </Tooltip>
    );
};

export default Preview;
