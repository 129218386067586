import { ensuredImageSource } from '@xeris/utilities';

export const validatePreviewVariant = (
    currentPreview: { [key: string]: string | undefined | null },
    features: string[],
    options: (string | null)[]
): boolean => {
    return features.every((featureId, index) => {
        // Both selectedPreviewOptionId and optionId needs to be of the same type for this to work
        // Hence the nullish coalescing(??) of the variables to ensure we don't get undefined === null

        const selectedPreviewOptionId = currentPreview[featureId] ?? null;
        const optionId = options[index] ?? null;

        return optionId === '*' || selectedPreviewOptionId === optionId;
    });
};

type Image = {
    url: string;
    title: string | null;
};

export const generateImageStack = (
    currentPreview: Record<string, string | null>,
    views:
        | {
              components: {
                  id: string | null;
                  features: string[];
                  variants: {
                      image: string | null;
                      options: (string | null)[];
                  }[];
              }[];
          }[]
        | null,
    productImage?: Image,
    selectedView = 0
): {
    hasPreviewImages: boolean;
    stack: Image[];
    hasPreviewImagesForCurrentCombination: boolean;
} => {
    const fallbackStack = productImage ?? {
        url: ensuredImageSource(undefined),
        title: 'unknown image',
    };

    const fallbackReturn = {
        hasPreviewImages: false,
        stack: [fallbackStack],
        hasPreviewImagesForCurrentCombination: false,
    };

    if (!views) {
        return fallbackReturn;
    }

    const view = views[selectedView];

    if (!view) {
        return fallbackReturn;
    }

    if (
        !view.components.some(
            (component) =>
                component.features.length > 0 && component.variants.length > 0
        )
    ) {
        return fallbackReturn;
    }

    const imageStack = view.components
        .map((component) => {
            const image = component.variants.find((variant) => {
                return validatePreviewVariant(
                    currentPreview,
                    component.features,
                    variant.options
                );
            });

            if (!image?.image) return null; //Not all components are valid for all variants.

            return {
                url: image.image,
                title: component.id ?? null,
            };
        })
        .filter((image) => !!image); //Filter not null.

    const missingImage = imageStack.find((image) => !image.url) !== undefined; //Guard check for variant where image is missing.

    if (imageStack.length === 0 || missingImage) {
        //If image is missing or if no valid components for variant
        return {
            hasPreviewImages: true,
            hasPreviewImagesForCurrentCombination: false,
            stack: [],
        };
    }

    return {
        hasPreviewImages: true,
        hasPreviewImagesForCurrentCombination: true,
        stack: imageStack,
    };
};
