import { gql } from 'graphql-request';

export const addProductsMutation = gql`
    mutation AddProducts(
        $data: DataSetProductsInput!
        $datasetId: String!
        $productIdsToRemove: [String!]
        $removeProducts: Boolean!
    ) {
        dataset {
            addProducts: add_products(dataset: $data) {
                id
                name
                description
                created
                lastModified: last_modified
                lastProductUpdate: last_product_update
                lastExported: last_exported
                previewImages: preview_images(limit: 1)
                brands {
                    id
                    name
                }
                totalCount: total_count
                owner {
                    id
                    name
                    username
                    organization {
                        id
                        name
                        type
                    }
                }
                privilege
                selectedProductData: selected_product_data {
                    cursor
                    nodes {
                        __typename
                        id
                        ... on SelectedMasterProduct {
                            configurationSets: configuration_sets {
                                id
                                name
                            }
                        }
                    }
                }
            }
            removeProducts: remove_products(
                dataset_id: $datasetId
                products: $productIdsToRemove
            ) @include(if: $removeProducts) {
                id
            }
        }
    }
`;

export const removeProductsMutation = gql`
    mutation RemoveProducts(
        $datasetId: String!
        $masterProductIds: [String!]
        $productIds: [String!]
    ) {
        dataset {
            removeProducts: remove_products(
                dataset_id: $datasetId
                master_products: $masterProductIds
                products: $productIds
            ) {
                id
                totalCount: total_count
                missingProductCount: missing_product_count
                selectedProductData: selected_product_data {
                    nodes {
                        __typename
                        id
                        ... on SelectedMasterProduct {
                            configurationSets: configuration_sets {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const removeMissingProductsMutation = gql`
    mutation DeleteMissingProducts($id: String!) {
        dataset {
            removeMissingProducts: remove_missing_products(id: $id) {
                id
                deleted
            }
        }
    }
`;

export const addConfigurationSetMutation = gql`
    mutation AddConfigurationSet(
        $datasetId: String!
        $masterProductId: String!
        $configurationSet: ConfigurationSetInput!
    ) {
        dataset {
            configurationSets: configuration_set {
                create(
                    datasetId: $datasetId
                    masterProductId: $masterProductId
                    configurationSet: $configurationSet
                ) {
                    id
                }
            }
        }
    }
`;

export const updateConfigurationSetMutation = gql`
    mutation UpdateConfigurationSet(
        $id: String!
        $configurationSet: ConfigurationSetInput!
    ) {
        dataset {
            configurationSet: configuration_set {
                update(id: $id, configurationSet: $configurationSet) {
                    id
                }
            }
        }
    }
`;

export const deleteConfigurationSetMutation = gql`
    mutation DeleteConfigurationSet($id: String!) {
        dataset {
            configurationSet: configuration_set {
                delete(id: $id)
            }
        }
    }
`;

export const getMasterProductWithSelectedDataQuery = gql`
    query GetMasterProductWithSelectedData(
        $masterProductId: String!
        $hasDatasetId: Boolean!
        $datasetId: String!
        $dataLanguage: Language!
    ) {
        masterProduct: master_product(
            id: $masterProductId
            language: $dataLanguage
        ) {
            __typename
            id
            name
            isConfigurable: is_configurable
            brand {
                id
                name
            }
            descriptions {
                id
                name
                value
            }
            documents {
                id
                mimeType
                fileName
                title
                type {
                    id
                    label
                }
                url
            }
            images {
                id
                title
                type
                objectFit
                url(sizeFormat: THUMBNAIL)
                id
            }
            models {
                id
                fileName
                type
                title
                url
            }
            featureConfiguration: feature_configuration_v2 {
                __typename
            }
            selectedData: selected_data(data_set_id: $datasetId)
                @include(if: $hasDatasetId) {
                configurationSets: configuration_sets {
                    id
                    name
                    images {
                        id
                    }
                    documents {
                        id
                    }
                    models {
                        id
                    }
                    features {
                        id
                        options {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const getProductsWithSelectedDataQuery = gql`
    query GetProductsWithSelectedData(
        $productIds: [String!]!
        $hasDatasetId: Boolean!
        $datasetId: String!
        $dataLanguage: Language!
    ) {
        products(ids: $productIds, language: $dataLanguage) {
            __typename
            id
            name
            brand {
                id
                name
            }
            description
            documents {
                id
                mimeType
                fileName
                title
                type {
                    id
                    label
                }
                url
            }
            images {
                id
                title
                type
                objectFit
                url(sizeFormat: THUMBNAIL)
                id
            }
            models {
                id
                fileName
                type
                title
                url
            }
            selectedData: selected_data(data_set_id: $datasetId)
                @include(if: $hasDatasetId) {
                images {
                    id
                }
                documents {
                    id
                }
                models {
                    id
                }
            }
        }
    }
`;

export const getProductIdsQuery = gql`
    query GetProductIds($datasetId: String!) {
        dataset(id: $datasetId) {
            id
            selectedMasterProducts: selected_master_products {
                id
            }
            selectedProducts: selected_products {
                id
                data {
                    masterProduct: master_product {
                        id
                    }
                }
            }
        }
    }
`;
