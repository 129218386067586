import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Typography } from '@mui/material';

import { CopyRightIcon } from '@xeris/components/icons';

export const Footer = (): ReactElement => {
    const { t } = useTranslation('navigation');
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                padding: 2,
            }}
        >
            <CopyRightIcon fontSize="small" color="icons" />
            <Typography variant="caption" color={'text.secondary'}>
                Xeris {new Date().getFullYear()}
            </Typography>
            <Box flexGrow={1} />

            <Link
                variant="caption"
                color={'text.secondary'}
                component={RouterLink}
                to={'mailto:feedback@xeris.no'}
                target={'_blank'}
            >
                {t('menu.sendFeedback')}
            </Link>
        </Box>
    );
};
