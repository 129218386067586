import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { loginApi } from '@xeris/pages/login/api/loginApi';
import { type StateType } from '@xeris/types';
import { clearLocalState } from '@xeris/utilities';

import { type LoginSliceType } from './loginTypes';

export const initialState: LoginSliceType = {
    isLoading: false,
    loginFailed: false,
    noCredentials: false,
    isLoggedIn: false,
    isLoggingOut: false,
    impersonating: false,

    username: undefined,
    expires: undefined,
    token: undefined,
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            loginApi.endpoints.login.matchPending,
            (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        );
        builder.addMatcher(
            isAnyOf(
                loginApi.endpoints.login.matchFulfilled,
                loginApi.endpoints.refreshToken.matchFulfilled
            ),
            (state, action) => {
                return {
                    ...initialState,
                    ...action.payload,
                    isLoggedIn: true,
                };
            }
        );
        builder.addMatcher(
            loginApi.endpoints.impersonate.matchFulfilled,
            (state, action) => {
                return {
                    ...initialState,
                    ...action.payload,
                    impersonating: true,
                    isLoggedIn: true,
                };
            }
        );
        builder.addMatcher(
            isAnyOf(
                loginApi.endpoints.login.matchRejected,
                loginApi.endpoints.refreshToken.matchRejected
            ),
            (state, action) => {
                const errorData = action.payload?.data;

                return {
                    ...initialState,
                    noCredentials:
                        typeof errorData === 'string' &&
                        errorData.includes('No cookie'),
                    loginFailed: true,
                    isLoggedIn: false,
                };
            }
        );
        builder.addMatcher(
            loginApi.endpoints.logout.matchPending,
            (state, action) => {
                clearLocalState();

                return {
                    ...initialState,
                    isLoading: true,
                    isLoggingOut: true,
                };
            }
        );
        builder.addMatcher(
            loginApi.endpoints.logout.matchFulfilled,
            (state, action) => {
                return {
                    ...initialState,
                    isLoggingOut: true,
                };
            }
        );
        builder.addMatcher(
            loginApi.endpoints.logout.matchRejected,
            (state, action) => {
                return {
                    ...initialState,
                    isLoggingOut: true,
                };
            }
        );
    },
});

export const loginSelectors = {
    selectIsLoggedIn: (state: StateType): LoginSliceType['isLoggedIn'] =>
        state.login.isLoggedIn,
    selectLoginSlice: (state: StateType): LoginSliceType => state.login,
};

export default loginSlice.reducer;
