import { type ReactElement } from 'react';

import {
    Box,
    TableCell,
    type TableCellProps,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material';

type ColumnId = 'name' | 'supplier' | 'selected' | 'price';

type MaterialSummaryTableHeaderProps = {
    name: string;
    supplier: string;
    selected: string;
    price: string;
    priceCurrency: string | null;
    sortedId: ColumnId;
    sortDirection: TableCellProps['sortDirection'];
    handleSort: (column: ColumnId) => void;
};

export const MaterialSummaryTableHeader = ({
    name,
    supplier,
    selected,
    price,
    priceCurrency,
    sortedId,
    sortDirection,
    handleSort,
}: MaterialSummaryTableHeaderProps): ReactElement => {
    return (
        <TableHead>
            <TableRow>
                <TableCell sortDirection={sortedId === 'name' && sortDirection}>
                    <TableSortLabel
                        active={sortedId === 'name'}
                        direction={
                            sortedId === 'name'
                                ? sortDirection || undefined
                                : 'asc'
                        }
                        onClick={() => handleSort('name')}
                    >
                        <Typography variant={'overline'}>{name}</Typography>
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    sortDirection={sortedId === 'supplier' && sortDirection}
                >
                    <TableSortLabel
                        active={sortedId === 'supplier'}
                        direction={
                            sortedId === 'supplier'
                                ? sortDirection || undefined
                                : 'asc'
                        }
                        onClick={() => handleSort('supplier')}
                    >
                        <Typography variant={'overline'}>{supplier}</Typography>
                    </TableSortLabel>
                </TableCell>
                <TableCell align={'center'}>
                    <Typography variant={'overline'}>{selected}</Typography>
                </TableCell>
                <TableCell
                    sortDirection={sortedId === 'price' && sortDirection}
                    align={'right'}
                >
                    <TableSortLabel
                        active={sortedId === 'price'}
                        direction={
                            sortedId === 'price'
                                ? sortDirection || undefined
                                : 'asc'
                        }
                        onClick={() => handleSort('price')}
                    >
                        <Box>
                            <Typography variant={'overline'}>
                                {price}
                            </Typography>
                            {priceCurrency !== null && (
                                <Typography variant={'overline'}>
                                    ({priceCurrency})
                                </Typography>
                            )}
                        </Box>
                    </TableSortLabel>
                </TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
    );
};
