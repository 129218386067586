import { type ReactElement, useMemo } from 'react';

import { MaterialWrapper as MaterialWrapperComponent } from '@xeris/components';
import { type Material } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';

import { variantGeneratorSelectors } from '../../../reducer';

import { MaterialDetails } from './MaterialDetails';
import { MaterialOptions } from './MaterialOptions';

type MaterialWrapperProps = {
    featureId: string;
    material: Material;
};

const MaterialWrapper = ({
    featureId,
    material,
}: MaterialWrapperProps): ReactElement | null => {
    const optionIds = useMemo(
        () => material.options.map(({ id }) => id),
        [material.options]
    );

    const { isPreviewed, isVisible } = useAppSelector((state) =>
        variantGeneratorSelectors.optionStatuses(state, featureId, optionIds)
    );

    if (!isVisible) {
        return null;
    }

    return (
        <MaterialWrapperComponent isPreviewed={isPreviewed}>
            <MaterialDetails featureId={featureId} material={material} />
            <MaterialOptions material={material} featureId={featureId} />
        </MaterialWrapperComponent>
    );
};

export default MaterialWrapper;
