import { type ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { datasetProductsApi, datasetsApi } from '@xeris/pages/dataset/api';
import { useActiveDataset } from '@xeris/pages/dataset/hooks';

import type { MasterProduct } from '../../types';
import { DeleteConfigurationDialog } from '../ProductEntityCard/ActionButtons/DeleteConfigurationDialog';

import SelectionActions from './SelectActions/SelectionActions';
import SelectProductDataDialog from './SelectProductDataDialog';

export type ModalTypesType =
    | undefined
    | 'add'
    | 'edit'
    | 'remove'
    | 'addRemaining';

export type SelectionType = {
    selectImages: boolean;
    selectDocuments: boolean;
    selectModels: boolean;
};

export type HandleSelectModalType = (modal: ModalTypesType) => void;

type SelectedProducts = {
    id: string;
    images: null | [];
    documents: null | [];
    models: null | [];
};

const generateSelectedProducts = (
    ids: string[],
    selection: SelectionType
): SelectedProducts[] => {
    return ids.map((id) => ({
        id: id,
        images: selection.selectImages ? null : [],
        documents: selection.selectDocuments ? null : [],
        models: selection.selectModels ? null : [],
    }));
};

type SelectedMasterProducts = SelectedProducts & { configuration_sets: [] };

const generateSelectedMasterProducts = (
    ids: string[],
    selection: SelectionType
): SelectedMasterProducts[] => {
    return ids.map((id) => ({
        id: id,
        images: selection.selectImages ? null : [],
        documents: selection.selectDocuments ? null : [],
        models: selection.selectModels ? null : [],
        configuration_sets: [],
    }));
};

const getGenericProductListInDataset = (
    productIds: string[],
    nodeList?: { id: string }[]
): string[] => {
    if (!nodeList) {
        return [];
    }

    return productIds.flatMap((product) =>
        nodeList.some((node) => node.id === product) ? product : []
    );
};

const getProductCount = (
    masterProducts: MasterProduct<'isConfigurable' | 'products'>[],
    nodeList?: { id: string }[]
): number => {
    if (!nodeList) {
        return 0;
    }

    return masterProducts.filter((masterProduct) => {
        if (masterProduct.isConfigurable) {
            return nodeList.some((node) => node.id === masterProduct.id);
        }

        return masterProduct.products.some((product) =>
            nodeList.some((node) => node.id === product.id)
        );
    }).length;
};

const getProductIds = (
    ids: string[],
    type: ModalTypesType,
    productsIdsInDataset: string[],
    isNewDataset: boolean
): string[] => {
    if (isNewDataset) {
        return ids;
    }

    if (type === 'add') {
        return ids;
    }

    if (type === 'addRemaining') {
        return ids.filter((id) => !productsIdsInDataset.includes(id));
    }

    if (type === 'edit') {
        return ids.filter((id) => productsIdsInDataset.includes(id));
    }

    return [];
};

type MultipleMasterProductsSelectorProps = {
    masterProductList: MasterProduct<'isConfigurable' | 'products'>[];
    showCounters?: boolean;
    showAmountInDataset?: boolean;
    showWhenSelectedButton?: boolean;
};

const MultipleMasterProductsSelector = ({
    masterProductList,
    showCounters = true,
    showAmountInDataset = false,
    showWhenSelectedButton = false,
}: MultipleMasterProductsSelectorProps): ReactElement => {
    const { t } = useTranslation('product');
    const [createDataset, createDatasetResult] =
        datasetsApi.useCreateDatasetMutation();

    const [removeProductsFromDataset, removeProductsFromDatasetResult] =
        datasetProductsApi.useRemoveProductsFromDatasetMutation();

    const [addProductsToDataset, addProductsToDatasetResult] =
        datasetProductsApi.useAddProductsToDatasetMutation();

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] =
        useState<boolean>(false);
    const [selectedModal, setSelectedModal] =
        useState<ModalTypesType>(undefined);

    const activeDataset = useActiveDataset();

    const configurableMasterProducts = masterProductList
        .filter((masterProduct) => masterProduct.isConfigurable)
        .map(({ id }) => id);

    const productIdsList = masterProductList.flatMap(
        (masterProduct) => masterProduct.products.map(({ id }) => id) ?? []
    );

    const variantCount =
        productIdsList.length + configurableMasterProducts.length;

    const productCount = masterProductList.length;

    const selectedProductsInDataset = getGenericProductListInDataset(
        productIdsList,
        activeDataset?.selectedProductData.nodeList
    );

    const selectedMasterProductsInDataset = getGenericProductListInDataset(
        configurableMasterProducts,
        activeDataset?.selectedProductData.nodeList
    );

    const selectedVariantCount =
        selectedProductsInDataset.length +
        selectedMasterProductsInDataset.length;

    const selectedProductsCount = getProductCount(
        masterProductList,
        activeDataset?.selectedProductData.nodeList
    );

    const handleCloseDialog = (): void => {
        setSelectedModal(undefined);
        setIsDialogOpen(false);
    };

    const handleSelectProducts = useCallback(
        async (
            dataSelection: SelectionType,
            newDataset: { id?: string | null; name: string }
        ): Promise<void> => {
            const productIdsInDataset =
                activeDataset?.selectedProductData?.nodeList?.map(
                    ({ id }) => id
                ) ?? [];

            const productIds = getProductIds(
                productIdsList,
                selectedModal,
                productIdsInDataset,
                !!newDataset.id
            );

            const masterProductIds = getProductIds(
                configurableMasterProducts,
                selectedModal,
                productIdsInDataset,
                !!newDataset.id
            );

            if (!newDataset.id) {
                await createDataset({
                    name: newDataset.name,
                    description: '',
                    selected_products: generateSelectedProducts(
                        productIds,
                        dataSelection
                    ),
                    selected_master_products: generateSelectedMasterProducts(
                        masterProductIds,
                        dataSelection
                    ),
                }).unwrap();
            } else {
                // Add/change products
                await addProductsToDataset({
                    datasetId: newDataset.id,
                    removeProducts: false,
                    productIdsToRemove: null,
                    data: {
                        id: newDataset.id,
                        selected_products: generateSelectedProducts(
                            productIds,
                            dataSelection
                        ),
                        selected_master_products:
                            generateSelectedMasterProducts(
                                masterProductIds,
                                dataSelection
                            ),
                    },
                }).unwrap();
            }

            handleCloseDialog();
        },
        [
            activeDataset,
            productIdsList,
            selectedModal,
            configurableMasterProducts,
            createDataset,
            addProductsToDataset,
        ]
    );

    const deselectProductsAndMasterProducts = useCallback((): void => {
        removeProductsFromDataset({
            datasetId: activeDataset?.id ?? '',
            productIds: selectedProductsInDataset,
            masterProductIds: selectedMasterProductsInDataset,
        });

        handleCloseDialog();
    }, [
        activeDataset?.id,
        removeProductsFromDataset,
        selectedMasterProductsInDataset,
        selectedProductsInDataset,
    ]);

    useEffect(() => {
        if (selectedModal === 'remove') {
            setIsDeleteDialogOpen(true);
        }
        if (
            selectedModal === 'edit' ||
            selectedModal === 'add' ||
            selectedModal === 'addRemaining'
        ) {
            setIsDialogOpen(true);
        }
    }, [selectedModal, setIsDialogOpen, setIsDeleteDialogOpen]);

    return (
        <>
            <SelectionActions
                handleSelectModal={setSelectedModal}
                productCount={productCount}
                selectedProductsCount={selectedProductsCount}
                selectedVariantCount={selectedVariantCount}
                variantCount={variantCount}
                showCounters={showCounters}
                showAmountInDataset={showAmountInDataset}
                showWhenSelectedButton={showWhenSelectedButton}
            />
            <SelectProductDataDialog
                disabledDatasetMenu={selectedModal !== 'add'}
                isDialogOpen={isDialogOpen}
                handleCloseDialog={handleCloseDialog}
                handleSelectProducts={handleSelectProducts}
                productCount={productCount}
                variantCount={variantCount}
                isLoading={
                    createDatasetResult.isLoading ||
                    removeProductsFromDatasetResult.isLoading ||
                    addProductsToDatasetResult.isLoading
                }
            />
            <DeleteConfigurationDialog
                isOpen={isDeleteDialogOpen}
                handleCloseDialog={() => {
                    setIsDeleteDialogOpen(false);
                    setSelectedModal(undefined);
                }}
                configurationSetName={t(
                    'common.deleteDialog.allConfigurationsAndProducts'
                )}
                titleName={t('common.deleteDialog.titleAll')}
                selectedConfiguration={null}
                masterProductId={undefined}
                handleRemoveAllProducts={() =>
                    deselectProductsAndMasterProducts()
                }
            />
        </>
    );
};

export default MultipleMasterProductsSelector;
