import { baseRtkApi } from '@xeris/reducers/baseApi';
import type { ApiDataError, ApiSourceData } from '@xeris/types/graphql';

import { productTransformers } from '../productTransformers';

import {
    getAllMasterProductsForBrandQuery,
    getAllProductsForMasterProductQuery,
    getConfigurableMasterProductQuery,
    getMasterProductDataErrorsQuery,
    getMasterProductQuery,
    getMasterProductSourceDataQuery,
    getRelatedMasterProductsQuery,
} from './masterProductQueries';
import {
    type ApiGetAllMasterProductsForBrandQuery,
    type ApiGetAllMasterProductsForBrandQueryVariables,
    type ApiGetAllProductsForMasterProductQuery,
    type ApiGetAllProductsForMasterProductQueryVariables,
    type ApiGetConfigurableMasterProductQuery,
    type ApiGetConfigurableMasterProductQueryVariables,
    type ApiGetMasterProductDataErrorsQuery,
    type ApiGetMasterProductDataErrorsQueryVariables,
    type ApiGetMasterProductQuery,
    type ApiGetMasterProductQueryVariables,
    type ApiGetMasterProductSourceDataQuery,
    type ApiGetMasterProductSourceDataQueryVariables,
    type ApiGetRelatedMasterProductsQuery,
    type ApiGetRelatedMasterProductsQueryVariables,
} from './masterProductQueries.gql';

export const masterProductApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllMasterProductsForBrand: builder.query<
            ApiGetAllMasterProductsForBrandQuery,
            ApiGetAllMasterProductsForBrandQueryVariables
        >({
            query: (variables) => ({
                document: getAllMasterProductsForBrandQuery,
                variables,
            }),
        }),
        getMasterProduct: builder.query<
            ReturnType<typeof productTransformers.masterProduct> | null,
            ApiGetMasterProductQueryVariables
        >({
            query: (variables) => ({
                document: getMasterProductQuery,
                variables,
            }),
            transformResponse: (data: ApiGetMasterProductQuery) =>
                productTransformers.masterProduct(data.master_product),
        }),
        getConfigurableMasterProduct: builder.query<
            ApiGetConfigurableMasterProductQuery['master_product'],
            ApiGetConfigurableMasterProductQueryVariables
        >({
            query: (variables) => ({
                document: getConfigurableMasterProductQuery,
                variables: variables,
            }),
            transformResponse: (data: ApiGetConfigurableMasterProductQuery) =>
                data.master_product,
            providesTags: (_, __, args) => [
                { type: 'dataset', id: args.datasetId },
            ],
        }),
        getAllProductsForMasterProduct: builder.query<
            NonNullable<
                ApiGetAllProductsForMasterProductQuery['master_product']
            >['products'],
            ApiGetAllProductsForMasterProductQueryVariables
        >({
            query: (variables) => ({
                document: getAllProductsForMasterProductQuery,
                variables,
            }),
            transformResponse: (data: ApiGetAllProductsForMasterProductQuery) =>
                data.master_product?.products ?? [],
        }),
        getMasterProductSourceData: builder.query<
            ApiSourceData[],
            ApiGetMasterProductSourceDataQueryVariables
        >({
            query: (variables) => ({
                document: getMasterProductSourceDataQuery,
                variables,
            }),
            transformResponse: (data: ApiGetMasterProductSourceDataQuery) =>
                data.master_product?.source_data ?? [],
        }),
        getMasterProductDataErrors: builder.query<
            (ApiDataError | null)[] | null,
            ApiGetMasterProductDataErrorsQueryVariables
        >({
            query: (variables) => ({
                document: getMasterProductDataErrorsQuery,
                variables,
            }),
            transformResponse: (data: ApiGetMasterProductDataErrorsQuery) =>
                data.master_product?.data_errors ?? null,
        }),
        getRelatedMasterProducts: builder.query<
            NonNullable<
                ApiGetRelatedMasterProductsQuery['master_product']
            >['relatedMasterProducts'],
            ApiGetRelatedMasterProductsQueryVariables
        >({
            query: (variables) => ({
                document: getRelatedMasterProductsQuery,
                variables,
            }),
            transformResponse: (data: ApiGetRelatedMasterProductsQuery) =>
                data.master_product?.relatedMasterProducts ?? [],
        }),
    }),
});
