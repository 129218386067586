import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { ProductCard } from '@xeris/components';
import { type MasterProduct, type Product } from '@xeris/pages/product/types';
import { datasheetUrl } from '@xeris/utilities';

import { ActionButtons } from './ActionButtons/ActionButtons';
import { Price } from './Price';
import { getProductDifferencesName } from './utilities';

type ProductEntityCardProps = {
    product?: Product<
        'images' | 'gtin' | 'price' | 'prices' | 'brand' | 'masterProduct'
    > | null;
    masterProduct?: MasterProduct<
        'images' | 'brand' | 'price' | 'prices' | 'isConfigurable' | 'products'
    > | null;
    minimalProduct?: Product<
        'name' | 'brand' | 'masterProduct' | 'distinguishingFeatures' | 'images'
    > | null;
    minimalMasterProduct?: MasterProduct<
        'name' | 'brand' | 'isConfigurable' | 'images' | 'products'
    > | null;
    isActionButtonsAlwaysVisible?: boolean;
};

const ProductEntityCard = ({
    product,
    masterProduct,
    minimalProduct,
    minimalMasterProduct,
    isActionButtonsAlwaysVisible = false,
}: ProductEntityCardProps): ReactElement | null => {
    const { t } = useTranslation('product');
    /* Note:
        The product entity card is adjusted to work for both product list and 
        for datasheet. The only difference is that product lists show the name 
        of the product entity, while datasheet show gtin and price information.
        The ProductEntityCard should be split into two or more subcomponents. 
    */
    const productEntity = masterProduct ?? product;
    const minimalProductEntity = minimalMasterProduct ?? minimalProduct;
    const combinedMasterProduct = masterProduct ?? minimalMasterProduct;

    if (!productEntity && !minimalProductEntity) {
        return null;
    }

    const images =
        productEntity?.images ?? minimalProductEntity?.images ?? null;

    const productDifferencesName = minimalProductEntity
        ? getProductDifferencesName(minimalProductEntity)
        : '';

    return (
        <ProductCard
            url={datasheetUrl(minimalMasterProduct, minimalProduct)}
            images={images}
            actions={
                <ActionButtons
                    product={minimalProduct ?? product}
                    masterProduct={minimalMasterProduct ?? masterProduct}
                    isActionButtonsAlwaysVisible={isActionButtonsAlwaysVisible}
                    size={'large'}
                />
            }
        >
            {productEntity && (
                <>
                    <Typography>
                        {product && `GTIN ${product.gtin ?? '-'}`}
                    </Typography>
                    <Price productEntity={productEntity} />
                </>
            )}
            {minimalProductEntity && (
                <Typography title={productDifferencesName}>
                    {productDifferencesName}
                </Typography>
            )}
            {combinedMasterProduct && (
                <Typography variant="body2">
                    {combinedMasterProduct.isConfigurable
                        ? t('productCard.configurable')
                        : t('dataSelector.numberOfVariants', {
                              count: combinedMasterProduct.products.length,
                          })}
                </Typography>
            )}
        </ProductCard>
    );
};

export default ProductEntityCard;
