import { type ReactElement, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Box, styled } from '@mui/material';

import { BrandCard, ErrorPage, Skeleton } from '@xeris/components';
import { productApi } from '@xeris/pages/product/api';
import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import { useAppSelector } from '@xeris/reducers';
import { search } from '@xeris/utilities';

const Wrapper = styled(Box)(() => ({
    width: '100%',
    display: 'grid',
    justifyContent: 'center',
    gap: '24px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gridAutoRows: '250px',
}));

const SkeletonLoad = (): ReactElement => {
    return (
        <Wrapper>
            <Skeleton count={16} height={'250px'} />
        </Wrapper>
    );
};

const NoBrandsWarning = (): ReactElement | null => {
    const { t } = useTranslation('products');

    return (
        <Box paddingTop={'2.5em'} width="100%">
            <Alert severity="info">{t('common.noBrandsFound')}</Alert>
        </Box>
    );
};

type BrandCardContainerProps = {
    brandId: string;
    children: ReactNode;
};

const BrandCardContainer = ({
    children,
    brandId,
}: BrandCardContainerProps): ReactNode | null => {
    const isVisible = useAppSelector((state) =>
        settingsSelectors.selectIsBrandVisible(state, brandId)
    );

    if (isVisible) return children;

    return null;
};

type BrandListProps = {
    brandFilter: string;
};

export const BrandList = ({ brandFilter }: BrandListProps): ReactElement => {
    const { t } = useTranslation('products');

    const { data, isLoading, isError, refetch } =
        productApi.brand.useGetBrandsQuery({});

    if (isLoading) {
        return <SkeletonLoad />;
    }

    if (isError) {
        return (
            <ErrorPage
                title={t('common.failedToLoadBrands')}
                actionText={t('common.tryAgain')}
                onClick={() => refetch()}
            />
        );
    }

    const brands = (data?.brands ?? [])
        .filter((brand) => search(brandFilter, brand.name))
        .sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );

    if (brands.length === 0) {
        return <NoBrandsWarning />;
    }

    return (
        <Wrapper data-testid={'brandList'}>
            {brands.map((brand) => (
                <BrandCardContainer key={brand.id} brandId={brand.id}>
                    <BrandCard
                        name={brand.name}
                        href={`/Products/${brand.id}`}
                        featureImage={brand.theme?.featureImage ?? undefined}
                        logo={brand.theme?.logo ?? undefined}
                    />
                </BrandCardContainer>
            ))}
        </Wrapper>
    );
};

export default BrandList;
