import { type ReactElement, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import { CenteredPage, Tooltip } from '@xeris/components';
import { ChevronLeftIcon } from '@xeris/components/icons';
import { transformFilterInputToOutput } from '@xeris/pages/ecoSearch/transformers';
import { NoProductsFound } from '@xeris/pages/product/Common/NoProductsFound';
import ProductEntityCard from '@xeris/pages/product/Common/ProductEntityCard/ProductEntityCard';
import { useAppSelector } from '@xeris/reducers';

import { ecoSearchHooks } from '../hooks';
import { ecoSearchSelectors } from '../selectors';
import { type EcoSearchTypes } from '../types';

import { FilterDrawer } from './FilterDrawer';
import { FilterSection } from './FilterSection';

const Header = (): ReactElement => {
    return (
        <Box marginBottom={1}>
            <Typography variant={'h1'}>X-Eco search</Typography>
        </Box>
    );
};

const gpcLookup: Record<EcoSearchTypes.CategoryTypesType, string> = {
    gpc_brick: 'Brick',
    gpc_class: 'Class',
    gpc_family: 'Family',
    gpc_segment: 'Segment',
};

const SubHeader = ({
    gpcElement,
}: {
    gpcElement?: EcoSearchTypes.GenericCategoryType;
}): ReactElement => {
    const productCounts = useAppSelector(
        ecoSearchSelectors.selectProductCounts
    );
    const percentageMatched =
        productCounts.totalProductCount === 0
            ? 0
            : (productCounts.filteredProductCount * 100) /
              productCounts.totalProductCount;
    const roundedPercentageMatched = Math.round(percentageMatched * 100) / 100;

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            padding={2}
            bgcolor={'background.default'}
            gap={2}
            borderRadius={1}
            marginBottom={3}
            border={1}
            borderColor={'divider'}
        >
            <Box display={'flex'} gap={2} alignItems={'center'}>
                <Button
                    component={Link}
                    to="/EcoSearch/Classifications"
                    variant="outlined"
                    color={'secondary'}
                    startIcon={<ChevronLeftIcon fontSize="small" />}
                >
                    {'Edit search'}
                </Button>
                <div>
                    <Box display={'inline-flex'} gap={0.5}>
                        <Typography>
                            {gpcLookup[gpcElement?.type ?? 'gpc_segment']}
                        </Typography>
                        <Typography>{'•'}</Typography>
                        <Typography fontWeight={'bold'}>
                            {gpcElement?.id}
                        </Typography>
                    </Box>
                    <Link to="/EcoSearch/Classifications">
                        <Typography
                            variant={'h6'}
                            fontWeight={'bold'}
                            component={'h2'}
                            color={'primary'}
                        >
                            {gpcElement?.name}
                        </Typography>
                    </Link>
                </div>
            </Box>
            <Box display={'flex'} gap={3}>
                <div>
                    <Tooltip
                        title={
                            'Percentage of products within filter parameters'
                        }
                    >
                        <>
                            <Typography
                                variant={'h4'}
                                component={'p'}
                                color={'primary'}
                            >
                                {roundedPercentageMatched}%
                            </Typography>
                            <Typography>{'Range'}</Typography>
                        </>
                    </Tooltip>
                </div>
                <Box borderLeft={2} borderColor={'divider'} />
                <Box>
                    <Typography
                        variant={'h4'}
                        component={'p'}
                        color={'primary'}
                    >
                        {productCounts.filteredProductCount}
                    </Typography>
                    <Typography>{'Filtered'}</Typography>
                </Box>
                <Box borderLeft={2} borderColor={'divider'} />
                <Box>
                    <Typography
                        variant={'h4'}
                        component={'p'}
                        color={'primary'}
                    >
                        {productCounts.totalProductCount}
                    </Typography>
                    <Typography>{'Available'}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

const ProductBrowser = (): ReactElement => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleSetIsDrawerOpen = (newValue: boolean): void => {
        setIsDrawerOpen(newValue);
    };

    const { classificationId = '' } = useParams();
    const gpcElement = useAppSelector((state) =>
        ecoSearchSelectors.selectGpcElementWithId(state, classificationId)
    );

    const stateFilters = useAppSelector(ecoSearchSelectors.selectFilters);

    const [filters, setFilters] = useState<
        Record<string, EcoSearchTypes.FilterType>
    >({});

    useEffect(() => {
        const filtersMap = Object.fromEntries(
            stateFilters.map((filter) => [filter.id, filter])
        );
        setFilters(filtersMap);
    }, [stateFilters]);

    ecoSearchHooks.api.queries.useGetFilteredProductsQuery(
        {
            filters: Object.values(filters)
                .filter((it) => it.isActive)
                .map((filter) => transformFilterInputToOutput(filter)),
            type: gpcElement?.type ?? 'gpc_segment',
            categoryId: gpcElement?.id ?? '',
        },
        { refetchOnMountOrArgChange: true, skip: !gpcElement }
    );

    const products = useAppSelector(ecoSearchSelectors.selectFilteredProducts);

    const handleRemoveAllActiveFilters = (): void => {
        const filtersMap = Object.fromEntries(
            stateFilters.map((filter) => [filter.id, filter])
        );
        setFilters(filtersMap);
    };

    const handleRemoveActiveFilter = (filterId: string): void => {
        const modifiedNewFilter = {
            ...filters[filterId],
            value: null,
            isActive: false,
        } as EcoSearchTypes.FilterType;

        setFilters({
            ...filters,
            [filterId]: modifiedNewFilter,
        });
    };

    const handleAddActiveFilter = (
        newFilter: EcoSearchTypes.FilterType
    ): void => {
        const modifiedNewFilter = {
            ...filters[newFilter.id],
            value: newFilter.value,
            isActive: true,
        } as EcoSearchTypes.FilterType;

        setFilters({
            ...filters,
            [newFilter.id]: modifiedNewFilter,
        });
    };

    const foundProducts = products?.length && products.length > 0;

    return (
        <CenteredPage>
            <section>
                <Header />
                <SubHeader gpcElement={gpcElement} />
            </section>
            <FilterSection
                handleSetIsDrawerOpen={handleSetIsDrawerOpen}
                filters={filters}
                handleRemoveFilter={handleRemoveActiveFilter}
            />

            {foundProducts ? (
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns:
                            'repeat(auto-fill, minmax(200px, 1fr))',
                        gap: 3,
                        paddingTop: 2,
                    }}
                >
                    {products?.map((product) => (
                        <ProductEntityCard
                            key={product.id}
                            minimalProduct={
                                product.__typename === 'Product'
                                    ? product
                                    : undefined
                            }
                            minimalMasterProduct={
                                product.__typename === 'MasterProduct'
                                    ? {
                                          ...product,
                                          products: product.products.map(
                                              ({ id }) => ({
                                                  id,
                                                  idNumbers: [],
                                                  markets: [],
                                              })
                                          ),
                                      }
                                    : undefined
                            }
                        />
                    ))}
                </Box>
            ) : (
                <NoProductsFound overrideLink="/EcoSearch" />
            )}

            <FilterDrawer
                isDrawerOpen={isDrawerOpen}
                handleSetIsDrawerOpen={handleSetIsDrawerOpen}
                handleAddActiveFilter={handleAddActiveFilter}
                handleRemoveAllActiveFilters={handleRemoveAllActiveFilters}
                handleRemoveActiveFilter={handleRemoveActiveFilter}
                filters={filters}
            />
        </CenteredPage>
    );
};

export default ProductBrowser;
