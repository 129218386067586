import { type ReactElement, useMemo } from 'react';

import {
    Box,
    Card,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

import { CheckIcon, CloseIcon } from '@xeris/components/icons';
import { AttributeName } from '@xeris/pages/imports/pages/Mapping/components/AttributeName';
import {
    removeAttributeMapping,
    selectAttributeStatus,
} from '@xeris/pages/imports/reducers/importMappingSlice';
import type { AttributeType } from '@xeris/pages/imports/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

type AttributeListItemProps = {
    attribute: AttributeType;
    handleSelectType: (entityType: AttributeType | null) => void;
};

export const AttributeListItem = ({
    attribute,
    handleSelectType,
}: AttributeListItemProps): ReactElement | null => {
    const dispatch = useAppDispatch();

    const path = useMemo(
        () => attribute.path.map((id) => ({ attributeId: id, index: 0 })),
        [attribute.path]
    );

    const { isVisible } = useAppSelector((state) =>
        selectAttributeStatus(state, path)
    );

    if (!isVisible && !attribute.required) return null;

    return (
        <Card
            variant={'outlined'}
            component={ListItem}
            key={attribute.id}
            disablePadding
            sx={{ flex: '0 1 49%' }}
            secondaryAction={
                <IconButton
                    edge={'end'}
                    disabled={attribute.required}
                    onClick={(event) => {
                        event.stopPropagation();

                        dispatch(removeAttributeMapping({ path }));
                    }}
                >
                    <CloseIcon />
                </IconButton>
            }
        >
            <ListItemButton
                onClick={(event) => {
                    event.stopPropagation();
                    handleSelectType(attribute);
                }}
            >
                <ListItemText
                    primary={
                        <>
                            <AttributeName attribute={attribute} />
                        </>
                    }
                    sx={{ flex: '0 1 100%' }}
                    primaryTypographyProps={{ noWrap: true, flexShrink: 0 }}
                />
                <ListItemIcon>
                    <CheckIcon fontSize={'small'} />
                </ListItemIcon>
                <Box sx={{ flex: '0 2 50%' }} />
            </ListItemButton>
        </Card>
    );
};
