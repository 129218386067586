import { type ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { TableCellProps } from '@mui/material';

import {
    MaterialSummary,
    MaterialSummaryHeader,
    MaterialSummaryTable,
    MaterialSummaryTableHeader,
    MaterialSummaryTableOptionsList,
    MaterialSummaryTableRow,
} from '@xeris/components';
import {
    type Material,
    type MaterialFeature,
} from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { selectOptions, variantGeneratorSelectors } from '../../../reducer';

import { MaterialOptionCard } from './MaterialOptions';

type MaterialRowProps = {
    material: Material;
    featureId: string;
};

type MaterialPanelProps = {
    feature: MaterialFeature;
};

const sortAlphabetical = (a?: string | number, b?: string | number): number =>
    (a ?? 0) > (b ?? 0) ? 1 : -1;

const HEADER_LOOKUP = {
    name: (a: Material, b: Material): number =>
        sortAlphabetical(a.name, b.name),
    supplier: (a: Material, b: Material): number =>
        sortAlphabetical(a.supplier?.name, b.supplier?.name),
    selected: (a: Material, b: Material): number =>
        sortAlphabetical(a.supplier?.name, b.supplier?.name),
    price: (a: Material, b: Material): number =>
        sortAlphabetical(a.price?.value, b.price?.value),
} as const;

const HEADER = ['name', 'supplier', 'selected', 'price'] as const;

type HeaderColumnType = (typeof HEADER)[number];

const MaterialRow = ({
    material,
    featureId,
}: MaterialRowProps): ReactElement | null => {
    const { t } = useTranslation('product');

    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const optionIds = useMemo(
        () => material.options.map(({ id }) => id),
        [material.options]
    );

    const { isPreviewed, isSelected } = useAppSelector((state) =>
        variantGeneratorSelectors.optionStatuses(state, featureId, optionIds)
    );

    const { selected } = useAppSelector((state) =>
        variantGeneratorSelectors.selectedOptionCountByIds(
            state,
            featureId,
            optionIds
        )
    );

    const handleMaterialClick = (): void => {
        setIsExpanded((value) => !value);
    };

    const handleToggleAllOptions = (): void => {
        dispatch(
            selectOptions({
                featureId: featureId,
                optionIds: material.options.map((option) => option.id),
                newValue: false,
            })
        );
    };

    if (!isSelected) {
        return null;
    }

    return (
        <>
            <MaterialSummaryTableRow
                materialName={material.name}
                supplierName={material.supplier?.name ?? null}
                selectedCount={selected}
                priceValue={material.price?.value ?? null}
                handleClick={handleMaterialClick}
                handleRemove={handleToggleAllOptions}
                isPreviewed={isPreviewed}
                removeMaterialTooltip={t(
                    'variantGenerator.materialSummary.removeMaterial'
                )}
            />
            <MaterialSummaryTableOptionsList isExpanded={isExpanded}>
                {material.options.map((option) => (
                    <MaterialOptionCard
                        key={option.id}
                        option={option}
                        featureId={featureId}
                    />
                ))}
            </MaterialSummaryTableOptionsList>
        </>
    );
};

const MaterialPanel = ({
    feature,
}: MaterialPanelProps): ReactElement | null => {
    const { t } = useTranslation('product');

    const [headerSortingId, setHeaderSortingId] =
        useState<HeaderColumnType>('name');
    const [sortingDirection, setSortingDirection] =
        useState<TableCellProps['sortDirection']>('asc');

    const { isSelected } = useAppSelector((state) =>
        variantGeneratorSelectors.featureStatus(state, feature.id)
    );

    const price = useAppSelector(variantGeneratorSelectors.selectPrices);

    if (!isSelected) {
        return null;
    }

    const handleSortClick = (headerId: HeaderColumnType): void => {
        if (headerId === headerSortingId) {
            if (sortingDirection === 'desc') {
                setSortingDirection('asc');
            } else {
                setSortingDirection('desc');
            }
        } else {
            setHeaderSortingId(headerId);
            setSortingDirection('asc');
        }
    };

    const selectedHeader = HEADER_LOOKUP[headerSortingId];
    const sortedMaterials = Object.values(feature.materials);

    if (selectedHeader) {
        sortedMaterials.sort(selectedHeader);
        if (sortingDirection === 'desc') sortedMaterials.reverse();
    }

    return (
        <MaterialSummary>
            <MaterialSummaryHeader name={feature.name} />
            <MaterialSummaryTable>
                <MaterialSummaryTableHeader
                    name={t('variantGenerator.materialSummary.name')}
                    supplier={t('variantGenerator.materialSummary.supplier')}
                    selected={t('variantGenerator.materialSummary.selected')}
                    price={t('variantGenerator.materialSummary.price')}
                    priceCurrency={price?.currency ?? null}
                    sortDirection={sortingDirection}
                    sortedId={headerSortingId}
                    handleSort={handleSortClick}
                />
                {sortedMaterials.map((material) => (
                    <MaterialRow
                        key={material.id}
                        material={material}
                        featureId={feature.id}
                    />
                ))}
            </MaterialSummaryTable>
        </MaterialSummary>
    );
};

export default MaterialPanel;
