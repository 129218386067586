import type { AttributeType } from '@xeris/pages/imports/types';

type AttributeGroup = {
    section: { id: string; name: string };
    attributes: AttributeType[];
};

export const groupAttributes = (
    attributes: AttributeType[]
): AttributeGroup[] => {
    const grouped: Record<string, AttributeGroup> = {};
    const sections: Record<string, { id: string; name: string }> = {};

    attributes.forEach((attribute) => {
        if (attribute.section) {
            if (!grouped[attribute.section.id]) {
                grouped[attribute.section.id] = {
                    section: attribute.section,
                    attributes: [attribute],
                };
            } else {
                grouped[attribute.section.id].attributes.push(attribute);
            }

            sections[attribute.section.id] = attribute.section;
        }
    });

    return Object.values(grouped);
};
