import { type CSSProperties, memo, type ReactElement } from 'react';
import { Draggable, type DraggableProvided } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { areEqual } from 'react-window';

import { Box, IconButton, lighten, Tooltip } from '@mui/material';

import { Image, Typography } from '@xeris/components';
import {
    ChevronDownIcon,
    CloseIcon,
    DragDotsIcon,
    EyeOutlineOffIcon,
    EyeOutlineOnIcon,
} from '@xeris/components/icons';
import { useAppDispatch } from '@xeris/reducers';

import {
    removeCategorizedProduct,
    toggleCategoryProductVisibility,
    toggleCategoryVisibility,
} from '../reducers/categorizationSlice';
import { type CategorizedCardData } from '../reducers/types';

type ProductItemToolbarProps = {
    listElement: CategorizedCardData;
    index: number;
};

const ProductItemToolbar = ({
    listElement,
    index,
}: ProductItemToolbarProps): ReactElement => {
    const { t } = useTranslation('dataset');
    const dispatch = useAppDispatch();

    return (
        <>
            {listElement.type === 'category' &&
                listElement.immediateProductCount > 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexBasis: '70px',
                        }}
                    >
                        <Tooltip
                            title={
                                listElement.isHidden
                                    ? t('categorization.showProducts')
                                    : t('categorization.hideProducts')
                            }
                            enterDelay={500}
                            enterNextDelay={500}
                        >
                            <IconButton
                                onClick={(): void => {
                                    dispatch(
                                        toggleCategoryProductVisibility({
                                            categoryId: listElement.id,
                                        })
                                    );
                                }}
                            >
                                {listElement.isHidden ? (
                                    <EyeOutlineOffIcon />
                                ) : (
                                    <EyeOutlineOnIcon />
                                )}
                            </IconButton>
                        </Tooltip>
                        <Typography>
                            {listElement.immediateProductCount}
                        </Typography>
                    </Box>
                )}
            {listElement.type === 'category' &&
                listElement.productCount > 0 && (
                    <Typography sx={{ flexBasis: '42px', textAlign: 'end' }}>
                        {listElement.productCount}
                    </Typography>
                )}
            {(listElement.type === 'product' ||
                (listElement.type === 'category' &&
                    listElement.productCount > 0)) && (
                <Tooltip
                    title={t('categorization.removeFromCategory')}
                    enterDelay={500}
                    enterNextDelay={500}
                >
                    <IconButton
                        onClick={(): void => {
                            dispatch(removeCategorizedProduct({ index }));
                        }}
                    >
                        <CloseIcon fontSize={'small'} />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

type ProductItemProps = {
    provided: DraggableProvided;
    listElement: CategorizedCardData;
    index: number;
    isDragging?: boolean;
    style?: CSSProperties;
};

export const ProductItem = ({
    provided,
    listElement,
    index,
    isDragging,
    style,
}: ProductItemProps): ReactElement => {
    const { t } = useTranslation('dataset');
    const dispatch = useAppDispatch();

    return (
        <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{ ...style, ...provided.draggableProps?.style }}
            ref={provided.innerRef}
        >
            <Box
                sx={(theme) => ({
                    'display': 'flex',
                    'padding': 1,
                    'border': 1,
                    'borderColor': 'divider',
                    'alignItems': 'center',
                    'height': 40,
                    'marginLeft': listElement.indentationLevel * 2,
                    'backgroundColor': isDragging
                        ? lighten(theme.palette.primary.main, 0.8)
                        : 'background.paper',
                    'gap': 1,
                    '&:hover':
                        listElement.type === 'product'
                            ? {
                                  backgroundColor: lighten(
                                      theme.palette.primary.main,
                                      0.8
                                  ),
                              }
                            : {},
                })}
            >
                {listElement.type === 'category' ? (
                    listElement.hasChildren ? (
                        <Tooltip
                            title={
                                listElement.isCollapsed
                                    ? t('categorization.expand')
                                    : t('categorization.collapse')
                            }
                            enterDelay={500}
                            enterNextDelay={500}
                        >
                            <IconButton
                                onClick={(): void => {
                                    dispatch(
                                        toggleCategoryVisibility({
                                            path: listElement.path,
                                        })
                                    );
                                }}
                            >
                                <Box
                                    sx={{
                                        rotate: listElement.isCollapsed
                                            ? '0deg'
                                            : '180deg',
                                    }}
                                >
                                    <ChevronDownIcon fontSize={'small'} />
                                </Box>
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Box sx={{ width: '36px', height: '36px' }} />
                    )
                ) : (
                    <Box>
                        <DragDotsIcon fontSize={'small'} />
                    </Box>
                )}
                {listElement.type === 'product' &&
                    (listElement.image ? (
                        <Image
                            src={listElement.image.url}
                            objectFit={listElement.image.objectFit}
                            alt={''}
                            height={'100%'}
                            width={'22px'}
                        />
                    ) : (
                        <Box sx={{ width: '22px', height: '22px' }} />
                    ))}
                <Typography sx={{ flexGrow: 1 }}>{listElement.name}</Typography>
                <ProductItemToolbar listElement={listElement} index={index} />
            </Box>
        </div>
    );
};

type ProductListItemProps = {
    data: CategorizedCardData[];
    index: number;
    style?: CSSProperties;
};

const ProductListItem = ({
    data: listElements,
    style,
    index,
}: ProductListItemProps): ReactElement => {
    const listElement = listElements[index];

    return (
        <Draggable
            draggableId={listElement.path}
            index={index}
            key={listElement.id}
            isDragDisabled={listElement.type === 'category'}
        >
            {(provided): ReactElement => (
                <ProductItem
                    provided={provided}
                    listElement={listElement}
                    index={index}
                    style={style}
                />
            )}
        </Draggable>
    );
};

export default memo(ProductListItem, areEqual);
