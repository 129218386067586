import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, type SxProps, Typography } from '@mui/material';

type ProductAndVariantsCounterProps = {
    productCount: number;
    selectedProductCount?: number;
    variantCount: number;
    selectedVariantCount?: number;
    fontWeight?: 'medium' | 'normal';
    sx?: SxProps;
};

const ProductAndVariantsCounter = ({
    productCount,
    selectedProductCount,
    variantCount,
    selectedVariantCount,
    fontWeight,
    sx,
}: ProductAndVariantsCounterProps): ReactElement => {
    const { t } = useTranslation('product');

    return (
        <Stack
            justifyContent={'flex-start'}
            gap={2}
            overflow={'hidden'}
            flexShrink={0}
            flexGrow={0}
            sx={sx}
        >
            <Typography
                fontWeight={fontWeight}
                sx={sx}
                minWidth={'140px'}
                noWrap
            >
                {selectedProductCount !== undefined
                    ? t('dataSelector.numberOfSelectedProducts', {
                          count: productCount,
                          selected: selectedProductCount,
                      })
                    : t('dataSelector.numberOfProducts', {
                          count: productCount,
                      })}
            </Typography>
            {variantCount !== 0 && (
                <Typography
                    fontWeight={fontWeight}
                    sx={sx}
                    minWidth={'140px'}
                    noWrap
                >
                    {selectedVariantCount !== undefined
                        ? t('dataSelector.numberOfSelectedVariants', {
                              selected: selectedVariantCount,
                              count: variantCount,
                          })
                        : t('dataSelector.numberOfVariants', {
                              count: variantCount,
                          })}
                </Typography>
            )}
        </Stack>
    );
};

export default ProductAndVariantsCounter;
