import { type Expression } from '@xeris/pages/imports/types';

export const createExpression = (
    type: 'get' | 'value' | 'advanced' | string
): Expression => {
    if (type === 'get') {
        return {
            operator: 'get',
            path: '',
        };
    }
    if (type === 'value') {
        return {
            operator: 'value',
            value: '',
        };
    }

    if (type === 'advanced') {
        return { operator: '' };
    }

    throw new Error('Invalid expression type ' + type);
};
