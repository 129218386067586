import { type HTMLAttributes, type ReactElement, type ReactNode } from 'react';
import {
    type Control,
    type FieldValues,
    type Path,
    useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    Autocomplete,
    type AutocompleteProps,
    Chip,
    FormControl,
    FormHelperText,
    TextField,
} from '@mui/material';
import { type AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';

import { InputLabelTop } from '../InputLabelTop/InputLabelTop';

type AutocompleteMultipleInputChipsProps<T extends FieldValues> = {
    fieldName: Path<T>;
    label?: string;
    showError?: boolean;
    control: Control<T>;
    options?: string[];
    placeholder: string;
    size?: AutocompleteProps<T, true, false, false>['size'];

    renderOption?: (
        props: HTMLAttributes<HTMLLIElement>,
        state: AutocompleteRenderOptionState
    ) => ReactNode;
};

export const AutocompleteMultipleInputChips = <T extends FieldValues>({
    fieldName,
    label,
    showError = false,
    control,
    options,
    placeholder,
    size,
}: AutocompleteMultipleInputChipsProps<T>): ReactElement | null => {
    const { t } = useTranslation('common');

    const {
        field: { ref, onChange, value, ...field },
        fieldState: { error },
    } = useController({
        name: fieldName,
        control,
    });

    return (
        <FormControl fullWidth error={!!error}>
            <InputLabelTop
                labelPlacement={'top'}
                label={label}
                required={false}
                control={
                    <Autocomplete
                        clearIcon={false}
                        disablePortal
                        options={options || []}
                        freeSolo
                        autoSelect
                        clearOnBlur
                        multiple
                        renderTags={(currentValue, props) =>
                            currentValue?.map((option, index) => {
                                return (
                                    <Chip
                                        label={option}
                                        {...props({ index })}
                                        key={option}
                                    />
                                );
                            })
                        }
                        fullWidth
                        defaultValue={options || []}
                        onChange={(_, data): void => {
                            onChange(
                                data
                                    .flatMap((d) => d.trim().split(/[\s,]+/))
                                    .filter((d) => !!d)
                            );
                        }}
                        value={value || []}
                        size={size}
                        renderInput={(params): ReactElement => {
                            return (
                                <TextField
                                    {...params}
                                    {...field}
                                    error={Boolean(error)}
                                    inputRef={ref}
                                    placeholder={placeholder}
                                />
                            );
                        }}
                    />
                }
            />
            {showError && (
                <FormHelperText>
                    {Array.isArray(error) &&
                        error.length > 0 &&
                        t('formErrors.invalidDomains', {
                            domains: error
                                .map((e, index) => ({
                                    error: e,
                                    value: value[index],
                                }))
                                .filter((fieldError) => fieldError.error)
                                .map((fieldError) => fieldError.value)
                                .join(', '),
                        })}
                </FormHelperText>
            )}
        </FormControl>
    );
};
