import { JSFeature } from 'js-feature-configuration';

import { type FeatureConfiguration } from '../types/featureConfiguration';
import {
    type FeatureSelection,
    type Visibility,
} from '../types/variantGeneratorSliceTypes';

import { getFeatureConfigurator } from './getFeatureConfigurator';

export const ensureValidSelection = (
    featureConfiguration: FeatureConfiguration,
    selections?: FeatureSelection | null
): {
    selectedOptions: FeatureSelection;
    visibility: Visibility;
} => {
    let currentSelections = Object.entries(selections ?? {})
        .filter(
            (selection): selection is [string, { [key: string]: true }] =>
                selection[1] !== null
        )
        .map(([key, value]) => new JSFeature(key, Object.keys(value)));

    const evaluator = getFeatureConfigurator(featureConfiguration);

    // Compute visible features and options
    // This will "always" be defined, unless there is something strange with the data
    const visibility = evaluator.visibility(currentSelections);

    // Remove invalid selections
    currentSelections = currentSelections
        .filter((selection) =>
            visibility?.find((vis) => vis.featureId === selection.featureId)
        )
        .map((selection) => {
            const visible = visibility?.find(
                (vis) => vis.featureId === selection.featureId
            );

            const values = selection.values.filter((val) =>
                visible?.values.includes(val)
            );

            if (values.length === 0) {
                return null;
            }

            return new JSFeature(selection.featureId, values);
        })
        .filter((selection) => selection !== null);

    return {
        visibility: Object.fromEntries(
            visibility?.map((feature) => [
                feature.featureId,
                feature.values.filter(
                    (value) => value !== null && value !== undefined
                ),
            ]) ?? []
        ),
        selectedOptions: Object.fromEntries(
            currentSelections.map((feature) => [
                feature.featureId,
                Object.fromEntries(
                    feature.values.map((optionId) => [optionId, true])
                ),
            ])
        ),
    };
};
