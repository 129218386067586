import { type ReactElement, type ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { SearchBar } from '@xeris/components';

type ProductSearchBarProps = {
    productCount?: number;
    showResult?: boolean;
    children?: ReactNode;
};

const ProductSearchBar = ({
    productCount,
    showResult,
    children,
}: ProductSearchBarProps): ReactElement => {
    const { t } = useTranslation('products');

    const [searchParam, setSearchParam] = useSearchParams([
        ['searchTerm', ''],
        ['tab', 'search'],
    ]);

    const filterTerm = searchParam.get('searchTerm') ?? '';

    const updateFilter = useCallback(
        (newFilter: string) => {
            setSearchParam(
                (searchParameters) => {
                    searchParameters.set('searchTerm', newFilter);
                    return searchParameters;
                },
                {
                    replace: true,
                }
            );
        },
        [setSearchParam]
    );

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexShrink: 0,
                gap: 2,
                marginBlock: showResult ? 2 : 3,
                justifyContent: showResult ? 'initial' : 'space-around',
            }}
        >
            <Box sx={{ width: showResult ? 380 : 440 }}>
                <SearchBar
                    inputLabel={t('common.search')}
                    onSubmit={updateFilter}
                    filter={filterTerm}
                />
            </Box>

            {showResult && (
                <>
                    <Box sx={{ display: 'flex', gap: 0.5 }}>
                        <Typography
                            sx={{ fontWeight: 'bold' }}
                            component={'span'}
                        >
                            {t('common.number', { count: productCount })}
                        </Typography>
                        <Typography component={'span'}>
                            {t('search.products', { count: productCount })}
                        </Typography>
                    </Box>
                    {children}
                </>
            )}
        </Box>
    );
};

export default ProductSearchBar;
