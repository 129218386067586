import { type ReactElement, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';

import {
    BackButton,
    CenteredPage,
    ErrorPage,
    MutationSnackbars,
} from '@xeris/components';
import {
    getFullMapping,
    initializeMapping,
} from '@xeris/pages/imports/reducers/importMappingSlice';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { importsApi } from '../../api/importsApi';
import { type AttributeType } from '../../types';
import { getEntityTree } from '../../utilities/getEntityTree';

import { AttributeList } from './components/AttributeList';
import { AttributeForm } from './forms/Attribute/AttributeForm';

export const CreateMapping = (): ReactElement => {
    const dispatch = useAppDispatch();
    const { state } = useLocation();
    const navigate = useNavigate();

    const [selectedType, setSelectedType] = useState<AttributeType | null>(
        null
    );

    useEffect(() => {
        dispatch(initializeMapping());
    }, [dispatch]);

    const {
        data: productDefinition,
        isLoading,
        isError,
    } = importsApi.useGetOntologyDefinitionQuery({
        entityId: state.targetEntityTypeId,
    });

    const [
        createMapping,
        {
            isLoading: isCreatingMapping,
            isSuccess: createMappingSuccess,
            isError: createMappingError,
        },
    ] = importsApi.useCreateMappingConfigurationMutation();

    const { data: sourcesData } = importsApi.useGetSourcesQuery({});

    const fields =
        sourcesData?.sources
            ?.find(({ id }) => id === state.sourceId)
            ?.schema.find(
                (schema) => schema.documentType === state.documentType
            )
            ?.properties.map((property) => ({
                label: property.name,
                path: property.type,
            })) ?? [];

    const productSchema = useMemo(
        () =>
            getEntityTree(
                state.targetEntityTypeId,
                productDefinition?.entitySchema,
                productDefinition?.sectionLayouts
            ),
        [productDefinition, state.targetEntityTypeId]
    );

    const mapping = useAppSelector(getFullMapping);

    if (isLoading) {
        return <Skeleton height={200} />;
    }

    if (isError) {
        return <ErrorPage title={'An error occurred'} />;
    }

    if (!productSchema) {
        return <ErrorPage title={'Not found'} />;
    }

    const handleCreateMapping = async (runMapping: boolean): Promise<void> => {
        await createMapping({
            sourceId: state.sourceId,
            runMapping: runMapping,
            mappingInput: {
                sourceId: state.sourceId,
                documentType: state.documentType,
                mapping: {
                    supplementarySources: [],
                    targetEntityTypeId: state.targetEntityTypeId,
                    attributes: mapping,
                },
            },
        }).unwrap();

        navigate('../mapping');
    };

    return (
        <>
            <CenteredPage>
                <BackButton to={'../mapping'}>{'Mappings'}</BackButton>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    sx={{ paddingBottom: 1 }}
                >
                    <Typography variant={'h1'}>
                        {'Mapping: ' + productSchema.name}
                    </Typography>
                    <Stack gap={1}>
                        <LoadingButton
                            loading={isCreatingMapping}
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={() => handleCreateMapping(false)}
                        >
                            {'Create mapping'}
                        </LoadingButton>
                        <LoadingButton
                            loading={isCreatingMapping}
                            variant={'contained'}
                            onClick={() => handleCreateMapping(true)}
                        >
                            {'Create and run mapping'}
                        </LoadingButton>
                    </Stack>
                </Box>
                <Divider />
                <AttributeList
                    attributes={productSchema.attributes}
                    handleSelectType={setSelectedType}
                />
            </CenteredPage>
            <AttributeForm
                isOpen={!!selectedType}
                handleClose={() => setSelectedType(null)}
                type={selectedType}
                fields={fields}
            />
            <MutationSnackbars
                isSuccess={createMappingSuccess}
                successText={'Created mapping'}
                isError={createMappingError}
                errorText={'Create mapping failed'}
            />
        </>
    );
};
