import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Step, StepLabel } from '@mui/material';

import { DialogTitleWithStepper } from '@xeris/components';

import { steps } from '../steps';

type HeaderProps = {
    activeStep: number;
};

export const Header = ({ activeStep }: HeaderProps): ReactElement => {
    const { t } = useTranslation('imports');

    return (
        <DialogTitleWithStepper
            title={t('createSource.newSource')}
            activeStep={activeStep}
        >
            {steps.map(({ id }) => (
                <Step key={id}>
                    <StepLabel>{t(`sources.steps.${id}`)}</StepLabel>
                </Step>
            ))}
        </DialogTitleWithStepper>
    );
};
