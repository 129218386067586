import { Box, styled } from '@mui/material';

import { Link } from '../Link/Link';

export const MasterProductSearchCardWrapper = styled(Box)(({ theme }) => ({
    'borderRadius': theme.shape.borderRadius * 1.5,
    'borderWidth': 1,
    'borderColor': theme.palette.divider,
    'borderStyle': 'solid',
    '&:hover': {
        '.action': {
            opacity: 1,
        },
    },
}));

export const MasterProductLinkCard = styled(Link)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius * 1.5,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
}));

export const MasterProductInfo = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius * 1.5,
    gap: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
}));

export const MasterProductChildWrapper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius * 1.5,
    borderColor: theme.palette.divider,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderWidth: 1,
    borderTopStyle: 'solid',
    gap: theme.spacing(8),
    alignItems: 'center',
    flexGrow: 1,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 8,
    backgroundColor: '#FAFBFC',
}));

export const MasterProductMoreWrapper = styled(Box)(({ theme }) => ({
    paddingBottom: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius * 1.5,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    position: 'sticky',
    bottom: theme.spacing(2),
    backgroundColor: '#FAFBFC',
}));

export const ProductSearchCardWrapper = styled(Box)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius * 1.5,
    borderWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    padding: theme.spacing(1.5),
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
}));

export const SearchHeaderWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2, 1.5),
    gap: theme.spacing(4),
}));
