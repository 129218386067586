import { type ReactElement, type ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Stack } from '@mui/material';

import LightBox from 'yet-another-react-lightbox';

import { Image } from '../Image/Image';
import { type SimpleImageType } from '../ProductCard/ProductCard';

type ProductListItemProps = {
    images: SimpleImageType[] | null;
    url?: string | null;
    children?: ReactNode;
    actions?: ReactNode;
};

export const ProductListItem = ({
    images,
    url,
    children,
    actions,
}: ProductListItemProps): ReactElement => {
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);

    return (
        <Box
            sx={{
                paddingTop: '4px',
                paddingBottom: '4px',
            }}
        >
            <Box
                sx={{
                    'display': 'flex',
                    'alignItems': 'center',
                    'backgroundColor': 'background.paper',
                    'border': 1,
                    'borderColor': 'divider',
                    'borderRadius': 1,
                    'overflow': 'hidden',
                    'height': '56px',
                    'paddingRight': 1,
                    '&:hover': {
                        '.action': {
                            opacity: 1,
                        },
                        'borderColor': 'grey.400',
                    },
                }}
            >
                {images && (
                    <LightBox
                        open={isImagePreviewOpen}
                        slides={images.map((image) => ({
                            src: image.url,
                            imageFit: image.objectFit,
                            alt: image.title ?? undefined,
                        }))}
                        close={(): void => setIsImagePreviewOpen(false)}
                    />
                )}
                <Image
                    src={images?.[0]?.url}
                    objectFit={images?.[0]?.objectFit ?? undefined}
                    loading={'lazy'}
                    title={''}
                    style={{
                        cursor: 'pointer',
                        aspectRatio: '1 / 1',
                        height: '100%',
                    }}
                    onClick={() => {
                        setIsImagePreviewOpen(true);
                    }}
                />
                <Stack
                    component={url ? Link : 'div'}
                    to={url ?? ''}
                    flexGrow={1}
                    height={'100%'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent={{ xs: 'center', md: 'flex-start' }}
                    alignItems={{ xs: 'flex-start', md: 'center' }}
                    gap={{ xs: 0, md: 2 }}
                    paddingInline={2}
                    sx={{
                        '& :first-of-type': {
                            flexGrow: { xs: 0, md: 1 },
                            maxWidth: { xs: '100%', md: '87%' },
                        },
                    }}
                >
                    {children}
                </Stack>
                <Box
                    flexShrink={0}
                    display={'flex'}
                    gap={1}
                    paddingInlineEnd={1.5}
                    justifyContent={'end'}
                    minWidth={'100px'}
                >
                    {actions}
                </Box>
            </Box>
        </Box>
    );
};
