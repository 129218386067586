import {
    type BaseQueryFn,
    createApi,
    type FetchArgs,
    fetchBaseQuery,
    type FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { loginApi } from '@xeris/pages/login/api/loginApi';
import { type StateType } from '@xeris/types';

import { validateToken } from './token/validateToken';

const baseQuery = fetchBaseQuery({
    baseUrl: window.location.origin,
    prepareHeaders: (headers, { getState, endpoint }) => {
        headers.set('Accept', 'application/json');

        const token = (getState() as StateType).login.token;

        if (token && endpoint !== 'uploadFile') {
            headers.set('Authorization', `Bearer ${token}`);
        }

        return headers;
    },
});

const baseQueryWithToken: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, apiMethods, extraOptions) => {
    const { getState, dispatch } = apiMethods;

    const { expires, token: currentToken } = (getState() as StateType).login;

    const isValidToken = validateToken(currentToken, expires);

    if (!isValidToken) {
        await dispatch(loginApi.endpoints.refreshToken.initiate({}));
    }

    return baseQuery(args, apiMethods, extraOptions);
};

export const baseRtkRestApi = createApi({
    baseQuery: baseQueryWithToken,
    reducerPath: 'restApi',
    tagTypes: [],
    endpoints: () => ({}),
});
