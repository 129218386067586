import { type ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';

import { MutationSnackbars } from '@xeris/components';
import {
    AutocompleteMultipleInputChips,
    FormDrawer,
    SelectInput,
    TextInput,
} from '@xeris/components/forms';
import { PlusIcon } from '@xeris/components/icons';
import { organizationsApi } from '@xeris/pages/admin/api';

import {
    getOrganizationTypeList,
    type OrganizationFormData,
    organizationSchema,
} from '../utilities';

export const AddOrganization = (): ReactElement => {
    const { t } = useTranslation('administration');
    const typeList = getOrganizationTypeList(t);

    const [isOpen, setIsOpen] = useState(false);

    const [addOrganizationMutation, { isError, isSuccess, isLoading }] =
        organizationsApi.useAddOrganizationMutation();

    const { handleSubmit, control, reset } = useForm<OrganizationFormData>({
        resolver: yupResolver(organizationSchema),
        defaultValues: {
            id: '',
            name: '',
            type: 'PRODUCER',
            domains: [],
            vatNumber: '',
        },
    });

    const handleSubmitOrganization = async (
        data: OrganizationFormData
    ): Promise<void> => {
        await addOrganizationMutation({
            ...data,
            domains: data.domains ?? null,
            vatNumber: data.vatNumber ?? null,
        }).unwrap();

        reset();
        setIsOpen(false);
    };

    return (
        <>
            <Button
                startIcon={<PlusIcon />}
                onClick={(): void => setIsOpen(true)}
                variant={'contained'}
            >
                {t(`organization.newOrganization`)}
            </Button>

            <FormDrawer
                open={isOpen}
                onClose={() => setIsOpen(false)}
                onSubmit={handleSubmit(handleSubmitOrganization)}
                title={t(`organization.addNewOrganization.addNewOrganization`)}
                cancelText={t('common.cancel')}
                saveText={t('brand.common.create')}
                isLoading={isLoading}
            >
                <TextInput
                    label={t('organization.id')}
                    fieldName={'id'}
                    control={control}
                    required
                    showError
                    autoFocus
                    placeholder={t('organization.id')}
                />
                <TextInput
                    label={t('organization.name')}
                    fieldName={'name'}
                    control={control}
                    required
                    showError
                    placeholder={t('organization.name')}
                />
                <SelectInput
                    fieldName={'type'}
                    label={t(
                        'organization.addNewOrganization.organizationType'
                    )}
                    options={typeList}
                    control={control}
                />
                <AutocompleteMultipleInputChips
                    fieldName={'domains'}
                    label={t(
                        'organization.addNewOrganization.organizationDomain'
                    )}
                    control={control}
                    showError
                    size={'small'}
                    options={[]}
                    placeholder={t(
                        'organization.addNewOrganization.organizationDomainPlaceholder'
                    )}
                />
                <TextInput
                    label={t(
                        'organization.addNewOrganization.vatNumberOptional'
                    )}
                    fieldName={'vatNumber'}
                    control={control}
                    required
                    showError
                    placeholder={t('organization.addNewOrganization.vatNumber')}
                />
            </FormDrawer>
            <MutationSnackbars
                isSuccess={isSuccess}
                successText={t('organization.addNewOrganization.success')}
                isError={isError}
                errorText={t('organization.somethingWentWrong')}
            />
        </>
    );
};
