import { type FormEvent, type ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';

import PlatformLanguageSelector from '@xeris/containers/LanguageSelector/PlatformLanguageSelector';
import { loginApi } from '@xeris/pages/login/api/loginApi';
import { useAppSelector } from '@xeris/reducers';

import { CheckboxFormControl, PasswordInput, UserNameInput } from '../Common';
import { loginSelectors } from '../reducers';

const LoginForm = (): ReactElement => {
    const { t } = useTranslation('login');

    const passwordInput = useRef<HTMLInputElement | null>(null);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(true);

    const [login] = loginApi.useLoginMutation();

    const form = useRef<HTMLFormElement | null>(null);

    const { isLoading, loginFailed, noCredentials } = useAppSelector(
        loginSelectors.selectLoginSlice
    );

    const handleSubmitLogin = (event: FormEvent): void => {
        event.preventDefault();

        passwordInput.current?.setCustomValidity(
            password.length >= 1 ? '' : t('form.noPasswordEntered')
        );
        form.current?.reportValidity();

        if (form.current?.checkValidity() === true) {
            login({ username, password, remember });
        }
    };

    return (
        <Stack direction={'column'} gap={2}>
            <Typography
                variant={'h3'}
                fontWeight={'normal'}
                component={'h1'}
                gutterBottom
            >
                {t('form.signInToContinue')}
            </Typography>
            <Stack
                component={'form'}
                direction={'column'}
                ref={form}
                onSubmit={(event): void => handleSubmitLogin(event)}
                id="login-form"
                gap={2}
                width={'100%'}
            >
                <UserNameInput value={username} handleChange={setUsername} />
                <PasswordInput
                    id={'user-password'}
                    ref={passwordInput}
                    value={password}
                    handleChange={setPassword}
                />
                <Box width={'100%'}>
                    <CheckboxFormControl
                        checked={remember}
                        handleToggle={() => setRemember((v) => !v)}
                        label={t('form.keepSignedIn')}
                        name="rememberMe"
                    />
                </Box>

                <LoadingButton
                    id={'sign-in'}
                    type={'submit'}
                    variant={'contained'}
                    onClick={handleSubmitLogin}
                    loading={isLoading}
                    fullWidth
                >
                    {t('form.signIn')}
                </LoadingButton>

                <Stack width={'100%'}>
                    <Button
                        component={Link}
                        to="/login/?action=forgot-password"
                        data-testid="forgot-password-button"
                        color={'primary'}
                        sx={{ marginLeft: '-8px' }}
                    >
                        {t('form.forgottenPassword')}
                    </Button>

                    <PlatformLanguageSelector
                        sx={{
                            'marginRight': '-11px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderWidth: 0,
                            },
                            '& .MuiSelect-select': {
                                'paddingBlock': 0.5,
                                '&:hover': {
                                    backgroundColor: 'secondary.soft',
                                },
                            },
                        }}
                    />
                </Stack>
            </Stack>
            {loginFailed && !noCredentials && (
                <Alert
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        border: 1,
                        borderColor: 'error.light',
                    }}
                    severity={'error'}
                >
                    {t('form.loginError')}
                </Alert>
            )}
        </Stack>
    );
};

export default LoginForm;
