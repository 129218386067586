import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { SearchBar } from '../../../SearchBar/index';
import { Typography } from '../../../Typography/Typography';

type MaterialFeatureHeaderProps = {
    hash: string;
    name: string;
    description?: string | null;
    optionSearchFilter: string;
    handleSetFilter: (value: string) => void;
};
export const MaterialFeatureHeader = ({
    hash,
    name,
    description,
    optionSearchFilter,
    handleSetFilter,
}: MaterialFeatureHeaderProps): ReactElement => {
    const { t } = useTranslation('product');

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 3,
                width: '100%',
            }}
            id={hash}
        >
            <Typography variant={'h3'} component={'h2'} gutterBottom>
                {name}
            </Typography>
            <Typography variant={'body2'} marginBottom={1.5}>
                {description}
            </Typography>
            <SearchBar
                filter={optionSearchFilter}
                onChange={handleSetFilter}
                inputLabel={t('variantGenerator.searchForMaterial')}
            />
        </Box>
    );
};
