import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableRow } from '@mui/material';

import type { TFunction } from 'i18next';

import {
    AlertIcon,
    CheckboxMarkedCircleOutlinedIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from '@xeris/components/icons';
import { getDateAndTime } from '@xeris/utilities';

import { type Batch, type BatchStatus } from '../types';

import { ImportDetails } from './ImportDetails';

const getStatusIcon = (
    status: BatchStatus | undefined | null,
    count: number | undefined | null,
    progressCount: number | null | undefined,
    t: TFunction<'administration'>
): ReactElement => {
    let progress = null;
    if (count) {
        progress = progressCount
            ? Math.floor((progressCount / count) * 100)
            : 0;
    } else {
        progress = ' - ';
    }
    switch (status) {
        case 'SUCCESSFUL':
            return (
                <CheckboxMarkedCircleOutlinedIcon
                    color="primary"
                    fontSize="small"
                />
            );
        case 'FAILED':
            return <AlertIcon color="error" fontSize="small" />;
        case 'PROCESSING':
            return <span> {t('imports.progressPercent', { progress })}</span>;
        default:
            return <span> {t('imports.na')}</span>;
    }
};

const getPanelIcon = (isOpen: boolean): ReactElement => {
    if (!isOpen) {
        return <ChevronDownIcon color={'icons'} />;
    } else {
        return <ChevronUpIcon color={'icons'} />;
    }
};

type ImportSummaryProps = {
    row: Batch;
};

export const ImportSummary = ({
    row,
}: ImportSummaryProps): ReactElement | null => {
    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation('administration');

    const togglePanelHandler = (): void => {
        setOpen(!isOpen);
    };

    if (!row.startTime) return null;

    const startTimeStamp = new Date(row.startTime);

    return (
        <>
            <TableRow
                hover
                onClick={(): void => togglePanelHandler()}
                tabIndex={-1}
                key={row.id}
                data-status={row.status} //Used by system tests
            >
                <TableCell>{row?.id?.substring(0, 7)}</TableCell>
                <TableCell>
                    {row.source ? row.source : t('imports.na')}
                </TableCell>
                <TableCell>{getDateAndTime(startTimeStamp)}</TableCell>
                <TableCell>
                    {t('imports.time', {
                        duration: Math.floor(row?.duration || 0),
                    })}
                </TableCell>
                <TableCell>
                    {getStatusIcon(row?.status, row.count, row.progress, t)}
                </TableCell>
                <TableCell>{getPanelIcon(isOpen)}</TableCell>
            </TableRow>
            <ImportDetails row={row} isOpen={isOpen} />
        </>
    );
};
