import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { EmptyState } from '@xeris/components';

export const NoExports = (): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <EmptyState title={t('exports.list.noExportSetups')}>
            <Typography>{t('exports.list.createNewSetup')}</Typography>
        </EmptyState>
    );
};
