import { type ReactElement } from 'react';

import { Box, Button } from '@mui/material';

import { CloseIcon, PlusIcon } from '@xeris/components/icons';
import { DataLanguageSelector } from '@xeris/containers';

import { type EcoSearchTypes } from '../types';

type FilterSectionProps = {
    handleSetIsDrawerOpen: (newValue: boolean) => void;
    filters: Record<string, EcoSearchTypes.FilterType>;
    handleRemoveFilter: (filterId: string) => void;
};

export const FilterSection = ({
    handleSetIsDrawerOpen,
    filters,
    handleRemoveFilter,
}: FilterSectionProps): ReactElement => {
    return (
        <Box
            component={'section'}
            display={'flex'}
            gap={1}
            justifyContent={'space-between'}
            marginBottom={2}
        >
            <Box display={'flex'} gap={1}>
                <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => handleSetIsDrawerOpen(true)}
                    startIcon={<PlusIcon fontSize="small" />}
                >
                    {'Add filters'}
                </Button>
                {Object.values(filters)
                    .filter((it) => it.isActive)
                    .map((filter) => (
                        <Button
                            key={filter.id}
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={(): void => handleRemoveFilter(filter.id)}
                        >
                            <CloseIcon fontSize="small" />
                            {filter.name}
                        </Button>
                    ))}
            </Box>
            <DataLanguageSelector />
        </Box>
    );
};
