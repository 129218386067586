import { type ReactElement, useEffect } from 'react';

import { Box, Button, IconButton } from '@mui/material';

import { CloseIcon } from '@xeris/components/icons';
import { StringField } from '@xeris/pages/imports/pages/Mapping/components/attributeTypes/StringField';
import { SelectLanguage } from '@xeris/pages/imports/pages/Mapping/components/SelectLanguage';
import { type Expression } from '@xeris/pages/imports/types';
import type { Path } from '@xeris/pages/imports/utilities/updateMapping';

import type { NewMapping } from '../AttributeTypeField';

const defaultMap: { [p: string]: Expression | null } = {};

export type LocalizedTextFieldProps = {
    fields: { label: string; path: string }[];
    languages: { id: string; language: string }[];
    path: Path;
    setNewMapping: (newMapping: NewMapping) => void;
    expression: Expression | null;
};

export const LocalizedTextField = ({
    fields,
    languages,
    path,
    setNewMapping,
    expression,
}: LocalizedTextFieldProps): ReactElement => {
    const currentMap =
        expression?.operator === 'make_object' ? expression.map : defaultMap;

    const usedLanguages = Object.keys(currentMap);
    const availableLanguages = languages.filter(
        ({ id }) => !usedLanguages.includes(id)
    );

    useEffect(() => {
        if (!expression && availableLanguages.length > 0) {
            setNewMapping({
                path: path,
                expression: {
                    operator: 'make_object',
                    map: {
                        [availableLanguages[0].id]: null,
                    },
                } as const,
            });
        }
    }, [availableLanguages, expression, path, setNewMapping]);

    return (
        <Box>
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'flex-end'}
                marginTop={'-36px'}
            >
                <Button
                    disabled={availableLanguages.length === 0}
                    sx={{ marginRight: '-8px' }}
                    onClick={() =>
                        setNewMapping({
                            path: path,
                            expression: {
                                operator: 'make_object',
                                map: {
                                    ...currentMap,
                                    [availableLanguages[0].id]: null,
                                },
                            } as const,
                        })
                    }
                >
                    {'Add language'}
                </Button>
            </Box>
            <Box
                display={'flex'}
                flexDirection={'column'}
                gap={1}
                paddingInlineStart={2}
                marginInlineStart={2}
                paddingBlock={usedLanguages.length > 0 ? 0.5 : 0}
                borderLeft={3}
                borderColor={'divider'}
            >
                {Object.entries(currentMap).map(
                    ([language, languageExpression]) => {
                        return (
                            <Box
                                display={'flex'}
                                alignItems={'flex-start'}
                                gap={1}
                                key={language}
                            >
                                <SelectLanguage
                                    value={language}
                                    languages={languages}
                                    usedLanguages={usedLanguages}
                                    onChange={(newLanguage) => {
                                        setNewMapping({
                                            path: path,
                                            expression: {
                                                operator: 'make_object',
                                                map: {
                                                    ...Object.fromEntries(
                                                        Object.entries(
                                                            currentMap
                                                        ).map(
                                                            ([key, value]) => {
                                                                if (
                                                                    key ===
                                                                    language
                                                                ) {
                                                                    return [
                                                                        newLanguage,
                                                                        value,
                                                                    ];
                                                                }

                                                                return [
                                                                    key,
                                                                    value,
                                                                ];
                                                            }
                                                        )
                                                    ),
                                                },
                                            } as const,
                                        });
                                    }}
                                />
                                <StringField
                                    fields={fields}
                                    path={path}
                                    setNewMapping={({
                                        expression: newExpression,
                                    }) =>
                                        setNewMapping({
                                            path: path,
                                            expression: {
                                                operator: 'make_object',
                                                map: {
                                                    ...currentMap,
                                                    [language]: newExpression,
                                                },
                                            },
                                        })
                                    }
                                    expression={languageExpression}
                                />
                                <IconButton
                                    aria-label={'Remove language'}
                                    edge={'end'}
                                    sx={{
                                        marginTop: 'calc((53.13px - 40px) / 2)',
                                    }}
                                    onClick={() =>
                                        setNewMapping({
                                            path: path,
                                            expression: {
                                                operator: 'make_object',
                                                map: Object.fromEntries(
                                                    Object.entries(
                                                        currentMap
                                                    ).filter(
                                                        ([key]) =>
                                                            key !== language
                                                    )
                                                ),
                                            },
                                        })
                                    }
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        );
                    }
                )}
            </Box>
        </Box>
    );
};
