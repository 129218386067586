import { type Dispatch, type ReactElement, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { MuiButton, ProductCard, SelectButton } from '@xeris/components';
import { RightPanelOpenIcon } from '@xeris/components/icons';

import { type MasterProductWithIds } from '../../../types';
import { getProductSelectedCount } from '../../../utilities/getSelectedCount';

type SelectProductCardProps = {
    product: MasterProductWithIds & { isConfigurable: boolean };
    selectedProducts: string[];
    setSelectedProducts: Dispatch<SetStateAction<string[]>>;
    setProductToShow: Dispatch<SetStateAction<MasterProductWithIds | null>>;
    setShowProductDrawer: Dispatch<SetStateAction<boolean>>;
};

export const SelectProductCard = ({
    product,
    selectedProducts,
    setSelectedProducts,
    setProductToShow,
    setShowProductDrawer,
}: SelectProductCardProps): ReactElement => {
    const { t } = useTranslation('administration');

    const {
        allSelected,
        partiallySelected,
        handleToggle,
        productCount,
        selectedCount,
    } = getProductSelectedCount(product, selectedProducts, setSelectedProducts);

    return (
        <Box
            key={product.id}
            sx={{
                height: '340px',
                width: '210px',
                flexGrow: 0,
                flexBasis: '220px',
            }}
        >
            <ProductCard
                images={product.images}
                actions={
                    <>
                        <SelectButton
                            size={'large'}
                            isSelected={allSelected}
                            isPartiallySelected={partiallySelected}
                            onToggleOption={handleToggle}
                        />
                        {product.products.length > 1 && (
                            <MuiButton
                                size={'large'}
                                variant={'outlinedSolid'}
                                color={'secondary'}
                                aria-label={t('connections.showVariants')}
                                round
                                onClick={() => {
                                    setProductToShow(product);
                                    setShowProductDrawer(true);
                                }}
                            >
                                <RightPanelOpenIcon color={'secondary'} />
                            </MuiButton>
                        )}
                    </>
                }
            >
                <Typography>{product.name}</Typography>
                <Typography variant="body2">
                    {product.isConfigurable
                        ? t('connections.configurable')
                        : t('connections.numberOfSelectedVariants', {
                              count: productCount,
                              selected: selectedCount,
                          })}
                </Typography>
            </ProductCard>
        </Box>
    );
};
