import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableRow, Typography } from '@mui/material';

type ImportErrorProps = {
    error?: string;
};

export const ImportError = ({ error }: ImportErrorProps): ReactElement => {
    const { t } = useTranslation('administration');
    return (
        <TableRow key={error}>
            <TableCell>
                <Typography
                    variant={'body1'}
                    fontSize={'small'}
                    paddingLeft={'60px'}
                    color={'error'}
                >
                    {t('imports.error')}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    variant={'body1'}
                    fontSize={'small'}
                    color={'error'}
                >
                    {error}
                </Typography>
            </TableCell>
        </TableRow>
    );
};
