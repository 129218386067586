import { type ReactElement } from 'react';

import { TextField } from '@mui/material';

import { SelectGroup } from '@xeris/components';
import { AdvancedExpression } from '@xeris/pages/imports/pages/Mapping/components/AdvancedExpression';
import { type Expression } from '@xeris/pages/imports/types';
import { type Path } from '@xeris/pages/imports/utilities/updateMapping';

import { type NewMapping } from '../AttributeTypeField';
import { SelectMode } from '../SelectMode';
import { SelectPath } from '../SelectPath';

export type StringFieldProps = {
    fields: { label: string; path: string }[];
    path: Path;
    setNewMapping: (newMapping: NewMapping) => void;
    expression: Expression | null;
};

export const StringField = ({
    fields,
    path,
    expression,
    setNewMapping,
}: StringFieldProps): ReactElement => {
    return (
        <SelectGroup sx={{ flexBasis: '220px', flexGrow: 1.618 }}>
            <SelectMode
                path={path}
                expression={expression ?? null}
                setNewMapping={setNewMapping}
            />
            {!expression && (
                <SelectPath
                    path={path}
                    expression={null}
                    fields={fields}
                    setNewMapping={setNewMapping}
                />
            )}
            {expression?.operator === 'get' && (
                <SelectPath
                    path={path}
                    expression={expression}
                    fields={fields}
                    setNewMapping={setNewMapping}
                />
            )}
            {expression?.operator === 'value' && (
                <TextField
                    sx={{ flexGrow: 1 }}
                    value={expression.value}
                    onChange={(event) =>
                        setNewMapping({
                            path,
                            expression: {
                                operator: 'value',
                                value: event.target.value,
                            },
                        })
                    }
                />
            )}
            <AdvancedExpression
                path={path}
                expression={expression ?? null}
                setNewMapping={setNewMapping}
            />
        </SelectGroup>
    );
};
