import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Skeleton, Typography } from '@mui/material';

import { BackButton, ErrorPage } from '@xeris/components';

export const Source = (): ReactElement => {
    const { t } = useTranslation('imports');

    const { data, isLoading, isError } = {
        data: { source: { name: 'My source' } },
        isLoading: false,
        isError: false,
    };

    if (isLoading) {
        return <Skeleton height={200} />;
    }

    if (isError) {
        return <ErrorPage title={t('common.anErrorOccurred')} />;
    }

    if (!data) {
        return <ErrorPage title={t('common.notFound')} />;
    }

    return (
        <Box>
            <BackButton to={'../source'}>{t('source.allSources')}</BackButton>
            <Typography variant={'h1'}>{data.source.name}</Typography>
        </Box>
    );
};
