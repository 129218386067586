import { ConfigureSource } from './ConfigureSource';
import { SelectSourceType } from './SelectSourceType';
import { UploadFile } from './UploadFile';

export const steps = [
    {
        id: 'selectSource',
        Component: SelectSourceType,
    },
    {
        id: 'uploadFile',
        Component: UploadFile,
    },
    {
        id: 'configureSource',
        Component: ConfigureSource,
    },
] as const;
