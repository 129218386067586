import { type ReactElement } from 'react';
import {
    Link as RouterLink,
    type LinkProps as RouterLinkProps,
} from 'react-router-dom';

import {
    Link as MuiLink,
    type LinkProps as MuiLinkProps,
    Typography,
} from '@mui/material';

type LinkProps = MuiLinkProps & {
    to?: RouterLinkProps['to'];
};

export const Link = ({ to, ...props }: LinkProps): ReactElement => {
    if (!to) {
        return <Typography component={'span'}>{props.children}</Typography>;
    }

    return <MuiLink component={RouterLink} to={to} {...props} />;
};
