import { type ReactElement, type ReactNode } from 'react';

import { Paper } from '@mui/material';

type InfoBoxProps = {
    children?: ReactNode;
};

export const InfoBox = ({ children }: InfoBoxProps): ReactElement => (
    <Paper
        sx={{
            marginBottom: 1.5,
            padding: 1.5,
            backgroundColor: 'grey.100',
        }}
        variant={'elevation'}
        elevation={0}
    >
        {children}
    </Paper>
);
