import { type ReactElement } from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

type Header = {
    columnName: string;
    values: string[];
};

type TabularPreviewProps = {
    headers?: Header[];
    headerRowPresent: boolean;
};

export const TabularPreview = ({
    headers,
    headerRowPresent,
}: TabularPreviewProps): ReactElement | null => {
    if (!headers) return null;

    const headerNames = headers.map((header) => header.columnName);
    const rows = Array.from({
        length: headers[0].values.length - (headerRowPresent ? 1 : 0),
    }).map((_, index) =>
        headers.map(
            (header) => header.values[index + (headerRowPresent ? 1 : 0)]
        )
    );

    return (
        <Table sx={{ border: 1, borderColor: 'divider', overflow: 'scroll' }}>
            <TableHead>
                <TableRow>
                    {headerNames.map((headerName, index) => (
                        <TableCell key={index}>{headerName}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            <TableCell key={cellIndex}>
                                <Typography noWrap>{cell}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
