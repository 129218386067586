import { type ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, IconButton, Typography } from '@mui/material';

import { CenteredPage, Skeleton } from '@xeris/components';
import {
    ChevronDownIcon,
    ChevronRightIcon,
    ChevronUpIcon,
} from '@xeris/components/icons';
import { useAppSelector } from '@xeris/reducers';

import { ecoSearchHooks } from '../hooks';
import { ecoSearchSelectors } from '../selectors';
import { type EcoSearchTypes } from '../types';
import { typeLookup } from '../types/EcoSearchTypes';

import { ClassificationHeader } from './ClassificationHeader';

const SkeletonLoad = (): ReactElement => {
    const placeholderList = Array.from(Array(10).keys());

    return (
        <Box marginBottom={4}>
            <ClassificationHeader
                activeFilter={'show all'}
                searchTerm={''}
                handleSetActiveFilter={(): void => {}}
                handleSetSearchTerm={(): void => {}}
            />
            <div>
                {placeholderList.map((index) => (
                    <Skeleton key={index} height={42} />
                ))}
            </div>
        </Box>
    );
};

type BranchProps = {
    element: EcoSearchTypes.CategoryType;
};

const Branch = ({ element }: BranchProps): ReactElement | null => {
    const [isBranchOpen, setIsBranchOpen] = useState(
        element.childrenMatches ?? false
    );

    useEffect(() => {
        //Resets the open/close state if the search changes state of this branch.
        setIsBranchOpen(element.childrenMatches ?? false);
    }, [element.childrenMatches, element.matches]);

    const isBrick = element.type === 'gpc_brick';

    const handleToggleOpen = (): void => {
        if (!isBrick) {
            setIsBranchOpen(!isBranchOpen);
        }
    };

    const ChevronIcon = isBrick
        ? ChevronRightIcon
        : isBranchOpen
          ? ChevronUpIcon
          : ChevronDownIcon;

    return (
        <>
            <Box
                onClick={handleToggleOpen}
                sx={{
                    'padding': 0.5,
                    'borderRadius': 0.5,
                    'minHeight': '48px',
                    ':hover': {
                        'backgroundColor': 'primary.soft',
                        '> .iconButton': { display: 'inline-flex' },
                    },
                    'display': 'flex',
                }}
            >
                <Box
                    display={'flex'}
                    gap={1}
                    alignItems={'center'}
                    flexGrow={1}
                >
                    {element.children ? (
                        <ChevronIcon />
                    ) : (
                        <Box width={'24px'} />
                    )}
                    <Typography>{typeLookup[element.type]}:</Typography>
                    <Link
                        to={`/EcoSearch/${element.id}/Products`}
                        style={{ display: 'flex' }}
                    >
                        <Typography fontWeight={'bold'} paddingInlineEnd={1}>
                            {element.id}
                        </Typography>
                        <Typography>{element.name}</Typography>
                    </Link>
                </Box>
                <IconButton
                    className={'iconButton'}
                    component={Link}
                    sx={{ display: 'none' }}
                    to={`/EcoSearch/${element.id}/Products`}
                >
                    <ChevronRightIcon />
                </IconButton>
            </Box>
            <Box paddingInlineStart={2}>
                {isBranchOpen &&
                    element.children &&
                    element.children.map(
                        (child: EcoSearchTypes.CategoryType) => (
                            <Branch element={child} key={child.id} />
                        )
                    )}
            </Box>
        </>
    );
};

const categoryTypeMatchesFilterType = (
    filter: EcoSearchTypes.FilterTypesType,
    type: EcoSearchTypes.CategoryTypesType
): boolean => {
    return (
        filter === 'show all' ||
        (filter === 'segment' && type === 'gpc_segment') ||
        (filter === 'family' && type === 'gpc_family') ||
        (filter === 'class' && type === 'gpc_class') ||
        (filter === 'brick' && type === 'gpc_brick')
    );
};

const filterCategoriesRecursively = (
    list: EcoSearchTypes.CategoryType[] | null,
    regExp: RegExp,
    activeFilter: EcoSearchTypes.FilterTypesType
): EcoSearchTypes.CategoryType[] => {
    return (
        (list
            ?.map((element) => {
                const isMatchingRegExp =
                    regExp.test(element.id) ||
                    regExp.test(element.name) ||
                    regExp.test(element.description ?? '');
                if (
                    categoryTypeMatchesFilterType(activeFilter, element.type) &&
                    isMatchingRegExp
                ) {
                    return { ...element, matches: true };
                } else if (element.children !== undefined) {
                    const children = filterCategoriesRecursively(
                        element.children,
                        regExp,
                        activeFilter
                    );
                    if (children.length === 0) {
                        return null;
                    } else {
                        return {
                            ...element,
                            children: children,
                            childrenMatches: true,
                        };
                    }
                } else {
                    return null;
                }
            })
            .filter((el) => !!el) as EcoSearchTypes.CategoryType[]) ?? []
    );
};

const ClassificationSelector = (): ReactElement | null => {
    const [activeFilter, setActiveFilter] =
        useState<EcoSearchTypes.FilterTypesType>('show all');

    const [searchTerm, setSearchTerm] = useState<string>('');

    const { isLoading } = ecoSearchHooks.api.queries.useGetSegmentsQuery({});

    const categoryList = useAppSelector(ecoSearchSelectors.selectGpcSegments);

    if (isLoading || !categoryList) {
        return <SkeletonLoad />;
    }

    const handleSetActiveFilter = (
        newActiveFilter: EcoSearchTypes.FilterTypesType
    ): void => {
        setActiveFilter(newActiveFilter);
    };

    const handleSetSearchTerm = (newSearchTerm: string): void => {
        setSearchTerm(newSearchTerm);
    };

    const activeCategories =
        searchTerm.length < 2
            ? categoryList
            : filterCategoriesRecursively(
                  categoryList,
                  new RegExp(searchTerm, 'i'),
                  activeFilter
              );

    return (
        <CenteredPage>
            <ClassificationHeader
                activeFilter={activeFilter}
                searchTerm={searchTerm}
                handleSetActiveFilter={handleSetActiveFilter}
                handleSetSearchTerm={handleSetSearchTerm}
            />
            {activeCategories.map((element) => (
                <Branch element={element} key={element.id} />
            ))}
        </CenteredPage>
    );
};

export default ClassificationSelector;
