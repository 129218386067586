import { type ReactElement, type SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, ListItemIcon, ListItemText } from '@mui/material';

import { type TFunction } from 'i18next';

import { SelectMenu, type SelectMenuItemType } from '@xeris/components';
import {
    CloseIcon,
    DotsHorizontalIcon,
    EditIcon,
    PlusIcon,
} from '@xeris/components/icons';

import {
    type HandleSelectModalType,
    type ModalTypesType,
} from '../MultipleMasterProductsSelector';

const getMenuItemList = (
    t: TFunction<'product'>,
    unselectedVariantsCount: number
): SelectMenuItemType<ModalTypesType>[] => [
    {
        key: 'addRemaining',
        value: 'addRemaining',
        content: (
            <>
                <ListItemIcon>
                    <PlusIcon />
                </ListItemIcon>
                <ListItemText>
                    {t('dataSelector.addRemaining', {
                        count: unselectedVariantsCount,
                    })}
                </ListItemText>
            </>
        ),
    },
    {
        key: 'edit',
        value: 'edit',
        divider: true,
        content: (
            <>
                <ListItemIcon>
                    <EditIcon fontSize={'small'} />
                </ListItemIcon>
                <ListItemText>
                    {t('dataSelector.editDataSelection')}
                </ListItemText>
            </>
        ),
    },
    {
        key: 'remove',
        value: 'remove',
        content: (
            <>
                <ListItemIcon>
                    <CloseIcon fontSize={'small'} />
                </ListItemIcon>
                <ListItemText>
                    {t('dataSelector.removeAllFromDataset')}
                </ListItemText>
            </>
        ),
    },
];

type EditMenuProps = {
    className?: string;
    handleSelectModal: HandleSelectModalType;
    unselectedVariantsCount: number;
};

export const EditMenu = ({
    className,
    handleSelectModal,
    unselectedVariantsCount,
}: EditMenuProps): ReactElement => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
    const { t } = useTranslation('product');

    const handleMenuToggle = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        if (menuAnchorEl) setMenuAnchorEl(null);
        else {
            setMenuAnchorEl(event.currentTarget);
        }
    };

    const menuItemList = getMenuItemList(t, unselectedVariantsCount);

    const filteredMenuItemList =
        unselectedVariantsCount > 0
            ? menuItemList
            : menuItemList.filter((item) => item.key !== 'addRemaining');

    return (
        <SelectMenu
            closedClassName={className}
            menuItemList={filteredMenuItemList}
            handleSelect={(event: SyntheticEvent, value): void =>
                handleSelectModal(value)
            }
            CustomButton={
                <IconButton
                    onClick={handleMenuToggle}
                    aria-label={t('common.button.edit')}
                >
                    <DotsHorizontalIcon />
                </IconButton>
            }
        />
    );
};
