import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { Tooltip } from '@xeris/components';
import { ArrowLeftIcon, ArrowRightIcon } from '@xeris/components/icons';
import { useAppSelector } from '@xeris/reducers';

import { variantGeneratorSelectors } from '../../reducer';
import { type Stage } from '../../VariantGeneratorWrapper/VariantGeneratorWrapper';

export type StageType = {
    pathFraction: Stage;
    title: string;
};

const StagesLookup: Record<Stage, StageType> = {
    configure: { pathFraction: 'configure', title: 'Select Options ' },
    summary: { pathFraction: 'summary', title: 'Summary' },
    selectData: { pathFraction: 'selectData', title: 'Select Data ' },
};

type PageStagesType = {
    previous: StageType;
    current: StageType;
    next: StageType;
};

type ConfigurePageStagesType = Omit<PageStagesType, 'previous'> & {
    previous: undefined;
};

type SelectDataPageStagesType = Omit<PageStagesType, 'next'> & {
    next: undefined;
};

type AllStagesType = {
    configure: ConfigurePageStagesType;
    summary: PageStagesType;
    selectData: SelectDataPageStagesType;
};

export const AllStages: AllStagesType = {
    configure: {
        previous: undefined,
        current: StagesLookup.configure,
        next: StagesLookup.summary,
    },
    summary: {
        previous: StagesLookup.configure,
        current: StagesLookup.summary,
        next: StagesLookup.selectData,
    },
    selectData: {
        previous: StagesLookup.summary,
        current: StagesLookup.selectData,
        next: undefined,
    },
};

type StageLinkProps = {
    stage?: StageType;
    stageType: 'previous' | 'next';
};

const StageLink = ({ stage, stageType }: StageLinkProps): ReactElement => {
    const { t } = useTranslation('product');

    const missingFeatures = useAppSelector(
        variantGeneratorSelectors.selectMissingFeatures
    );

    if (!stage) {
        return <div className="link" />;
    }

    const iconLookup = {
        previous: <ArrowLeftIcon color={'primary'} />,
        next: <ArrowRightIcon color={'primary'} />,
    };

    const disabled =
        stage.pathFraction === 'selectData' && missingFeatures.length > 0;

    const tooltip = disabled
        ? t('variantGenerator.selectAtLeastOneOption')
        : '';

    const renderedLinkChildren = (
        <>
            {stageType === 'previous' ? iconLookup[stageType] : null}
            {t(`variantGenerator.stages.${stage.pathFraction}`)}
            {stageType === 'next' ? iconLookup[stageType] : null}
        </>
    );

    if (disabled) {
        return (
            <Box display={'flex'} justifyContent={'center'}>
                <Tooltip title={tooltip}>
                    <Typography
                        variant={'h6'}
                        component={'p'}
                        color={'primary'}
                        display={'flex'}
                        gap={1}
                    >
                        {renderedLinkChildren}
                    </Typography>
                </Tooltip>
            </Box>
        );
    }

    return (
        <Box display={'flex'} justifyContent={'center'}>
            <Typography
                component={Link}
                to={{}}
                state={{ page: stage.pathFraction }}
                variant={'h6'}
                display={'flex'}
                color={'primary'}
                gap={1}
            >
                {renderedLinkChildren}
            </Typography>
        </Box>
    );
};

type LineProps = {
    hidden: boolean;
};

const Line = ({ hidden }: LineProps): ReactElement => {
    return (
        <Box
            sx={{
                margin: 'auto 0',
                borderBottom: hidden ? 'none' : '2px solid #d5d9e0',
            }}
        />
    );
};

type HeaderProps = {
    type: Stage;
};

const Header = ({ type }: HeaderProps): ReactElement => {
    const { t } = useTranslation('product');
    const stages = AllStages[type];

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: '88px 180px 1fr 180px 1fr 180px 88px',
                paddingInline: 0,
                paddingBlock: 1.5,
                backgroundColor: 'background.default',
            }}
        >
            <Line hidden={!stages.previous} />
            <StageLink stage={stages.previous} stageType="previous" />
            <Line hidden={!stages.previous} />
            <Box display={'flex'} justifyContent={'center'}>
                <Typography
                    color={'secondary.light'}
                    variant={'h6'}
                    component={'p'}
                >
                    {t(
                        `variantGenerator.stages.${stages.current?.pathFraction}`
                    )}
                </Typography>
            </Box>
            <Line hidden={!stages.next} />
            <StageLink stage={stages.next} stageType="next" />
            <Line hidden={!stages.next} />
        </Box>
    );
};

export default Header;
