import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Skeleton, Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { CellLink, DataGrid, ErrorPage, SearchBar } from '@xeris/components';
import { DeleteIcon, PlusIcon } from '@xeris/components/icons';
import { importsApi } from '@xeris/pages/imports/api/importsApi';
import { search } from '@xeris/utilities';

import { CreateSource } from './CreateSource/CreateSource';

export const Sources = (): ReactElement => {
    const { t } = useTranslation('imports');
    const [showCreateSource, setShowCreateSource] = useState(false);

    const [filter, setFilter] = useState<string>('');

    const { data, isLoading, isError } = importsApi.useGetSourcesQuery({});

    if (isLoading) {
        return <Skeleton height={200} />;
    }

    if (isError) {
        return <ErrorPage title={t('common.anErrorOccurred')} />;
    }

    if (!data) {
        return <ErrorPage title={t('common.notFound')} />;
    }

    const rows = data.sources.filter(({ name }) => search(filter, name));

    return (
        <Box sx={{ minHeight: 'calc(100vh - 186px)' }}>
            <Box
                sx={{
                    paddingBlock: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <SearchBar
                    inputLabel={'Filter'}
                    onChange={setFilter}
                    filter={filter}
                />
                <Button
                    startIcon={<PlusIcon />}
                    variant={'contained'}
                    onClick={() => setShowCreateSource(true)}
                >
                    {t('sources.newSource')}
                </Button>
            </Box>
            <Box sx={{ overflow: 'hidden' }}>
                {data.sources.length === 0 ? (
                    <Typography>{t('sources.noSources')}</Typography>
                ) : (
                    <DataGrid
                        hideFooterPagination
                        autoHeight
                        columns={[
                            {
                                field: 'id',
                                headerName: t('sources.table.id'),
                                flex: 1,
                                renderCell: ({ row }) => (
                                    <CellLink to={`./${row.id}`}>
                                        {row.id}
                                    </CellLink>
                                ),
                            },
                            {
                                field: 'name',
                                headerName: t('sources.table.name'),
                                flex: 1,
                                renderCell: ({ row }) => (
                                    <CellLink to={`./${row.id}`}>
                                        {row.name}
                                    </CellLink>
                                ),
                            },
                            {
                                field: 'actions',
                                type: 'actions',
                                headerName: '',
                                getActions: () => [
                                    <GridActionsCellItem
                                        key={'deleteSource'}
                                        icon={<DeleteIcon />}
                                        label={t('sources.table.deleteSource')}
                                        showInMenu
                                    />,
                                ],
                            },
                        ]}
                        rows={rows}
                    />
                )}
            </Box>
            <CreateSource
                isOpen={showCreateSource}
                handleClose={() => setShowCreateSource(false)}
            />
        </Box>
    );
};
