import { type ReactElement, useState } from 'react';

import { Drawer } from '@mui/material';

import { Header } from './components/Header';
import { steps } from './steps';
import { type CreateSourceFormData } from './types';

type CreateSourceProps = {
    isOpen: boolean;
    handleClose: () => void;
};

export const CreateSource = ({
    isOpen,
    handleClose,
}: CreateSourceProps): ReactElement => {
    const [activeStep, setActiveStep] = useState(0);
    const [data, setData] = useState<CreateSourceFormData>({});

    const step = steps[activeStep];

    return (
        <Drawer open={isOpen} onClose={handleClose}>
            <step.Component
                activeStep={activeStep}
                handleClose={handleClose}
                header={<Header activeStep={activeStep} />}
                data={data}
                setData={(newData, next = 0) => {
                    if (newData === null) {
                        setActiveStep(0);
                        setData({});
                    } else {
                        setActiveStep((s) => s + next);
                        setData((d) => ({ ...d, ...newData }));
                    }
                }}
            />
        </Drawer>
    );
};
