import { type ReactElement } from 'react';

import { SelectShowIcon } from '@xeris/components';
import {
    FileOutlineIcon,
    FunctionIcon,
    TextFieldsIcon,
} from '@xeris/components/icons';
import { type Expression } from '@xeris/pages/imports/types';
import { createExpression } from '@xeris/pages/imports/utilities/createExpression';
import { knownExpressions } from '@xeris/pages/imports/utilities/data';
import { type Path } from '@xeris/pages/imports/utilities/updateMapping';

import type { NewMapping } from './AttributeTypeField';

type SelectModeProps = {
    path: Path;
    expression: Expression | null;
    setNewMapping: (newMapping: NewMapping) => void;
};

export const SelectMode = ({
    path,
    expression,
    setNewMapping,
}: SelectModeProps): ReactElement => {
    const value =
        expression && expression.operator !== null
            ? knownExpressions.includes(expression.operator)
                ? expression.operator
                : 'advanced'
            : 'get';

    return (
        <SelectShowIcon
            value={value}
            onChange={(event) =>
                setNewMapping({
                    path,
                    expression: createExpression(event.target.value as string),
                })
            }
            options={[
                {
                    id: 'get',
                    label: 'Reference',
                    Icon: FileOutlineIcon,
                },
                {
                    id: 'value',
                    label: 'Value',
                    Icon: TextFieldsIcon,
                },
                {
                    id: 'advanced',
                    label: 'Function',
                    Icon: FunctionIcon,
                },
            ]}
        />
    );
};
