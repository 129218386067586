import {
    type FormEvent,
    type ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Link, Skeleton, Stack, Typography } from '@mui/material';

import { LockPrivateIcon } from '@xeris/components/icons';
import { useQueryParams } from '@xeris/hooks';
import { useChangeLanguage } from '@xeris/i18n/useChangeLanguage';
import { userVerificationApi } from '@xeris/pages/admin/api';

import {
    BrokenLinkMessage,
    CheckboxFormControl,
    LoginFormControl,
    PasswordInput,
    UserNameInput,
} from '../Common';
import { isValidPassword } from '../utillities';

const AcceptInviteForm = (): ReactElement => {
    const { t } = useTranslation('login');
    const handleChangeLanguage = useChangeLanguage();

    const token = useQueryParams('token');
    const { data, isError } =
        userVerificationApi.useGetUserInformationFromTokenQuery(
            { token },
            { skip: !token }
        );

    useEffect(() => {
        handleChangeLanguage(data?.language);
    }, [data, handleChangeLanguage]);

    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [policyAgreement, setPolicyAgreement] = useState(false);

    const passwordInput = useRef<HTMLInputElement | null>(null);
    const form = useRef<HTMLFormElement | null>(null);

    const validatePassword = (): void => {
        const message = isValidPassword(password);
        passwordInput.current?.setCustomValidity(message);
    };

    const handleSubmit = (event: FormEvent): void => {
        validatePassword();
        form.current?.reportValidity();
        if (!form.current?.checkValidity()) {
            event.preventDefault();
        }
    };

    if (!token || isError) {
        return <BrokenLinkMessage variant="invite" />;
    }

    if (!data) {
        return <Skeleton height={300} />;
    }

    return (
        <Stack direction={'column'} gap={4}>
            <Typography variant={'h3'} component={'h1'} fontWeight={'normal'}>
                {t('invite.createAccount')}
            </Typography>
            <Stack
                component={'form'}
                direction={'column'}
                gap={2}
                ref={form}
                action={`/login/user-info?token=${token}`}
                method="POST"
                onSubmit={handleSubmit}
            >
                <UserNameInput
                    value={data.username}
                    disabled
                    showAdornment={true}
                    adornment={<LockPrivateIcon fontSize={'small'} />}
                />
                <PasswordInput
                    ref={passwordInput}
                    value={password}
                    handleChange={setPassword}
                    name="password"
                />
                <LoginFormControl
                    formId="name"
                    formLabel={t('invite.fullName')}
                    formType="text"
                    required
                    formValue={name}
                    name="name"
                    setFunction={setName}
                    showAdornment={false}
                />
                <LoginFormControl
                    formId="phone"
                    formLabel={t('invite.phoneNumber')}
                    formType="text"
                    formValue={phone}
                    name="phone"
                    setFunction={setPhone}
                    showAdornment={false}
                />
                <CheckboxFormControl
                    checked={policyAgreement}
                    handleToggle={(): void =>
                        setPolicyAgreement(!policyAgreement)
                    }
                    name="agreement"
                    label={
                        <Typography>
                            {t('invite.agreeToPrivacyPolicies')}{' '}
                            <Link
                                href="https://www.xeris.no/legal/privacy-policy"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t('invite.privacyPolicies')}
                            </Link>
                        </Typography>
                    }
                />
                <Button
                    variant={'contained'}
                    type={'submit'}
                    disabled={!name || !password || !policyAgreement}
                >
                    {t('invite.save')}
                </Button>
            </Stack>
        </Stack>
    );
};

export default AcceptInviteForm;
