import { type ReactElement } from 'react';

import { TableBody } from '@mui/material';

import { type Order, type OrderBy } from '@xeris/pages/admin/Import';
import { getSorting, stableSort } from '@xeris/utilities';

import { type Batch } from '../types';

import { ImportSummary } from './ImportSummary';

type ImportTableBodyProps = {
    importList: Batch[];
    orderBy: OrderBy;
    order: Order;
    page: number;
    rowsPerPage: number;
};

export const ImportTableBody = ({
    importList,
    orderBy,
    order,
    page,
    rowsPerPage,
}: ImportTableBodyProps): ReactElement => {
    return (
        <TableBody>
            {stableSort(importList, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: Batch) => {
                    return <ImportSummary key={row.id} row={row} />;
                })}
        </TableBody>
    );
};
