import { JSFeature } from 'js-feature-configuration';

import { type FeatureConfiguration } from '../types/featureConfiguration';
import {
    type Features,
    type PreviewSelection,
    type Visibility,
} from '../types/variantGeneratorSliceTypes';

import { getFeatureConfigurator } from './getFeatureConfigurator';

export const ensureValidPreviewSelection = (
    featureConfiguration: FeatureConfiguration,
    featureIds: Features,
    selections?: PreviewSelection | null
): {
    selectedOptions: PreviewSelection;
    visibility: Visibility;
} => {
    let currentSelections = Object.entries(selections ?? {})
        .filter(
            (selection): selection is [string, string] => selection[1] !== null
        )
        .map(([key, value]) => new JSFeature(key, [value]));

    let changed = true;

    const evaluator = getFeatureConfigurator(featureConfiguration);

    while (changed) {
        changed = false;

        // Compute visible features and options
        const visibility = evaluator.visibility(currentSelections);

        // Remove invalid selections
        currentSelections = currentSelections
            .filter((selection) => {
                const isVisible = visibility?.find(
                    (vis) => vis.featureId === selection.featureId
                );

                if (isVisible) {
                    return true;
                } else {
                    changed = true;
                    return false;
                }
            })
            .map((selection) => {
                const visible = visibility?.find(
                    (vis) => vis.featureId === selection.featureId
                );

                const values = selection.values.filter((val) => {
                    const isVis = visible?.values.includes(val);

                    if (isVis) {
                        return true;
                    } else {
                        changed = true;
                        return false;
                    }
                });

                if (values.length === 0) {
                    return null;
                }

                return new JSFeature(selection.featureId, values);
            })
            .filter((selection): selection is JSFeature => selection !== null);

        // Add missing selections that are visible
        visibility?.forEach((feature) => {
            if (
                !currentSelections.find(
                    (selection) => selection.featureId === feature.featureId
                )
            ) {
                const visibleOptionIds = feature.values.filter(
                    (value) => value !== null
                );

                const featureOptionIds = featureIds.find(
                    ({ id }) => id === feature.featureId
                )?.optionIds;

                // Get the first visible option from the list of options. The array from the visibility library is not ordered
                const newValue = featureOptionIds?.filter((option) =>
                    visibleOptionIds?.includes(option.id)
                )?.[0]?.id;

                if (newValue) {
                    currentSelections.push(
                        new JSFeature(feature.featureId, [newValue])
                    );
                    changed = true;
                }
            }
        });
    }

    const visibility = evaluator.visibility(currentSelections);

    return {
        visibility: Object.fromEntries(
            visibility?.map((feature) => [
                feature.featureId,
                feature.values.filter(
                    (value) => value !== null && value !== undefined
                ),
            ]) ?? []
        ),
        selectedOptions: Object.fromEntries(
            // Make sure all features are in the object
            featureIds.map((feature) => [
                feature.id,
                currentSelections.find(
                    (selection) => selection.featureId === feature.id
                )?.values?.[0] ?? null,
            ])
        ),
    };
};
