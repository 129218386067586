import { type ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { SearchBar } from '@xeris/components';

import BrandList from './BrandList';

const Brands = (): ReactElement => {
    const { t } = useTranslation('products');
    const [searchParam, setSearchParam] = useSearchParams([['filter', '']]);

    const filterTerm = searchParam.get('filter') ?? '';

    const updateFilter = useCallback(
        (newFilter: string) => {
            if (filterTerm !== newFilter) {
                setSearchParam({ filter: newFilter }, { replace: true });
            }
        },
        [setSearchParam, filterTerm]
    );

    return (
        <>
            <Box
                sx={{
                    maxWidth: 440,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 3,
                    marginBottom: 3,
                }}
            >
                <SearchBar
                    inputLabel={t('common.searchForBrand')}
                    onChange={updateFilter}
                    filter={filterTerm}
                />
            </Box>
            <BrandList brandFilter={filterTerm} />
        </>
    );
};

export default Brands;
