import { type ReactElement, type ReactNode, useMemo } from 'react';
import { FixedSizeList as List } from 'react-window';

import { Box } from '@mui/material';

import type { MasterProductWithIds } from '@xeris/pages/product/Common';

import { type ListData, type SectionListData } from '../types';

import { Section } from './Section';

export type ProductListViewProps<MP extends MasterProductWithIds> = {
    listData: ListData<MP>[];
    listItem: (product: MP) => ReactNode;
    sectionActions?: (section: SectionListData<MP>) => ReactNode;
    sectionInfo?: (section: SectionListData<MP>) => ReactNode;
    toggleSectionOpen: (id: string) => void;
    width: number;
    height: number;
};

export const ProductListView = <MP extends MasterProductWithIds>({
    listData,
    sectionActions,
    sectionInfo,
    toggleSectionOpen,
    width,
    listItem,
    height,
}: ProductListViewProps<MP>): ReactElement => {
    const hasSections = useMemo(
        () => listData.some((data) => data.type === 'section'),
        [listData]
    );

    return (
        <List
            width={width}
            height={height}
            itemData={listData}
            itemSize={64}
            itemCount={listData.length}
        >
            {({ style, data, index }) => {
                const element = data[index];

                if (element.type === 'section') {
                    const isFirst = index === 0;
                    const previous = data[index - 1];
                    const isAfterPreviousSection =
                        previous?.type === 'section' && !previous.isOpen;

                    return (
                        <div key={element.id} style={style}>
                            <Section
                                data={element}
                                actions={sectionActions}
                                info={sectionInfo}
                                isOpen={element.isOpen}
                                toggleSectionOpen={() =>
                                    toggleSectionOpen(element.id)
                                }
                                sx={{
                                    borderTopWidth:
                                        isFirst || isAfterPreviousSection
                                            ? 0
                                            : 1,
                                }}
                            />
                        </div>
                    );
                }

                return (
                    <div key={element.id} style={style}>
                        <Box
                            sx={{
                                padding: hasSections ? '0 12px' : 0,
                                borderRight: hasSections ? 1 : 0,
                                borderLeft: hasSections ? 1 : 0,
                                borderColor: 'divider',
                                backgroundColor: hasSections
                                    ? 'background.default'
                                    : 'background.paper',
                            }}
                        >
                            {listItem(element)}
                        </Box>
                    </div>
                );
            }}
        </List>
    );
};
