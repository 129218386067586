import { type ReactElement, type ReactNode } from 'react';

import { Box } from '@mui/material';

type MaterialFeaturePanelProps = {
    children?: ReactNode;
};

export const MaterialFeaturePanel = ({
    children,
}: MaterialFeaturePanelProps): ReactElement | null => {
    return (
        <Box
            sx={{
                marginBottom: 3,
                width: '100%',
                backgroundColor: 'background.paper',
            }}
        >
            {children}
        </Box>
    );
};
