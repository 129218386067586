import { type ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { MutationSnackbars } from '@xeris/components';
import { FormDrawer } from '@xeris/components/forms';
import { RadioCard } from '@xeris/components/forms/RadioCard/RadioCard';
import {
    AllInclusiveIcon,
    CloseIcon,
    ListIcon,
    SyncIcon,
} from '@xeris/components/icons';
import { accessApi } from '@xeris/pages/admin/Connections/api';

import { type AccessRuleType } from '../../types';

const types = [
    {
        id: 'None',
        icon: CloseIcon,
    },
    {
        id: 'BrandAccessRule',
        icon: AllInclusiveIcon,
    },
    {
        id: 'BrandGroupMarketAccessRule',
        icon: SyncIcon,
    },
    {
        id: 'BrandProductAccessRule',
        icon: ListIcon,
    },
] as const;

const emptyRule = {
    brandRule: null,
    groupRule: null,
    marketRule: null,
    organizationRule: null,
    brandProductRule: null,
    brandGroupMarketRule: null,
};

type TypeSelectorProps = {
    open: boolean;
    handleClose: () => void;
    brandId: string;
    activeType: AccessRuleType | null;
    productCount: number;
    tradingPartner: {
        id: string;
        name: string;
    };
    ruleIds: string[];
    setShowEdit: (value: 'products' | 'filter' | null) => void;
    setShowEditType: (value: boolean) => void;
    noMarketsOrGroups: boolean;
};

export const TypeSelector = ({
    open,
    handleClose,
    brandId,
    productCount,
    tradingPartner,
    activeType,
    ruleIds,
    setShowEdit,
    setShowEditType,
    noMarketsOrGroups,
}: TypeSelectorProps): ReactElement => {
    const { t } = useTranslation('administration');

    const { control, handleSubmit, watch } = useForm<{
        type: AccessRuleType | 'None';
    }>({
        defaultValues: {
            type: activeType ?? 'None',
        },
    });

    const selectedType = watch('type');

    const [setBrandAccess, { isLoading, isError, isSuccess }] =
        accessApi.useSetBrandAccessMutation();

    return (
        <>
            <FormDrawer
                cancelText={t('common.cancel')}
                title={t('connections.brandAccess.changeAccessType')}
                open={open}
                saveText={
                    ['None', 'BrandAccessRule'].includes(selectedType)
                        ? t('common.save')
                        : t('common.continue')
                }
                isLoading={isLoading}
                onClose={handleClose}
                onSubmit={handleSubmit(async (data) => {
                    const common = {
                        organizationId: tradingPartner.id,
                        ruleIdsToDelete: ruleIds,
                        deleteRules: ruleIds.length > 0,
                        addRule: true,
                    };

                    if (data.type === 'None') {
                        await setBrandAccess({
                            ...common,
                            addRule: false,
                            rule: emptyRule,
                        }).unwrap();

                        setShowEdit(null);
                    }
                    if (data.type === 'BrandGroupMarketAccessRule') {
                        setShowEdit('filter');
                    }
                    if (data.type === 'BrandProductAccessRule') {
                        setShowEdit('products');
                    }
                    if (data.type === 'BrandAccessRule') {
                        await setBrandAccess({
                            ...common,
                            rule: {
                                ...emptyRule,
                                brandRule: {
                                    targetBrand: brandId,
                                    action: 'ALLOW',
                                },
                            },
                        }).unwrap();
                        setShowEdit(null);
                    }

                    setShowEditType(false);
                })}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography>
                        {t('connections.brandAccess.selectAccessType', {
                            tradingPartnerName: tradingPartner.name,
                        })}
                    </Typography>
                    {types.map((type) => (
                        <RadioCard
                            key={type.id}
                            control={control}
                            fieldName={'type'}
                            disabled={
                                type.id === 'BrandGroupMarketAccessRule' &&
                                noMarketsOrGroups
                            }
                            value={type.id}
                            title={t(
                                `connections.accessTypes.${type.id}.title`
                            )}
                            icon={<type.icon />}
                        >
                            <Box
                                component={'ul'}
                                margin={0}
                                paddingInlineStart={4}
                            >
                                {type.id === 'BrandGroupMarketAccessRule' &&
                                    noMarketsOrGroups && (
                                        <Typography
                                            component={'li'}
                                            color={'error'}
                                            sx={{ marginBottom: 1 }}
                                        >
                                            {t('connections.noGroupsOrMarkets')}
                                        </Typography>
                                    )}
                                {t(
                                    `connections.accessTypes.${type.id}.descriptions`,
                                    { returnObjects: true, productCount }
                                ).map((description: string) => (
                                    <Typography
                                        component={'li'}
                                        key={description}
                                        sx={{ marginBottom: 1 }}
                                    >
                                        {description}
                                    </Typography>
                                ))}
                            </Box>
                        </RadioCard>
                    ))}
                </Box>
            </FormDrawer>
            <MutationSnackbars
                isError={isError}
                errorText={t('connections.brandAccess.updateAccessFailed')}
                isSuccess={isSuccess}
                successText={t('connections.brandAccess.updateAccessSucceeded')}
            />
        </>
    );
};
