import { type ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { SelectInput, TextInput } from '@xeris/components/forms';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';
import { importsApi } from '@xeris/pages/imports/api/importsApi';

import { StepWrapper } from '../components/StepWrapper';
import { type StepProps } from '../types';

const configureSourceSchema = yup
    .object({
        id: yup.string().required(),
        name: yup.string().required(),
        idColumn: yup.string().required(),
    })
    .required();

export type ConfigureSourceSchema = yup.InferType<typeof configureSourceSchema>;

type ConfigureSourceProps = StepProps;

export const ConfigureSource = ({
    header,
    handleClose,
    activeStep,
    data,
    setData,
}: ConfigureSourceProps): ReactElement => {
    const { t } = useTranslation('imports');

    const activeOrganizationId = useActiveOrganizationId();

    const [createSource, { isLoading }] = importsApi.useCreateSourceMutation();

    const { handleSubmit, control, getValues } = useForm({
        resolver: yupResolver(configureSourceSchema),
        defaultValues: data?.configure,
    });

    return (
        <StepWrapper
            title={t('sources.configureSource.configureSource')}
            handleClose={handleClose}
            activeStep={activeStep}
            header={header}
            isLoading={isLoading}
            onBack={() => setData({ configure: getValues() }, -1)}
            onSubmit={handleSubmit(async (newData) => {
                // TODO: Replace with new create source endpoint when backend is done
                await createSource({
                    id: newData.id,
                    name: newData.name,
                    organization: activeOrganizationId,
                }).unwrap();

                handleClose();
                setData(null);
            })}
        >
            <TextInput
                fieldName={'id'}
                control={control}
                label={t('sources.configureSource.id')}
            />
            <TextInput
                fieldName={'name'}
                control={control}
                label={t('sources.configureSource.name')}
            />
            <SelectInput
                control={control}
                fieldName={'idColumn'}
                options={
                    data?.file?.headerNames?.map((columnName) => ({
                        id: columnName,
                        label: columnName,
                    })) ?? []
                }
                label={t('sources.configureSource.idColumn')}
            />
        </StepWrapper>
    );
};
