export const search = (
    terms: string,
    ...data: (string | null | undefined)[]
): boolean => {
    return (
        !terms ||
        terms
            .toLowerCase()
            .split(' ')
            .every((term) =>
                data.some((d) => d && d.toLowerCase().includes(term))
            )
    );
};
