import { type ReactElement, type ReactNode } from 'react';

import {
    DialogTitle,
    Divider,
    Stepper,
    styled,
    Typography,
} from '@mui/material';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
    flexDirection: 'column',
}));

const StyledDivider = styled(Divider)(() => ({
    width: 'calc(100% + 48px)',
    marginInline: '-24px',
}));

const StyledStepper = styled(Stepper)(() => ({
    'width': '100%',
    'flex': 1,
    '> :first-of-type': { paddingInlineStart: 0 },
    '> :last-of-type': { paddingInlineEnd: 0 },
}));

export type DialogTitleWithStepperProps = {
    title: string;
    activeStep: number;
    children: ReactNode;
};

export const DialogTitleWithStepper = ({
    title,
    activeStep,
    children,
}: DialogTitleWithStepperProps): ReactElement => {
    return (
        // @ts-expect-error -- MUI type error
        <StyledDialogTitle component={'div'}>
            <Typography variant={'h2'}> {title}</Typography>
            <StyledDivider />
            <StyledStepper activeStep={activeStep}>{children}</StyledStepper>
        </StyledDialogTitle>
    );
};
