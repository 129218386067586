import { type ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { fileUploadApi } from '@xeris/api';
import { BrandCard, MutationSnackbars } from '@xeris/components';
import { FileInput, FormDrawer, TextInput } from '@xeris/components/forms';
import { PlusIcon } from '@xeris/components/icons';
import { brandApi } from '@xeris/pages/admin/api';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';

const imageSchema = yup.mixed<File>().required();

const newBrandSchema = yup
    .object({
        id: yup.string().required('required'),
        name: yup.string().required('required'),
        logo: yup.array().of(imageSchema).required(),
        featureImage: yup.array().of(imageSchema).required(),
    })
    .required();

export type NewBrandFormData = yup.InferType<typeof newBrandSchema>;

export const AddBrand = (): ReactElement | null => {
    const { t } = useTranslation('administration');

    const [isOpen, setIsOpen] = useState(false);

    const activeOrganizationId = useActiveOrganizationId();

    const [addBrandMutation, addBrandResult] = brandApi.useAddBrandMutation();
    const [getSignedUploadBrandUrl, { isLoading: signedUrlLoading }] =
        fileUploadApi.useGetSignedUploadBrandUrlMutation();
    const [uploadFile, { isLoading: uploadImageLoading }] =
        fileUploadApi.useUploadFileMutation();

    const { handleSubmit, control, reset, watch } = useForm<NewBrandFormData>({
        resolver: yupResolver(newBrandSchema),
        defaultValues: {
            id: '',
            name: '',
            logo: [],
            featureImage: [],
        },
    });

    if (!activeOrganizationId) {
        return null;
    }

    const handleSubmitBrand = async (
        newBrandData: NewBrandFormData
    ): Promise<void> => {
        const imageUrls: { logo: string | null; featureImage: string | null } =
            { logo: null, featureImage: null };

        if (newBrandData.logo[0]) {
            const { url, targetUrl } = await getSignedUploadBrandUrl({
                brandId: newBrandData.id,
                imageName: newBrandData.logo[0].name,
            }).unwrap();

            await uploadFile({
                url,
                file: newBrandData.logo[0],
            }).unwrap();

            imageUrls.logo = targetUrl;
        }

        if (newBrandData.featureImage[0]) {
            const { url, targetUrl } = await getSignedUploadBrandUrl({
                brandId: newBrandData.id,
                imageName: newBrandData.featureImage[0].name,
            }).unwrap();

            await uploadFile({
                url,
                file: newBrandData.featureImage[0],
            }).unwrap();

            imageUrls.featureImage = targetUrl;
        }

        await addBrandMutation({
            id: newBrandData.id,
            name: newBrandData.name,
            brandOwner: activeOrganizationId,
            theme: {
                colors: null,
                logo: imageUrls.logo,
                feature_image: imageUrls.featureImage,
            },
        }).unwrap();

        setIsOpen(false);
        reset();
    };

    const name = watch('name');
    const logo = watch('logo')[0];
    const featureImage = watch('featureImage')[0];

    const logoUrl = logo ? URL.createObjectURL(logo) : null;
    const featureImageUrl = featureImage
        ? URL.createObjectURL(featureImage)
        : null;

    return (
        <>
            <Button
                variant={'contained'}
                startIcon={<PlusIcon color="white" />}
                onClick={(): void => setIsOpen(true)}
            >
                {t(`brand.addBrand.addNewBrand`)}
            </Button>
            <FormDrawer
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title={t(`brand.addBrand.addNewBrandLong`)}
                onSubmit={handleSubmit(handleSubmitBrand)}
                saveText={t('brand.common.create')}
                cancelText={t('common.cancel')}
                isLoading={
                    addBrandResult.isLoading ||
                    signedUrlLoading ||
                    uploadImageLoading
                }
            >
                <TextInput
                    label={t('brand.addBrand.id')}
                    fieldName={'id'}
                    control={control}
                    required
                    showError
                    autoFocus
                    placeholder={t('brand.addBrand.idPlaceholder')}
                />
                <TextInput
                    label={t('brand.addBrand.name')}
                    fieldName={'name'}
                    control={control}
                    required
                    showError
                    placeholder={t('brand.addBrand.namePlaceholder')}
                />
                <FileInput
                    label={t('brand.common.logo')}
                    fieldName={'logo'}
                    control={control}
                    showError
                />
                <FileInput
                    label={t('brand.common.featureImage')}
                    fieldName={'featureImage'}
                    control={control}
                    showError
                />
                <Typography variant={'h3'} marginBlock={1.5}>
                    {t('brand.common.preview')}
                </Typography>
                <BrandCard
                    href={'.'}
                    name={name}
                    logo={logoUrl}
                    featureImage={featureImageUrl}
                    sx={{ height: '300px' }}
                />
            </FormDrawer>
            <MutationSnackbars
                isSuccess={addBrandResult.isSuccess}
                isError={addBrandResult.isError}
                errorText={t('brand.addBrand.errorOnAdd')}
                successText={t('brand.addBrand.addBrandSuccess')}
            />
        </>
    );
};
