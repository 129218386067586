import {
    type ReactElement,
    type ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorPage } from '@xeris/components';
import { useDataLanguage } from '@xeris/hooks';
import { datasetProductsApi, datasetsApi } from '@xeris/pages/dataset/api';
import { useActiveDataset } from '@xeris/pages/dataset/hooks';
import { datasetActions } from '@xeris/pages/dataset/reducers';
import { type MasterProduct } from '@xeris/pages/product/types';
import { useAppDispatch } from '@xeris/reducers';

import {
    ContentForm,
    DialogWrapper,
    Footer,
    InlineDialogWrapper,
    Loader,
} from './components';
import { type HandleAddToDatasetData, type SelectedDataRefType } from './types';
import {
    getMasterProductDataSelection,
    getOptionalProductData,
} from './utilities';

const getSuggestedConfigurationSetName = (
    masterProduct: MasterProduct<'name' | 'selectedData'> | null
): string => {
    if (!masterProduct) return '';

    const configurationSetNames =
        masterProduct.selectedData?.configurationSets.map(({ name }) => name);

    for (let index = 1; ; index++) {
        const candidateName = `${masterProduct.name} ${index}`;

        if (!configurationSetNames?.includes(candidateName)) {
            return candidateName;
        }
    }
};

const initialNewDataset = {
    name: '',
    description: '',
    isNameValid: false,
    attemptedSubmitted: false,
    showForm: false,
};

type ConfigurableProductDataSelectorProps = {
    masterProductId?: string;
    masterProduct?: MasterProduct<
        'name' | 'images' | 'brand' | 'selectedData' | 'documents' | 'models'
    > | null;
    configurationSetId?: string;
    datasetId?: string;
    selectedFeatures?: { id: string; options: { id: string }[] }[];
    customContentHeader?: ReactNode;
    customPanels?: ReactNode;
    isInlineDialog?: boolean;
    handleSelectionClose: () => void;
};

const ConfigurableProductDataSelector = ({
    selectedFeatures,
    customContentHeader,
    customPanels,
    isInlineDialog,
    handleSelectionClose,
    masterProduct: givenMasterProduct,
    masterProductId,
    configurationSetId,
    datasetId,
}: ConfigurableProductDataSelectorProps): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');
    const dataLanguage = useDataLanguage();

    const selectedData = useRef<SelectedDataRefType>({});

    const [createDataset, createDatasetResult] =
        datasetsApi.useCreateDatasetMutation();

    const [addProductsToDataset, addProductsToDatasetResult] =
        datasetProductsApi.useAddProductsToDatasetMutation();

    const [addConfigurationSet, addConfigurationSetResult] =
        datasetProductsApi.useAddConfigurationSetMutation();

    const [updateConfigurationSet, updateConfigurationSetResult] =
        datasetProductsApi.useUpdateConfigurationSetMutation();

    const [isDialogOpen, setIsDialogOpen] = useState(true);

    const [newDataset, setNewDataset] = useState({ ...initialNewDataset });

    const activeDataset = useActiveDataset();

    const [activeDatasetId, setActiveDatasetId] = useState<string | null>(
        datasetId ?? activeDataset?.id ?? null
    );

    const { data, isFetching, isError, refetch } =
        datasetProductsApi.useGetMasterProductWithSelectedDataQuery(
            {
                datasetId: activeDatasetId ?? '',
                hasDatasetId: !!activeDatasetId,
                masterProductId: masterProductId ?? '',
                dataLanguage,
            },
            { skip: !masterProductId || !!givenMasterProduct }
        );

    const masterProduct = givenMasterProduct ?? data?.masterProduct;

    const configurationSet =
        masterProduct?.selectedData?.configurationSets.find(
            (c) => c.id === configurationSetId
        );

    const dataSelection = useMemo(
        () => getMasterProductDataSelection(masterProduct, configurationSetId),
        [masterProduct, configurationSetId]
    );

    const products = useMemo(
        () => (masterProduct ? [masterProduct] : []),
        [masterProduct]
    );

    useEffect(() => {
        if (!activeDataset) {
            setNewDataset((previousState) => ({
                ...previousState,
                showForm: true,
            }));
        }
    }, [activeDataset]);

    const setActiveDataset = useCallback(
        (newDatasetId: string | null) => setActiveDatasetId(newDatasetId),
        [setActiveDatasetId]
    );

    const handleCloseDialog = useCallback(() => {
        if (handleSelectionClose) handleSelectionClose();
        setIsDialogOpen(false);
    }, [handleSelectionClose]);

    const handleAddToDataset = useCallback(
        async ({ configurationSetName = '' }: HandleAddToDatasetData) => {
            if (!masterProduct) {
                return Promise.resolve();
            }

            if (newDataset.showForm && !newDataset.isNameValid) {
                setNewDataset({ ...newDataset, attemptedSubmitted: true });
                return Promise.resolve();
            }

            const optionalProductData = getOptionalProductData(
                selectedData.current[masterProduct.id]
            );

            if (newDataset.showForm) {
                // Create a new dataset with this product
                return createDataset({
                    name: newDataset.name,
                    description: newDataset.description,
                    selected_products: [],
                    selected_master_products: [
                        {
                            id: masterProduct.id,
                            ...optionalProductData,
                            configuration_sets: selectedFeatures
                                ? [
                                      {
                                          name: configurationSetName,
                                          views: null,
                                          features: selectedFeatures,
                                          ...optionalProductData,
                                      },
                                  ]
                                : [],
                        },
                    ],
                }).then((response) => {
                    if ('data' in response && response.data?.dataset.create) {
                        setNewDataset({ ...initialNewDataset });
                        dispatch(
                            datasetActions.changeActiveDataset(
                                response.data.dataset.create.id
                            )
                        );
                    }
                });
            }

            if (!activeDatasetId) {
                return Promise.resolve();
            }

            if (!selectedFeatures) {
                // Add a product to the dataset
                return addProductsToDataset({
                    data: {
                        id: activeDatasetId,
                        selected_products: null,
                        selected_master_products: [
                            {
                                id: masterProduct.id,
                                configuration_sets: [],
                                ...optionalProductData,
                            },
                        ],
                    },
                    removeProducts: false,
                    datasetId: activeDatasetId,
                    productIdsToRemove: null,
                });
            }

            if (configurationSetId) {
                // Update configuration set
                return updateConfigurationSet({
                    id: configurationSetId,
                    datasetId: activeDatasetId,
                    configurationSet: {
                        name: configurationSetName,
                        views: null,
                        features: selectedFeatures,
                        ...optionalProductData,
                    },
                });
            }

            if (datasetId) {
                // Add a new configuration set when product is in dataset
                return addConfigurationSet({
                    datasetId: activeDatasetId,
                    masterProductId: masterProduct.id,
                    configurationSet: {
                        name: configurationSetName,
                        views: null,
                        features: selectedFeatures,
                        ...optionalProductData,
                    },
                });
            }

            // Add a product to the dataset with configured features
            return addProductsToDataset({
                datasetId: activeDatasetId,
                productIdsToRemove: null,
                removeProducts: false,
                data: {
                    id: activeDatasetId,
                    selected_products: null,
                    selected_master_products: [
                        {
                            id: masterProduct.id,
                            ...optionalProductData,
                            configuration_sets: [
                                {
                                    name: configurationSetName,
                                    views: null,
                                    features: selectedFeatures,
                                    ...optionalProductData,
                                },
                            ],
                        },
                    ],
                },
            });
        },
        [
            activeDatasetId,
            addConfigurationSet,
            addProductsToDataset,
            configurationSetId,
            createDataset,
            datasetId,
            dispatch,
            masterProduct,
            newDataset,
            selectedFeatures,
            updateConfigurationSet,
        ]
    );

    const Wrapper = isInlineDialog ? InlineDialogWrapper : DialogWrapper;

    useEffect(() => {
        if (
            createDatasetResult.isSuccess ||
            addProductsToDatasetResult.isSuccess ||
            addConfigurationSetResult.isSuccess ||
            updateConfigurationSetResult.isSuccess
        ) {
            handleCloseDialog();
        }
    }, [
        createDatasetResult,
        addProductsToDatasetResult,
        addConfigurationSetResult,
        updateConfigurationSetResult,
        dispatch,
        activeDatasetId,
        handleCloseDialog,
    ]);

    return (
        <Wrapper
            isDialogOpen={isDialogOpen}
            handleClose={handleCloseDialog}
            footer={
                <Footer
                    isInlineDialog={!!isInlineDialog}
                    handleAddToDataset={handleAddToDataset}
                    handleClose={handleCloseDialog}
                    newDataset={newDataset}
                    handleSetNewDataset={setNewDataset}
                    activeDatasetName={activeDataset?.name}
                    setActiveDatasetId={setActiveDataset}
                    activeDatasetId={activeDatasetId}
                    isEditing={!!configurationSetId}
                    datasetSelectorDisabled={!!datasetId}
                    configurationSetName={
                        configurationSet?.name ??
                        getSuggestedConfigurationSetName(masterProduct ?? null)
                    }
                    isError={
                        createDatasetResult.isError ||
                        addProductsToDatasetResult.isError ||
                        addConfigurationSetResult.isError ||
                        updateConfigurationSetResult.isError
                    }
                />
            }
        >
            {isFetching && <Loader />}
            {!isFetching && isError && (
                <ErrorPage
                    title={t('errors.failedToLoad')}
                    actionText={t('errors.tryAgain')}
                    onClick={() => refetch()}
                />
            )}
            {!isFetching && !isError && masterProduct && (
                <ContentForm
                    selectedDataRef={selectedData}
                    data={dataSelection}
                    products={products}
                    customContentHeader={customContentHeader}
                    newDataset={newDataset}
                    handleSetNewDataset={setNewDataset}
                />
            )}
            {customPanels}
        </Wrapper>
    );
};

export default ConfigurableProductDataSelector;
