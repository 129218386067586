import { type ReactElement } from 'react';

import ToolBar from '@xeris/pages/product/Common/Datasheet/Toolbar';
import {
    type MasterProduct,
    type Product,
    type ProductDataList,
} from '@xeris/pages/product/types';

import { getCollapsePanelListId } from '../utilities';

import ProductDataFactory from './ProductDataFactory/ProductDataFactory';
import { DataErrors } from './DataErrors';

type ProductDataProps = {
    productEntity?: Product<'name'> | MasterProduct<'name'>;
    productData?: ProductDataList;
};

const ProductData = ({
    productEntity,
    productData,
}: ProductDataProps): ReactElement | null => {
    const panelListId = productEntity
        ? getCollapsePanelListId(productEntity.id, productEntity.name)
        : '';

    if (!productEntity || !productData) {
        return null;
    }

    return (
        <>
            <ToolBar productEntity={productEntity} />
            <DataErrors productEntity={productEntity} />
            {productData.map((productPanelData) => (
                <ProductDataFactory
                    productData={productPanelData}
                    key={productPanelData.type}
                    panelListId={panelListId}
                />
            ))}
        </>
    );
};

export default ProductData;
