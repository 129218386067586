import { type ReactElement, type SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
    Box,
    Divider,
    IconButton,
    type IconButtonProps,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    Tooltip,
} from '@mui/material';

import { MuiButton, Typography } from '@xeris/components';
import {
    CloseIcon,
    DownIcon,
    EditIcon,
    PlusIcon,
    SettingsIcon,
} from '@xeris/components/icons';
import {
    useActiveDatasetId,
    useConfigurationSets,
} from '@xeris/pages/dataset/hooks';

import { DeleteConfigurationDialog } from './DeleteConfigurationDialog';

type EditConfigurationMenuProps = {
    masterProductId: string;
    brandId: string;
    size: IconButtonProps['size'];
};
const EditConfigurationMenu = ({
    masterProductId,
    brandId,
    size,
}: EditConfigurationMenuProps): ReactElement => {
    const { t } = useTranslation('product');
    const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);
    const [selectedConfiguration, setSelectedConfiguration] = useState<{
        id: string;
        name: string;
    } | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const activeDatasetId = useActiveDatasetId();
    const configurationSets = useConfigurationSets(masterProductId);

    return (
        <>
            <MuiButton
                variant={'outlinedSolid'}
                round
                size={size}
                onClick={(event) => {
                    event.stopPropagation();
                    setMenuAnchorEl((current) =>
                        current ? null : event.currentTarget
                    );
                }}
            >
                <Box sx={{ display: 'flex', padding: 1 }}>
                    <EditIcon />
                    <DownIcon />
                </Box>
            </MuiButton>
            <Menu
                open={!!menuAnchorEl}
                anchorEl={menuAnchorEl}
                onClose={(event: SyntheticEvent): void => {
                    event.stopPropagation();
                    setMenuAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {configurationSets.map(({ id, name }) => (
                    <ListItem
                        key={id}
                        sx={{ paddingRight: '124px', maxWidth: '450px' }}
                    >
                        <ListItemText>
                            <Typography maxLines={2}>{name}</Typography>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <Tooltip
                                title={t(
                                    'editConfigurationMenu.changeDataSelection'
                                )}
                            >
                                <IconButton
                                    size={'small'}
                                    component={Link}
                                    to={`/Products/${brandId}/MasterProducts/${masterProductId}/Configure/${activeDatasetId}/${id}`}
                                    state={{ page: 'selectData' }}
                                >
                                    <EditIcon fontSize={'small'} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title={t('editConfigurationMenu.configure')}
                            >
                                <IconButton
                                    size={'small'}
                                    component={Link}
                                    to={`/Products/${brandId}/MasterProducts/${masterProductId}/Configure/${activeDatasetId}/${id}`}
                                >
                                    <SettingsIcon fontSize={'small'} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title={t(
                                    'editConfigurationMenu.removeConfiguration'
                                )}
                            >
                                <IconButton
                                    size={'small'}
                                    edge="end"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setSelectedConfiguration({ id, name });
                                        setDeleteDialogOpen(true);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
                {configurationSets.length > 0 && <Divider sx={{ m: 1 }} />}
                <ListItemButton
                    component={Link}
                    to={`/Products/${brandId}/MasterProducts/${masterProductId}/Configure/${activeDatasetId}`}
                >
                    <ListItemIcon>
                        <PlusIcon color={'primary'} />
                    </ListItemIcon>
                    <ListItemText>
                        {t('editConfigurationMenu.newConfiguration')}
                    </ListItemText>
                </ListItemButton>
                <Divider sx={{ m: 1 }} />
                <ListItemButton
                    onClick={(event) => {
                        event.stopPropagation();
                        setSelectedConfiguration(null);
                        setDeleteDialogOpen(true);
                    }}
                >
                    <ListItemIcon>
                        <CloseIcon />
                    </ListItemIcon>
                    <ListItemText>
                        {t('editConfigurationMenu.removeFromDataset')}
                    </ListItemText>
                </ListItemButton>
            </Menu>
            <DeleteConfigurationDialog
                isOpen={deleteDialogOpen}
                handleCloseDialog={() => setDeleteDialogOpen(false)}
                configurationSetName={
                    selectedConfiguration?.name ??
                    t('common.deleteDialog.allConfigurations')
                }
                selectedConfiguration={selectedConfiguration}
                masterProductId={masterProductId}
            />
        </>
    );
};

export default EditConfigurationMenu;
