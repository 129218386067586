import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';

import { MuiButton } from '@xeris/components';
import { DeleteIcon } from '@xeris/components/icons';
import { connectionsApi } from '@xeris/pages/admin/api';

type DeleteConnectionDialogProps = {
    isOpen: boolean;
    connection?: string;
    handleCloseDialog: () => void;
};

const DeleteConnectionDialog = ({
    isOpen,
    connection,
    handleCloseDialog,
}: DeleteConnectionDialogProps): ReactElement => {
    const { t } = useTranslation('administration');

    const [deleteConnectionMutation] =
        connectionsApi.useDeleteConnectionMutation();

    const handleDeleteConnection = async (): Promise<void> => {
        if (!connection) {
            return;
        }
        await deleteConnectionMutation({ connectionId: connection }).unwrap();

        handleCloseDialog();
    };

    return (
        <Dialog open={isOpen} onClose={handleCloseDialog}>
            <DialogTitle>{t('connections.removeConnection.title')}</DialogTitle>

            <DialogContent>
                {t('connections.removeConnection.description')}
            </DialogContent>

            <DialogActions>
                <MuiButton onClick={handleCloseDialog} color="secondary">
                    {t('common.cancel')}
                </MuiButton>
                <MuiButton
                    onClick={handleDeleteConnection}
                    startIcon={<DeleteIcon />}
                    variant={'contained'}
                    color="error"
                >
                    {t('common.delete')}
                </MuiButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConnectionDialog;
