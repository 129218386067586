import { type ReactElement } from 'react';

import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    Typography,
} from '@mui/material';

import { AttributeName } from '@xeris/pages/imports/pages/Mapping/components/AttributeName';
import { type AttributeType } from '@xeris/pages/imports/types';
import { type Path } from '@xeris/pages/imports/utilities/updateMapping';

import { AttributeTypeField } from '../../components/AttributeTypeField';

type ContentProps = {
    type: AttributeType | null;
    fields: { label: string; path: string }[];
    noTitle?: true;
    path: Path;
};

const Content = ({
    type,
    path,
    fields,
    noTitle,
}: ContentProps): ReactElement | null => {
    if (!type) {
        return null;
    }

    if (type.__typename === 'ReferenceAttributeType') {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {!noTitle && (
                    <Typography variant={'h6'} component={'p'}>
                        <AttributeName attribute={type} />
                    </Typography>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: noTitle ? 0 : 2,
                        borderLeft: noTitle ? 0 : 1,
                        gap: 4,
                    }}
                >
                    {type.entity.attributes.map((attribute) => (
                        <Content
                            key={attribute.id}
                            type={attribute}
                            fields={fields}
                            path={[
                                ...path,
                                { attributeId: attribute.id, index: 0 },
                            ]}
                        />
                    ))}
                </Box>
            </Box>
        );
    }

    return (
        <AttributeTypeField
            key={type.id}
            attribute={type}
            fields={fields}
            path={path}
        />
    );
};

type AttributeFormProps = {
    isOpen: boolean;
    handleClose: () => void;
    type: AttributeType | null;
    fields: { label: string; path: string }[];
};

export const AttributeForm = ({
    isOpen,
    handleClose,
    type,
    fields,
}: AttributeFormProps): ReactElement => {
    return (
        <Drawer open={isOpen} onClose={handleClose}>
            <DialogTitle>{type?.name}</DialogTitle>
            <DialogContent
                sx={{
                    maxWidth: '1000px',
                    width: '100vw',
                }}
                dividers
            >
                <Box
                    sx={{
                        gap: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '1rem 0',
                    }}
                >
                    <Content
                        type={type}
                        fields={fields}
                        noTitle
                        path={[{ attributeId: type?.id ?? '', index: 0 }]}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color={'secondary'} onClick={handleClose}>
                    {'Close'}
                </Button>
            </DialogActions>
        </Drawer>
    );
};
