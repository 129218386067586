import { verifyUserRtkApi } from '@xeris/reducers/verifyUserApi';

type Empty = { [key: string]: never };
type UserToken = {
    username: string;
    token: string;
    expires: string;
};

type ApiLogin = UserToken;
type ApiLoginVariables = {
    username: string;
    password: string;
    remember?: boolean;
};

type ApiLogout = Empty;
type ApiLogoutVariables = Empty;

type ApiImpersonate = UserToken;
type ApiImpersonateVariables = {
    userId: string;
};

type ApiRefreshToken = UserToken;
type ApiRefreshTokenVariables = Empty;

export const loginApi = verifyUserRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<ApiLogin, ApiLoginVariables>({
            query: (body) => ({
                method: 'POST',
                url: '',
                body: body,
            }),
        }),
        logout: builder.mutation<ApiLogout, ApiLogoutVariables>({
            query: () => ({
                method: 'DELETE',
                url: '',
            }),
        }),
        impersonate: builder.mutation<ApiImpersonate, ApiImpersonateVariables>({
            query: ({ userId }) => ({
                method: 'GET',
                url: `/debug/impersonate?id=${userId}`,
            }),
        }),
        refreshToken: builder.mutation<
            ApiRefreshToken,
            ApiRefreshTokenVariables
        >({
            query: () => ({
                method: 'GET',
                url: '',
            }),
        }),
    }),
});
